import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { CompetencyStandard } from '../../../models/competency-standard.model';
import { CompetencyStandardStorageService } from '../../../services/competency-standard-storage.service';
import { CompetencyStandardService } from '../../../services/competency-standard.service';
import { AddCsGenericComponent } from '../add-cs-generic/add-cs-generic.component';

@Component({
  selector: 'app-cs-generic-table',
  templateUrl: './cs-generic-table.component.html',
  styleUrls: ['./cs-generic-table.component.scss'],
})
export class CsGenericTableComponent implements OnInit, OnDestroy {
  @Input() tamMainId: number | null;
  @Input() createButton: boolean;
  showingAction: boolean | null = false;
  total: number;
  page = 1;
  size = 10;

  modalRef: NzModalRef;
  csList: CompetencyStandard[] = [];
  csListFiltered: CompetencyStandard[] = [];
  onCsGenericSubscription$: Subscription;


  constructor(
    private modal: NzModalService,
    private csService: CompetencyStandardService,
    private competencyStandardStorageService: CompetencyStandardStorageService,
    private notification: NzNotificationService,
    private route: ActivatedRoute
  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    const urlPath = String(this.route.snapshot.url[0].path);
    (urlPath.includes("tam-view")) ? this.showingAction = true : this.showingAction = false ;
    this.csList = this.csService.getCsGenericList(Number(this.tamMainId));
    this.csListFiltered = [...this.csList];
    if (this.csList.length === 0) {
      this.competencyStandardStorageService.readCsGenericListByTamId(Number(this.tamMainId)).subscribe();
    }
    this.onCsGenericSubscription$ =
      this.csService.onRefreshCsGenericList.subscribe(
        (res) => {
          this.csList = res;
          this.csListFiltered = [...this.csList];
        }
      );
  }

  ngOnInit(): void {
    this.loadManagementFromServer();
  }

  loadManagementFromServer() {
      if (!this.tamMainId) {
        this.notification.error(
          'Error',
          'Training and Assessment Material Id not found'
        );
        return;
      }
    // this.csList = this.csService.getCsGenericList(Number(this.tamMainId));

    // if (this.csList.length === 0) {
    //   this.competencyStandardStorageService
    //     .readCsGenericListByTamId(Number(this.tamMainId))
    //     .subscribe({
    //       next: (res) => {
    //         console.log("res++++++++++++++++++", res);
    //         this.csList = res;
    //       },
    //     });
    // }

    // this.onCsGenericSubscription$ =
    //   this.csService.onRefreshCsGenericList.subscribe((res) => {
    //     this.csList = res.filter(
    //       (item) =>
    //         item.tamMainId == this.tamMainId ||
    //         item.tamMain.id == this.tamMainId
    //     );

    //     if (this.modalRef) {
    //       this.modalRef.close();
    //     }
    //   });
  }

  createCustomButtonModal(data? : any): void {
    this.modalRef = this.modal.create({
      nzContent: AddCsGenericComponent,
      nzClosable: false,
      nzOkText: null,
      nzWidth: 1000,
      nzComponentParams: {
        InputData: data
      }
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    const { pageSize, pageIndex, sort } = params;
    this.loadManagementFromServer();
  }


  onEdit(id: number): void {
    this.csService.setEditingCsGenericId(id);
    this.createCustomButtonModal(id);
  }

  onDelete(id: number): void {
    this.competencyStandardStorageService.deleteCsGeneric(id).subscribe({
      next: (response) => {
        this.notification.success('Success!', 'Staff deleted successfully');
        this.loadManagementFromServer();
      },
      error: (error) => this.notification.error('Failed!', error),
    });
  }
  
  ngOnDestroy(): void {
    this.onCsGenericSubscription$.unsubscribe();
  }
}
