import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CadLearningMaterial } from '../../../models/cad-learning-material.model';
import { CourseAccreditationDocumentStorageService } from '../../../services/course-accreditation-document-storage.service';
import {UtilityService} from '../../../../../shared/services/utility.service';
import * as XLSX from 'xlsx';
import {CadTrainerAndStaff} from '../../../models/cad-trainer-and-staff.model';
import { CourseAccreditationDocumentService } from '../../../services/course-accreditation-document.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-cad-learning-material',
  templateUrl: './add-cad-learning-material.component.html',
  styleUrls: ['./add-cad-learning-material.component.scss']
})
export class AddCadLearningMaterialComponent implements OnInit {


  @Input() InputData: any;
  isEditMode: boolean = false;
  singleCad: CadLearningMaterial;
  onStartSingleCadEdit$: Subscription;
  onEditingSingleCadId!: number | null;
  cadForm: FormGroup;
  editedItem!: any;
  editedItemId!: number;
  cadList: CadLearningMaterial[] = [];
  tamMainId: number;
  onCadLearningMaterialSubscription$: Subscription;


  // cadForm: FormGroup;
  // tamMainId: string | null;
  excelData: any[] = [];
  userFile: any = File;
  allowedExtensions = '.xlsx,.xls';
  maxFormatFileSize = 3;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  total: number;
  page = 1;
  size = 10;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cadService: CourseAccreditationDocumentService,
    private cadStorageService: CourseAccreditationDocumentStorageService,
    private notification: NzNotificationService,
    private utilityService: UtilityService
  ) { 
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
  this.onEditingSingleCadId = this.cadService.getEditingSingleCadLearningMaterialId();
  this.onStartSingleCadEdit$ =
    this.cadService.onStartCadLearningMaterialEditing.subscribe(
      (res: number | null) => {
        this.onEditingSingleCadId = res;
      }
    );
  this.cadList = this.cadService.getCadLearningMaterialList(this.tamMainId);
  if (this.cadList.length === 0) {
    this.cadStorageService.readCadLearningMaterialListByTamId(this.tamMainId, this.page, this.size).subscribe();
  }
  this.onCadLearningMaterialSubscription$ =
    this.cadService.onRefreshCadLearningMaterialList.subscribe((res) => {
      this.cadList = res;
    });
  }

  ngOnInit(): void {
    console.log(this.InputData);
    this.createCadForm();
    this.editedItemId = this.InputData;
    this.isEditMode = true;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.cadService.getCadLearningMaterialById(this.editedItemId);
    
    this.cadForm.patchValue(this.editedItem);
    // this.createCadForm();
    // this.tamMainId = this.route.snapshot.queryParamMap.get('tamMainId');

  }

  createCadForm() {
    this.cadForm = this.fb.group({
      learningMaterialsName: [],
      specification: [],
      unit: [],
      minQuantityReq: [0, [Validators.pattern("^[0-9]*$")]],
      remark: []
    });
  }

  resetForm(): void {
    this.cadForm.reset();
  }

  submitForm(): void {
    
    for (const key of Object.keys(this.cadForm.controls)) {
    this.cadForm.controls[key].markAsDirty();
    this.cadForm.controls[key].updateValueAndValidity();
  }
  this.singleCad = this.cadForm.value;
  this.singleCad.tamMainId = Number(this.tamMainId);
  const isFalsy = this.cadService.getNullableFormCheck(this.singleCad, this.tamMainId);
  if (isFalsy) {
    this.notification.error('Error', 'All input blank form submit is not allowed');
    return;
  }
  if (this.editedItemId != null) {
    this.cadStorageService.updateCadLearningMaterial(this.singleCad, this.editedItemId).subscribe({
      next: (response) =>
        this.notification.success('Success!', 'Learning Material update successfully'),
      error: (error) => this.notification.error('Failed', error),
    });
  }else {
    const cadData: CadLearningMaterial = this.cadForm.value;
    const isFalsy = this.cadService.getNullableFormCheck(cadData, this.tamMainId);
    if (isFalsy) {
      this.notification.error('Error', 'All input blank form submit is not allowed');
      return;
    }
    if(!this.tamMainId) {
      this.notification.error('Error', 'Training and Assessment Material Id not found');
      return;
    }

    cadData.tamMainId = Number(this.tamMainId);
    cadData.minQuantityReq = Number(cadData.minQuantityReq);

    this.cadStorageService
      .createCadLearningMaterial(cadData)
      .subscribe({
        next: (response) => {
          this.notification.success('Success','Added Learning Material data');
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });
      this.cadForm.reset();
    }
    }

  //#region Excel File Upload And Read Excel Data

  onSelectFile(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    this.userFile = file;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
      this.excelData = data;
      return data;
    };
  }

  submitExcelData(): void {
    const cadLearningMaterialList: CadLearningMaterial[] = [];

    if (this.myInputVariable.nativeElement.value == 0) {
      this.notification.error('Failed!', 'Please upload cad learning material excel file');
      return;
    } else {
      for (const item of this.excelData) {
        const cadLearningMaterial = new CadLearningMaterial();

        cadLearningMaterial.learningMaterialsName = item.LearningMaterialName;
        cadLearningMaterial.specification = item.VersionDetails;
        cadLearningMaterial.unit = item.Unit;
        cadLearningMaterial.minQuantityReq = item.MinimumQuantityRequired;
        cadLearningMaterial.remark = item.Remarks;
        cadLearningMaterial.tamMainId = Number(this.tamMainId);

        cadLearningMaterialList.push(cadLearningMaterial);

        const existItem = cadLearningMaterialList.filter(
          // tslint:disable-next-line: no-shadowed-variable
          (item) => item.learningMaterialsName == cadLearningMaterial.learningMaterialsName
        );

        for (let item of cadLearningMaterialList) {
          let msg = item.learningMaterialsName;
          if (isNaN(item.minQuantityReq)) {
            this.notification.error(
              'Failed',
              `Invalid minimum quantity requierment for learning material name:  ${msg}, Please correct and reupload!`
            );
            this.myInputVariable.nativeElement.value = '';
            return;
          }
        }

        if (existItem.length > 1) {
          this.notification.error(
            'Failed',
            'Duplicate learning materials name found in Excel, Please check!'
          );
          this.myInputVariable.nativeElement.value = '';
          return;
        }
      }
    }

    //#region Excel duplication value check

    // let existItem = this.excelData.filter(
    //   (item) => item.furnitureName == stpTrainer.furnitureName
    // );
    // if (existItem.length > 1) {
    //   this.notification.error(
    //     'Failed',
    //     'Duplicate Data found in Excel, Please check!'
    //   );
    //   return;
    // }
    this.cadStorageService
      .saveExcelCadLearningMaterialsData(cadLearningMaterialList)
      .subscribe({
        next: (response) => {
          this.notification.success(
            'Success!',
            'Cad Learning Material created successfully'
          );
        },
        error: (error) =>
          this.notification.error(
            'Failed',
            'Please! provide valid information'
          ),
      });

    this.myInputVariable.nativeElement.value = '';
    return;
  }

}
