import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';

@Component({
  selector: 'app-publish-certificate-video-demo',
  templateUrl: './publish-certificate-video-demo.component.html',
  styleUrls: ['./publish-certificate-video-demo.component.scss'],
})
export class PublishCertificateVideoDemoComponent implements OnInit {
  isShowTab: boolean = false;
  userInfo: any;
  constructor() {}

  ngOnInit(): void {}
  onEnd() {
    window.close();
  }
}
