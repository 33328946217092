<main>
  <div class="container-fluid banner_img">
    <div class="container">
      <!-- <div class="row">
        <div class="col-md-6 home-map">
          <img src="assets/images/Group 101.png" style="width: 300px" />
        </div>
        <div class="col-md-6 for-slide-text">
          <h1>Welcome to</h1>
          <h2>National Skills Portal (NSP)</h2>
          <br /><br />
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-12 text-center"> <!-- Center the text using Bootstrap's text-center class -->
            <h1 style="color: #fff;">Welcome to</h1>
            <h2>National Skills Portal (NSP)</h2>
        </div>
    </div>
    </div>
  </div>

  <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <!--div class="carousel-item active">
        <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>

        <div class="container">
          <div class="carousel-caption text-start">
            <h1>Example headline.</h1>
            <p>Some representative placeholder content for the first slide of the carousel.</p>
            <p><a class="btn btn-lg btn-primary" href="#">Sign up today</a></p>
          </div>
        </div>
      </div-->

      <!-- <div class="carousel-item active">

        <img src="assets/images/Group 274.png" width="100%" height="100%">
        <div class="container">

          <div class="carousel-caption text-end" style="padding-bottom:0px!important;">
            <div class="d-flex justify-content-between">
              <div class="p-2 bd-highlight">
                <img src="assets/images/Group 101.png" style="width:280px; ">
              </div>
              <div class="p-2 bd-highlight align-bottom for-slide-text">
                <h1>Welcome to</h1>
                <h2>National Skills Portal (NSP)</h2>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!--div class="carousel-item">
        <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>

        <div class="container">
          <div class="carousel-caption">
            <h1>Another example headline.</h1>
            <p>Some representative placeholder content for the second slide of the carousel.</p>
            <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p>
          </div>
        </div>
      </div-->
      <!--div class="carousel-item">
        <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>

        <div class="container">
          <div class="carousel-caption text-end">
            <h1>One more for good measure.</h1>
            <p>Some representative placeholder content for the third slide of this carousel.</p>
            <p><a class="btn btn-lg btn-primary" href="#">Browse gallery</a></p>
          </div>
        </div>
      </div-->
    </div>
    <!--button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button-->
  </div>

  <div class="container-fluid">
    <div class="row info">
      <div class="col-lg-4 d-flex flex-row">
        <div class="p-2"><img src="assets/images/Group 228.svg" /></div>
        <div class="p-2 brant-text">
          <h4>{{ totalNoOfApprovedStpRegisterCount }}</h4>
          Skills Training Providers Registered
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-row">
        <div class="p-2"><img src="assets/images/Group 228.svg" /></div>
        <div class="p-2 brant-text">
          <h4>{{ totalApprovedCourseCount }}</h4>
          STP Got Course Approval
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-row">
        <div class="p-2"><img src="assets/images/Group 228.svg" /></div>
        <div class="p-2 brant-text">
          <h4>{{ totalApprovedCenterCount }}</h4>
          STP Got Center Approval
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-row">
        <div class="p-2"><img src="assets/images/Group 114.svg" /></div>
        <div class="p-2 brant-text">
          <h4>{{ totalNoOfCertificatesCount }}</h4>
          Trainees Assessed As 'Competent'
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-row">
        <div class="p-2"><img src="assets/images/Group 112.svg" /></div>
        <div class="p-2 brant-text">
          <h4>{{ totalNoOfCSCount }}</h4>
          Competency Standards Developed
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-row">
        <div class="p-2"><img src="assets/images/Group 229.svg" /></div>
        <div class="p-2 brant-text">
          <h4>16</h4>
          Industry Skills Councils Registered
        </div>
      </div>
    </div>
  </div>

  <!--section box-->
  <div class="container text-center both-space">
    <h3>National Skills Portal Features</h3>
    <div class="row">
      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 159.svg" />
          <div class="box-title">Skills Training Providers (STP)</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/register"
            >Click To Apply</a
          >
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            routerLink="/public-stp-applications-list"
            >View List</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 174.svg" />
          <div class="box-title">
            Course and Assessment Center Accreditation
          </div>
          <!-- <a type="" class="btn btn-outline-primary rectanlge-btn-style" href="#">Development Ongoing</a> -->
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/login"
            >Click To Apply</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 176.svg" />
          <div class="box-title">Training Management</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            routerLink="/login"
            >Click To Login</a
          >
        </div>
      </div>
      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 181.svg" />
          <div class="box-title">Assessment & Certification</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            href="#"
            routerLink="/applicant-register"
            >Click to Apply</a
          >
        </div>
      </div>
      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 180.svg" />
          <div class="box-title">Policies, Guidelines & Documents</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            href="#"
            routerLink="/policy-guidelines/document-list"
            >View List</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 179.svg" />
          <div class="box-title">Training & Assessment Materials</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            routerLink="/tam-list"
            >View List</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 178.svg" />
          <div class="box-title">Assessor Pool</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/assessor-register"
            >Click To Apply</a
          >
          <!-- <a type="" class="btn btn-outline-primary rectanlge-btn-style" href="#">Development Ongoing</a> -->
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 175.svg" />
          <div class="box-title">National Action Plan Monitoring</div>
          <!-- <a type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/login"
            >Click To Apply</a> -->
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            (click)="NAPLogin()"
            >Click To Login</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 178.svg" />
          <div class="box-title">Events</div>
          <a type="" class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/login">Click To Login</a>
          <a type="" class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/event/public-dashboard">View List</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 182.svg" />
          <div class="box-title">Industry Skills Council (ISC)</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/login"
            >Click To Login</a
          >
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/industry-skills-council/published-isc-list"
            >View List</a
          >
        </div>
      </div>
      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 183.svg" />
          <div class="box-title">Research & Studies</div>
          <a
          *ngIf="isActiveExternalRegForm"
          type=""
          class="btn btn-outline-primary rectanlge-btn-style mr-1"
          routerLink="/external-user-register"
          >Click To Apply</a
          >
          <!-- [appRequiredPermission]="
          applicationPermissions.researchAndStudies.clickToApplyButton
        " -->
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/research-and-studies/list-public"
            >View List</a
          >
        </div>
      </div>
      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 178.svg" />
          <div class="box-title">Financing</div>
          <!-- <a type="" class="btn btn-outline-primary rectanlge-btn-style mr-1" routerLink="/login">Click To Apply</a> -->
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/financial/circular-list-public"
            >View List</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle feature">
          <img src="assets/images/Group 178.svg" />
          <div class="box-title">Mutual Recognition Arrangements (MRA)</div>
          <!-- <a type="" class="btn btn-outline-primary rectanlge-btn-style mr-1" routerLink="/login">Click To Apply</a> -->
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style mr-1"
            routerLink="/mra/mra-list-public"
            >View MRA/MOU</a>

        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle">
          <img src="assets/images/Group 184.svg" />
          <div class="box-title">Inspection, Coordination, & Monitoring</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            href="#"
            >Development Ongoing</a
          >
        </div>
      </div>

      <div class="col-lg-3 div-hover">
        <div class="col-lg-12 rectangle">
          <img src="assets/images/Group 159.svg" />
          <div class="box-title">Live Job Opportunities</div>
          <a
            type=""
            class="btn btn-outline-primary rectanlge-btn-style"
            href="#"
            >Development Ongoing</a
          >
        </div>
      </div>
    </div>
  </div>
  <!--section box end-->

  <!--section circel-->
  <div class="container-fluid box-circle-bg text-center">
    <h3>Key Functions of NSDA</h3>
    <div class="row">
      <div class="col-lg-4">
        <img src="assets/images/Group 271.svg" class="skills-hover img-fluid" />
        <img
          src="assets/images/Group 272.svg"
          class="skills-hover portal-image-up img-fluid"
        />
        <img
          src="assets/images/Group 273.svg"
          class="skills-hover portal-image-up img-fluid"
        />
      </div>
      <div class="col-lg-4">
        <img src="assets/images/Group 270.svg" class="skills-hover img-fluid" />
        <img
          src="assets/images/Group 269.svg"
          class="skills-hover portal-image-up img-fluid"
        />
        <img
          src="assets/images/Group 268.svg"
          class="skills-hover portal-image-up img-fluid"
        />
      </div>
      <div class="col-lg-4">
        <img src="assets/images/Group 265.svg" class="skills-hover img-fluid" />
        <img
          src="assets/images/Group 266.svg"
          class="skills-hover portal-image-up img-fluid"
        />
        <img
          src="assets/images/Group 267.svg"
          class="skills-hover portal-image-up img-fluid"
        />
      </div>
    </div>
  </div>
  <!--section circel-->

  <!--section content-->
  <div class="container-fluid section-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <img
            src="assets/images/about.png"
            width="260"
            style="margin-top: 40px"
          />
        </div>
        <div class="col-lg-8" style="text-align: justify">
          <h3>About NSDA and NSP</h3>
          Formed under the National Skills Development Authority (NSDA) act
          2018, NSDA has started its operation under the Prime Minister’s Office
          since 2019 with an aim to steer and coordinate all skills development
          activities of the country. Key activities of NSDA includes developing
          policy, action plan, strategies and guidelines; forecasting demand of
          skilled labor in domestic as well as overseas job market; identify
          skills gap; development of demand based training curricula; register
          skills training providers; monitoring quality of skills development
          trainings and programs; assessment and certification of the trainees;
          strengthening Industry Skills Council (ISC), raising awareness on
          skills training and establishing mutual recognition agreement with
          destination countries for the migrant skilled workers etc. Aligning
          with the “Digital Bangladesh-Vision 2021”, NSDA has developed this
          National Skills Portal (NSP) with an aim to systematically collect,
          organize, analyze and publish data on all indicators related to skills
          development ecosystem of the country. Through this NSP, NSDA is
          offering all its services online and thus increasing effectiveness,
          transparency and accountability of related stakeholders.
        </div>
      </div>
    </div>
  </div>
</main>
