import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { CompetencyStandard } from '../models/competency-standard.model';
import { CsGeneric } from '../models/cs-generic.model';
import { CsOccupationSpecific } from '../models/cs-occupation-specific.model';
import { CsSectorSpecific } from '../models/cs-sector-specific.model';

@Injectable({
  providedIn: 'root',
})
export class CompetencyStandardService {
  onRefreshCsGenericList = new Subject<CompetencyStandard[]>();
  csGenericList: CompetencyStandard[] = [];
  editingCsGenericId!: number | null;
  onStartCsGenericEditing = new Subject<number | null>();

  onRefreshCsSectorSpecificList = new Subject<CompetencyStandard[]>();
  csSectorSpecificList: CompetencyStandard[] = [];
  editingCsSectorSpecificId!: number | null;
  onStartCsSectorSpecificEditing = new Subject<number | null>();

  onRefreshCsOccupationSpecificList = new Subject<CompetencyStandard[]>();
  csOccupationSpecificList: CompetencyStandard[] = [];
  editingCsOccupationSpecificId!: number | null;
  onStartCsOccupationSpecificEditing = new Subject<number | null>();

  onRefreshCsList = new Subject<CompetencyStandard[]>();
  csList: CompetencyStandard[] = [];

  constructor() {}

  //#region CS Generic
  addCsGeneric(csGeneric: CsGeneric): void {
    this.csGenericList.push(csGeneric);
    this.onRefreshCsGenericList.next(this.csGenericList.slice());
  }

  getCsGenericList(tamMainId: number): CsGeneric[] {
    return this.csGenericList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCsGenericById(id: number | null): CsGeneric | undefined  {
    return this.csGenericList.find((item) => item.id === id );
  }

  getEditingSingleCsGenericId(): any {
    return this.editingCsGenericId;
  }
  
  getUpdateCsGenericList(): Observable<any> {
    return this.onRefreshCsGenericList.asObservable();
  }

  setCsGenericList(csGenericList: CsGeneric[]): void {
    this.csGenericList = csGenericList;
    this.onRefreshCsGenericList.next(this.csGenericList.slice());
  }
  
  setEditingCsGenericId(id: number): void {
    this.editingCsGenericId = id;
    this.onStartCsGenericEditing.next(id);
  }

  upatedCsGeneric(csGeneric: CsGeneric, id?: number): void {
    const index = this.csGenericList.findIndex((singleCs) => singleCs.id === id);
    this.csGenericList[index] = csGeneric;
    this.onRefreshCsGenericList.next(this.csGenericList.slice());
  }
  
  clearEditingCsGenericId(): void {
    this.editingCsGenericId = null;
    this.onStartCsGenericEditing.next(null);
  }

  deleteCsGeneric(id: number): void {
    this.csGenericList = this.csGenericList.filter(
      (csGenericList, _index) => {
        return csGenericList.id !== id;
      }
    );
    this.onRefreshCsGenericList.next(this.csGenericList.slice());
  }
   //#endregion

   //#region Sector Specific
  addCsSectorSpecific(csSectorSpecific: CsSectorSpecific): void {
    this.csSectorSpecificList.push(csSectorSpecific);
    this.onRefreshCsSectorSpecificList.next(this.csSectorSpecificList.slice());
  }

  getCsSectorSpecificList(tamMainId: number): CsSectorSpecific[] {
    return this.csSectorSpecificList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCsSectorSpecificById(id: number | null): CsSectorSpecific | undefined  {
    return this.csSectorSpecificList.find((item) => item.id === id );
  }

  getEditingSingleCsSectorSpecificId(): any {
    return this.editingCsSectorSpecificId;
  }
  
  getUpdateCsSectorSpecificList(): Observable<any> {
    return this.onRefreshCsSectorSpecificList.asObservable();
  }

  setCsSectorSpecificList(csSectorSpecificList: CsSectorSpecific[]): void {
    this.csSectorSpecificList = csSectorSpecificList;
    this.onRefreshCsSectorSpecificList.next(this.csSectorSpecificList.slice());
  }
  
  setEditingCsSectorSpecificId(id: number): void {
    this.editingCsSectorSpecificId = id;
    this.onStartCsSectorSpecificEditing.next(id);
  }

  upatedCsSectorSpecific(csSectorSpecific: CsSectorSpecific, id?: number): void {
    const index = this.csSectorSpecificList.findIndex((singleCs) => singleCs.id === id);
    this.csSectorSpecificList[index] = csSectorSpecific;
    this.onRefreshCsSectorSpecificList.next(this.csSectorSpecificList.slice());
  }
  
  clearEditingCsSectorSpecificId(): void {
    this.editingCsSectorSpecificId = null;
    this.onStartCsSectorSpecificEditing.next(null);
  }

  deleteCsSectorSpecific(id: number): void {
    this.csSectorSpecificList = this.csSectorSpecificList.filter(
      (csSectorSpecificList, _index) => {
        return csSectorSpecificList.id !== id;
      }
    );
    this.onRefreshCsSectorSpecificList.next(this.csSectorSpecificList.slice());
  }
   //#endregion

   //#region Occupation Specific

   addCsOccupationSpecific(csOccupationSpecific: CsOccupationSpecific): void {
    this.csOccupationSpecificList.push(csOccupationSpecific);
    this.onRefreshCsOccupationSpecificList.next(this.csOccupationSpecificList.slice());
  }

  getCsOccupationSpecificList(tamMainId: number): CsOccupationSpecific[] {
    return this.csOccupationSpecificList.filter(item => item.tamMainId == tamMainId);
  }
 
  getCsOccupationSpecificById(id: number | null): CsOccupationSpecific | undefined  {
    return this.csOccupationSpecificList.find((item) => item.id === id );
  }

  getEditingSingleCsOccupationSpecificId(): any {
    return this.editingCsOccupationSpecificId;
  }
  
  getUpdateCsOccupationSpecificList(): Observable<any> {
    return this.onRefreshCsOccupationSpecificList.asObservable();
  }

  setCsOccupationSpecificList(csOccupationSpecificList: CsOccupationSpecific[]): void {
    this.csOccupationSpecificList = csOccupationSpecificList;
    this.onRefreshCsOccupationSpecificList.next(this.csOccupationSpecificList.slice());
  }
  
  setEditingCsOccupationSpecificId(id: number): void {
    this.editingCsOccupationSpecificId = id;
    this.onStartCsOccupationSpecificEditing.next(id);
  }

  upatedCsOccupationSpecific(csOccupationSpecific: CsOccupationSpecific, id?: number): void {
    const index = this.csOccupationSpecificList.findIndex((singleCs) => singleCs.id === id);
    this.csOccupationSpecificList[index] = csOccupationSpecific;
    this.onRefreshCsOccupationSpecificList.next(this.csOccupationSpecificList.slice());
  }
  
  clearEditingCsOccupationSpecificId(): void {
    this.editingCsOccupationSpecificId = null;
    this.onStartCsOccupationSpecificEditing.next(null);
  }

  deleteCsOccupationSpecific(id: number): void {
    this.csOccupationSpecificList = this.csOccupationSpecificList.filter(
      (csOccupationSpecificList, _index) => {
        return csOccupationSpecificList.id !== id;
      }
    );
    this.onRefreshCsOccupationSpecificList.next(this.csOccupationSpecificList.slice());
  }





  // setCsOccupationSpecificList(csList: CompetencyStandard[]): void {
  //   this.csOccupationSpecificList = csList;
  //   this.onRefreshCsOccupationSpecificList.next(
  //     this.csOccupationSpecificList.slice()
  //   );
  // }

  // getCsOccupationSpecificList(tamMainId: number): CompetencyStandard[] {
  //   return this.csOccupationSpecificList.filter(
  //     (item) => item.tamMainId === tamMainId
  //   );
  // }
    
  // getCsOccupationSpecificById(id: number | null): CompetencyStandard | undefined  {
  //   return this.csOccupationSpecificList.find((item) => item.id === id );
  // }

  // addCsOccupationSepcific(csOccupationSpecific: CompetencyStandard): void {
  //   this.csOccupationSpecificList.push(csOccupationSpecific);
  //   this.onRefreshCsOccupationSpecificList.next(
  //     this.csOccupationSpecificList.slice()
  //   );
  // }

    
  // setEditingCsOccupationSpecificId(id: number): void {
  //   this.editingCsOccupationSpecificId = id;
  //   this.onStartCsOccupationSpecificEditing.next(id);
  // }

  
  // upatedCsOccupationSpecific(csOccupationSpecific: CompetencyStandard, id?: number): void {
  //   const index = this.csOccupationSpecificList.findIndex((singleCs) => singleCs.id === id);
  //   this.csOccupationSpecificList[index] = csOccupationSpecific;
  //   this.onRefreshCsOccupationSpecificList.next(this.csOccupationSpecificList.slice());
  // }
  
  // clearEditingCsOccupationSpecificId(): void {
  //   this.editingCsOccupationSpecificId = null;
  //   this.onStartCsOccupationSpecificEditing.next(null);
  // }

  // deleteCsOccupationSpecific(id: number): void {
  //   this.csOccupationSpecificList = this.csOccupationSpecificList.filter(
  //     (csOccupationSpecificList, _index) => {
  //       return csOccupationSpecificList.id !== id;
  //     }
  //   );
  //   this.onRefreshCsOccupationSpecificList.next(this.csOccupationSpecificList.slice());
  // }
 //#endregion

  setCsList(csList: CompetencyStandard[]): void {
    this.csList = csList;
    this.onRefreshCsList.next(this.csList.slice());
  }

  addCs(csList: CompetencyStandard[]): void {
    this.csList.push(...csList);
    this.onRefreshCsList.next(this.csList.slice());
  }

  getCsList(): CompetencyStandard[] {
    return this.csList;
  }
  addAllCsList(tamMainId: number): void {
    // this.addCs(this.getCsSectorSpecificList(tamMainId));
    // this.addCs(this.getCsSectorSpecificList(tamMainId));
    // this.addCs(this.getCsOccupationSpecificList(tamMainId));
    this.addCs(this.csGenericList);
    this.addCs(this.csSectorSpecificList);
    this.addCs(this.csOccupationSpecificList);
  }

  checkIfUnitCodeExists(
    unitCode: string
  ): Observable<CompetencyStandard[] | null> {
    return of(
      this.getCsList().filter(
        (f) => f.unitCode?.toLowerCase() === unitCode.toLowerCase()
      )
    ).pipe(delay(1000));
  }

  UnitCodeValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkIfUnitCodeExists(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          const existUnitCode = res?.length ? true : false;
          return existUnitCode ? { unitCodeExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

getNullableFormCheck(csData: any, tamMainId: number): boolean{
  const isFalsy =Object.values(csData).every((value: any) => {
    if (!value || !value.toString().trim() || value == 0 || value == tamMainId) {
      return true;
    }
    return false;
  });
  return isFalsy;
}
}
