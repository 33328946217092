// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //Prod Environment Setup
  baseApiUrl: 'http://103.92.84.241:9091/',
  stpRegistrationApiUrl: 'http://103.92.84.241:9191/api/',
  tamApiUrl: 'http://103.92.84.241:8501/api/',
  tciUrl: 'http://103.92.84.241:8701/api/',
  stpOperationApiUrl: 'http://103.92.84.241:8101/api/',
  fileServiceApiUrl: 'http://103.92.84.241:8401/',
  paymentUrl: 'http://103.92.84.241:8801/api/',
  napApiUrl: 'http://103.92.84.132:8888/api/nap-service/',
  pgApiUrl: 'http://103.92.84.241:8902/api/',
  eventApiUrl: 'http://103.92.84.132:8888/api/event-service/',
  commonServiceApiUrl: 'http://103.92.84.132:8901/api/',
  researchAndStudiesApiUrl: 'http://103.92.84.241:8889/api/',
  financeApiUrl: 'http://103.92.84.241:8903/api/financing/',



  //Stage Environment Setup
//   baseApiUrl: 'http://localhost:8201/',
//   stpRegistrationApiUrl: 'http://localhost:8301/api/',
//   tamApiUrl: 'http://localhost:8501/api/',
//   tciUrl:'http://localhost:8701/api/',
//   stpOperationApiUrl: 'http://localhost:8101/api/',
//   fileServiceApiUrl: 'http://localhost:8401/',
//   paymentUrl: 'http://103.92.84.241:8801/api/',
//   napApiUrl: 'http://103.92.84.132:8888/api/nap-service/',
//   pgApiUrl: 'http://localhost:8902/api/',
//   eventApiUrl:'http://103.92.84.132:8888/api/event-service/',
//   commonServiceApiUrl: 'http://localhost:8901/api/',
//   researchAndStudiesApiUrl: 'http://localhost:8889/api/',
//   financeApiUrl: 'http://localhost:8903/api/financing/',

  validation: {
    allowedPaymentExtensions: '.pdf,.png,.jpg,.jpeg',
    allowedExtensions: '.pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg',
    maxFileSize: 3,
  },
  autoLogoutTime: {
    inMinute: 30,
  },
  recaptcha: {
    siteKey: '6LfeCUchAAAAACKLeOFSzhIupKp1UegrfOJr28g8',
  },
  roles: {
    stpMasterId: 13,
    stpBasicId: 14,
  },
  language: {
    setDefaultLang: 'bn',
  },
  // roles: {
  //   stpMasterId: 10,
  //   stpBasicId: 11
  // }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
