import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { Designation } from '../models/designation.model';
import { Permission } from '../models/permission.model';
import { PermissionType } from '../models/permissionType.model';
import { ProjectModule } from '../models/project-module.model';
import { RolePermissionDto } from '../models/role-permission-dto';
import { Role } from '../models/role.model';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationStorageService {
  // private handleError: HandleError;
  constructor(
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService
  ) {
    // this.handleError = this.httpErrorHandler.createHandleError(
    //   'Authorization Service'
    // );
  }

  //#region PROJECT_MODULE
  readModule(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.module.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.authorizationService.setModules(response.data);
          }
        })
      );
  }

  readModuleById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.module.readById}` + id
    );
  }

  createModule(module: ProjectModule): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.module.create}`, module)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.authorizationService.addModule(response.data);
          }
        })
      );
  }

  deleteModule(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.module.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedModule) => {
          this.authorizationService.deleteModule(id);
        })
      );
  }

  updateModule(module: ProjectModule): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.module.update}` + module.id,
        module
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.authorizationService.upatedModule(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  readModules(): Observable<ProjectModule | ProjectModule[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.module.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data.sort(
            (a: ProjectModule, b: ProjectModule) => (a.name < b.name ? -1 : 1)
          );
        }),
        tap((modules: ProjectModule[]) => {
          this.authorizationService.setModules(modules);
        })
      );
  }
  //#endregion PROJECT_MODULE

  //#region DESIGNATION
  readDesignations(): Observable<Designation[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.designation.read}`)
      .pipe(
        // catchError(this.handleError('Read Designation', [])),
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((designation: Designation[]) => {
          this.authorizationService.setDesignations(designation);
        })
      );
  }

  readDesignationById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.designation.readById}` + id
    );
  }

  createDesignation(designation: Designation): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.designation.create}`,
        designation
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.authorizationService.addDesignation(res.data);
          }
        })
      );
  }

  deleteDesignation(id?: number | null): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.designation.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedRole) => {
          this.authorizationService.deleteDesignation(id);
        })
      );
  }

  updateDesignation(designation: Designation): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.designation.update}` + designation.id,
        designation
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.authorizationService.upatedDesignation(res.data, res.data.id);
          }
        })
      );
  }
  //#endregion DESIGNATION

  //#region PERMISSION
  readPermissions(
    pageIndex: number = 1,
    pageSize: number = 10,
    sortField: string | null = null,
    sortOrder: string | null = null,
    filters: Array<{ key: string; value: string[] }> = []
  ): Observable<Permission | Permission[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.permission.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((permissions: Permission[]) => {
          this.authorizationService.setPermissions(permissions);
        })
      );
  }

  readPermissionTypes(): Observable<PermissionType[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.permissionType.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((permissionTypes) => {
          this.authorizationService.setPermissionTypes(permissionTypes);
        })
      );
  }

  createPermission(permission: Permission): Observable<Permission> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.permission.create}`,
        this.authorizationService.convertPermissionToDTO(permission)
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedPermission) => {
          this.authorizationService.addPermission(updatedPermission);
        })
      );
  }

  updatePermission(id: number, permission: Permission): Observable<Permission> {
    permission.id = id;
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.permission.update}`,
        this.authorizationService.convertPermissionToDTO(permission)
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedPermission) => {
          this.authorizationService.updatePermission(
            permission.id,
            updatedPermission
          );
        })
      );
  }

  deletePermission(id: number): Observable<Permission> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.permission.delete}${id}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedPermission) => {
          this.authorizationService.deletePermission(id);
        })
      );
  }
  //#endregion PERMISSION

  //#region ROLE
  readRoles(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.role.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.authorizationService.setRoles(response.data);
          }
        })
      );
  }

  readRoleById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.role.readById}` + id
    );
  }

  createRole(role: Role): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.role.create}`, role)
      .pipe(
        tap((res) => {
          if (res.data) {
            this.authorizationService.addRole(res.data);
          }
        })
      );
  }

  updateRole(role: Role): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.role.update}` + role.id, role)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.authorizationService.upatedRole(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  createRolePermissions(
    rolePermissions: RolePermissionDto
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        applicationUrls.role.saveRolePermission,
        rolePermissions
      )
      .pipe(
        tap((response) => {
          this.authorizationService.upatedRole(
            response.data,
            rolePermissions.roleId
          );
        })
      );
  }

  deleteRole(id?: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.role.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedRole) => {
          this.authorizationService.deleteRole(id);
        })
      );
  }
  //#endregion ROLE

  //#region Feature
  getFeatureListByModule(moduleId: Number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.feature.getFeatureListByModuleId}` + moduleId);
  }

  getPermissionListByFeature(featureId: any): Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.permission.getPermissionListByFeatureId}` + featureId);
  }
  //#endregion Feature
}
