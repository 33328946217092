<!--
<div style="text-align:center">
    <h1>
      QR Code Generation
    </h1>
  </div>
   <div class="center">
    <ngx-qrcode
        [elementType]="elementType"
        [value] = "value"
        cssClass = "aclass"
        correctionLevel = "L">
    </ngx-qrcode>

   </div> -->

<div class="container-fluid" *ngIf="isLoadingData">
  <div class="row">
    <div class="container">
      <div class="row">
        <!-- Header starts -->

        <div class="col-md-3 col-6">
          <img
            class="img-fluid"
            src="assets/images/gov-modify.png"
            id="bd-logo"
          />
        </div>

        <div class="col-md-3 col-6 offset-md-6 text-right">
          <img
            class="img-fluid"
            id="nsda-logo"
            src="assets/images/Group 98.png"
          />
        </div>

        <!-- Header ends -->

        <!-- main content starts -->

        <!-- certificate title -->

        <div class="col-md-12 text-center certificate-center">
          <h4>Government of the People’s Republic of Bangladesh</h4>
          <h2 class="text-success">{{ officeText }}</h2>
          <h4 class="text-uppercase">
            <b>National Skills Development Authority (NSDA)</b>
          </h4>
        </div>

        <!--certificate title -->

        <!--certificate body -->
        <div class="certificate-type col-md-12 text-center">
          <!-- <h1 class="font-weight-bold" *ngIf="this.certificateData.isCompetent == true" >National Skills Certificate</h1>
            <h1 class="font-weight-bold" *ngIf="!this.certificateData.isCompetent == true || this.certificateData.isCompetent == null">Statement Of Achievement</h1> -->
          <h1 class="font-weight-bold" *ngIf="this.competent == true">
            National Skills Certificate
          </h1>
          <h1 class="font-weight-bold" *ngIf="!this.competent == true">
            Statement Of Achievement
          </h1>
          <h3>On</h3>
          <h2 class="font-weight-bold">
            {{ this.certificateData?.occupationNameEnglish }}, Level-{{
              this.certificateData?.level
            }}
          </h2>
        </div>

        <div class="stp-reg-name col-md-12 text-center">
          <h4 class="font-italic">is awarded to</h4>
          <h2>
            <b>{{ this.certificateData?.participantName }}</b>
          </h2>
        </div>
        <div class="col-md-10 offset-md-1 mt-2 font-italic">
          <h4>
            Father's name: {{ this.certificateData?.fatherNameEnglish }} &
            Mother's name: {{ this.certificateData?.motherNameEnglish }} bearing
            National ID/Birth Registration no.
            {{ this.certificateData?.nidEnglish }} for successful completion of
            the following competencies under National Skills Qualification
            Framework; NSQF (BNQF Level 1-6):
          </h4>
          <div class="mt-5">
            <div
              *ngFor="let skill of this.certificateData?.competencyListAsList"
            >
              <h5>
                <i
                  class="mr-4"
                  nz-icon
                  nzType="check-square"
                  nzTheme="outline"
                ></i
                >{{ skill }}
              </h5>
            </div>
          </div>
        </div>
        <!-- certficate body -->

        <!-- certificate footer -->

        <div class="col-md-5 certificate-footer-right font-italic">
          <h6>
            Serial No.<span style="margin-left: 84px"
              >: {{ this.certificateData?.serialNo }}</span
            >
          </h6>
          <h6>
            Registration No.<span style="margin-left: 35px"></span> :
            {{ this.certificateData?.registrationNo }}
          </h6>
          <h6>
            Result Published on <span style="margin-left: 10px"></span> :
            {{ this.certificateData?.resultPublishedOn }}
          </h6>
          <h6>
            Valid until <span style="margin-left: 75px"></span> :
            {{ this.certificateData?.validUntil }}
          </h6>
        </div>

        <div
          class="col-md-4 offset-md-3 text-center certificate-footer-left font-italic"
        >
          <h6>{{ this.certificateData?.chairmanEnglishName }}</h6>
          <h5>{{ this.certificateData?.chairmanEnglishDesignation }}</h5>
        </div>

        <!-- certificate footer -->

        <!-- main content ends -->
      </div>
    </div>
  </div>
</div>
