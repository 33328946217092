import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedCertificateListComponent } from './shared-certificate-list/shared-certificate-list.component';
import { DownloadCertificateComponent } from './download-certificate/download-certificate.component';
import { FormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { LandingModule } from '../landing/landing.module';
import { TemplateForCertificateIssuanceRoutingModule } from '../template-for-certificate-issuance/template-for-certificate-issuance-routing.module';
import { ReCaptchaModule } from '../re-captcha/re-captcha.module';
@NgModule({
  declarations: [SharedCertificateListComponent, DownloadCertificateComponent],
  imports: [
    CommonModule,
    SharedModule,
    TemplateForCertificateIssuanceRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    LandingModule,
    ReCaptchaModule
  ],
  exports: [
    SharedCertificateListComponent, DownloadCertificateComponent
  ]
})
export class SharedCertificateModule { }
