import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';

import {
  ErrorHandler,
  HttpErrorHandler,
} from 'src/app/shared/services/http-error-handler.service';
import { DocumentSearch } from '../components/documents/models/DTO/document-search';
import { PgService } from './pg.service';
@Injectable({
  providedIn: 'root',
})
export class PgStorageService {
  private handleError: ErrorHandler;
  constructor(
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private pgService: PgService
  ) {
    this.handleError =
      this.httpErrorHandler.createErrorHandler('Freelancer Service');
  }

  //#region read document list for nsda
  readDocumentListForNSDA(
    documentSearch: DocumentSearch
  ): Observable<ServerResponse> {
    documentSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.pgDocument.getDocumentInfoForNsda}`,
      documentSearch
    );
  }
  //#endregion

  //#region read archived document list
  readDocumentList(documentSearch: DocumentSearch): Observable<ServerResponse> {
    documentSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.pgDocument.getDocumentInfoPublic}`,
      documentSearch
    );
  }
  //#endregion


    //#region read archived document list for nsda
    readDocumentListForNSDAarchived(
      documentSearch: DocumentSearch
    ): Observable<ServerResponse> {
      documentSearch.disabled = false;
      return this.httpClient.post<ServerResponse>(
        `${applicationUrls.pgDocument.getDocumentInfoForNsdaArchived}`,
        documentSearch
      );
    }
    //#endregion

    //#region read document list
    readDocumentListArchived(documentSearch: DocumentSearch): Observable<ServerResponse> {
      documentSearch.disabled = false;
      return this.httpClient.post<ServerResponse>(
        `${applicationUrls.pgDocument.getDocumentInfoPublicArchived}`,
        documentSearch
      );
    }
    //#endregion

  //#region delete document
  deleteDocument(documentId: number) {
    return this.httpClient
      .delete<ServerResponse>(
        `${applicationUrls.pgDocument.deleteDocumentByDocumentId}` + documentId
      )
  }
  //#endregion

  getDocumentOpinionListById(documentId: number) {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.pgDocument.getDocumentOpinionListByDocumentId}` + documentId
      )
  }

  //#region download document report
  onDownloadDocumentReport(documentSearch: DocumentSearch): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.pgDocument.downloadDocumentReport}`,
      documentSearch
    );
  }
  //#endregion

  //#region download document report
  onDownloadOpinionReport(documentSearch: DocumentSearch): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.pgDocument.downloadOpinionReport}`,
      documentSearch
    );
  }
  //#endregion

  readDocumentType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.pgDocument.readDocumentType}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readVersion(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.pgDocument.readVersion}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readLanguage(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.pgDocument.readLanguage}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readAccessType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.pgDocument.readAccessType}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  //#region get documents info by id
  readDocumentsInfoById(documentId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.pgDocument.readDocumentInfoById}` + documentId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region save documents
  saveDocuments(document: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.pgDocument.saveDocuments}`,
        document
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region update documents
  updateDocuments(document: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.pgDocument.updateDocuments}`,
        document
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region Submit document
  onSubmitDocument(documentId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.pgDocument.submitDocument}`,
      { id: documentId }
    );
  }
  //#endregion

  //#region collect opinion
  onOpenToCollectOpinion(
    documentId: number,
    collectingOpinionNote: string,
    collectTillDate: Date
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.pgDocument.openToCollectPublicOpinion}`,
      {
        id: documentId,
        collectingOpinionNote: collectingOpinionNote,
        collectTillDate: collectTillDate,
      }
    );
  }
  //#endregion

  //#region close collecting opinion
  onCloseToCollectingOpinion(documentId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.pgDocument.onCloseToCollectingOpinion}`,
      {
        id: documentId,
      }
    );
  }
  //#endregion

  //#region send to archived
  onSendToArchived(documentId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.pgDocument.onSendToArchived}`,
      {
        id: documentId,
      }
    );
  }
  //#endregion

  //#region send to archived
  onSendToLiveList(documentId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.pgDocument.onSendToLiveList}`,
      {
        id: documentId,
      }
    );
  }
  //#endregion

  //#region get documents info by id
  readOpinionInfoById(documentId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.pgDocument.readOpinionInfoById}` + documentId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region save Opinion
  saveOpinion(document: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.pgDocument.saveOpinion}`,
        document
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region update Opinion
  updateOpinion(document: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.pgDocument.updateOpinion}`,
        document
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion
}
