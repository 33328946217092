import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PublishCertificateVideoDemoComponent } from "./component/publish-certificate-video-demo/publish-certificate-video-demo.component";

const routes: Routes = [
    {path:'publish-certificate-demo-video', component: PublishCertificateVideoDemoComponent},
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class LandingRoutingModule {}