import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  District,
  Division,
  Upazila,
} from 'src/app/shared/models/common.model';
import { Furniture } from '../models/furniture.model';
import { StpManagementPosition } from '../models/stp-management-position.model';
import { StpManagement } from '../models/stp-management.model';
import { StpOccupationTool } from '../models/stp-occupation-tool.model';
import { StpTrainerOccupation } from '../models/stp-trainer-occupation.model';
import { StpTrainer } from '../models/stp-trainer.model';
import { STPAppStatus } from '../models/stp_app_status.model';
import { STPInfrastructure } from '../models/stp_infrastructure.model';
import { STPLocation } from '../models/stp_location.model';
import { STPMain } from '../models/stp_main.model';
import { STPMinistry } from '../models/stp_ministry.model';
import { STPOwnershipType } from '../models/stp_ownership_type.model';
import { STPOwnerType } from '../models/stp_owner_type.model';
import { STPType } from '../models/stp_type.model';
import { SYSCountry } from '../models/sys_country.model';
import { SYSPostOffice } from '../models/sys_post_office.model';
import { SysUnit } from '../models/sys_unit.model';
import { STPAgency } from './../models/stp_agency.model';
import { STPAppType } from './../models/stp_app_type.model';
import { DatePipe } from '@angular/common';
import { StpApplications } from '../../stp-registration/models/stp-applications.model';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { __assign } from 'tslib';
import { FinancialDetails } from '../models/FinancialDetails.model';

@Injectable({
  providedIn: 'root',
})
export class StpRegistrationService {
  onStartBasicProfileEditing = new Subject<number | null>();
  onRefreshBasicProfileList = new Subject<STPMain[]>();
  stpMains: STPMain[] = [];
  editingStpMainId!: number | null;
  public isStpMainAlreadyCreated = false;
  banglaDate: any;

  onStartStpManagementEditing = new Subject<number | null>();
  onStartStpManagementList = new Subject<number | null>();
  onRefreshStpTrainerList = new Subject<StpTrainer[]>();
  onRefreshStpManagementList = new Subject<StpManagement[]>();
  onRefreshStpTrainerOccupationList = new Subject<StpTrainerOccupation[]>();
  onRefreshSysUnitList = new Subject<SysUnit[]>();
  onRefreshStpOccupationToolList = new Subject<StpOccupationTool[]>();
  onRefreshStpPositionList = new Subject<StpManagementPosition[]>();
  stpManagements: StpManagement[] = [];
  stpManagementPositions: StpManagementPosition[] = [];
  stpManagementPosition!: StpManagementPosition;
  editingStpManagementId!: number | null;
  // StpOccupationTools
  editingStpOccupationToolId!: number | null;
  onStartStpOccupationToolEditing = new Subject<number | null>();
  stpOccupationTools: StpOccupationTool[] = [];
  // StpTrainers
  stpTrainer!: StpTrainer;
  stpTrainers: StpTrainer[] = [];
  // stpTrainerOccupations: StpTrainerOccupation[] = [];
  sysUnit!: SysUnit;
  sysUnits: SysUnit[] = [];
  // new
  onStartStpTypeEditing = new Subject<number | null>();
  onRefreshStpTypeList = new Subject<STPType[]>();
  stpTypes: STPType[] = [];
  editingStpTypeId!: number | null;

  onStartStpOwnerTypeEditing = new Subject<number | null>();
  onRefreshStpOwnerTypeList = new Subject<STPOwnerType[]>();
  stpOwnerTypes: STPOwnerType[] = [];
  editingStpOwnerTypeId!: number | null;

  onStartStpOwnerShipTypeEditing = new Subject<number | null>();
  onRefreshStpOwnerShipTypeList = new Subject<STPOwnershipType[]>();
  stpOwnerShipTypes: STPOwnershipType[] = [];
  editingStpOwnerShipTypeId!: number | null;

  onStartStpMinistryEditing = new Subject<number | null>();
  onRefreshStpMinistryList = new Subject<STPMinistry[]>();
  stpMinistries: STPMinistry[] = [];
  editingStpMinistryId!: number | null;

  onStartStpAgencyEditing = new Subject<number | null>();
  onRefreshStpAgencyList = new Subject<STPAgency[]>();
  stpAgencies: STPAgency[] = [];
  editingStpAgencyId!: number | null;

  onStartStpLocationEditing = new Subject<number | null>();
  onRefreshStpLocationList = new Subject<STPLocation[]>();
  stpLocations: STPLocation[] = [];
  editingStpLocationId!: number | null;

  onStartStpPostOfficeEditing = new Subject<number | null>();
  onRefreshStpPostOfficeList = new Subject<SYSPostOffice[]>();
  // stpPostOffices: SYSPostOffice[] = [];
  // editingStpPostOfficeId!: number | null;

  onStartStpCountryEditing = new Subject<number | null>();
  onRefreshStpCountryList = new Subject<SYSCountry[]>();
  stpCountries: SYSCountry[] = [];
  editingStpCountryId!: number | null;

  onStartStpDivisionEditing = new Subject<number | null>();
  onRefreshStpDivisionList = new Subject<Division[]>();
  stpDivisions: Division[] = [];
  editingStpDivisionId!: number | null;

  onStartStpDistrictEditing = new Subject<number | null>();
  onRefreshStpDistrictList = new Subject<District[]>();
  stpDistricts: District[] = [];
  editingStpDistrictId!: number | null;

  onStartStpUpazillaEditing = new Subject<number | null>();
  onRefreshStpUpazillaList = new Subject<Upazila[]>();
  stpUpazillas: Upazila[] = [];
  editingStpUpazillaId!: number | null;

  onStartStpAppStatusEditing = new Subject<number | null>();
  onRefreshStpAppStatusList = new Subject<STPAppStatus[]>();
  stpAppStatuses: STPAppStatus[] = [];
  editingStpAppStatusId!: number | null;

  onStartStpAppTypeEditing = new Subject<number | null>();
  onRefreshStpAppTypeList = new Subject<STPAppType[]>();
  stpAppTypes: STPAppType[] = [];
  editingStpAppTypeId!: number | null;

  onStartStpTrainerEditing = new Subject<number | null>();
  editingStpTrainerId!: number | null;

  stpInfrastructure: STPInfrastructure[] = [];
  financialDetails: FinancialDetails[] = [];
  trainerOccupation: any;
  onUpdateTrainerOccupation = new Subject<any[]>();
  onStartFurnitureEditing = new Subject<number | null>();
  onRefreshFurnitureList = new Subject<Furniture[]>();
  furnitures: Furniture[] = [];
  editingFurnitureId!: number | null;

  ownershipStatus: any;
  constructionType: any;
  onUpdateOwnershipStatus = new Subject<any[]>();
  onUpdateConstructionType = new Subject<any[]>();

  // Mushfiq
  private stpManagementLoad = new Subject<any>();
  private stpOccupationToolsLoad = new Subject<any>();
  router: Router;

  isComplete = new BehaviorSubject<boolean>(false);
  isComplete$ = this.isComplete.asObservable();
  stpMainInfo = new BehaviorSubject<any>(null);
  stpMainInfo$ = this.stpMainInfo.asObservable();

  constructor(
  ) { }

  //#region nobin

  getBasicProfiles(): any {
    return this.stpMains;
  }

  setBasicProfiles(stpMains: STPMain[]): void {
    this.stpMains = stpMains;
    this.onRefreshBasicProfileList.next(this.stpMains.slice());
  }

  getSTPTypes(): any {
    return this.stpTypes;
  }

  setSTPTypes(stpTypes: STPType[]): void {
    this.stpTypes = stpTypes;
    this.onRefreshStpTypeList.next(this.stpTypes.slice());
  }

  getSTPOwnerTypes(): any {
    return this.stpOwnerTypes;
  }

  setSTPOwnerTypes(stpOwnerTypes: STPOwnerType[]): void {
    this.stpOwnerTypes = stpOwnerTypes;
    this.onRefreshStpOwnerTypeList.next(this.stpOwnerTypes.slice());
  }

  getSTPOwnerShipTypes(): any {
    return this.stpOwnerShipTypes;
  }

  setSTPOwnerShipTypes(stpOwnerShipTypes: STPOwnershipType[]): void {
    this.stpOwnerShipTypes = stpOwnerShipTypes;
    this.onRefreshStpOwnerShipTypeList.next(this.stpOwnerShipTypes.slice());
  }

  getSTPMinitries(): any {
    return this.stpMinistries;
  }

  setSTPMinitries(stpMinistries: STPMinistry[]): void {
    this.stpMinistries = stpMinistries;
    this.onRefreshStpMinistryList.next(this.stpMinistries.slice());
  }

  getSTPAgencies(): any {
    return this.stpAgencies;
  }

  setSTPAgencies(stpAgencies: STPAgency[]): void {
    this.stpAgencies = stpAgencies;
    this.onRefreshStpAgencyList.next(this.stpAgencies.slice());
  }

  getSTPLocations(): any {
    return this.stpLocations;
  }

  setSTPLocations(stpLocations: STPLocation[]): void {
    this.stpLocations = stpLocations;
    this.onRefreshStpLocationList.next(this.stpLocations.slice());
  }

  // getSTPPostOffices(): any {
  //   return this.stpPostOffices;
  // }

  // setSTPPostOffices(stpPostOffices: SYSPostOffice[]): void {
  //   this.stpPostOffices = stpPostOffices;
  //   this.onRefreshStpPostOfficeList.next(this.stpPostOffices.slice());
  // }

  getSTPCountries(): any {
    return this.stpCountries;
  }

  setSTPCountries(stpCountries: SYSCountry[]): void {
    this.stpCountries = stpCountries;
    this.onRefreshStpCountryList.next(this.stpCountries.slice());
  }

  setSTPDivisions(stpDivisions: Division[]): void {
    this.stpDivisions = stpDivisions;
    this.onRefreshStpDivisionList.next(this.stpDivisions.slice());
  }

  setSTPDistricts(stpDistricts: District[]): void {
    this.stpDistricts = stpDistricts;
    this.onRefreshStpDistrictList.next(this.stpDistricts.slice());
  }

  setSTPUpazillas(stpUpazillas: Upazila[]): void {
    this.stpUpazillas = stpUpazillas;
    this.onRefreshStpUpazillaList.next(this.stpUpazillas.slice());
  }

  getSTPAppStatuses(): any {
    return this.stpAppStatuses;
  }

  setSTPAppStatuses(stpAppStatuses: STPAppStatus[]): void {
    this.stpAppStatuses = stpAppStatuses;
    this.onRefreshStpAppStatusList.next(this.stpAppStatuses.slice());
  }

  getSTPAppTypes(): any {
    return this.stpAppTypes;
  }

  setSTPAppTypes(stpAppTypes: STPAppType[]): void {
    this.stpAppTypes = stpAppTypes;
    this.onRefreshStpAppTypeList.next(this.stpAppTypes.slice());
  }

  addBasicProfile(stpMain: STPMain): void {
    this.stpMains.push(stpMain);
    this.onRefreshBasicProfileList.next(this.stpMains.slice());
  }

  updateBasicProfile(stpMain: STPMain): void {
    this.stpMains.push(stpMain);
    this.onRefreshBasicProfileList.next(this.stpMains.slice());
  }

  //#endregion nobin

  //#region OCCUPATION_SPECIFIC_TRAINER

  //#region TRAINER
  getStpTrainers(): StpTrainer[] {
    return this.stpTrainers;
  }

  getStpTrainerById(id: number | null): StpTrainer | undefined {
    return this.stpTrainers.find((stpTrainer) => stpTrainer.id === id);
  }

  setStpTrainers(stpTrainers: StpTrainer[]): void {
    this.stpTrainers = stpTrainers;
    this.onRefreshStpTrainerList.next(this.stpTrainers.slice());
  }

  setStpTrainersForExcel(stpTrainers: StpTrainer[]): void {
    this.stpTrainers = [...this.stpTrainers, ...stpTrainers];
    this.onRefreshStpTrainerList.next(this.stpTrainers.slice());
  }

  addStpTrainer(stpTrainer: StpTrainer): void {
    this.stpTrainers.push(stpTrainer);
    this.onRefreshStpTrainerList.next(this.stpTrainers.slice());
  }

  upatedStpTrainer(newRecipe: StpTrainer, id: number): void {
    const index = this.stpTrainers.findIndex(
      (stpTrainer) => stpTrainer.id === id
    );
    this.stpTrainers[index] = newRecipe;
    this.onRefreshStpTrainerList.next(this.stpTrainers.slice());
    // console.log(newRecipe);
  }

  deleteStpTrainer(id: number): void {
    this.stpTrainers = this.stpTrainers.filter((stpTrainer, _index) => {
      return stpTrainer.id !== id;
    });
    this.onRefreshStpTrainerList.next(this.stpTrainers.slice());
  }

  getEditingStpTrainerId(): any {
    return this.editingStpTrainerId;
  }

  setEditingStpTrainerId(id: number): void {
    this.editingStpTrainerId = id;
    this.onStartStpTrainerEditing.next(id);
  }

  clearEditingStpTrainerId(): void {
    this.editingStpTrainerId = null;
    this.onStartStpTrainerEditing.next(null);
  }
  //#endregion TRAINER

  //#region OCCUPATION
  // setStpTrainerOccupations(
  //   stpTrainerOccupations: StpTrainerOccupation[]
  // ): void {
  //   this.stpTrainerOccupations = stpTrainerOccupations;
  //   this.onRefreshStpTrainerOccupationList.next(
  //     this.stpTrainerOccupations.slice()
  //   );
  // }

  // getStpTrainerOccupations(): StpTrainerOccupation[] {
  //   return this.stpTrainerOccupations;
  // }

  // getStpTrainerOccupationById(
  //   id: number | null
  // ): StpTrainerOccupation | undefined {
  //   return this.stpTrainerOccupations.find(
  //     (stpTrainerOccupation) => stpTrainerOccupation.id === id
  //   );
  // }
  //#endregion  OCCUPATION
  //#region Unit
  setSysUnits(sysUnits: SysUnit[]): void {
    this.sysUnits = sysUnits;
    this.onRefreshSysUnitList.next(this.sysUnits.slice());
  }
  getSysUnits(): SysUnit[] {
    return this.sysUnits;
  }
  getSysUnitById(id: number | null): SysUnit | undefined {
    // console.log('Unitsssss', this.sysUnits, id);
    return this.sysUnits.find((sysUnit) => sysUnit.id === id);
  }
  //#endregion  Unit

  //#endregion  OCCUPATION_SPECIFIC_TRAINER

  //#region TrainerOccupations
  // tslint:disable-next-line: typedef
  setTrainerOccupations(trainerOccupations: any) {
    this.trainerOccupation = trainerOccupations;
    this.onUpdateTrainerOccupation.next(this.trainerOccupation);
  }

  //#region  Irfan
  getFurnitures(): Furniture[] {
    return this.furnitures;
  }
  getFurnitureById(id: number | null): Furniture | undefined {
    return this.furnitures.find((furniture) => furniture.id === id);
  }
  setFurniture(furnitures: Furniture[]): void {
    this.furnitures = furnitures;
    this.onRefreshFurnitureList.next(furnitures.slice());
  }

  setFurnitureForExcel(furnitures: Furniture[]): void {
    this.furnitures = [...this.furnitures, ...furnitures];
    this.onRefreshFurnitureList.next(this.furnitures.slice());
  }

  setEditingFurnitureId(id: number): void {
    this.editingFurnitureId = id;
    this.onStartFurnitureEditing.next(id);
  }

  upatedFurniture(newRecipe: Furniture, id: number): void {
    const index = this.furnitures.findIndex((furniture) => furniture.id === id);
    this.furnitures[index] = newRecipe;
    this.onRefreshFurnitureList.next(this.furnitures.slice());
  }

  deleteFurniture(id: number): void {
    this.furnitures = this.furnitures.filter((furniture, _index) => {
      return furniture.id !== id;
    });
    this.onRefreshFurnitureList.next(this.furnitures.slice());
  }
  getEditingStpFurnitureId(): any {
    return this.editingFurnitureId;
  }
  setEditingStpFurnitureId(id: number): void {
    this.editingFurnitureId = id;
    this.onStartFurnitureEditing.next(id);
  }
  clearEditingStpFurnitureId(): void {
    this.editingFurnitureId = null;
    this.onStartFurnitureEditing.next(null);
  }
  addFurniture(furniture: Furniture): void {
    this.furnitures.push(furniture);
    this.onRefreshFurnitureList.next(this.furnitures.slice());
  }

  //#endregion irfan

  //#region Tareque
  addInfrastructure(stpInfrastructure: STPInfrastructure): void {
    this.stpInfrastructure.push(stpInfrastructure);
  }

  updateInfrastructure(stpInfrastructure: STPInfrastructure): void {
    this.stpInfrastructure.push(stpInfrastructure);
  }

  updateFinancialDetails(financialDetails: FinancialDetails): void {
    this.financialDetails.push(financialDetails);
  }

  // tslint:disable-next-line:typedef
  setOwnershipStatus(ownershipStatus: any) {
    this.ownershipStatus = ownershipStatus;
    this.onUpdateOwnershipStatus.next(this.ownershipStatus);
  }
  // tslint:disable-next-line:typedef
  setConstructionType(constructionType: any) {
    this.constructionType = constructionType;
    this.onUpdateConstructionType.next(this.constructionType);
  }
  //#endregion Tareque
  //#endregion  OCCUPATION_SPECIFIC_TRAINER

  // Mushfiq
  // Management and Staff
  //#region fuad
  getStpManagements(): StpManagement[] {
    return this.stpManagements;
  }
  setStpManagements(stpManagements: StpManagement[]): void {
    this.stpManagements = stpManagements;
    this.onRefreshStpManagementList.next(this.stpManagements.slice());
  }

  clearEditingStpManagementId(): void {
    this.editingStpManagementId = null;
    this.onStartStpManagementEditing.next(null);
  }
  setEditingStpManagementId(id: number): void {
    this.editingStpManagementId = id;
    this.onStartStpManagementEditing.next(id);
  }

  deleteStpManagement(id: number): void {
    this.stpManagements = this.stpManagements.filter(
      (stpManagement, _index) => {
        return stpManagement.id !== id;
      }
    );
    this.onRefreshStpManagementList.next(this.stpManagements.slice());
  }
  // Management-and-staffs-create
  getEditingStpManagementId(): any {
    return this.editingStpManagementId;
  }

  getStpManagementPositions(): StpManagementPosition[] {
    return this.stpManagementPositions;
  }

  setStpManagementPositions(
    stpManagementPositions: StpManagementPosition[]
  ): void {
    this.stpManagementPositions = stpManagementPositions;
    this.onRefreshStpPositionList.next(this.stpManagementPositions.slice());
  }
  getStpManagementById(id: number | null): StpManagement | undefined {
    console.log('stpManagements', this.stpManagements);
    return this.stpManagements.find(
      (stpManagements) => stpManagements.id === id
    );
  }
  getStpManagementPositionsById(
    id: number | null
  ): StpManagementPosition | undefined {
    return this.stpManagementPositions.find(
      (stpManagementPosition) => stpManagementPosition.id === id
    );
  }

  updatedStpManagement(newRecipe: StpManagement, id: number): void {
    const index = this.stpManagements.findIndex(
      (stpManagement) => stpManagement.id === id
    );
    this.stpManagements[index] = newRecipe;
    this.onRefreshStpManagementList.next(this.stpManagements.slice());
  }

  addStpManagement(stpManagement: StpManagement): void {
    this.stpManagements.push(stpManagement);
    this.onRefreshStpManagementList.next(this.stpManagements.slice());
  }

  //#endregion fuad

  // Mushfiq
  // Management and Staff
  sendUpdateManagement(message: string): any {
    this.stpManagementLoad.next({ text: message });
  }
  getUpdateManagement(): Observable<any> {
    return this.stpManagementLoad.asObservable();
  }
  // Occupation Tools
  sendUpdateOccupationTools(message: string): any {
    this.stpOccupationToolsLoad.next({ text: message });
  }
  getUpdateOccupationTools(): Observable<any> {
    return this.stpOccupationToolsLoad.asObservable();
  }

  getEditingStpOccupationToolId(): any {
    return this.editingStpOccupationToolId;
  }

  clearEditingStpOccupationToolId(): void {
    this.editingStpOccupationToolId = null;
    this.onStartStpOccupationToolEditing.next(null);
  }
  getStpOccupationTools(): StpOccupationTool[] {
    return this.stpOccupationTools;
  }
  setEditingStpOccupationToolId(id: number): void {
    // console.log('setting OT id', id);
    this.editingStpOccupationToolId = id;
    this.onStartStpOccupationToolEditing.next(id);
  }

  getStpOccupationToolById(id: number | null): StpOccupationTool | undefined {
    return this.stpOccupationTools.find(
      (stpOccupationTool) => stpOccupationTool.id === id
    );
  }

  setStpOccupationTools(stpOccupationTools: StpOccupationTool[]): void {
    this.stpOccupationTools = stpOccupationTools;
    this.onRefreshStpOccupationToolList.next(this.stpOccupationTools.slice());
  }

  addStpOccupationTool(stpOccupationTool: StpOccupationTool): void {
    this.stpOccupationTools.push(stpOccupationTool);
    this.onRefreshStpOccupationToolList.next(this.stpOccupationTools.slice());
  }

  updatedStpOccupationTool(newRecipe: StpOccupationTool, id: number): void {
    const index = this.stpOccupationTools.findIndex(
      (stpOccupationTool) => stpOccupationTool.id === id
    );
    this.stpOccupationTools[index] = newRecipe;
    this.onRefreshStpOccupationToolList.next(this.stpOccupationTools.slice());
  }

  async makeCertificatePdf(user: StpApplications): Promise<any> {
    const formUrl = '../../../../assets/certificate/new_certificates.pdf';
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const fontUrl = '../../../../assets/fonts/BANGLA.TTF';
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    const qrBase64 = 'data:image/jpeg;base64,' + user.qrImageString;
    // console.log(user);
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    // Embed the Ubuntu font
    pdfDoc.registerFontkit(fontkit);
    const form = pdfDoc.getForm();

    if (qrBase64 && qrBase64 != '') {
      const embedImage = await pdfDoc.embedPng(qrBase64);
      const qrCodeField = form.getButton('qrCode');
      const qrCodeBanglaField = form.getButton('qrCodeBangla');
      qrCodeField.setImage(embedImage);
      qrCodeBanglaField.setImage(embedImage);
    }
    const unicode = await pdfDoc.embedFont(fontBytes, { subset: true });
    const stpName = form.getTextField('stpName');
    const stpNameBangla = form.getTextField('stpNameBangla');
    const registrationNo = form.getTextField('registrationNo');
    const registrationNoBangla = form.getTextField('registrationNoBangla');
    const issuedOn = form.getTextField('issuedOn');
    const validUntil = form.getTextField('validUntil');
    const issuedOnBangla = form.getTextField('issuedOnBangla');
    const validUntilBangla = form.getTextField('validUntilBangla');
    const name = user?.stpName?.toUpperCase() || '';
    stpName.setText(name);
    stpNameBangla.setText(user?.stpNameBangla || '');
    // let newRegistration = user?.registrationNo.substring(0, user.registrationNo.length-2);
    registrationNo.setText(user?.registrationNo || '');
    registrationNoBangla.setText(user?.registrationNo || '');
    let approvalDateStp = (user?.approvalDate || '').toString();
    let newFormattedApprovalDateStp =
      approvalDateStp.substring(8, 10) +
      '-' +
      approvalDateStp.substring(5, 7) +
      '-' +
      approvalDateStp.substring(0, 4);
    let validUntilDateStp = (user?.expireDate || '').toString();
    let newFormattedvalidUntilDateStp =
      validUntilDateStp.substring(8, 10) +
      '-' +
      validUntilDateStp.substring(5, 7) +
      '-' +
      validUntilDateStp.substring(0, 4);
    console.log(validUntilDateStp);
    console.log(newFormattedApprovalDateStp);
    console.log(newFormattedvalidUntilDateStp);
    issuedOn.setText(newFormattedApprovalDateStp);
    validUntil.setText(newFormattedvalidUntilDateStp);
    console.log(
      'USER API ',
      registrationNo,
      newFormattedApprovalDateStp,
      newFormattedvalidUntilDateStp,
      user
    );
    const convertedStringIssuedon = this.convertNumberToUnicodeData(
      newFormattedApprovalDateStp
    );
    issuedOnBangla.setText(convertedStringIssuedon);
    const convertedStringValidUntil = this.convertNumberToUnicodeData(
      newFormattedvalidUntilDateStp
    );
    validUntilBangla.setText(convertedStringValidUntil);
    form.getTextField('stpNameBangla').updateAppearances(unicode);
    form.getTextField('issuedOnBangla').updateAppearances(unicode);
    form.getTextField('validUntilBangla').updateAppearances(unicode);

    form.flatten();
    const pdfBytes = await pdfDoc.save();
    const certiStpName = user?.stpName?.toUpperCase() || '';
    const replacingSpace = certiStpName.split(' ').join('_');
    const fileName = 'Certificate' + '_' + replacingSpace + '.pdf';
    const pdfBlob = new File([pdfBytes], fileName, {
      type: 'application/pdf',
    });
    return pdfBlob;
  }

  async downloadCertificate(user: StpApplications) {
    const certificatePdf = await this.makeCertificatePdf(user);
    FileSaver.saveAs(certificatePdf);
  }
  // siam_rupali
  // tslint:disable-next-line: typedef
  async makeFreelancePDF(data: any) {

    let userInfo = data.tciInfoPayload;
    const formUrl = '../../../../assets/certificate/freelancer_certificate.pdf';
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

    //const fontUrl = '../../../../assets/fonts/BANGLA.TTF';
    const fontUrl = '../../../../assets/fonts/BANGLA.TTF';
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

    const signImgUrl = '../../../../assets/certificate/green-check-mark.jpeg';
    const imageBytes = await fetch(signImgUrl).then(res => res.arrayBuffer());
    //console.log(imageBytes);

    const qrBase64 = 'data:image/jpeg;base64,' + data.imageStringQR;
    const pdfDoc = await PDFDocument.load(formPdfBytes)

    pdfDoc.registerFontkit(fontkit);

    const pngImage = await pdfDoc.embedJpg(imageBytes);
    const form = pdfDoc.getForm();
    const pages = pdfDoc.getPages();
    const timesRomanFontItalic = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    //QR code generate
    if (qrBase64 && qrBase64 != '') {
      const embedImage = await pdfDoc.embedPng(qrBase64);
      const qrCodeField = form.getButton('qrCode');
      const qrCodeBanglaField = form.getButton('qrCodeBangla');
      qrCodeField.setImage(embedImage);
      qrCodeBanglaField.setImage(embedImage);
    }

    // embeded font
    const unicode = await pdfDoc.embedFont(fontBytes, { subset: true });
    //const signcode = await pdfDoc.embedFont(signBytes, { subset: true });
    // page 1 Get value
    const certificateName = form.getTextField('certificateName');
    const participantName = form.getTextField('participantName');
    const participantDetails = form.getTextField('participantDetails');

    //const competencyList = form.getTextField('competencyList');
    const serialNo = form.getTextField('serialNo');
    const registrationNo = form.getTextField('registrationNo');
    const issuedOn = form.getTextField('issuedOn');
    const validUntil = form.getTextField('validUntil');
    // page2
    const certificateNameBangla = form.getTextField('certificateNameBangla');
    const participantNameBangla = form.getTextField('participantNameBangla');
    const participantDetailsBangla = form.getTextField('participantDetailsBangla');
    //const competencyListBangla = form.getTextField('competencyListBangla');
    const serialNoBangla = form.getTextField('serialNoBangla');
    const registrationNoBangla = form.getTextField('registrationNoBangla');
    const issuedOnBangla = form.getTextField('issuedOnBangla');
    const validUntilBangla = form.getTextField('validUntilBangla');

    // page1 set value
    certificateName.setText(userInfo.occupationNameEnglish + ', ' + 'Level-' + userInfo.level);
    participantName.setText(userInfo.nameEnglish);
    participantDetails.setText('Son of ' + userInfo.fatherNameEnglish + ' & ' + userInfo.motherNameEnglish + ' ' + 'bearing National ID No. ' + userInfo.nidEnglish + ' for successful completion of the following competencies under National Skills Qualification Framework; NSQF (BNQF Level 1-6):');

    serialNo.setText(userInfo.certificateSerialNo);
    registrationNo.setText(userInfo.registrationNo);

    issuedOn.setText(new DatePipe('en-US').transform(userInfo.issueDate, 'dd-MM-yyyy')?.toString());
    validUntil.setText(new DatePipe('en-US').transform(userInfo.validityDate, 'dd-MM-yyyy')?.toString());

    //competencyList.setText(userTrainingList.join('\n'));
    const convertLevelBangla = this.convertLevelInBangla(userInfo.level);
    certificateNameBangla.setText(userInfo.occupationNameBangla + ', ' + 'লেভেল-' + convertLevelBangla);
    participantNameBangla.setText(userInfo.nameBangla);

    const convertNidBangla = this.convertNumberToUnicodeData(userInfo.nidEnglish);
    //console.log('Bngla'+convertNidBangla);
    // userInfo.fatherNameBangla
    participantDetailsBangla.setText('পিতা: ' + userInfo.fatherNameBangla + ' মাতা: ' + userInfo.motherNameBangla + ' জাতীয় পরিচয়পত্র নম্বর: '
      + convertNidBangla + "' " + 'কে ন্যাশনাল স্কিলস্ কোয়ালিফিকেশান ফ্রেমওয়ার্ক; এনএসকিউএফ (বিএনকিউএফ ১-৬) এর অধীনে নিন্মোক্ত কম্পিটেন্সি সমূহ সফলভাবে সম্পন্ন করায় জাতীয় দক্ষতা সনদ প্রদান করা হলো:');
    serialNoBangla.setText(userInfo.certificateSerialNo);
    registrationNoBangla.setText(userInfo.registrationNo);

    this.banglaDate = new DatePipe('en-US').transform(userInfo.validityDate, 'dd-MM-yyyy')?.toString();
    const convertedStringIssuedon = this.convertNumberToUnicodeData(
      this.banglaDate
    );

    validUntilBangla.setText(convertedStringIssuedon.substring(0, 10));

    this.banglaDate = new DatePipe('en-US').transform(userInfo.issueDate, 'dd-MM-yyyy')?.toString();
    const issuedOnBanglaDate = this.convertNumberToUnicodeData(
      this.banglaDate
    );


    issuedOnBangla.setText(issuedOnBanglaDate);
    const fontSize = 9;
    let yAxispage0 = 330;
    let pngYaxis0 = 328;

    let yAxispage1 = 330;
    let pngYaxis1 = 328;
    for (let userDetails of userInfo.tciTrainingDetailsPayload) {
      pages[0].drawText(userDetails.trainingName, {
        x: 95,
        y: yAxispage0,
        size: fontSize,
        font: timesRomanFontItalic,
      })
      yAxispage0 = yAxispage0 - 13;

      pages[0].drawImage(pngImage, {
        x: 80,
        y: pngYaxis0,
        width: 8,
        height: 8,
      })
      pngYaxis0 = pngYaxis0 - 13;
    }

    for (let userDetails of userInfo.tciTrainingDetailsPayload) {
      pages[1].drawText(userDetails.trainingName, {
        x: 95,
        y: yAxispage1,
        size: fontSize,
        font: timesRomanFontItalic,
      })
      yAxispage1 = yAxispage1 - 13;

      pages[1].drawImage(pngImage, {
        x: 80,
        y: pngYaxis1,
        width: 8,
        height: 8,
      })
      pngYaxis1 = pngYaxis1 - 13;
    }

    form.getTextField('certificateName').updateAppearances(timesRomanFontBold);
    form.getTextField('participantDetails').updateAppearances(timesRomanFontItalic);
    form.getTextField('serialNo').updateAppearances(timesRomanFontItalic);
    form.getTextField('registrationNo').updateAppearances(timesRomanFontItalic);
    form.getTextField('issuedOn').updateAppearances(timesRomanFontItalic);
    form.getTextField('validUntil').updateAppearances(timesRomanFontItalic);
    form.getTextField('certificateNameBangla').updateAppearances(unicode);
    form.getTextField('participantNameBangla').updateAppearances(unicode);
    form.getTextField('participantDetailsBangla').updateAppearances(unicode);
    form.getTextField('serialNoBangla').updateAppearances(timesRomanFontItalic);
    form.getTextField('registrationNoBangla').updateAppearances(timesRomanFontItalic);
    form.getTextField('issuedOnBangla').updateAppearances(unicode);
    form.getTextField('validUntilBangla').updateAppearances(unicode);
    form.flatten();
    const pdfBytes = await pdfDoc.save();
    const download = new Blob([pdfBytes], { type: 'application/pdf', });
    let name = userInfo.nameEnglish + ' certificate.pdf';
    FileSaver.saveAs(download, name);
  }


  async mailCertificate(user: StpApplications): Promise<any> {
    const certificatePdf = await this.makeCertificatePdf(user);
    return certificatePdf;
  }

  convertLevelInBangla(number: Number): string {
    let num = '১';
    if (number == 2) {
      return '২';
    }
    else if (number == 3) {
      return '৩';
    }
    else if (number == 4) {
      return '৪';
    }
    else if (number == 5) {
      return '৫';
    }
    else if (number == 6) {
      return '৬';
    } else if (number == 7) {
      return '৭';
    }
    else if (number == 8) {
      return '৮';
    }
    else if (number == 8) {
      return '৯';
    }
    return num;
  }

  async convertingToBangla(user: StpApplications): Promise<any> {
    const certificatePdf = await this.makeCertificatePdf(user);
    return certificatePdf;
  }

  convertNumberToUnicodeData(number: string) {
    let intValue = '';
    for (let i = 0; i < number.length; i++) {
      const item = number[i];
      if (item.charCodeAt(0) >= 48 && item.charCodeAt(0) <= 57) {
        intValue = intValue + this.convertNumberToUnicode(item.charCodeAt(0));
      } else {
        intValue = intValue + number[i];
      }
    }

    return intValue.toString();
  }

  convertNumberToUnicode(number: number): string {
    if (number == 48) {
      return '০';
    } else if (number == 49) {
      return '১';
    } else if (number == 50) {
      return '২';
    } else if (number == 51) {
      return '৩';
    } else if (number == 52) {
      return '৪';
    } else if (number == 53) {
      return '৫';
    } else if (number == 54) {
      return '৬';
    } else if (number == 55) {
      return '৭';
    } else if (number == 56) {
      return '৮';
    } else if (number == 57) {
      return '৯';
    }

    return '';
  }

  getCheckIsCompleteRegistration(): Observable<boolean> {
    return this.isComplete$;
  }
  setCheckIsCompleteRegistration(value: boolean) {
    this.isComplete.next(value);
    //   localStorage.setItem('isUserApplicationSubmitted', 'false');
  }

  getStpMainInfo(): Observable<boolean> {
    return this.stpMainInfo$;
  }
  setStpMainInfo(value: any) {
    this.stpMainInfo.next(value);
  }

}
function getDigitBanglaFromEnglish() {
  throw new Error('Function not implemented.');
}

