<div class="container-fluid">
  <nz-card [nzExtra]="extraTemplate">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> List of Generic Competency
    </div>
    <div class="boxAerar">
      <nz-table #basicTable [nzData]="csList" nzShowSizeChanger
      nzBordered [nzTotal]="total" [(nzPageSize)]="size"
      [nzShowTotal]="totalRowRangeTemplate" [(nzPageIndex)]="page" >
      <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template>
        <thead>
          <tr>
            <th>Unit Code</th>
            <th>Title (Unit of Competency)</th>
            <th>Duration (Nominal Hours)</th>
            <th>Remarks</th>
            <th [hidden]="(this.showingAction)" >Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.unitCode }}</td>
            <td>{{ data.title }}</td>
            <td>{{ data.duration }}</td>
            <td>{{ data.remark }}</td>
            <td [hidden]="(this.showingAction)" style="text-align: center; vertical-align: middle;">
              <button (click)="onEdit(data.id!)" nz-button nzType="default" [nzSize]="'small'">
                <i nz-icon nzType="edit" nzTheme="fill"></i>
              </button>
              |
              <button nz-popconfirm nzPopconfirmTitle="Are you sure delete this task?" nzPopconfirmPlacement="bottom"
                (nzOnConfirm)="onDelete(data.id!)" nz-button nzType="default" [nzSize]="'small'">
                <i nz-icon nzType="delete" nzTheme="fill"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>

<ng-template #extraTemplate>
  <button
    nz-button
    nzType="primary"
    (click)="createCustomButtonModal()"
    *ngIf="createButton"
  >
    Add Generic Competency
  </button>
</ng-template>
