<app-header></app-header>
<div class="container-fluid">
  <!-- <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="search" nzTheme="outline"></i> Search
    </div>
    <form
      nz-form
      [formGroup]="searchForm"
      class="ant-advanced-search-form"
      (ngSubmit)="submitSearchForm()"
      style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      "
    >
      <div class="row">
        <div class="col-md-4">
          <nz-form-label style="margin-left: 15px"
            >Research Start Date</nz-form-label
          >
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                nzErrorTip="Please insert valid Date"
              >
                <nz-date-picker
                  formControlName="researchFromDate"
                  placeholder="From Date"
                  style="width: 100%"
                >
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <nz-form-label style="margin-left: 15px"
            >Research End Date</nz-form-label
          >
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                nzErrorTip="Please insert valid Date"
              >
                <nz-date-picker
                  formControlName="researchToDate"
                  placeholder="to Date"
                  style="width: 100%"
                >
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12">
            <nz-form-label>Document ID</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please insert valid document id">
                <input
                  nz-input
                  type="text"
                  placeholder="Document ID"
                  formControlName="documentId"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="col-md-12">
            <div class="row">
              <div style="margin-left: 15px">
                <nz-form-label>Research Type</nz-form-label>
              </div>
            </div>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="rtNameErrorTpl">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="researchTypeId"
                  nzPlaceHolder="Select type"
                >
                  <nz-option
                    *ngFor="let rt of researchTypeList"
                    [nzValue]="rt.id"
                    [nzLabel]="rt.name"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #rtNameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select type !
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #rtNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Insert title please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Insert valid title please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area text-right">
          <button nz-button [nzType]="'primary'">Search</button>
          <button class="btn-success ml-3" nz-button (click)="onRefresh()">
            Refresh Data
          </button>
        </div>
      </div>
    </form>
  </nz-card> -->

  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> List of Research
      & Studies
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTable
        [nzData]="rsDemoList"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzPaginationPosition]="'both'"
        [nzScroll]="{ x: '1100px' }"
      >
        <!-- <ng-template
          #totalRowRangeTemplate
          let-range="range"
          let-total
          style="text-align: left"
        >
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template> -->
        <thead>
          <tr>
            <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
            <th style="text-align: center" rowspan="1">Document Name</th>
            <th style="text-align: center" rowspan="1">Document Title</th>
            <!-- <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="document_id"
              [nzSortFn]="true"
            >
              Document ID
            </th> -->
            <!-- <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="area_of_focus"
              [nzSortFn]="true"
            >
              Area of Focus
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="research_type_id"
              [nzSortFn]="true"
            >
              Research Type
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="research_from_date"
              [nzSortFn]="true"
            >
              Research Start Date
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="research_to_date"
              [nzSortFn]="true"
            >
              Research End Date
            </th> -->
            <!-- <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="outcome"
              [nzSortFn]="true"
            >
              Future Scope
            </th> -->
            <!-- <th
              style="width: 15%; text-align: center"
              rowspan="1"
              nzColumnKey="outcome"
              [nzSortFn]="true"
            >
              Document Status
            </th> -->
            <th style="width: 15%; text-align: center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <!-- <td style="text-align: center">
              {{ (page - 1) * size + 1 + i }}
            </td> -->
            <td style="text-align: center">
              {{ data.id }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.name }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.documentTitle }}
            </td>
            <!-- <td style="text-align: center; vertical-align: middle">
                {{
                data.applicationId ? data.applicationId : "Not generated yet"
                }}
              </td> -->
            <!-- <td style="text-align: center; vertical-align: middle">
              {{ data.documentId }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.areaOfFocus }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.researchType.name }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.researchFromDate | date : "dd-MM-yyyy" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.researchToDate | date : "dd-MM-yyyy" }}
            </td> -->
            <!-- <td style="text-align: center; vertical-align: middle">
              {{ data.futureScope }}
            </td> -->
            <!-- <td style="text-align: center; vertical-align: middle">
              <span
                *ngIf="data.statusType?.id == 1"
                class="badge badge-primary"
                style="font-size: 10px"
              >
                {{ data?.statusType?.name }}
              </span>
              <span
                *ngIf="data.statusType?.id == 2"
                class="badge badge-success"
                style="font-size: 10px"
              >
                {{ data?.statusType?.name }}
              </span>
            </td> -->
            <td style="text-align: center; vertical-align: middle">
              <a
                class="ml-2 mt-2"
                nz-tooltip
                nzTooltipTitle="Preview"
                nzTooltipPlacement="bottomLeft"
                nz-button
                [href]="data?.link || '#'"
                nzType="primary"
                target="_blank"
                routerLinkActive="router-link-active"
                style="margin-right: 5px"
              >
                <i nz-icon nzType="eye" nzTheme="outline"></i>
              </a>
              <!-- <a
                class="ml-2 mt-2"
                nz-tooltip
                nzTooltipTitle="View in Details"
                nzTooltipPlacement="bottomLeft"
                nz-button
                nzType="primary"
                (click)="showModalForDetails(data.id)"
                target="_blank"
                routerLinkActive="router-link-active"
                style="margin-right: 5px"
              >
                <i nz-icon nzType="eye" nzTheme="outline"></i>
              </a> -->
              <!-- <a
                nz-button
                type="button"
                nzType="primary"
                (click)="onDownloadFile(data.id)"
                nz-tooltip
                [nzTooltipTitle]="'Download Document'"
                nzTooltipPlacement="bottomLeft"
                style="margin-right: 5px"
              >
                <i nz-icon nzType="download" nzTheme="outline"></i>
              </a> -->
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>
<nz-modal
  [nzTitle]="'Research & Studies Details Information'"
  [(nzVisible)]="isVisibleDetails"
  (nzOnCancel)="handleCancelDetailsModal()"
  [nzWidth]="1100"
>
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Research &
      Studies Details
    </div>
    <div class="container-fluid">
      <nz-table
        #basicTable
        [nzData]="['']"
        nzBordered
        [nzShowPagination]="false"
        nzAlign="middle"
      >
        <tbody *ngIf="researchAndStudiesMainInfoDetails">
          <tr>
            <td style="text-align: left; font-weight: bold">Research Type</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.researchType.name }}
            </td>
            <td style="text-align: left; font-weight: bold">Title</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.title }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Abstract</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.abstracts }}
            </td>
            <td style="text-align: left; font-weight: bold">Area of Focus</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.areaOfFocus }}
            </td>
          </tr>

          <tr>
            <td style="text-align: left; font-weight: bold">Document ID</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.documentId }}
            </td>
            <td style="text-align: left; font-weight: bold">Outcome</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.outcome }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Future Scope</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.futureScope }}
            </td>
            <td style="text-align: left; font-weight: bold">Conduct Type</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.conductType?.name }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Conducted By</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.conductedByName }}
            </td>
            <td style="text-align: left; font-weight: bold">Other Conductor</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.otherConductor }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Execution Type</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.executionType?.name }}
            </td>
            <td style="text-align: left; font-weight: bold">Executed By</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.executionType?.name }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Other Executor</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.otherExecutor }}
            </td>
            <td style="text-align: left; font-weight: bold">Supported By</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.supportedBy }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Commission By</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.commission.name }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Other Commission
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.otherCommission }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Fund</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.fund?.name }}
            </td>
            <td style="text-align: left; font-weight: bold">Other Fund</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails?.otherFund }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Research From Date
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.researchFromDate
                  | date : "dd-MM-YYYY"
              }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Research To Date
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.researchToDate
                  | date : "dd-MM-YYYY"
              }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Validation Conducted?
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.isValidationConducted == true
                  ? "Yes"
                  : "No"
              }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Date of Validation
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails?.dateOfValidation
                  | date : "dd-MM-yyyy"
              }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Date of Publication
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails?.dateOfPublication
                  | date : "dd-MM-yyyy"
              }}
            </td>
            <td style="text-align: left; font-weight: bold">Sector Specify?</td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.isSectorSpecify == true
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Name of Sector</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.nameOfSector }}
            </td>
            <td style="text-align: left; font-weight: bold">Questionnaire</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.questionnaire }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Tor</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.tor }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Related Research
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.relatedResearch }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Language</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.language.name }}
            </td>
            <td style="text-align: left; font-weight: bold">Access Type</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.accessType.name }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Remarks</td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.remarks }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Opinion Open to Collect?
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.isOpinionOpenToCollect == true
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Opinion Start Date
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.opinionStartDate
                  | date : "dd-MM-yyyy"
              }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Opinion End Date
            </td>
            <td style="text-align: left">
              {{
                researchAndStudiesMainInfoDetails.opinionEndDate
                  | date : "dd-MM-yyyy"
              }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Opinion Giver Name
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.opinionGiverName }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Opinion Giver Occupation
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.opinionGiverOccupation }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Opinion Giver Designation
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.opinionGiverDesignation }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Opinion Giver Company
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.opinionGiverCompany }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">
              Opinion Giver Email
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.opinionGiverEmail }}
            </td>
            <td style="text-align: left; font-weight: bold">
              Opinion Giver Mobile
            </td>
            <td style="text-align: left">
              {{ researchAndStudiesMainInfoDetails.opinionGiverMobile }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-weight: bold">Objective</td>
            <td style="text-align: left; width: 30%">
              {{ researchAndStudiesMainInfoDetails.objectives }}
            </td>
            <td style="text-align: left; font-weight: bold">Other Research</td>
            <td style="text-align: left; width: 30%">
              {{ researchAndStudiesMainInfoDetails?.otherResearch }}
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="handleCancelDetailsModal()">
          Close
        </button>
      </div>
    </div>
  </nz-card>
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Author Info
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTables
        [nzData]="authorList"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzFrontPagination]="false"
      >
        <thead>
          <tr style="overflow: auto">
            <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
            <th style="text-align: center" rowspan="1">Name</th>
            <th style="text-align: center" rowspan="1">Designation</th>
            <th style="text-align: center" rowspan="1">Email</th>
            <th style="text-align: center" rowspan="1">Contact No</th>
            <th style="text-align: center" rowspan="1">Gender</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTables.data; let i = index">
            <td style="text-align: center">
              {{ 1 + i }}
            </td>
            <td style="text-align: center">
              {{ data.name }}
            </td>

            <td style="text-align: center">
              {{ data.designation }}
            </td>
            <td style="text-align: center">
              {{ data.email }}
            </td>
            <td style="text-align: center">
              {{ data.contactNo }}
            </td>
            <td style="text-align: center">
              {{ data?.gender == "male" ? "Male" : "Female" }}
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</nz-modal>
<app-footer></app-footer>
