<app-header></app-header>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Industry Skills Council (ISC)
  </div>
  <!-- <ng-container style="padding: 24px; background: #fbfbfb; border: 1px solid #d9d9d9; border-radius: 6px;">
    <nz-table #basicTable
    [nzData]="publishedIscList"
    nzTableLayout="fixed"
    nzShowSizeChanger
    nzBordered
    nzSize="middle"
    nzAlign="middle"
    [nzFrontPagination]="false"
    [nzTotal]="total"
    [(nzPageSize)]="pageSize"
    [nzShowTotal]="totalRowRangeTemplate"
    [(nzPageIndex)]="currentPage" (nzQueryParams)="onPageIndexChange($event)"
    [nzPaginationPosition]="'both'"
    [nzScroll]="{ x: '1250px' }">
      <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template>
      <thead>
        <tr style="overflow: auto">
          <th style="width: 5%; text-align: center;">Sl.</th>
          <th style="width: 45%; text-align: center;" nzColumnKey="isc_name_eng" [nzSortFn]="true">ISC Name</th>
          <th style="width: 25%; text-align: center;" nzColumnKey="registration_no" [nzSortFn]="true">Registration No</th>
          <th style="width: 25%; text-align: center;" nzColumnKey="license_no" [nzSortFn]="true">License No</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of basicTable.data; let i = index">
          <tr>
            <td style="text-align: center;">{{ (currentPage - 1) * pageSize + 1 + i }}</td>
            <td style="text-align: center;">
              <a [routerLink]="['/industry-skills-council/published-isc-director-list/' + data?.id]"
              target="_blank" routerLinkActive="router-link-active">{{ data?.iscNameEng }}</a>
            </td>
            <td style="text-align: center;">{{ data?.registrationNo }}</td>
            <td style="text-align: center;">{{ data?.licenseNo }}</td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-container> -->

  <ng-container style="padding: 24px; background: #fbfbfb; border: 1px solid #d9d9d9; border-radius: 6px;">
    <nz-table #basicTable
    [nzData]="publishedIscListDemo"
    nzTableLayout="fixed"
    nzShowSizeChanger
    nzBordered
    nzSize="middle"
    nzAlign="middle"
    [nzFrontPagination]="false"
    [nzPaginationPosition]="'both'"
    [nzScroll]="{ x: '1250px' }">
      <thead>
        <tr style="overflow: auto">
          <th style="width: 10%; text-align: center;">Sl.</th>
          <th style="width: 90%; text-align: center;">ISC Name</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of basicTable.data; let i = index">
          <tr>
            <td style="text-align: center;">{{ 1 + i }}</td>
            <td style="text-align: center;">
              <a *ngIf="data?.link != ''" href= '{{data?.link}}'
              target="_blank" routerLinkActive="router-link-active">{{ data?.name }}</a>
              <a *ngIf="data?.link == ''"
              target="_blank" routerLinkActive="router-link-active">{{ data?.name }}</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-container>
</nz-card>
<app-footer></app-footer>
