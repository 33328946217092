import { SharedModule } from './../shared/modules/shared/shared.module';
import { httpInterceptorProviders } from './../shared/services/http-interceptors/index';
import { NgModule } from '@angular/core';
import { DefaultLayoutModule } from '../shared/modules/default-layout/default-layout.module';
import { AuthenticationModule } from '../modules/authentication/authentication.module';

@NgModule({
  imports: [SharedModule, AuthenticationModule, DefaultLayoutModule],
  declarations: [],
  providers: [httpInterceptorProviders],
})
export class CoreModule {}
