import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { ResearchStudiesMainSearch } from '../../models/research-studies-main-search-dto.model';
import { ResearchAndStudiesStorageService } from '../../services/research-and-studies-storage.service';
import { ResearchType } from '../../models/l_research-type.model';
import { ResearchStudiesMain } from '../../models/research-studies-main.model';
import { Author } from '../../models/author.model';
import { environment } from 'src/environments/environment';
import { RelatedResearch } from '../../models/related-research-DTO';
import { Attachment } from '../../models/attachment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-research-list-public',
  templateUrl: './research-list-public.component.html',
  styleUrls: ['./research-list-public.component.scss'],
})
export class ResearchListPublicComponent implements OnInit {
  searchForm: FormGroup;
  opinionForm: FormGroup;

  baseUrl: string;
  researchStudiesMainSearch: ResearchStudiesMainSearch;
  researchTypeList: ResearchType[] = [];
  adminListInfo: ResearchStudiesMain[] = [];
  authorList: Author[] = [];

  relatedResearchList: RelatedResearch[] = [];
  researchAndStudiesMainInfoDetails: any;
  stpMainId: number;
  stpCenterId: number;
  occupationId: number;
  attachmentList: Attachment[] = [];
  researchStudiesMainId: number;

  // isVisibleDetails: boolean = false;
  applicationPermissions = applicationPermissions;
  previousFileBase64: any;

  //#region Excel part
  excelReportData: any;
  //#endregion

  // for pagination
  total: number = 0;
  size: number = 10;
  page: number = 1;
  sortingKey: string;
  sortingValue: string;

  //#region image part
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];
  pdfUrl: any;
  imageUrl: any;
  imageURL: SafeUrl;
  signatureImageURL: SafeUrl;
  isImageLoading: boolean = true;
  isVisible: boolean = false;
  //#endregion

  constructor(
    private fb: FormBuilder,
    private researchAndStudiesStorageService: ResearchAndStudiesStorageService,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  //#region fetch research type data
  fetchResearchTypeData() {
    //Get researchType list
    this.researchAndStudiesStorageService.readResearchType().subscribe({
      next: (res) => {
        this.researchTypeList = [...res];
        this.researchTypeList.forEach((item, index) => {
          item.name = `${item.name} (${item.code})`;
        });
      },
    });
  }
  //#endregion

  formInit() {
    this.opinionForm = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.pattern(/^(?!\s*$)[a-zA-Z\s]+$/)],
      ],
      designation: [
        '',
        [Validators.pattern(/^(?!\s*$)[a-zA-Z\s]+$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
      contactNo: [
        '',
        [
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(11),
          Validators.pattern(/^01[0-9]*$/),
        ],
      ],
      opinion: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });
  }

  ngOnInit() {
    // this.fetchBaseUrl();
    this.formInit();
    this.fetchResearchTypeData();
    this.createSearchForm();
    this.submitSearchForm();
  }

  loadDatasFromServer(researchStudiesMainSearch: ResearchStudiesMainSearch) {
    this.researchAndStudiesStorageService
      .readResearchAndStudiesPublicInfo(researchStudiesMainSearch)
      .subscribe({
        next: (res: ServerResponse) => {
          if (res.success) {
            this.adminListInfo = res?.data?.content;
            this.total = res.data?.totalElements;
          } else {
            this.adminListInfo = [];
            this.total = 0;
          }
        },
        error: (error) => {
          // this.notification.warning('Warning!!! ', 'No data found');
          this.adminListInfo = [];
          this.total = 0;
        },
      });
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      documentId: [''],
      researchTypeId: [null],
      researchFromDate: [null],
      researchToDate: [null],
      title: [null],
    });
  }

  //#region submit
  submitSearchForm() {
    var pipe = new DatePipe('en-US');
    if (this.searchForm.controls.researchFromDate.value) {
      const generalFromDateFormat = pipe.transform(
        this.searchForm.controls.researchFromDate.value,
        'yyyy-MM-dd 00:00'
      );
      this.searchForm.controls.researchFromDate.setValue(generalFromDateFormat);
    }
    if (this.searchForm.controls.researchToDate.value) {
      const generalToDateFormat = pipe.transform(
        this.searchForm.controls.researchToDate.value,
        'yyyy-MM-dd 23:59'
      );
      this.searchForm.controls.researchToDate.setValue(generalToDateFormat);
    }
    this.researchStudiesMainSearch = this.searchForm.value;
    this.researchStudiesMainSearch.size = 10;
    this.researchStudiesMainSearch.page = 0;
    this.loadDatasFromServer(this.researchStudiesMainSearch);
  }
  //#endregion

  onRefresh() {
    this.searchForm.reset();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });

    this.researchStudiesMainSearch.size = this.size;
    this.researchStudiesMainSearch.page = this.page - 1;
    this.researchStudiesMainSearch.sortingKey = this.sortingKey;
    this.researchStudiesMainSearch.sortingValue = this.sortingValue;
    this.loadDatasFromServer(this.researchStudiesMainSearch);
  }

  getDocumentsInfoById(id: number): void {
    this.researchAndStudiesStorageService
      .getResearchAndStudiesMainInfoDetailsById(id)
      .subscribe({
        next: (res) => {
          this.researchAndStudiesMainInfoDetails = res.data;
          this.relatedResearchList =
            this.researchAndStudiesMainInfoDetails?.relatedResearchList;
          this.attachmentList =
            this.researchAndStudiesMainInfoDetails?.previewAttachments;
        },
      });
  }

  getAuthorInfoById(id: number): void {
    this.researchAndStudiesStorageService.getAuthorInfoById(id).subscribe({
      next: (res) => {
        this.authorList = res.data;
      },
    });
  }

  async setBase64AttachmentforPreviewing(fileName: string, i: number) {
    let fileUrl = '';
    switch (fileName) {
      case 'document':
        fileUrl =
          this.researchAndStudiesMainInfoDetails?.previewAttachments[i]
            ?.fileUrl ?? '';
        break;
    }
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    let fileUrlBase64 = URL.createObjectURL(blob);
    let fileType = this.getFileTypeFromPreviousAttachment(fileUrl);
    this.previewAllImage(fileType, fileUrlBase64);
  }

  previewAllImage(fielType: string | undefined, fileUrl: any) {
    this.imageURL = '';
    this.pdfUrl = '';
    if (fielType == 'image') {
      this.isVisible = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else if (fielType == 'pdf') {
      this.isVisible = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.previousFileBase64);
      link.setAttribute('download', 'uploaded_document');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;

    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType)) {
      contentType = 'image';
    } else if (fileType == 'pdf') {
      contentType = 'pdf';
    } else if (fileType == 'doc') {
      contentType = 'doc';
    } else if (fileType == 'docx') {
      contentType = 'docx';
    } else if (fileType == 'xlsx') {
      contentType = 'xlsx';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  //#endregion

  // handleCancelDetailsModal(): void {
  //   this.isVisibleDetails = false;
  // }

  isVisibleOpinion: boolean = false;

  showModalForOpinion(id: number): void {
    this.researchStudiesMainId = id;
    this.isVisibleOpinion = true;
  }

  handleCancelOpinionModal(): void {
    this.isVisibleOpinion = false;
    this.resetOpinionForm();
  }

  saveOpinion() {
    let opinionData = this.opinionForm.value;
    opinionData.researchStudiesMainId = this.researchStudiesMainId;
    this.researchAndStudiesStorageService.saveOpinion(opinionData).subscribe({
      next: (response) => {
        if (response.success) {
          this.notification.success('Success!', response.message);
          this.resetOpinionForm();
        } else if (response.success == false) {
          this.notification.error('Error!', response.message);
        }
      },
      error: (error) => {
        this.notification.error('Failed', error);
      },
    });
  }
  //#endregion

  resetOpinionForm() {
    this.opinionForm.reset();
    for (const key of Object.keys(this.opinionForm.controls)) {
      this.opinionForm.controls[key].markAsPristine();
      this.opinionForm.controls[key].updateValueAndValidity();
    }
  }

  checkIsOpinionOpenToCollect(collectOpinionED?: any) {
    let today = new Date();
    let dateToday = formatDate(today, 'yyyyMMdd', 'en_US');
    let opinionEndDate = formatDate(collectOpinionED, 'yyyyMMdd', 'en_US');
    if (collectOpinionED != null) {
      if (
        parseInt(dateToday.toString()) <= parseInt(opinionEndDate.toString())
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  encodeParameter(param: number): string {
    return btoa(String(param));
  }

  // Example method to use when a view button is clicked
  onViewButtonClick(researchStudiesMainId: number) {
    const encodedId = this.encodeParameter(researchStudiesMainId);
    this.router.navigate(['/research-and-studies/document-public-view'], {
      queryParams: { researchStudiesMainId: encodedId },
      relativeTo: this.activatedRoute,
    });
  }
}
