import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { RplStorageService } from 'src/app/modules/stp-operations/services/rpl-storage.service';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CircularStorageService } from '../../services/circular-storage.service';
import { CirApplicantType } from '../../models/cir-applicant-type.model';
import { CirOrganizationType } from '../../models/cir-organization-type.model';
import { CircularInfo } from '../../models/circular-info.model';
import { CircularInfoSearch } from '../../models/DTO/circular-info-search';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-circular-list-public',
  templateUrl: './circular-list-public.component.html',
  styleUrls: ['./circular-list-public.component.scss'],
})
export class CircularListPublicComponent implements OnInit {
  searchForm: FormGroup;

  circularInfoSearch: CircularInfoSearch;
  circularListInfo: CircularInfo[] = [];
  cirApplicantTypeList: CirApplicantType[] = [];
  // cirOrganizationTypeList: CirOrganizationType[] = [];
  circularInfo: CircularInfo;

  applicationPermissions = applicationPermissions;

  //#region Excel part
  excelReportData: any;
  //#endregion

  // for pagination
  total: number = 0;
  size: number = 10;
  page: number = 1;
  sortingKey: string = 'generated_circular_id';
  sortingValue: string = 'descend';

  //#region image part
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];
  pdfUrl: any;
  imageUrl: any;
  imageURL: SafeUrl;
  signatureImageURL: SafeUrl;
  isImageLoading: boolean;
  isVisible: boolean = false;
  //#endregion

  constructor(
    private fb: FormBuilder,
    private rplStorageService: RplStorageService,
    private circularStorageService: CircularStorageService,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer,
    private excelExport: ExcelDownloadService,
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.translate.use(environment.language.setDefaultLang);
  }

  //#region fetch research type data
  fetchCirApplicantTypeData() {
    this.circularStorageService.readCirApplicantType().subscribe({
      next: (res) => {
        this.cirApplicantTypeList = [...res];
        this.cirApplicantTypeList.forEach((item, index) => {
          item.name = `${item.name}`;
        });
      },
    });
  }
  //#endregion

  // //#region fetch research type data
  // fetchCirOrganizationTypeData() {
  //   this.circularStorageService.readCirOrganizationType().subscribe({
  //     next: (res) => {
  //       this.cirOrganizationTypeList = [...res];
  //       this.cirOrganizationTypeList.forEach((item, index) => {
  //         item.name = `${item.name}`;
  //       });
  //     },
  //   });
  // }
  // //#endregion

  ngOnInit() {
    this.fetchCirApplicantTypeData();
    // this.fetchCirOrganizationTypeData();
    this.createSearchForm();
    this.submitSearchForm();
  }

  loadDatasFromServer(circularInfoSearch: CircularInfoSearch) {
    this.circularStorageService
      .readCircularPublicInfo(circularInfoSearch)
      .subscribe({
        next: (res: ServerResponse) => {
          if (res.success) {
            this.circularListInfo = res?.data?.content;
            this.total = res.data?.totalElements;
          } else {
            this.circularListInfo = [];
            this.total = 0;
          }
        },
        error: (error) => {
          this.notification.warning('Warning!!! ', 'No data found');
          this.circularListInfo = [];
          this.total = 0;
        },
      });
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      applicantTypeId: [null],
      orgTypeId: [null],
      maxCourseInApplication: [null],
      generatedCircularId: [''],
      // isPublished: [''],
      circularStartDate: [null],
      circularEndDate: [null],
      globalText: [null],
    });
  }

  //#region submit form
  submitSearchForm() {
    var pipe = new DatePipe('en-US');
    if (this.searchForm.controls.circularStartDate.value) {
      const generalFromDateFormat = pipe.transform(
        this.searchForm.controls.circularStartDate.value,
        'yyyy-MM-dd 00:00'
      );
      this.searchForm.controls.circularStartDate.setValue(
        generalFromDateFormat
      );
    }
    if (this.searchForm.controls.circularEndDate.value) {
      const generalToDateFormat = pipe.transform(
        this.searchForm.controls.circularEndDate.value,
        'yyyy-MM-dd 23:59'
      );
      this.searchForm.controls.circularEndDate.setValue(generalToDateFormat);
    }
    this.circularInfoSearch = this.searchForm.value;
    this.circularInfoSearch.size = 10;
    this.circularInfoSearch.page = 0;

    if (this.searchForm?.get('globalText')?.value) {
      this.getResearchStudiesDataByGlobalSearch(this.circularInfoSearch);
    } else {
      this.loadDatasFromServer(this.circularInfoSearch);
    }
  }
  //#endregion

  onRefresh() {
    this.searchForm?.reset();
    this.loadDatasFromServer(this.circularInfoSearch);
  }

  getResearchStudiesDataByGlobalSearch(circularInfoSearch: CircularInfoSearch) {
    this.circularStorageService
      .getCircularDataByGlobalSearch(circularInfoSearch)
      .subscribe({
        next: (res: ServerResponse) => {
          if (res.success) {
            this.circularListInfo = res?.data?.content;
            this.total = res.data?.totalElements;
          } else {
            this.circularListInfo = [];
            this.total = 0;
          }
        },
        error: (error) => {
          this.notification.warning('Warning!!! ', 'No data found');
          this.circularListInfo = [];
          this.total = 0;
        },
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex ? params.pageIndex : 1;
    this.size = params.pageSize ? params.pageSize : 10;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });

    this.circularInfoSearch.size = this.size;
    this.circularInfoSearch.page = this.page - 1;
    this.circularInfoSearch.sortingKey = this.sortingKey;
    this.circularInfoSearch.sortingValue = this.sortingValue;
    this.loadDatasFromServer(this.circularInfoSearch);
  }

  //#region roll back
  onRollBack(id: number, assessmentType: number) {
    this.rplStorageService.onRollBack(id, assessmentType).subscribe({
      next: (res) => {
        if (res.success) {
          this.notification.success('Success!', res.message);
          this.submitSearchForm();
          // this.router.navigateByUrl(
          //   '/home/'
          // );
        } else {
          this.notification.error('Failed!', res.message);
        }
      },
      error: (error) => {
        this.notification.error('Failed', error);
      },
    });
  }
  //#endregion

  deleteCircular(id: number, index: number) {
    this.circularStorageService.deleteCircular(id).subscribe(
      (res: ServerResponse) => {
        if (res.success) {
          this.notification.success('Success!', res.message);
          this.onRefresh();
        } else {
          this.notification.error('Error!', res.message);
        }
        // this.onRefresh();
      },
      (err) => {
        this.notification.error('Error!', 'Something went wrong!!');
      }
    );
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  getFormatDataForExcel(dataReport: any) {
    let i = 1;
    const dataList = [];
    for (const data of dataReport) {
      const reportMap = new Map();
      reportMap.set('#SL', i);
      reportMap.set('Circular ID', data.generatedCircularId);
      reportMap.set('Circular Applicant Type', data.cirApplicantTypeName);
      reportMap.set(
        'Is Max Course Applicable?',
        data.isMaxCourseApplicable == true ? 'Yes' : 'No'
      );
      reportMap.set(
        'Is Max Course in Application?',
        data.maxCourseInApplication
      );
      reportMap.set(
        'Circular Start Date',
        this.datePipe.transform(data.circularStartDate, 'MM-dd-yyyy')
      );
      reportMap.set(
        'Circular To Date',
        this.datePipe.transform(data.circularEndDate, 'MM-dd-yyyy')
      );
      reportMap.set(
        'Is Payment Applicable?',
        data.isPaymentApplicable == true ? 'Yes' : 'No'
      );
      reportMap.set('Payment Amount', data.payment);
      reportMap.set('Organization Type', data.organizationTypeName);
      reportMap.set('Circular Status', data.statusTypeName);
      reportMap.set(
        'Course Expiry Date',
        this.datePipe.transform(data.courseExpiryDate, 'MM-dd-yyyy')
      );
      reportMap.set(
        'Center Expiry Date',
        this.datePipe.transform(data.centerExpiryDate, 'MM-dd-yyyy')
      );
      reportMap.set('Is Published?', data.isPublished == true ? 'Yes' : 'No');
      const jsonObject: any = {};
      reportMap.forEach((value, key) => {
        jsonObject[key] = value;
      });
      dataList.push(jsonObject);
      i++;
    }
    return dataList;
  }

  exportPdf(dataList: any[], reportTitle: string) {
    const doc = new jsPDF();

    const headers = Object.keys(dataList[0]);

    const data = dataList.map((item) => headers.map((key) => item[key]));

    doc.text(reportTitle, 14, 16);
    autoTable(doc, {
      startY: 20,
      head: [headers],
      body: data,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      headStyles: {
        fillColor: [0, 81, 158],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
      },
      bodyStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255],
      },
      margin: { top: 30 },
    });

    doc.save(`${reportTitle}.pdf`);
  }
  encodeParameter(param: number): string {
    return btoa(String(param));
  }

  onViewButtonClick(circularId: number) {
    const encodedId = this.encodeParameter(circularId);
    this.router.navigate(['/financial/circular-view'], {
      queryParams: { circularId: encodedId },
      relativeTo: this.activatedRoute,
    });
  }

  async downloadZipAttachment(generatedCircularId: any, attachmentList: any) {
    const zip = new JSZip();
    const name = generatedCircularId + '.zip';

    for (let i = 0; i < attachmentList.length; i++) {
      const data = await fetch(environment.fileServiceApiUrl + attachmentList[i]?.attachmentUrl);

      if (data?.status == 200) {
        const blob = await data.blob();
        zip.file(attachmentList[i]?.attachmentTitle, blob);
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }
}
