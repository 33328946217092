import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { InspectionCadDto } from '../../course-accreditation/DTO/InspectionCadDto';
import { BasicInformation } from '../../course-accreditation/models/BasicInformation';
import { fileDeleteRequest } from '../DTO/fileDeleteRequest';
import { Evidence } from '../models/evidence';
import { CourseAccInspectionReportService } from './course-acc-inspection-report.service';

@Injectable()
export class CourseAccInspectionReportStorageService {
  constructor(
    private httpClient: HttpClient,
    private courseAccInspectionReportService: CourseAccInspectionReportService,
    private httpErrorHandler: HttpErrorHandler,
    private router: Router
  ) { }

  //#region All tab save Info (Inspection)

  createBasicInformation(
    basicInformation: BasicInformation
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveBasicInspection}`,
        basicInformation
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.courseAccInspectionReportService.addBasicInformation(res.data);
          }
        })
      );
  }

  saveAllCadForCourseAccreditation(
    inspectionCadDto: InspectionCadDto
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.inspection.saveInspectionCad}`,
        inspectionCadDto
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.courseAccInspectionReportService.addBasicInformation(res.data);
          }
        })
      );
  }
  //#endregion All tab save Info (Inspection)

  //#region get all inspection by acl user id(Inspection)

  searchInspectionList(page: number, size: number): Observable<ServerResponse> {
    const startPage = page - 1;
    let param: string = '';
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getAllInspectionbyAclUserId}` +
      '?page=' +
      `${startPage}` +
      '&size=' +
      `${size}`
    );
  }

  saveCctv(cctv: any): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.cctv.saveCctv}`,
      cctv
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getAllInspectionbyAclUserId(): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getAllInspectionbyAclUserId}`
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getSummaryOccupationListByInspectionId(
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getSummaryOccupationListByInspectionId}` +
      id
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getCourseAccreditationByResultId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getCourseAccreditationByResultId}` + id
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getAllApplicationTypeByAccreditation(type: string) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getAllApplicationTypeByAccreditation}?type=${type}`
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }

  getAllApplicationType() {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.getAllApplicationTypes}`
    );
  }

  submitCourseAccreditationApplication(id: number) {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.submitApplication}`,
      { id: id }
    );
  }
  //#endregion get all inspection by acl user id(Inspection)

  //#region occupation delete
  deleteOccupation(id?: number | null): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.deleteOccupation}`,
      { inspectionResultId: id }
    );
    // .pipe(
    //   map((serverResponse: ServerResponse) => {
    //     return serverResponse.data;
    //   }),
    //   tap((updatedRole) => {
    //     this.authorizationService.deleteDesignation(id);
    //   })
    // );
  }
  //#endregion occupation delete



  //#region Course Acc. Inspection Report
  saveCourseAccInspectionReport(evidance: Evidence): Observable<ServerResponse> {
    let formData = new FormData();

    evidance.files?.forEach((item: any, index: number) => {
      formData.append("file", item);
    })

    let evidenceObject = { ...evidance };
    delete evidenceObject.files;
    formData.append('inspectionDto', JSON.stringify(evidenceObject));


    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.courseAccInspectionReport.saveCourseAccInspectionReport}`, formData);
  }


  saveAllCadForCourseAccreditationInspectionReport(
    inspectionCadDto: InspectionCadDto
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.courseAccInspectionReport.savecourseAccInspectionCadReport}`,
        inspectionCadDto
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.courseAccInspectionReportService.addBasicInformation(res.data);
          }
        })
      );
  }



  saveCctvForCourseAccInspectionReport(cctv: any): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.inspection.cctv.saveCctvForCourseAccInspectionReport}`,
      cctv
    );
    // .pipe(
    //   tap((res) => {
    //     if (res.data) {
    //       this.courseAccInspectionReportService.addBasicInformation(res.data);
    //     }
    //   })
    // );
  }
  //#endregion Course Acc. Inspection Report



  //#region Recommendation
  getAllRecommendation() {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.recommendation.getAllRecommendation}`
    );
  }
  getRecommendationByType(type: string): Observable<ServerResponse> {

    let params = new HttpParams();
    params = params.append("type", type);

    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.recommendation.getRecommendationByType}`, { params: params })
      .pipe(
        tap((response) => {
          if (response.data) {
            this.courseAccInspectionReportService.setRecommandation(response.data);
          }
        })
      );
  }

  saveCourseAccInspectionReportPreview(stpSacScrutinyResultDto: any): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(`${applicationUrls.scrutiny.saveCourseAccInspectionReportPreview}`, stpSacScrutinyResultDto)
  }
  //#endregion Recommendation

  //region remove attachment from inspection report
   deleteCourseAccAttachmentReport(data : fileDeleteRequest) : Observable<ServerResponse>{
    return this.httpClient.post<ServerResponse>(`${applicationUrls.courseAccInspectionReport.deleteCourseAccInspectionReportAttachment}`, data);
   }

  //#endregion
}
