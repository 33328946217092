import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../authorization/services/authorization.service';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ResearchAndStudiesService } from './research-and-studies.service';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { map } from 'rxjs/operators';
import { DemandSurvey } from '../models/demand-survey.model';

@Injectable({
  providedIn: 'root',
})
export class DemandSurveyStorageService {
  constructor(
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService,
    private httpErrorHandler: HttpErrorHandler,
    private notification: NzNotificationService,
    private researchAndStudiesService: ResearchAndStudiesService
  ) {}

  readSkillLevelData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.readSkillLevel}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readSubSectorData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.industrySkillsCouncil.readSubSectorInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readBnqfLevelData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.readBnqfLevel}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readEducationLevelData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.readEducationLevel}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readPotentialToEmployData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.readPotentialToEmployLevel}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readCompetenciesData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.readCompetencies}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  //#region Save demand survey
  saveDemandSurveyInfo(
    demandSurveyInfo: DemandSurvey
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.demandSurvey.save}`,
      demandSurveyInfo
    );
  }
  //#endregion

  //#region Save demand survey
  updateDemandSurveyInfo(
    demandSurvey: any,
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.researchAndStudies.demandSurvey.update}` + id,
      demandSurvey
    );
  }
  //#endregion

  //#region get demand survey info by research studies main id
  readDemandSurveyInfoByRsMainId(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.getDemandSurveyInfoByRSMainId}` +
          id
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region get demand survey info by id
  readDemandSurveyInfoById(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.demandSurvey.getDemandSurveyInfoById}` +
          id
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  deleteDemandSurvey(demandSurveyId?: number): Observable<ServerResponse> {
    return this.httpClient.delete<ServerResponse>(
      `${applicationUrls.researchAndStudies.demandSurvey.delete}` + demandSurveyId
    );
  }
}
