import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TamIndustrialSector } from '../models/tam-Industrial-sector.model';
import { TamLanguage } from '../models/tam-language.model';
import { TamLevel } from '../models/tam-level.model';
import { TamMain } from '../models/tam-main.model';
import { TamOccupation } from '../models/tam-occupation.model';
import { TamType } from '../models/tam-type.model';

@Injectable({
  providedIn: 'root',
})
export class TrainingAndAssessmentMaterialsService {
  onStartTamMainEditing = new Subject<number | null>();
  onRefreshTamMainList = new Subject<TamMain[]>();
  tamMains: TamMain[] = [];
  editingTamMainId?: number | null;

  onStartTamOccupationEditing = new Subject<number | null>();
  onRefreshTamOccupationList = new Subject<TamOccupation[]>();
  tamOccupations: TamOccupation[] = [];
  editingTamOccupationId!: number | null;

  onRefreshTamTypeList = new Subject<TamType[]>();
  tamTypes: TamType[] = [];

  onRefreshTamLevelList = new Subject<TamLevel[]>();
  tamLevels: TamLevel[] = [];

  onRefreshTamLanguageList = new Subject<TamLanguage[]>();
  tamLanguages: TamLanguage[] = [];

  onRefreshTamIndustrialSectorList = new Subject<TamIndustrialSector[]>();
  tamIndustrialSectors: TamIndustrialSector[] = [];

  constructor() {}

  //#region TAM_MAIN
  getTamMains(): any {
    return this.tamMains;
  }

  getTamMainById(id?: number | null): TamMain | undefined {
    return this.tamMains.find((tamMain) => tamMain.id === id);
  }

  setTamMains(tamMains: TamMain[]): void {
    this.tamMains = tamMains;
    this.onRefreshTamMainList.next(this.tamMains.slice());
  }

  addTamMain(tamMain: TamMain): void {
    this.tamMains.push(tamMain);
    this.onRefreshTamMainList.next(this.tamMains.slice());
  }

  upatedTamMain(newRecipe: TamMain, id: number): void {
    const index = this.tamMains.findIndex((tamMain) => tamMain.id === id);
    this.tamMains[index] = newRecipe;
    this.onRefreshTamMainList.next(this.tamMains.slice());
  }

  deleteTamMain(id?: number | null): void {
    this.tamMains = this.tamMains.filter((tamMain) => {
      return tamMain.id !== id;
    });
    this.onRefreshTamMainList.next(this.tamMains.slice());
  }

  getEditingTamMainId(): any {
    return this.editingTamMainId;
  }

  setEditingTamMainId(id?: number): void {
    this.editingTamMainId = id;
    this.onStartTamMainEditing.next(id);
  }

  clearEditingTamMainId(): void {
    this.editingTamMainId = null;
    this.onStartTamMainEditing.next(null);
  }

  clearTamMains(): void {
    this.tamMains = [];
    this.onRefreshTamMainList.next([]);
  }

  //#endregion TAM_MAIN

  //#region TAM_OCCUPATION
  getTamOccupations(): TamOccupation[] {
    return this.tamOccupations;
  }

  getTamOccupationById(id: number | null): TamOccupation | undefined {
    return this.tamOccupations.find((tamOccupation) => tamOccupation.id === id);
  }

  setTamOccupations(tamOccupations: TamOccupation[]): void {
    this.tamOccupations = tamOccupations;
    this.onRefreshTamOccupationList.next(this.tamOccupations.slice());
  }

  addTamOccupation(tamOccupation: TamOccupation): void {
    this.tamOccupations.push(tamOccupation);
    this.onRefreshTamOccupationList.next(this.tamOccupations.slice());
  }

  upatedTamOccupation(newRecipe: TamOccupation, id: number): void {
    const index = this.tamOccupations.findIndex(
      (tamOccupation) => tamOccupation.id === id
    );
    this.tamOccupations[index] = newRecipe;
    this.onRefreshTamOccupationList.next(this.tamOccupations.slice());
  }

  deleteTamOccupation(id: number): void {
    this.tamOccupations = this.tamOccupations.filter((tamOccupation) => {
      return tamOccupation.id !== id;
    });
    this.onRefreshTamOccupationList.next(this.tamOccupations.slice());
  }

  getEditingTamOccupationId(): any {
    return this.editingTamOccupationId;
  }

  setEditingTamOccupationId(id: number): void {
    this.editingTamOccupationId = id;
    this.onStartTamOccupationEditing.next(id);
  }

  clearEditingTamOccupationId(): void {
    this.editingTamOccupationId = null;
    this.onStartTamOccupationEditing.next(null);
  }

  clearTamOccupations(): void {
    this.tamOccupations = [];
    this.onRefreshTamOccupationList.next([]);
  }
  //#endregion TAM_OCCUPATION

  //#region TAM_TYPE
  getTamTypes(): TamType[] {
    return this.tamTypes;
  }

  getTamTypeById(id: number | null): TamType | undefined {
    return this.tamTypes.find((tamType) => tamType.id === id);
  }

  setTamTypes(tamTypes: TamType[]): void {
    this.tamTypes = tamTypes;
    this.onRefreshTamTypeList.next(this.tamTypes.slice());
  }

  clearTamTypes(): void {
    this.tamTypes = [];
    this.onRefreshTamTypeList.next([]);
  }
  //#endregion TAM_TYPE

  //#region TAM_LEVEL
  getTamLevels(): TamLevel[] {
    return this.tamLevels;
  }

  getTamLevelById(id: number | null): TamLevel | undefined {
    return this.tamLevels.find((tamLevel) => tamLevel.id === id);
  }

  setTamLevels(tamLevels: TamLevel[]): void {
    this.tamLevels = tamLevels;
    this.onRefreshTamLevelList.next(this.tamLevels.slice());
  }
  clearTamLevels(): void {
    this.tamLevels = [];
    this.onRefreshTamLevelList.next([]);
  }
  //#endregion TAM_LEVEL

  //#region TAM_LANGUAGE
  getTamLanguages(): TamLanguage[] {
    return this.tamLanguages;
  }

  getTamLanguageById(id: number | null): TamLanguage | undefined {
    return this.tamLanguages.find((tamLanguage) => tamLanguage.id === id);
  }

  setTamLanguages(tamLanguages: TamLanguage[]): void {
    this.tamLanguages = tamLanguages;
    this.onRefreshTamLanguageList.next(this.tamLanguages.slice());
  }

  clearTamLanguages(): void {
    this.tamLanguages = [];
    this.onRefreshTamLanguageList.next([]);
  }
  //#endregion TAM_LANGUAGE

  //#region Tam_Industrial_Sector
  getTamIndustrialSectors(): TamIndustrialSector[] {
    return this.tamIndustrialSectors;
  }

  getTamIndustrialSectorById(
    id: number | null
  ): TamIndustrialSector | undefined {
    return this.tamIndustrialSectors.find(
      (tamIndustrialSector) => tamIndustrialSector.id === id
    );
  }

  setTamIndustrialSectors(tamIndustrialSectors: TamIndustrialSector[]): void {
    this.tamIndustrialSectors = tamIndustrialSectors;
    this.onRefreshTamIndustrialSectorList.next(
      this.tamIndustrialSectors.slice()
    );
  }

  clearTamIndustrialSectors(): void {
    this.tamIndustrialSectors = [];
    this.onRefreshTamIndustrialSectorList.next([]);
  }
  //#endregion Tam_Industrial_Sector
}
