import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Applicant } from '../../models/applicant';
import { ApplicantSelfRegistrationRequest } from '../../models/applicantSelfRegistrationRequest';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';

@Component({
  selector: 'app-applicant-profile-registration',
  templateUrl: './applicant-profile-registration.component.html',
  styleUrls: ['./applicant-profile-registration.component.scss'],
})
export class ApplicantProfileRegistrationComponent implements OnInit {
  applicantProfileRegistrationForm: FormGroup;
  otpForm!: FormGroup;

  freelancer!: Applicant;
  selfRegistrationRequest!: ApplicantSelfRegistrationRequest;
  regex = /[a-zA-Z]*/;
  nameRegex = /^[a-zA-Z\s][a-zA-Z0-9\s][\w\s.,-,(,)]+$/;

  tempNumber = 0;
  isVisible = false;
  validateForm!: FormGroup;
  contactNumber!: string;
  receivedOtp!: string;
  nzFooterVisible = false;
  emailAlredyExist = false;
  nameAlredyExist = false;

  passwordVisible = false;
  confirmPasswordVisible = false;

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private authStorageService: AuthenticationStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.applicantProfileRegistrationForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(this.nameRegex),
          this.noWhitespaceValidator,
        ],
      ],
      nid: ['', [Validators.pattern(/^(?:\01)?(?:\d{10}|\d{13}|\d{17})$/)]],
      email: [null, [Validators.email, Validators.required]],
      password: ['', [Validators.required, 
                      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%?&^*;:,<>.`~-])[A-Za-z\d$@#$!%?&^*;:,<>.`~-]{8,}$/)]],
      confirmPassword: ['', [       
         this.confirmValidator]],
      contactNumberPrefix: ['+880'],
      contactNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern(/^1[3-9][0-9]{8}$/),
        ],
      ],
    });
    this.otpForm = this.fb.group({
      otp: [
        '',
        [Validators.required, Validators.pattern(/^(?:\01)?(?:\d{5})$/)],
      ],
    });
  }
  ngOnInit(): void {}

  submitForm(): void {
    console.log('--> ' + this.applicantProfileRegistrationForm.controls);
    for (const key of Object.keys(
      this.applicantProfileRegistrationForm.controls
    )) {
      this.applicantProfileRegistrationForm.controls[key].markAsDirty();
      this.applicantProfileRegistrationForm.controls[
        key
      ].updateValueAndValidity();
    }
    console.log(this.applicantProfileRegistrationForm.value);
    this.showModal();
  }

  submitOtp(): void {
    this.receivedOtp = this.otpForm.get('otp')?.value;

    const selfRegistrationRequest = new ApplicantSelfRegistrationRequest();

    selfRegistrationRequest.email =
      this.applicantProfileRegistrationForm.controls.email.value;

    selfRegistrationRequest.contactNumber =
      this.applicantProfileRegistrationForm.controls.contactNumber.value;

    selfRegistrationRequest.contactNumberPrefix =
      this.applicantProfileRegistrationForm.controls.contactNumberPrefix.value;

    selfRegistrationRequest.name =
      this.applicantProfileRegistrationForm.controls.name.value;

    selfRegistrationRequest.password =
      this.applicantProfileRegistrationForm.controls.confirmPassword.value;

    selfRegistrationRequest.nid =
      this.applicantProfileRegistrationForm.controls.nid.value;

    selfRegistrationRequest.role = 'Applicant, Assessor';

    // selfRegistrationRequest.designation = this.applicantProfileRegistrationForm.controls.stpDesignation.value;

    this.authStorageService
      .getVerifiedOtp(this.receivedOtp, this.contactNumber)
      .subscribe({
        next: (response) => {
          this.authStorageService
            .createApplicant(selfRegistrationRequest)
            .subscribe({
              next: (serverResponse) => {
                this.selfRegistrationRequest = serverResponse.data;
                // const applicant = new Applicant();

                // applicant.userId = this.selfRegistrationRequest.id;

                // applicant.nid =
                //   this.applicantProfileRegistrationForm.controls.nid.value;

                // applicant.name =
                //   this.applicantProfileRegistrationForm.controls.name.value;

                // applicant.applicantEmail =
                //   this.applicantProfileRegistrationForm.controls.email.value;

                // applicant.applicantMobileNo =
                //   this.applicantProfileRegistrationForm.controls.mobileNo.value;

                // this.saveApplicantProfile(applicant);

                this.applicantProfileRegistrationForm.reset();
                const returnUrl =
                  this.route.snapshot.queryParams.returnUrl || '/login';
                this.router.navigateByUrl(returnUrl);
                this.notification.success(
                  'Success!',
                  'Please check your email for validation'
                );
                this.isVisible = false;
              },

              error: (error) => {
                this.notification.error(error, error.message);
              },
            });
        },
        error: (error) => this.notification.error('Failed', 'OTP is not valid'),
      });
    this.otpForm.reset();

    // this.validateForm.reset();
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !==
      this.applicantProfileRegistrationForm.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  checkEmailExist(email: any): void {
    this.emailAlredyExist = false;
    setTimeout(() => {
      if (email.value.length > 3) {
        this.authStorageService
          .checkApplicantEmailExist(email.value)
          .subscribe({
            next: (res) => {
              if (res.data == null) {
                this.emailAlredyExist = false;
              } else {
                this.emailAlredyExist = true;
                this.applicantProfileRegistrationForm.controls.email.setErrors({
                  incorrect: true,
                });
              }
            },
          });
      }
    }, 2000);
  }

  checkNameExist(name: any): void {
    this.nameAlredyExist = false;
    setTimeout(() => {
      if (name.value.length > 3) {
        this.authStorageService.checkApplicantEmailExist(name.value).subscribe({
          next: (res) => {
            if (res.data == null) {
              this.nameAlredyExist = false;
            } else {
              this.nameAlredyExist = true;
              this.applicantProfileRegistrationForm.controls.name.setErrors({
                incorrect: true,
              });
            }
          },
        });
      }
    }, 2000);
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.applicantProfileRegistrationForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  nidValidation(control: AbstractControl) {
    if (control.value.length <= 10) {
      return { error: true, required: true };
    } else if (control.value.length <= 17) {
      return { error: true, required: true };
    }
    return {};
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.applicantProfileRegistrationForm.reset();
    this.applicantProfileRegistrationForm
      .get('contactNumberPrefix')
      ?.setValue('+880');

    for (const key of Object.keys(
      this.applicantProfileRegistrationForm.controls
    )) {
      this.applicantProfileRegistrationForm.controls[key].markAsPristine();
      this.applicantProfileRegistrationForm.controls[
        key
      ].updateValueAndValidity();
    }
  }

  saveApplicantProfile(applicant: Applicant): void {
    this.authStorageService.createApplicantUser(applicant).subscribe({
      next: (serverResponse) => {
        console.log(serverResponse);
        this.applicantProfileRegistrationForm.reset();
        const returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
        this.router.navigateByUrl(returnUrl);
        this.notification.success(
          'Success!',
          'Please check your email for validation'
        );
      },
      error: (error) => {
        this.notification.error(error, error.message);
      },
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  //#region OTP

  showModal(): void {
    this.isVisible = false;
    this.contactNumber =
      '0' + this.applicantProfileRegistrationForm.get('contactNumber')?.value;
    this.authStorageService.getOtp(this.contactNumber).subscribe({
      next: (res) => {
        console.log('Message Send');
        console.log(res.message);
        this.notification.success(
          'Success!',
          'Please check your mobile for OTP'
        );
        this.isVisible = true;
      },
      error: (error) => this.notification.error('Failed', 'OTP is not Sent, Please try again later'),
    });
    // this.isVisible = true;
    //  this.nzFooter = false;
    setTimeout(() => {
      this.isVisible = false;
    }, 300000);
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //#endregion OTP
}
