import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { CompetencyStandard } from '../../../models/competency-standard.model';
import { CsSectorSpecific } from '../../../models/cs-sector-specific.model';
import { CompetencyStandardStorageService } from '../../../services/competency-standard-storage.service';
import { CompetencyStandardService } from '../../../services/competency-standard.service';

@Component({
  selector: 'app-add-cs-sector-specific',
  templateUrl: './add-cs-sector-specific.component.html',
  styleUrls: ['./add-cs-sector-specific.component.scss'],
})
export class AddCsSectorSpecificComponent implements OnInit {
  @Input() InputData: any;
  isEditMode: boolean = false;
  title = this.isEditMode ? 'Update Competency' : 'Create New Competency';
  singleCs: CsSectorSpecific;
  onStartSingleCsEdit$: Subscription;
  onEditingSingleCsId!: number | null;
  csForm: FormGroup;
  editedItem!: any;
  editedItemId!: number | null;
  csList: CsSectorSpecific[] = [];
  tamMainId: number;
  onCsSectorSpecificSubscription$: Subscription;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private csStorageService: CompetencyStandardStorageService,
    private csService: CompetencyStandardService
  ) {
    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));
    this.onEditingSingleCsId = this.csService.getEditingSingleCsSectorSpecificId();
    this.onStartSingleCsEdit$ =
      this.csService.onStartCsSectorSpecificEditing.subscribe(
        (res: number | null) => {
          this.onEditingSingleCsId = res;
        }
      );
    this.csList = this.csService.getCsSectorSpecificList(this.tamMainId);
    if (this.csList.length === 0) {
      this.csStorageService.readCsSectorSpecificListByTamId(this.tamMainId).subscribe();
    }
    this.onCsSectorSpecificSubscription$ =
      this.csService.onRefreshCsSectorSpecificList.subscribe((res) => {
        this.csList = res;
      });
    // if (csService.getCsSectorSpecificList(Number(this.tamMainId)).length === 0) {
    //   this.csStorageService
    //     .readCsSectorSpecificListByTamId(Number(this.tamMainId))
    //     .subscribe();
    // }
    // if (
    //   csService.getCsOccupationSpecificList(Number(this.tamMainId)).length === 0
    // ) {
    //   this.csStorageService
    //     .readCsOccupationSpecificListByTamId(Number(this.tamMainId))
    //     .subscribe();
    // }
    // if (
    //   csService.getCsSectorSpecificList(Number(this.tamMainId)).length === 0
    // ) {
    //   this.csStorageService
    //     .readCsSectorSpecificListByTamId(Number(this.tamMainId))
    //     .subscribe();
    // }
    // this.csService.addAllCsList(Number(this.tamMainId));
  }

  ngOnInit(): void {
    console.log(this.InputData);
    this.isEditMode = true;
    this.editedItemId = this.InputData;
    this.isEditMode = this.editedItemId !== null;
    this.editedItem = this.csService.getCsSectorSpecificById(this.editedItemId);
    
    this.createForm();
    this.csForm.patchValue(this.editedItem);
    // this.createForm();
    // this.tamMainId = this.route.snapshot.queryParamMap.get('tamMainId');
  }

  createForm() {
    this.csForm = this.fb.group({
      unitCode: ['', Validators.required],
      title: [],
      duration: [],
      remark: [],
    });
  }

  resetForm(): void {
    this.csForm.reset();
  }

  submitForm(): void {
    for (const key of Object.keys(this.csForm.controls)) {
      this.csForm.controls[key].markAsDirty();
      this.csForm.controls[key].updateValueAndValidity();
    }
    this.singleCs = this.csForm.value;
    this.singleCs.tamMainId = Number(this.tamMainId);
    if (this.editedItemId != null) {
      this.csStorageService.updateCsSectorSpecific(this.singleCs, this.editedItemId).subscribe({
        next: (response) =>
          this.notification.success('Success!', 'Sector Specific Competency update successfully'),
        error: (error) => this.notification.error('Failed', error),
      });
    }else {
    const csData: CompetencyStandard = this.csForm.value;

    const isFalsy = Object.values(csData).every((value) => {
      if (!value || !value.toString().trim() || value == 0 || value == this.tamMainId) {
        return true;
      }

      return false;
    });

    if (isFalsy) {
      this.notification.error(
        'Error',
        'All input blank form submit is not allowed'
      );
      return;
    }

    if (!this.tamMainId) {
      this.notification.error(
        'Error',
        'Training and Assessment Material Id not found'
      );
      return;
    }

    csData.tamMainId = Number(this.tamMainId);

    this.csStorageService.createCsSectorSpecific(csData).subscribe({
      next: (response) => {
        this.notification.success(
          'Success',
          'Added Sector Specific Competency'
        );
      },
      error: (error) =>
        this.notification.error('Failed', 'Please! provide valid information'),
    });
  }
  this.csForm.reset();
}
}
