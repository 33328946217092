export class CadLearningMaterial {
    public id?: number;
    public learningMaterialsName?: string | null;
    public specification?: string | null;
    public unit?: string | null;
    public minQuantityReq: number;
    public remark?: string | null;
    public tamMainId?: number | null;
    public tamMain?: any;
    constructor() {}
  }
