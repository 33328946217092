import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { ResearchStudiesMainSearch } from '../../models/research-studies-main-search-dto.model';
import { ResearchAndStudiesStorageService } from '../../services/research-and-studies-storage.service';
import { ResearchType } from '../../models/l_research-type.model';
import { ResearchStudiesMain } from '../../models/research-studies-main.model';
import { Author } from '../../models/author.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-research-document-list-public',
  templateUrl: './research-document-list-public.component.html',
  styleUrls: ['./research-document-list-public.component.scss'],
})
export class ResearchDocumentListPublicComponent implements OnInit {
  searchForm: FormGroup;

  researchStudiesMainSearch: ResearchStudiesMainSearch;
  researchTypeList: ResearchType[] = [];
  adminListInfo: ResearchStudiesMain[] = [];
  authorList: Author[] = [];

  rsDemoList: {
    id: number;
    name: string;
    documentTitle: string;
    link: string;
  }[] = [
    {
      id: 1,
      name: 'DTE TMED Research 2021-22',
      documentTitle: 'Executive Summery',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-06-00-10e998c0455d4a88b2e2babab175efe3.pdf',
    },
    {
      id: 2,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Reports on Aquaculture Skills Acquisition Through TVET and Practices in Fish Production Self-Employment',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-06-00-41bc0bcb1fa1fb1ac9c38d0ee2d8d529.pdf',
    },
    {
      id: 3,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Financial Opportunities and Challenges of Diploma Graduates to Become Entrepreneur',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-59-bee4df4c113adc8aa7a9d41361f78b1f.pdf',
    },
    {
      id: 4,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Effectiveness of Industrial Attachment of Polytechnic Graduates Focusing on Skills Development: A Case Study of Barishal District',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-58-e3a7ff08b4ca29ea6f8af36cf626c66b.pdf',
    },
    {
      id: 5,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Research proposal “Online teaching-learning Practices at Graphic Arts Institute: Status, Challenges and Way forwards',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-57-aeb8d03070f9ded9a3f6ff4553e0cf1c.pdf',
    },
    {
      id: 6,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Feasibility of Flipped Classroom Approach at Bachelor in Electrical and Electronic Engineering Courses: Engineering Colleges Perspective of Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-55-2ff3524cca2d4a5c95da65e99030c308.pdf',
    },
    {
      id: 7,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'RESEARCH REPORT ON COMPETENCY STANDARD RELATED FACTORS AFFECTING THE EXPANSION AND IMPLEMENTATION OF NTVQF SYSTEM IN BANGLADESH',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-54-2d96f71d945f7a815734fb23bbebb1dd.pdf',
    },
    {
      id: 8,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'A Research on Employment Status and its challenges for Female Diploma Engineers Graduated from Polytechnic Institutes of Barishal City',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-54-8a7995e9fbf474d40479e3b7e2df0577.pdf',
    },
    {
      id: 9,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Challenges in Producing Competent Graduates in Government Polytechnic Institutes of Sylhet Division',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-54-6a4bdc9211555f012e708e1bf6d4d02f.pdf',
    },
    {
      id: 10,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Subject-Based Teacher’s Training for Government Polytechnic Institue in Bangladesh: Status, Challenges and the Way Forwards',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-53-89171b36262433762d9d9f1d50c11561.pdf',
    },
    {
      id: 11,
      name: 'DTE TMED Research 2021-22',
      documentTitle:
        'Quality TVET: Opportunities, Challenges and Policy Options for Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/297ae7c4_4462_463d_b7aa_a7c3cbe197f7/2023-11-21-05-50-ed7b9e65ef515a6926490c81d577b09d.pdf',
    },
    {
      id: 12,
      name: 'A2I',
      documentTitle:
        'Future Skills Finding Emerging Skills to Tackle The Chalanges of Automation in Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/68a7ba9a_03ab_4f28_a9b9_49b97a1c749e/2023-11-19-05-50-0094401cb8f33e07a763d046b6fa7265.pdf',
    },
    {
      id: 13,
      name: 'BMET Research',
      documentTitle:
        'Research on Skill Upgradation Schemes for Migrant Workers in Light of Changing Global Demand, 4IR and Artificial Intelligence',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6509978a_00ac_4dd8_960a_0c7ea659e713/2023-11-20-04-29-29864c105f1ca390c132b375264634a1.pdf',
    },
    {
      id: 14,
      name: 'BMET Research',
      documentTitle:
        'Research on Measures Needed for Bangladesh to Shift from Un-Skilled to Semi-Skilled and Semi-Skilled to Skilled and High-Skilled Labour Migration',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6509978a_00ac_4dd8_960a_0c7ea659e713/2023-11-20-04-28-f4fc9d1f4c2def634660475e6919e2a2.pdf',
    },
    {
      id: 15,
      name: 'BMET Research',
      documentTitle:
        'An Assessment of Basic Services and their Access for the Migrant Workers in Countries of Destinations (Global Compact for Migration)',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6509978a_00ac_4dd8_960a_0c7ea659e713/2023-11-20-04-28-b723b29c2aae2f39526e734ba120506d.pdf',
    },
    {
      id: 16,
      name: 'BMET Research',
      documentTitle:
        'A Research Study on Exploring the employment opportunities in East European countries for less-skilled, semi- skilled and skilled migrant workers of Bangladesh prospects, challenges & way forward',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6509978a_00ac_4dd8_960a_0c7ea659e713/2023-11-20-04-28-77775a09dd254e873824571450f637dc.PDF',
    },
    {
      id: 17,
      name: 'BMET Research',
      documentTitle:
        'A critical assessment of financial literacy of migrant workers and their access to financial services in destination countries',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6509978a_00ac_4dd8_960a_0c7ea659e713/2023-11-20-04-26-126886ecaf59b88bcf38f67fc3d1b872.pdf',
    },
    {
      id: 18,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Enrollment of female students in TVET at jashore district in Bangladesh: challenges and remedies',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-47-25b609b894656be013775f1640d0e0d6.pdf',
    },
    {
      id: 19,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Exploring the Advantages of labour market suitable language skill for diploma engineers in global job market',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-46-cdb50095bb1a6643cab3653c43784924.pdf',
    },
    {
      id: 20,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        '“Demand analysis of diploma in engineering (Printing) in current Job Market of Bangladesh.”',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-45-ad9f175147833c82c13576eaac82b04c.pdf',
    },
    {
      id: 21,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Title: Participation Barrier of Differently Abled Person in TVET: A Case Study in Dhaka Polytechnic Institute',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-45-dbc2d2df8c7e78a58be20a90dcb539bf.pdf',
    },
    {
      id: 22,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Effectivity of Innovative Biofloc Technology : Mono-sex Tilapia Production in TVET Facilities',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-44-28f8170e706b07a2f736c2da82c89424.pdf',
    },
    {
      id: 23,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Innovating E-training App for Digitalization of the Technical and Vocational Education',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-42-9f187ff9fe2ad6ba60942491fbc4382a.pdf',
    },
    {
      id: 24,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Effectiveness of Job Placement Cell for Graduates of Dhaka Mohila Polytechnic Institute',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-42-230f7ce3c416c4d626e4050902724b15.pdf',
    },
    {
      id: 25,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Assessing the Implementation of Dual Certification Initiatives under NTVQF in Polytechnics',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-41-9a8197e98a4a8a670abb0b57b102f376.pdf',
    },
    {
      id: 26,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'Selection and Recruitment of Competent Teachers’ Lead to Improve Teaching Learning Quality of TVET',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-40-c9e486f0e9b1564fe2262c14fc443230.pdf',
    },
    {
      id: 27,
      name: 'DTE TMED Research 2020-21',
      documentTitle:
        'The Role of Technical and Vocational Education and Training (TVET) on Livelihood Development of the Chakma Diploma Engineers in Khagrachari District.',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/2a9917f6_2a55_433f_b81f_245f395d265c/2023-11-20-04-38-23db08509d81e926e6da118f0efc6df5.pdf',
    },
    {
      id: 28,
      name: 'Plastic ISC',
      documentTitle: 'Plastic ISC member list (English)',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/72a24711_d0dd_43db_8423_cba797d5266d/2023-11-13-09-31-0983421ac0b8525f7e5b228aaf2d583a.pdf',
    },
    {
      id: 29,
      name: 'Plastic ISC',
      documentTitle: 'Plastic ISC member list (Bangla)',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/72a24711_d0dd_43db_8423_cba797d5266d/2023-11-13-09-31-0a74b046591c4b6f5e2c15aa8553a1ee.pdf',
    },
    {
      id: 30,
      name: 'SEIP',
      documentTitle:
        'SKILL GAP ANALYSIS OF OVERSEAS JOB MARKET – THE EXPERIENCE OF BANGLADESH',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-39-8ceb6c004e876f7ace0ffb324f102cff.pdf',
    },
    {
      id: 31,
      name: 'SEIP',
      documentTitle:
        'Skill Gap, Skill Shortage, and the COVID-19 Pandemic: Evidence from the Hospitality and Tourism Sector in Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-38-dd1521eee37b16c48d1f6e5ecf6d977d.pdf',
    },
    {
      id: 32,
      name: 'SEIP',
      documentTitle:
        'Labor Market and Skills Gap Analysis for the Shipbuilding Sector in Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-37-013659fec1be4e70657440f932003706.pdf',
    },
    {
      id: 33,
      name: 'SEIP',
      documentTitle:
        'Labor Market Study under Skills for Employment Investment Program (SEIP) LIGHT ENGINEERING SECTOR',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-36-2ae91110f88718795bfa4d1cf16cd07b.pdf',
    },
    {
      id: 34,
      name: 'SEIP',
      documentTitle:
        'LABOR MARKET STUDY FOR SKILLS FOR EMPLOYMENT INVESTMENT PROJECT (SEIP): LEATHER AND FOOTWEAR SECTOR',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-34-a9523d25ea08ae4a272f529360e82a89.pdf',
    },
    {
      id: 35,
      name: 'SEIP',
      documentTitle: 'Analysis of Skills Gap in the ICT Sector in Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-32-85af52a3d3194f7133db1b1bdf05c592.pdf',
    },
    {
      id: 36,
      name: 'SEIP',
      documentTitle:
        'Labor Market and Skills Gap Analyses Healthcare: Nursing and Care',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-31-555426c448837430367574f516a69aec.pdf',
    },
    {
      id: 37,
      name: 'SEIP',
      documentTitle:
        'Labor Market Study under Skills for Employment Investment Program (SEIP)',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-30-79059792a0382e5238f6707113577bd6.pdf',
    },
    {
      id: 38,
      name: 'SEIP',
      documentTitle:
        'Labour Market and Skill Gap Analysis for the Construction Sector in Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-29-08bf88be839c031ffb438e1c8a5d569a.pdf',
    },
    {
      id: 39,
      name: 'SEIP',
      documentTitle:
        'LABOR MARKET STUDY FOR SKILLS FOR EMPLOYMENT INVESTMENT PROJECT (SEIP): AGRO FOOD PROCESSING SECTO',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-29-0fcd6abaad2aa2fe57e0d84ccaf99c13.pdf',
    },
    {
      id: 40,
      name: 'SEIP',
      documentTitle:
        'Labour Market and Skill Gap Analysis for Readymade Garment Industry in Bangladesh:',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-28-86ed6a7ea76119ccfd6fb6cff0632a7f.pdf',
    },
    {
      id: 41,
      name: 'SEIP',
      documentTitle:
        'Stocktaking of Technical and Vocational Education and Training (TVET) Institutions in Bangladesh',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-26-16b3dc1d1df4fc10c36a7315a2af693c.pdf',
    },
    {
      id: 42,
      name: 'SEIP',
      documentTitle: 'Macro-economic Outlook for Bangladesh over 2020 and 2035',
      link: 'https://nsda.portal.gov.bd/sites/default/files/files/nsda.portal.gov.bd/files/6845b2a5_0c2f_4e55_80dc_65127652120b/2023-11-19-05-25-2e4595d7b9b9ee61bc71c7a27dc7a159.pdf',
    },
  ];

  researchAndStudiesMainInfoDetails: any;
  stpMainId: number;
  stpCenterId: number;
  occupationId: number;

  isVisibleDetails: boolean = false;
  applicationPermissions = applicationPermissions;
  previousFileBase64: any;

  //#region Excel part
  excelReportData: any;
  //#endregion

  // for pagination
  total: number = 0;
  size: number = 10;
  page: number = 1;
  sortingKey: string;
  sortingValue: string;

  constructor(
    private fb: FormBuilder,
    private researchAndStudiesStorageService: ResearchAndStudiesStorageService,
    private notification: NzNotificationService
  ) {}

  //#region fetch research type data
  fetchResearchTypeData() {
    //Get researchType list
    this.researchAndStudiesStorageService.readResearchType().subscribe({
      next: (res) => {
        this.researchTypeList = [...res];
        this.researchTypeList.forEach((item, index) => {
          item.name = `${item.name} (${item.code})`;
        });
      },
    });
  }
  //#endregion

  ngOnInit() {
    this.fetchResearchTypeData();
    this.createSearchForm();
    this.submitSearchForm();
  }

  loadDatasFromServer(researchStudiesMainSearch: ResearchStudiesMainSearch) {
    this.researchAndStudiesStorageService
      .readResearchAndStudiesPublicInfo(researchStudiesMainSearch)
      .subscribe({
        next: (res: ServerResponse) => {
          if (res.success) {
            this.adminListInfo = res?.data?.content;
            this.total = res.data?.totalElements;
          } else {
            this.adminListInfo = [];
            this.total = 0;
          }
        },
        error: (error) => {
          // this.notification.warning('Warning!!! ', 'No data found');
          this.adminListInfo = [];
          this.total = 0;
        },
      });
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      documentId: [''],
      researchTypeId: [null],
      researchFromDate: [null],
      researchToDate: [null],
    });
  }

  //#region submit
  submitSearchForm() {
    var pipe = new DatePipe('en-US');
    if (this.searchForm.controls.researchFromDate.value) {
      const generalFromDateFormat = pipe.transform(
        this.searchForm.controls.researchFromDate.value,
        'yyyy-MM-dd 00:00'
      );
      this.searchForm.controls.researchFromDate.setValue(generalFromDateFormat);
    }
    if (this.searchForm.controls.researchToDate.value) {
      const generalToDateFormat = pipe.transform(
        this.searchForm.controls.researchToDate.value,
        'yyyy-MM-dd 23:59'
      );
      this.searchForm.controls.researchToDate.setValue(generalToDateFormat);
    }
    this.researchStudiesMainSearch = this.searchForm.value;
    this.researchStudiesMainSearch.size = 10;
    this.researchStudiesMainSearch.page = 0;
    this.loadDatasFromServer(this.researchStudiesMainSearch);
  }
  //#endregion

  onRefresh() {
    this.searchForm.reset();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });

    this.researchStudiesMainSearch.size = this.size;
    this.researchStudiesMainSearch.page = this.page - 1;
    this.researchStudiesMainSearch.sortingKey = this.sortingKey;
    this.researchStudiesMainSearch.sortingValue = this.sortingValue;
    this.loadDatasFromServer(this.researchStudiesMainSearch);
  }

  showModalForDetails(id: any): void {
    this.isVisibleDetails = true;
    this.getDocumentsInfoById(id);
    this.getAuthorInfoById(id);
    // this.rplCandidate = rplCandidate;
  }
  handleCancelDetailsModal(): void {
    this.isVisibleDetails = false;
  }

  getDocumentsInfoById(id: number): void {
    this.researchAndStudiesStorageService
      .getResearchAndStudiesMainInfoDetailsById(id)
      .subscribe({
        next: (res) => {
          this.researchAndStudiesMainInfoDetails = res.data;
        },
      });
  }

  getAuthorInfoById(id: number): void {
    this.researchAndStudiesStorageService.getAuthorInfoById(id).subscribe({
      next: (res) => {
        this.authorList = res.data;
      },
    });
  }

  getDocumentsInfoByIdForDownload(id: number): void {
    this.researchAndStudiesStorageService
      .getResearchAndStudiesMainInfoDetailsById(id)
      .subscribe({
        next: (res) => {
          this.researchAndStudiesMainInfoDetails = res.data;
          if (this.researchAndStudiesMainInfoDetails.documentUrl) {
            this.setBase64AttachmentforPreviewing(
              this.researchAndStudiesMainInfoDetails.documentUrl
            );
          }
        },
      });
  }

  onDownloadFile(id: number) {
    this.getDocumentsInfoByIdForDownload(id);
  }
  async setBase64AttachmentforPreviewing(fileUrl: string) {
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    this.previousFileBase64 = URL.createObjectURL(blob);
    this.downloadFile();
  }
  downloadFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.previousFileBase64);
    link.setAttribute('download', 'uploaded_document');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  isVisibleOpinion: boolean = false;

  showModalForOpinion(): void {
    this.isVisibleOpinion = true;
  }

  handleCancelOpinionModal(): void {
    this.isVisibleOpinion = false;
  }
}
