import { Component, OnInit } from '@angular/core';
import { MraStorageService } from '../../services/mra-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MRAInfoSearch } from '../../models/DTO/mra-info-search';
import { CountryService } from '../../services/country.service';
import { Country } from '../model/country.model';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { MRA } from '../model/mra.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { TranslateService } from '@ngx-translate/core';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-mra-list-public',
  templateUrl: './mra-list-public.component.html',
  styleUrls: ['./mra-list-public.component.scss'],
})
export class MraListPublicComponent implements OnInit {
  searchForm: FormGroup;
  mraInfoSearch: MRAInfoSearch;
  isVisible = false;
  totalNoOfCSCount: number;
  totalNoOfCADCount: number;
  totalNoOfCBLMCount: number;
  mraDescription: any;
  rssFeedData: any[] = [];
  today: Date = new Date();

  groupedFeedData: {
    [key: string]: {
      id: string;
      subjectEnglish?: string;
      subjectBangla?: string;
      titles: string[];
    };
  } = {};

  agreementType: any;
  countries: Country[] = [];
  accessType: { id: number; name: string }[] = [
    { id: 1, name: 'Public' },
    { id: 2, name: 'Limited' },
  ];
  mraPublicListInfo: MRA[] = [];

  // for pagination
  total: number = 0;
  size: number = 10;
  page: number = 1;
  sortingKey: string = 'date_of_signature';
  sortingValue: string = 'descend';
  isHovered = false;
  constructor(
    private fb: FormBuilder,
    private mraStorageService: MraStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private countryService: CountryService,
    private notification: NzNotificationService
  ) {
    this.translate.use(environment.language.setDefaultLang);
  }

  //#region get cs count
  getCSCount() {
    this.mraStorageService.readCSCount().subscribe({
      next: (res) => {
        this.totalNoOfCSCount = res;
      },
    });
  }
  //#endregion

  //#region get cad count
  getCADCount() {
    this.mraStorageService.readCADCount().subscribe({
      next: (res) => {
        this.totalNoOfCADCount = res;
      },
    });
  }
  //#endregion

  //#region get cblm count
  getCBLMCount() {
    this.mraStorageService.readCBLMCount().subscribe({
      next: (res) => {
        this.totalNoOfCBLMCount = res;
      },
    });
  }
  //#endregion

  //#region get mra description
  getMraDescription() {
    this.mraStorageService.readMraDescription().subscribe({
      next: (res) => {
        this.mraDescription = res?.description;
      },
    });
  }
  //#endregion

  //#region get mra description
  getRssFeedData() {
    this.mraStorageService.readRssFeedData().subscribe({
      next: (res) => {
        this.rssFeedData = res;
        if (this.rssFeedData && this.rssFeedData.length > 0) {
          this.groupedFeedData = this.rssFeedData.reduce((acc, item) => {
            if (item.mra && item.mra.id) {
              const id = item.mra.id.toString();

              if (!acc[id]) {
                // Default to subjectEnglish, fallback to subjectBangla
                const subject =
                  item.mra.subjectEnglish?.trim() ||
                  item.mra.subjectBangla?.trim() ||
                  'No Subject Available';

                acc[id] = {
                  id, // Add the id explicitly
                  subjectEnglish: subject,
                  subjectBangla: item.mra.subjectBangla?.trim() || '',
                  titles: [],
                };
              }

              // acc[id].titles.push(item.attachment?.attachmentTitle || "No Title");
            }
            return acc;
          }, {});
        }
      },
      error: (err) => {
        console.error('Error fetching RSS feed data:', err);
      },
    });
  }
  onMouseOver(): void {
    this.isHovered = true;
  }

  onMouseOut(): void {
    this.isHovered = false;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  //#endregion

  //#region load agreement type data
  loadAgreementType(): void {
    this.countryService.getAllAgreementType().subscribe(
      (data) => {
        this.agreementType = data;
      },
      (error) => {
        console.error('Error fetching agreementType:', error);
      }
    );
  }
  //#endregion
  isPaused = false; // Flag to track hover state

  onHover(isHovering: boolean): void {
    this.isPaused = isHovering;
  }

  onClick(key: string): void {
    const feed = this.groupedFeedData[key];
    this.router.navigate(['/mra/mra-view-details-public'], {
      queryParams: { id: feed.id },
    });
  }

  stopMarquee(event: Event): void {
    const marquee = event.target as HTMLMarqueeElement;
    if (marquee) {
      marquee.setAttribute('scrollamount', '0'); // Stop scrolling
    }
  }
  startMarquee(event: Event): void {
    const marquee = event.target as HTMLMarqueeElement;
    if (marquee) {
      marquee.setAttribute('scrollamount', '6'); // Restore scrolling speed
    }
  }

  //#region load country
  loadCountries(): void {
    this.countryService.getCountriesAll().subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }
  //#endregion

  ngOnInit(): void {
    this.getCSCount();
    this.getCADCount();
    this.getCBLMCount();
    this.getMraDescription();
    this.getRssFeedData();

    this.loadAgreementType();
    this.loadCountries();
    this.createSearchForm();
    this.submitSearchForm();
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      agreementTypeId: [null],
      nameOfOrganization: [null],
      countryId: [null],
      subjectEnglish: [null],
      signingDateFrom: [null],
      signingDateTo: [null],
    });
  }

  //#region submit form
  submitSearchForm() {
    var pipe = new DatePipe('en-US');
    if (this.searchForm.controls.signingDateFrom.value) {
      const generalFromDateFormat = pipe.transform(
        this.searchForm.controls.signingDateFrom.value,
        'yyyy-MM-dd 00:00'
      );
      this.searchForm.controls.signingDateFrom.setValue(generalFromDateFormat);
    }
    if (this.searchForm.controls.signingDateTo.value) {
      const generalToDateFormat = pipe.transform(
        this.searchForm.controls.signingDateTo.value,
        'yyyy-MM-dd 23:59'
      );
      this.searchForm.controls.signingDateTo.setValue(generalToDateFormat);
    }
    this.mraInfoSearch = this.searchForm.value;
    this.mraInfoSearch.size = 10;
    this.mraInfoSearch.page = 0;
    this.loadDataFromServer(this.mraInfoSearch);
  }
  //#endregion

  loadDataFromServer(mraInfoSearch: MRAInfoSearch) {
    this.mraStorageService.readMraPublicInfo(mraInfoSearch).subscribe({
      next: (res: ServerResponse) => {
        if (res.success) {
          this.mraPublicListInfo = res?.data?.content;
          this.total = res.data?.totalElements;
        } else {
          this.mraPublicListInfo = [];
          this.total = 0;
        }
      },
      error: (error) => {
        this.notification.warning('Warning!!! ', 'No data found');
        this.mraPublicListInfo = [];
        this.total = 0;
      },
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex ? params.pageIndex : 1;
    this.size = params.pageSize ? params.pageSize : 10;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });

    this.mraInfoSearch.size = this.size;
    this.mraInfoSearch.page = this.page - 1;
    this.mraInfoSearch.sortingKey = this.sortingKey;
    this.mraInfoSearch.sortingValue = this.sortingValue;
    this.loadDataFromServer(this.mraInfoSearch);
  }

  onRefresh() {
    this.searchForm?.reset();
    this.loadDataFromServer(this.mraInfoSearch);
  }

  encodeParameter(param: number): string {
    return btoa(String(param));
  }

  onViewButtonClick(tamTypeId: number) {
    const encodedId = this.encodeParameter(tamTypeId);
    this.router.navigate(['/mra/view-mra-public'], {
      queryParams: { tamTypeId: encodedId },
      relativeTo: this.activatedRoute,
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  disabledPastDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledFutureDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;
}
