import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otp-for-stp-sac-inspection-report-preview',
  templateUrl: './otp-for-stp-sac-inspection-report-preview.component.html',
  styleUrls: ['./otp-for-stp-sac-inspection-report-preview.component.scss']
})
export class OtpForStpSacInspectionReportPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
