<div class="card">
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="form" nzTheme="outline"></i>{{this.editedItemId ? "Update Materials/Consumables": "Create New Materials/Consumables"}}
  </div>
  <div class="searchboxAerar p-3">
    <form nz-form [formGroup]="cadForm" (ngSubmit)="submitForm()">
      <div class="col-md-9">
        <div class="mb-2 row">
          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Name of Materials/Consumables</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="ppeName"
                  placeholder="Name of Materials/Consumables"
                  formControlName="materialConsumablesName"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Specification</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="specification"
                  placeholder="Specification"
                  formControlName="specification"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Unit (Piece/Kg/Set etc.)</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="unit"
                  placeholder="Unit"
                  formControlName="unit"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Minimum Quantity Required</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control
                class="row"
                nzHasFeedback
                [nzErrorTip]="minQuantityReq"
              >
                <input
                  nz-input
                  type="text"
                  id="minQuantityReq"
                  placeholder="Minimum Quantity Required"
                  formControlName="minQuantityReq"
                />
                <ng-template #minQuantityReq let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    Only Integer Numbers are allowed
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6 col-sm-12 text-right">
            <nz-form-label>Remarks</nz-form-label>
          </div>
          <div class="col-md-5">
            <nz-form-item>
              <nz-form-control class="row">
                <input
                  nz-input
                  type="text"
                  id="remark"
                  placeholder="Remarks"
                  formControlName="remark"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div class="row justify-content-center media-button">
        <div class="col-xs-6 col-sm-2">
          <button
            type="submit"
            class="btn btn-success active btn-lg btn-block cari border-redius"
            [disabled]="!cadForm.valid"
          >
            <i nz-icon nzType="save" nzTheme="fill"></i> {{this.editedItemId ? "Update": "Save"}}
          </button>
        </div>
        <div class="col-xs-6 col-sm-2">
          <button
            type="button"
            class="btn btn-danger active btn-lg btn-block cari border-redius"
            (click)="resetForm()"
          >
            <i nz-icon nzType="redo" nzTheme="outline"></i> Reset
          </button>
          <br />
        </div>
      </div>
    </form>

    
    <div class="col-md-12" [hidden]="(this.editedItemId)">
      <hr>
      <div class="row">
        <div class="col-md-4">
          <nz-form-label nzRequired>Upload Excel File</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input #myInput type="file" nz-input (change)="onSelectFile($event)" accept=".xlsx,.xls" />
            </nz-form-control>
            <span style="color: red;"><sup>**</sup>Note: </span> &nbsp;<span> Please upload only excel file</span>
          </nz-form-item>
        </div>


        <div class="col-md-2 downloadButton">
          <nz-form-item>
            <nz-form-control>
              <button nz-button type="button" class="btn btn-success active btn-lg btn-block cari border-redius"
                (click)="submitExcelData()">
                <i nz-icon nzType="save" nzTheme="fill"></i>
                Upload</button>
            </nz-form-control>
          </nz-form-item>

        </div>



        <div class="col-md-4 downloadButton">
          <button nz-button nzType="primary" style="background-color: black;" class="border-redius"
            onclick="window.location.href='/assets/file/CadMaterialConsumable.xlsx'">
            <i nz-icon nzType="download"></i>
            Download Sample File
          </button>
        </div>
      </div>
    </div>




  </div>
</div>
