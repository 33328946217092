import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewEventModel } from '../../models/new-event-model';
import { ReportModel } from '../../models/report-model';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { environment } from 'src/environments/environment';
import { EventModuleService } from '../../event-module.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-public-add-event-report',
  templateUrl: './public-add-event-report.component.html',
  styleUrls: ['./public-add-event-report.component.scss']
})
export class PublicAddEventReportComponent implements OnInit {

  addEventReportForm: FormGroup;
  getEventForm: FormGroup;
  eventTypesList: any;
  industrialSectorsList: any;
  districtList: any;
  divisionList: any;
  countryId: number;
  newEventModel: NewEventModel;
  reportModel: ReportModel;
  organizedByList: any;
  eventDataList: any;
  reportDataList: any;
  tempScheduleId: any;
  reportId: number;
  id: any;
  tempStatus: string;
  totalNumberOfParticipants: number = 0;
  print: any;
  buttonShow: boolean;

  currentUserInfo: any;
  imageURL: any;
  pdfUrl: any;
  otherUrl: any;
  isImageLoading: boolean;
  isVisibleForAttachment = false;
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png', 'PNG'];

  applicationPermissions = applicationPermissions;

  allowedExtensions = environment.validation.allowedExtensions;
  maxFormatFileSize = 10;
  conceptTemplateRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private eventService: EventModuleService,
    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService

  ) { }

  ngOnInit(): void {
    this.addEventReportForm = this.fb.group({
      facilitators: [null],
      eventObjectives: [null],
      actualCost: [null],
      challengesFaced: [null],
      femaleParticipants: [null],
      maleParticipants: [null],
      otherGenderParticipants: [null],
      pwdParticipants: [null],
      minorityParticipants: [null],
      commentsAboutParticipants: [null],
      participantsFeedback: [null],
      lessonsLearnt: [null],
      futureSteps: [null],
      preparedBy: [null,[Validators.required]],
      remarks: [null],
      status: [null],
      reportStatus: [null],
      attachment: [null],
      attachmentLinks: [null],
      attachmentFileName: [null],
      attachmentFileType: [null]
    });
    this.getEventForm = this.fb.group({
      eventTitle: [null],
      eventTypeIfOthers: [null],
      eventTypeId: [null],
      subType: [null],
      dateFrom: [null],
      dateTo: [null],
      purpose: [null],
      venue: [null],
      focalPerson: [null],

      organisedByIfOthers: [null],
      organiserId: [null],

      status: [null],
      divisions: [null],
      districts: [null],
    });
    this.getEventTypesPublic();
    this.getOrganizersPublic();
    this.readIndustrialSectors();
    this.getAllDivision();

    this.route.queryParams
      .subscribe(params => {
        if (params?.status != null) {
          this.tempScheduleId = params.id;
          this.tempStatus = params.status;
          this.getNewEventPublic(params.id, params.reportId, params.status);
        }
      });

      this.currentUserInfo = JSON.parse(
        localStorage.getItem('currentUserInfo') ?? '{}'
      );
  }

  getEventTypesPublic(): void {
    this.eventService.getEventTypesPublic()
      .subscribe({
        next: (response) => {
          this.eventTypesList = response;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }


  getOrganizersPublic(): void {
    this.organizedByList = [];
    this.eventService.getOrganizersPublic()
      .subscribe({
        next: (response) => {
          this.organizedByList = response;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  readIndustrialSectors(): void {
    this.industrialSectorsList = [];
    this.eventService.readIndustrialSectors()
      .subscribe({
        next: (response) => {
          this.industrialSectorsList = response?.data;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }
  getAllDivision() {
    this.eventService
      .readStpDivisionsBYCountryID(Number(1))
      .subscribe({
        next: (res) => {
          this.divisionList = res;
        },
      });
  }

  onSelectDivision(event: number[]): void {
    const divisionIds = event;
    this.districtList = []
    divisionIds.forEach(divisionId => {
      if (divisionId) {
        this.eventService
          .readStpDistrictsByDivisionId(Number(divisionId))
          .subscribe({
            next: (response) => {
              this.districtList = this.districtList.concat(response);
            },
          });
      }
    });

  }

  getNewEventPublic(scheduleId: number, reportId: number, status: string) {
    if (reportId != null) {
      this.id = reportId;
    }
    else {
      this.id = null;
    }
    this.eventService.getNewEventPublic(scheduleId).subscribe({
      next: (res) => {
        if (res != null) {
          let divisionListNumber: number[] = [];
          res?.divisionList.forEach((element: String) => {
            divisionListNumber.push(+element)
          });
          let districtListNumber: number[] = [];
          res?.districtList.forEach((element: String) => {
            districtListNumber.push(+element)
          });
          this.eventDataList = res;
          this.getEventForm.patchValue({
            eventId: res?.eventId,
            eventTypeId: res?.eventType.id,
            eventTitle: res?.eventTitle,
            eventTypeIfOthers: res?.eventTypeIfOthers,
            subType: res?.subType,
            dateFrom: res?.dateFrom,
            dateTo: res?.dateTo,
            purpose: res?.purpose,
            venue: res?.venue,
            focalPerson: res?.focalPerson,
            organisedByIfOthers: res?.organisedByIfOthers,
            organiserId: res?.organiser.id,
            status: res?.status,
            divisions: divisionListNumber,
            districts: districtListNumber,

          })
          this.getEventForm.disable();

          if (reportId != null) {
            this.eventService.getReportPublic(reportId).subscribe({
              next: (res) => {
                if (res != null) {
                  this.reportId = reportId;
                  let divisionListNumber: number[] = [];
                  res?.schedule?.divisionList?.forEach((element: String) => {
                    divisionListNumber.push(+element)
                  });
                  let districtListNumber: number[] = [];
                  res?.schedule?.districtList?.forEach((element: String) => {
                    districtListNumber.push(+element)
                  });

                  this.reportDataList = res;
                  this.addEventReportForm.patchValue({
                    eventId: res?.schedule.eventId,
                    eventTypeId: res?.schedule.eventType.id,
                    eventTitle: res?.schedule.eventTitle,
                    eventTypeIfOthers: res?.schedule.eventTypeIfOthers,
                    subType: res?.schedule.subType,
                    dateFrom: res?.schedule.dateFrom,
                    dateTo: res?.schedule.dateTo,
                    purpose: res?.schedule.purpose,
                    venue: res?.schedule.venue,
                    focalPerson: res?.schedule.focalPerson,
                    organisedByIfOthers: res?.schedule.organisedByIfOthers,
                    organiserId: res?.schedule.organiser.id,
                    status: res?.schedule.status,
                    divisions: divisionListNumber,
                    districts: districtListNumber,

                    facilitators: res?.facilitators,
                    eventObjectives: res?.eventObjectives,
                    actualCost: res?.actualCost,
                    challengesFaced: res?.challengesFaced,
                    femaleParticipants: res?.femaleParticipants,
                    maleParticipants: res?.maleParticipants,
                    otherGenderParticipants: res?.otherGenderParticipants,
                    pwdParticipants: res?.pwdParticipants,
                    minorityParticipants: res?.minorityParticipants,
                    commentsAboutParticipants: res?.commentsAboutParticipants,
                    participantsFeedback: res?.participantsFeedback,
                    lessonsLearnt: res?.lessonsLearnt,
                    futureSteps: res?.futureSteps,
                    preparedBy: res?.preparedBy,
                    attachment: res?.attachment,
                    remarks: res?.remarks,
                    reportStatus: res?.status,

                    attachmentLinks: res?.attachmentLinks,
                    attachmentFileName: res?.attachmentFileName,
                    attachmentFileType: this.getFileTypeFromPreviousAttachment(res?.attachmentLinks),

                  })
                  if(res?.attachmentFileName != null && res?.attachmentFileName != ''){
                    this.setBlobAttachmentforPreviewing(res?.attachmentFileName);
                  }
                  if (status != 'Report Drafted' && status != 'Report Need Correction') {
                    this.addEventReportForm.disable();
                    this.buttonShow = false;
                  }
                  this.updateTotalParticipants();


                }
              }
            })
          }
        }
      }
    })
    // this.id = reportId;
  }


  onSave() {

    const formData: any = new FormData();

    if (this.id != null) {
      formData.append("id", this.id);

    }
    formData.append("scheduleId", this.tempScheduleId);
    for (const field in this.addEventReportForm.controls) {
      let fieldValue = this.addEventReportForm.get(field)?.value;

      if (this.addEventReportForm.get(field)?.value) {
        formData.append(field, fieldValue);
      }

    }

    this.eventService.saveReport(formData)
      .subscribe(
        (response) => {
          if (response.success == 'true') {
            this.notification.success('Success!', response.message);
            if (this.id == null) {
              this.id = response?.data;

            }
            this.router.navigate(['/home/event/add-event-report']);
          }
          else {
            this.notification.error('Failed!', response.message);
          }
        },
        (error) => this.notification.error('Failed', error.error.message)
      );
  }

  onSubmit() {
    this.eventService.submitReport(this.id)
      .subscribe(
        (response) => {
          if (response.success == 'true') {
            this.notification.success('Success!', response.message);
            this.router.navigate(['/home/event/user-dashboard']);
          }
          else {
            this.notification.error('Failed!', response.message);
          }
        },
        (error) => this.notification.error('Failed', error.error.message)
      );
  }

  numericValidation(controlName: string, type: string) {
    let value = this.addEventReportForm.controls[controlName].value;
    if (type == 'int') {
      value = value.replace(/[^0-9^]+/g, '').replace(/^0+/, '');
    }
    else if (type == 'float') {
      value = value.replace(/[^0-9^\.]+/g, '').replace(/^0+/, '');
    }
    this.addEventReportForm.controls[controlName].setValue(value)
  }


  updateTotalParticipants() {
    this.totalNumberOfParticipants = +this.addEventReportForm.controls.femaleParticipants.value +
      +this.addEventReportForm.controls.maleParticipants.value +
      +this.addEventReportForm.controls.otherGenderParticipants.value;
  }

  uploadAttachmentFile(event: any, attachmentTemplateRef: any) {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      attachmentTemplateRef.value = null;
      return;
    }

    const file = event.target.files[0];
    let fileFor = event.target.id;
    this.addEventReportForm.patchValue({
      [fileFor]: file,
    });
    this.addEventReportForm.get(fileFor)?.updateValueAndValidity();

    this.addEventReportForm.get('attachmentFileName')?.setValue(file?.name);

    this.addEventReportForm.get('attachmentLinks')?.setValue(URL.createObjectURL(file));
    this.addEventReportForm.get('attachmentFileType')?.setValue(this.getFileTypeFromNewAttachment(file?.type));
    attachmentTemplateRef.value = null;
  }


  getFileTypeFromNewAttachment(fileType: string) {
    let contentType = '';
    if (fileType.match(/image/)) {
      contentType = 'image';
    } else if (fileType == 'application/pdf') {
      contentType = 'pdf';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {

    let contentType = '';

    if(fileUrl != null && fileUrl != ''){
      let basename = fileUrl.split(/[\\/]/).pop();
      let pos = basename?.lastIndexOf('.') ?? 0;

      let fileType = basename?.slice(pos + 1) ?? '';
      if (this.imageTypeArray.includes(fileType)) {
        contentType = 'image';
      } else if (fileType == 'pdf') {
        contentType = 'pdf';
      } else {
        contentType = 'other';
      }
    }

    return contentType;
  }

  async setBlobAttachmentforPreviewing(fileName: string) {

    const data = await fetch(`${environment.eventApiUrl}public-dashboard/download-file?fileName=${fileName}`, {
      method: 'GET',
      headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${this.currentUserInfo?.token}`,
      }
    });

    if(data.status == 200){
      const blob = await data?.blob();
      this.addEventReportForm.get('attachmentLinks')?.setValue(URL.createObjectURL(blob));
    }
  }


  previewImage(fileType: string | undefined, downloadLink: any, fileName: any) {


    this.imageURL = '';
    this.pdfUrl = '';
    this.otherUrl = '';

    if (fileType == 'image') {
      this.isVisibleForAttachment = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(downloadLink);
    } else if (fileType == 'pdf') {
      this.isVisibleForAttachment = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(downloadLink);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', downloadLink);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  handleOkFile(): void {
    this.isVisibleForAttachment = false;
  }

  handleCancelFile(): void {
    this.isVisibleForAttachment = false;
  }
}
