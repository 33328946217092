<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="form" nzTheme="outline"></i> Certificate Upload
  </div>
  <div class="col-md-12" style="
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
">
    <div class="row">
      <div class="col-md-4">
        <nz-form-label nzRequired>Upload Excel File</nz-form-label>
        <nz-form-item>
          <nz-form-control>
            <input
              #myInput
              type="file"
              nz-input
              (change)="onSelectFile($event)"
              accept=".xlsx,.xls"
            />
          </nz-form-control>
          <span style="color: red"><sup>**</sup>Note: </span> &nbsp;<span>
            Please upload only excel file and file size maximum 10MB</span
          >
        </nz-form-item>
      </div>

      <div class="col-md-2 downloadButton">
        <nz-form-item>
          <nz-form-control>
            <button
              nz-button
              type="button"
              class="btn btn-success active btn-lg btn-block cari border-redius"
              (click)="submitExcelData()"
            >
              <i nz-icon nzType="save" nzTheme="fill"></i>
              Upload
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-md-4 downloadButton">
        <button
          nz-button
          nzType="primary"
          style="background-color: black"
          class="border-redius"
          onclick="window.location.href='/assets/file/Template for Certificate Issuance.xlsx'"
        >
          <i nz-icon nzType="download"></i>
          Download Sample File
        </button>
      </div>

      <!-- <div class="col-md-3 downloadButton">
        <button
          nz-button
          nzType="primary"
          style="background-color: brown"
          class="border-redius"
          (click) = "onDownloadRegistrationCard()"
        >
          <i nz-icon nzType="download"></i>
          Download Registration Card
        </button>
      </div> -->
    </div>
  </div>
</nz-card>
<app-tic-list></app-tic-list>
