<app-header></app-header>
<div class="searchboxAerar p-3">
  <div class="card">
    <form nz-form [formGroup]="addNewEventForm">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i>
          Event Schedule
      </div>
      <br>

      <div class="col-md-12">
        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Event Type
            </nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row" nzHasFeedback>
                <nz-select nzShowSearch nzAllowClear formControlName="eventTypeId" nzPlaceHolder="Select One">
                  <nz-option *ngFor="let item of eventTypesList" [nzValue]="item.id" [nzLabel]="item.typeName">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Specify if Others</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Specify Others Event Type"
                  formControlName="eventTypeIfOthers" [readonly]="addNewEventForm.controls.eventTypeId.value != 10">
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Event Title</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row" nzHasFeedback>
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Event Title"
                  formControlName="eventTitle" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Sub-type</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Sub-type" formControlName="subType" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Date From
            </nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="orientationStartDateErrorTpl" class="row">
                <nz-date-picker id="dateFrom" formControlName="dateFrom" placeholder="Date" class="col-md-12">
                </nz-date-picker>
              </nz-form-control>
              <ng-template #orientationStartDateErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please select valid orientation start date!
                </ng-container>
                <ng-container *ngIf="control.errors?.dateValidator">
                  Start date can not be greater than End Date
                </ng-container>
              </ng-template>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Date To</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-form-control nzHasFeedback [nzErrorTip]="orientationEndDateErrorTpl">
                  <nz-date-picker id="dateTo" formControlName="dateTo" placeholder="Date" class="col-md-12">
                  </nz-date-picker>
                </nz-form-control>
                <ng-template #orientationEndDateErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select valid orientation end date!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Purpose of the event</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Purpose of the event"
                  formControlName="purpose" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Industrial Sectors (if applicable)</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzMode="multiple" nzShowSearch nzAllowClear formControlName="sectors"
                  nzPlaceHolder="Select One" style="color:#2d2d2d;">
                  <nz-option *ngFor="let item of industrialSectorsList" [nzValue]="item.id" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Venue</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Venue" formControlName="venue" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Division</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzMode="multiple" nzShowSearch nzAllowClear formControlName="divisions"
                  nzPlaceHolder="Select Division" (ngModelChange)="onSelectDivision($event)">
                  <nz-option *ngFor="let division of divisionList" [nzValue]="division.id"
                    [nzLabel]="division.divisionName">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>District</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzMode="multiple" nzShowSearch nzAllowClear formControlName="districts"
                  nzPlaceHolder="Select District">
                  <nz-option *ngFor="let district of districtList" [nzValue]="district.id"
                    [nzLabel]="district.districtName">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Organised by</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row" nzHasFeedback>
                <nz-select nzShowSearch nzAllowClear formControlName="organiserId" nzPlaceHolder="Select One">
                  <nz-option *ngFor="let item of organizedByList" [nzValue]="item.id" [nzLabel]="item.organiserName">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Specify if Others</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Specify if Others"
                  formControlName="organisedByIfOthers" [readonly]="addNewEventForm.controls.organiserId.value != 2" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Event Partner(s)</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Event Partner(s)"
                  formControlName="partners" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Focal Person From Organiser</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Focal Person From Organiser"
                  formControlName="focalPerson" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Required materials</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Required materials"
                  formControlName="requiredMaterials" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Concept Note Available?</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzShowSearch nzAllowClear formControlName="isConceptNoteAvailable"
                  nzPlaceHolder="Select One">
                  <nz-option *ngFor="let item of yesNoList" [nzValue]="item.bool" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Budget Approved?</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzShowSearch nzAllowClear formControlName="isBudgetApproved" nzPlaceHolder="Select One">
                  <nz-option *ngFor="let item of yesNoList" [nzValue]="item.bool" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Concept file</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <table>
                  <tr>
                    <td colspan="2">
                      <input #conceptInput style="color:#2d2d2d;" type="file" class="form-control" id="conceptNote"
                  (change)="uploadConceptFile($event, conceptInput)">
                    </td>
                  </tr>
                  <tr *ngIf="this.addNewEventForm.get('conceptFileName')?.value">
                    <td style="width: 60%">
                      {{ this.addNewEventForm.get('conceptFileName')?.value }}
                    </td>
                    <td style="width: 40%">
                      <button nz-button type="button" nzType="default" [nzSize]="'small'" nz-tooltip
                          nzTooltipTitle="Preview" nzTooltipPlacement="top" (click)="previewImage(this.addNewEventForm.get('conceptFileType')?.value,
                          this.addNewEventForm.get('conceptDownloadLink')?.value, this.addNewEventForm.get('conceptFileName')?.value)">
                          <i nz-icon *ngIf="this.addNewEventForm.get('conceptFileType')?.value == 'image' || this.addNewEventForm.get('conceptFileType')?.value == 'pdf'"
                          nzType="eye" nzTheme="fill"></i>
                          <i nz-icon *ngIf="this.addNewEventForm.get('conceptFileType')?.value == 'other'" nzType="file" nzTheme="fill"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- <label style="color:#D2232A; font-size: 10px; font-weight: bold;">(File Allowed: pdf/excel/doc/jpg/jpeg/png. Max Size: 10 MB)</label> -->
                      <span style="color: red; font-size: 11px"><sup>*</sup>Note: </span>&nbsp;<span style="font-size: 11px; ">
                        Please upload .pdf/.doc/.docx/.xls/.xlsx/.png/.jpg/.jpeg file. File size max 10MB.</span>
                    </td>
                  </tr>
                </table>

              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Budget file</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <table>
                  <tr>
                    <td colspan="2">
                      <input #budgetInput style="color:#2d2d2d;" type="file"  class="form-control"
                      id="budget" (change)="uploadBudgetFile($event, budgetInput)">
                    </td>
                  </tr>
                  <tr *ngIf="this.addNewEventForm.get('budgetFileName')?.value">
                    <td style="width: 60%">
                      {{ this.addNewEventForm.get('budgetFileName')?.value }}
                    </td>
                    <td style="width: 40%">
                      <button nz-button type="button" nzType="default" [nzSize]="'small'" nz-tooltip
                          nzTooltipTitle="Preview" nzTooltipPlacement="top" (click)="previewImage(this.addNewEventForm.get('budgetFileType')?.value,
                          this.addNewEventForm.get('budgetDownloadLink')?.value, this.addNewEventForm.get('budgetFileName')?.value)">
                          <i nz-icon *ngIf="this.addNewEventForm.get('budgetFileType')?.value == 'image' || this.addNewEventForm.get('budgetFileType')?.value == 'pdf'"
                          nzType="eye" nzTheme="fill"></i>
                          <i nz-icon *ngIf="this.addNewEventForm.get('budgetFileType')?.value == 'other'" nzType="file" nzTheme="fill"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- <label style="color:#D2232A; font-size: 10px; font-weight: bold;">(Please upload pdf/doc/docx/xls/xlsx/png/jpg/jpeg and max file size 3 mb)</label> -->
                      <span style="color: red; font-size: 11px"><sup>*</sup>Note: </span>&nbsp;
                      <span style="font-size: 11px; ">
                        Please upload .pdf/.doc/.docx/.xls/.xlsx/.png/.jpg/.jpeg file. File size max 10MB.</span>
                    </td>
                  </tr>
                </table>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Budget Head/Code</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Budget Head/Code"
                  formControlName="budgetHeadCode" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Estimated Budget (BDT)</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Amount Only"
                  formControlName="budgetEstimate" (input)="numericValidation('budgetEstimate','float')" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label>Remarks</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Remarks" formControlName="remarks" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <!-- <div class="row justify-content-center media-button" *ngIf="this.buttonShow != false">
          <div class="col-xs-6 col-sm-2">
            <button type="submit" class="btn btn-success active btn-lg btn-block cari border-redius" nz-popconfirm
              nzPopconfirmTitle="Are you sure to save the New Event?" (nzOnConfirm)="onSave()" [disabled]="!addNewEventForm.valid">
              <i nz-icon nzType="save" nzTheme="fill"></i> Save
            </button>
          </div>
          <div class="col-xs-6 col-sm-2" *ngIf="this.id != null">
            <button class="btn btn-danger active btn-lg btn-block cari border-redius" style="background-color: #D2232A;"
              nz-popconfirm nzPopconfirmTitle="Are you sure to submit the New Event? Once submitted you can not change it !"
              (nzOnConfirm)="onSubmit()" [disabled]="!addNewEventForm.valid">
              <i nz-icon nzType="save" nzTheme="fill"></i> Submit
            </button>
          </div>
        </div> -->
        <br>
      </div>
    </form>

    <div *ngIf="this.tempStatus == 'Schedule Approved'" [appRequiredPermission]="applicationPermissions.event.eventScheduleCreate">
      <form nz-form [formGroup]="updateEventForm">
        <div class="ng-Header col-xs-12">
          <i nz-icon nzType="form" nzTheme="outline"></i> Update Status
        </div>
        <br>

        <div class="mb-2 row">
          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Status</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzShowSearch nzAllowClear formControlName="updateStatus" nzPlaceHolder="Select One">
                  <nz-option *ngFor="let item of statusList" [nzValue]="item.id" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3 col-sm-12 text-right">
            <nz-form-label nzRequired>Remarks</nz-form-label>
          </div>
          <div class="col-md-3 col-sm-12">
            <nz-form-item>
              <nz-form-control class="row">
                <input style="color:#2d2d2d;" nz-input type="text" placeholder="Remarks" formControlName="updateRemarks" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <!-- <div class="row justify-content-center media-button">
          <div class="col-xs-6 col-sm-2">
            <button class="btn btn-danger active btn-lg btn-block cari border-redius"
              style="background-color: #D2232A;" nz-popconfirm
              nzPopconfirmTitle="Are you sure to submit? Once submitted you can not change it !" (nzOnConfirm)="onPostpone()" [disabled]="!updateEventForm.valid">
              <i nz-icon nzType="save" nzTheme="fill"></i> Submit
            </button>
          </div>
        </div> -->
        <br>
      </form>
    </div>
  </div>
</div>




<nz-modal
  [(nzVisible)]="isVisibleForAttachment"
  (nzOnCancel)="handleCancelFile()"
  [nzClosable]="false"
  [nzOkText]="null"
  (nzOnOk)="handleOkFile()"
  [nzWidth]="1200"
>
  <ng-container *nzModalContent>
    <iframe
      *ngIf="!isImageLoading && pdfUrl"
      [src]="pdfUrl"
      style="width: 100%; height: 35vw"
    ></iframe>
    <div style="text-align: center">
      <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
      <ng-template #noImageFound>
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
          alt="Fallbackimage"
        />
      </ng-template>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="handleCancelFile()">Close</button>
  </div>
</nz-modal>
<app-footer></app-footer>
