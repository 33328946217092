import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CourseAccreditationModule } from '../course-accreditation.module';
import { BasicInfoData } from '../DTO/basicInfoTabData';
import { InspectionResultResponse } from '../DTO/InspectionResultResponseDto';
import { BasicInformationResponse } from '../models/basic-information-response';
import { CompleteInspection } from '../models/complete-inspection.model';
import { InspectionOfficer } from '../models/inspectionOfficer';
import { PaymentVerification } from '../models/paymentVerification.model';
import { Recommandation } from '../models/recommandation';
import { Representatives } from '../models/representatives.model';
import { ScheduleInspection } from '../models/schedule-inspection.model';
import { StpSacInspectionRegistrationReport } from '../models/stp-sac-inspection-registration-report.model';

@Injectable()
export class InspectionService {
  onStartPaymentVerificationEditing = new Subject<number | null>();
  onRefreshPaymentVerificationList = new Subject<PaymentVerification[]>();
  paymentVerifications: PaymentVerification[] = [];
  editingpaymentVerificationId!: number | null;
  public inspectorOfficerSubject: Subject<any> = new Subject();
  //#region Representative BehaviorSubject
  representativeBehavSub: BehaviorSubject<Representatives[]> =
    new BehaviorSubject<Representatives[]>([]);
  representativeList = this.representativeBehavSub.asObservable();
  //#endregion

  //#region Emitter
  courseAcrreditationEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  basicInfoDataEmitter: EventEmitter<BasicInfoData> =
    new EventEmitter<BasicInfoData>();
  machineryEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  measuringInstrumentEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  toolEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  materialsConsumableEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  ppeEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  furnitureEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  learningMaterialEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  trainingFacilityEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  trainersOtherStaffEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  otherRequirementEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();
  cctvEmitter: EventEmitter<BasicInformationResponse> =
    new EventEmitter<BasicInformationResponse>();

  paymentEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  occupationListEmitter: EventEmitter<InspectionResultResponse[]> =
    new EventEmitter<InspectionResultResponse[]>();

  addNewOccupationEmitter: EventEmitter<number> = new EventEmitter<number>();
  formResetEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  inspectionIdForNewOccupation: EventEmitter<number> =
    new EventEmitter<number>();

  previousBtnEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  //#endregion Emitter

  onStartBasicInformationEditing = new Subject<number | null>();
  onRefreshBasicInformationList = new Subject<any[]>();
  basicInformations: any[] = [];
  editingBasicInformationId?: number | null;

  onStartScheduleInspectionEditing = new Subject<number | null>();
  onRefreshScheduleInspectionList = new Subject<any[]>();
  scheduleInspections: any[] = [];
  editingScheduleInspectionId?: number | null;

  onStartInspectionOfficerEditing = new Subject<number | null>();
  onRefreshInspectionOfficerList = new Subject<any[]>();
  inspectionOfficers: InspectionOfficer[] = [];
  scheduleInspection: ScheduleInspection[] = [];
  editingInspectionOfficerId?: number | null;

  onStartRecommandationEditing = new Subject<number | null>();
  onRefreshRecommandationList = new Subject<any[]>();
  recommandations: Recommandation[] = [];
  editingRecommandationId?: number | null;

  //#region
  completeInspection: CompleteInspection[] = [];
  onRefreshCompleteInspectionList = new Subject<any[]>();
  completeInspections: any[] = [];
  //#endregion

  inspectionRegistrationReport: StpSacInspectionRegistrationReport[] = [];
  onRefreshinspectionRegistrationReportList = new Subject<any[]>();
  inspectionRegistrationReports: any[] = [];
  constructor() {}

  setPaymentVerifications(paymentVerifications: PaymentVerification[]): void {
    this.paymentVerifications = paymentVerifications;
    this.onRefreshPaymentVerificationList.next(
      this.paymentVerifications.slice()
    );
  }

  addPaymentVerification(paymentVerification: PaymentVerification): void {
    this.paymentVerifications.push(paymentVerification);
    this.onRefreshPaymentVerificationList.next(
      this.paymentVerifications.slice()
    );
  }

  //#region Set representative
  setRepresentatives(representativeList: any) {
    this.representativeBehavSub.next(representativeList);
  }
  //#endregion

  //#region Course Acrreditation (Parent)

  setCourseAcrreditationTamId(data: any): void {
    this.courseAcrreditationEmitter.emit(data);
  }

  getCourseAcrreditationTamId(): EventEmitter<BasicInformationResponse> {
    return this.courseAcrreditationEmitter;
  }
  //#endregion Course Acrreditation (Parent)

  //#region Machinery (Child)

  setMachineryTamId(data: BasicInformationResponse) {
    // console.log(data);
    this.machineryEmitter.emit(data);
  }

  getMachineryTamId(): EventEmitter<BasicInformationResponse> {
    return this.machineryEmitter;
  }
  //#endregion Machinery (Child)

  //#region measuring-instruments (Child)

  setMeasuringInstrumentTamId(data: BasicInformationResponse) {
    this.measuringInstrumentEmitter.emit(data);
  }

  getMeasuringInstrumentTamId(): EventEmitter<BasicInformationResponse> {
    return this.measuringInstrumentEmitter;
  }
  //#endregion measuring-instruments (Child)

  //#region Tools (Child)

  setToolTamId(data: BasicInformationResponse) {
    this.toolEmitter.emit(data);
  }

  getToolTamId(): EventEmitter<BasicInformationResponse> {
    return this.toolEmitter;
  }
  //#endregion Tools (Child)

  //#region Material Consumable (Child)

  setMaterialsConsumableTamId(data: BasicInformationResponse) {
    this.materialsConsumableEmitter.emit(data);
  }

  getMaterialsConsumableTamId(): EventEmitter<BasicInformationResponse> {
    return this.materialsConsumableEmitter;
  }
  //#endregion Material Consumable (Child)

  //#region PPE (Child)

  setPpeTamId(data: BasicInformationResponse) {
    this.ppeEmitter.emit(data);
  }

  getPpeTamId(): EventEmitter<BasicInformationResponse> {
    return this.ppeEmitter;
  }
  //#endregion PPE (Child)

  //#region Furniture (Child)

  setFurnitureTamId(data: any) {
    this.furnitureEmitter.emit(data);
  }

  getFurnitureTamId(): EventEmitter<BasicInformationResponse> {
    return this.furnitureEmitter;
  }
  //#endregion Furniture (Child)

  //#region LearningMaterial (Child)

  setLearningMaterialTamId(data: BasicInformationResponse) {
    this.learningMaterialEmitter.emit(data);
  }

  getLearningMaterialTamId(): EventEmitter<BasicInformationResponse> {
    return this.learningMaterialEmitter;
  }
  //#endregion LearningMaterial (Child)

  //#region TrainingFacility (Child)

  setTrainingFacilityTamId(data: BasicInformationResponse) {
    this.trainingFacilityEmitter.emit(data);
  }

  getTrainingFacilityTamId(): EventEmitter<BasicInformationResponse> {
    return this.trainingFacilityEmitter;
  }
  //#endregion TrainingFacility (Child)

  //#region TrainersOtherStaff (Child)

  setTrainersOtherStaffTamId(data: BasicInformationResponse) {
    this.trainersOtherStaffEmitter.emit(data);
  }

  getTrainersOtherStaffTamId(): EventEmitter<BasicInformationResponse> {
    return this.trainersOtherStaffEmitter;
  }
  //#endregion TrainersOtherStaff (Child)

  //#region otherRequirement (Child)

  setOtherRequirementTamId(data: BasicInformationResponse) {
    this.otherRequirementEmitter.emit(data);
  }

  getOtherRequirementTamId(): EventEmitter<BasicInformationResponse> {
    return this.otherRequirementEmitter;
  }
  //#endregion otherRequirement (Child)

  //#region CCTV (Child)

  setCCTVTamId(data: BasicInformationResponse) {
    this.cctvEmitter.emit(data);
  }

  getCCTVTamId(): EventEmitter<BasicInformationResponse> {
    return this.cctvEmitter;
  }
  //#endregion CCTV (Child)

  //#region Basic Information
  addBasicInformation(basicInformation: any): void {
    this.basicInformations.push(basicInformation);
    this.onRefreshBasicInformationList.next(this.basicInformations.slice());
  }
  //#endregion Basic Information

  //#region Schedule Inspection

  addScheduleInspection(scheduleInspection: any): void {
    this.scheduleInspections.push(scheduleInspection);
    this.onRefreshScheduleInspectionList.next(this.scheduleInspections.slice());
  }

  addCompleteInspection(completeInspection: any): void {
    this.completeInspections.push(completeInspection);
    this.onRefreshCompleteInspectionList.next(this.completeInspections.slice());
  }

  setScheduleInspection(scheduleInspections: ScheduleInspection[]): void {
    this.scheduleInspection = scheduleInspections;
    this.onRefreshScheduleInspectionList.next(this.scheduleInspections.slice());
  }

  addInspectionOfficer(officer: InspectionOfficer): void {
    this.inspectionOfficers.push(officer);
    this.onRefreshInspectionOfficerList.next(this.inspectionOfficers.slice());
  }

  addInspectionOfficers(officer: any): void {
    this.inspectorOfficerSubject.next(officer);
  }

  setInspectionOfficer(officers: InspectionOfficer[]): void {
    this.inspectionOfficers = officers;
    this.onRefreshInspectionOfficerList.next(this.inspectionOfficers.slice());
  }
  //#endregion Schedule Inspection

  //#region Complete Inspection
  setCompleteInspection(completeInspections: CompleteInspection[]): void {
    this.completeInspection = completeInspections;
    this.onRefreshCompleteInspectionList.next(this.completeInspections.slice());
  }
  //#endregion

  //#region stp sac Inspection
  setStpSacInspection(
    inspectionRegistrationReports: StpSacInspectionRegistrationReport[]
  ): void {
    this.inspectionRegistrationReport = inspectionRegistrationReports;
    this.onRefreshinspectionRegistrationReportList.next(
      this.inspectionRegistrationReports.slice()
    );
  }
  //#endregion

  //#region Occupation List
  setOccupationList(list: InspectionResultResponse[]): void {
    this.occupationListEmitter.emit(list);
  }

  getOccupationList(): EventEmitter<InspectionResultResponse[]> {
    return this.occupationListEmitter;
  }
  //#endregion Occupation List

  //#region Basic Information Tab Data
  setBasicInfoData(data: BasicInfoData) {
    this.basicInfoDataEmitter.emit(data);
  }

  getBasicInfoData() {
    return this.basicInfoDataEmitter;
  }
  //#endregion Basic Information Tab Data

  //#region occupation list emitter
  setNewOccupationData(inspectionId: number) {
    this.addNewOccupationEmitter.emit(inspectionId);
  }

  getNewOccupationData(): EventEmitter<number> {
    return this.addNewOccupationEmitter;
  }
  //#endregion occupation list emitter

  //#region reset all tabs
  setResetForm(data: boolean) {
    this.formResetEmitter.emit(data);
  }

  getResetForm(): EventEmitter<any> {
    return this.formResetEmitter;
  }
  //#endregion reset all tabs

  //#region set inspection id for new occupation
  setInspectionIdForNewOccupation(id: number) {
    this.inspectionIdForNewOccupation.emit(id);
  }

  getInspectionIdForNewOccupation(): EventEmitter<number> {
    return this.inspectionIdForNewOccupation;
  }

  //#endregion set inspection id for new occupation

  //#region Recommandation

  setRecommandation(recommandations: Recommandation[]): void {
    this.recommandations = recommandations;
    this.onRefreshRecommandationList.next(this.recommandations.slice());
  }
  //#endregion Recommandation

  //#region PrevButton

  setPreviousBtn(): void {
    this.previousBtnEmitter.emit(true);
  }

  getPreviousBtn(): EventEmitter<boolean> {
    return this.previousBtnEmitter;
  }
  //#endregion PrevButton

  //#endregion set inspection id for new occupation

  //#region payment (Child)

  setIsPaymentExist(data: boolean) {
    this.paymentEmitter.emit(data);
  }

  getIsPaymentExist(): EventEmitter<boolean> {
    return this.paymentEmitter;
  }
  //#endregion payment (Child)
}
