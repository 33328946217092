import { Component, OnInit } from '@angular/core';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.scss'],
})
export class UserVerificationComponent implements OnInit {
  userId: string | null;
  // userId: number;
  userActive!: boolean;

  constructor(
    private authentication: AuthenticationStorageService,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    // this.getUserVerfication();
  }

  ngOnInit(): void {
    this.getUserVerfication();
  }

  // tslint:disable-next-line:typedef
  getUserVerfication() {
    this.authentication.readUserByDecreptId(this.userId).subscribe(
      (res) => {
        if (res.success) {
          this.userActive = true;
          this.notification.success('Success!', res.message,{ nzDuration: 60000 });
          this.router.navigate(['/login'], {
            relativeTo: this.activatedRoute,
          });
          // const returnUrl =
          //   this.route.snapshot.queryParams.returnUrl || '/login';
          // this.router.navigateByUrl('http://skillsportal.gov.bd/#/login');
          // window.location.href = 'http://skillsportal.gov.bd/#/login';
        }
        else{
          this.userActive = false;
          this.notification.error('Error!', res.message,{ nzDuration: 60000 });
          this.router.navigate(['/login'], {
            relativeTo: this.activatedRoute,
          });
        }
      },
      // (err) => {
      //   console.log("Error Message");
      //   this.userActive = false;
      //   this.notification.error('Error!', 'User Activation is not successful',{ nzDuration: 60000 });
      //   this.router.navigate(['/login'], {
      //     relativeTo: this.activatedRoute,
      //   });
      // }
    );
  }
}
