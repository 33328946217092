import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tic-list',
  templateUrl: './tic-list.component.html',
  styleUrls: ['./tic-list.component.scss']
})
export class TicListComponent implements OnInit {


  constructor(
  ) {

  }

  ngOnInit(): void {
  }


}
