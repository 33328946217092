import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TciInfoStorageService } from 'src/app/modules/template-for-certificate-issuance/services/tci-info-storage.service';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';
import { CompetencyStandardStorageService } from 'src/app/modules/training-and-assessment-materials/services/competency-standard-storage.service';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { ResearchAndStudiesStorageService } from 'src/app/modules/research-and-studies/services/research-and-studies-storage.service';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
})
export class MainContentComponent implements OnInit {
  isShowTab: boolean = false;
  userInfo: any;
  isOkLoading = false;
  totalNoOfCertificatesCount: number;
  totalNoOfApprovedStpRegisterCount: number;
  totalNoOfCSCount: number;
  totalApprovedCourseCount: number;
  totalApprovedCenterCount: number;
  isActiveExternalRegForm: boolean = false;

  applicationPermissions = applicationPermissions;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService,
    private tciInfoStorageService: TciInfoStorageService,
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private competencyStandardStorageService: CompetencyStandardStorageService,
    private researchAndStudiesStorageService: ResearchAndStudiesStorageService,
    private modal: NzModalService
  ) {}

  ngOnInit() {
    let currentuserInfo = localStorage.getItem('currentUserInfo');
    if (currentuserInfo != null) {
      this.userInfo = JSON.parse(currentuserInfo);
    }
    this.getCertificatesCount();
    this.getApprovedStpRegisterCount();
    this.getCSCount();
    this.getApprovedStpCourseCount();
    this.getApprovedStpCenterCount();
    this.getExternalUserRegFormMangerInfo();
  }

  // showCertificateTab(): void {
  //   window.open('#/publish-certificate-demo-video', '_blank');
  //   if (this.userInfo == null) {
  //     this.notification.success(
  //       'Success!',
  //       'Please login from admin side first !'
  //     );
  //   } else if (this.userInfo?.roles[0].name?.toLowerCase() == 'admin') {
  //     this.isShowTab = !this.isShowTab;
  //     this.authenticationService.setPublishCertificate(this.isShowTab);
  //   } else {
  //     this.isShowTab = false;
  //     this.authenticationService.setPublishCertificate(this.isShowTab);
  //     this.notification.success(
  //       'Success!',
  //       'Please login from admin side first !'
  //     );
  //   }
  // }

  NAPLogin() {
    this.authenticationService.NAPLogout();
  }

  //#region get certificates count
  getCertificatesCount() {
    this.tciInfoStorageService.readCertificatesCount().subscribe({
      next: (res) => {
        this.totalNoOfCertificatesCount = res;
      },
    });
  }
  //#endregion

  //#region get approved stp register count
  getApprovedStpRegisterCount() {
    this.stpRegistrationStorageService
      .readApprovedStpRegisterCount()
      .subscribe({
        next: (res) => {
          this.totalNoOfApprovedStpRegisterCount = res;
        },
      });
  }
  //#endregion
  //#region get approved stp course count
  getApprovedStpCourseCount() {
    this.stpRegistrationStorageService.readApprovedStpCoursesCount().subscribe({
      next: (res) => {
        this.totalApprovedCourseCount = res;
      },
    });
  }
  //#endregion
  //#region get approved stp center count
  getApprovedStpCenterCount() {
    this.stpRegistrationStorageService.readApprovedStpCenterCount().subscribe({
      next: (res) => {
        this.totalApprovedCenterCount = res;
      },
    });
  }
  //#endregion

  //#region get approved stp register count
  getCSCount() {
    this.competencyStandardStorageService.readCSCount().subscribe({
      next: (res) => {
        this.totalNoOfCSCount = res;
      },
    });
  }
  //#endregion

getExternalUserRegFormMangerInfo() {
  this.researchAndStudiesStorageService
    .readExternalUserRegFormInfo()
    .subscribe({
      next: (res) => {
        if (res?.success === true && res.data) {
          this.isActiveExternalRegForm = res.data.isActive;
        } else {
          this.isActiveExternalRegForm = false;
        }
      },
      error: (err) => {
        console.error('API error occurred:', err);
        this.isActiveExternalRegForm = false;
      },
    });
}



}
