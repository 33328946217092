import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { RequestData } from 'src/app/modules/stp-registration/models/stp_onlinePayment.model';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { TamMainSearchResultDto } from 'src/app/modules/training-and-assessment-materials/models/dto/tam_main_search_result_dto.model';
import { TamIndustrialSector } from 'src/app/modules/training-and-assessment-materials/models/tam-Industrial-sector.model';
import { TamMainSearch } from 'src/app/modules/training-and-assessment-materials/models/tam-main-search.model';
import { TamOccupation } from 'src/app/modules/training-and-assessment-materials/models/tam-occupation.model';
import { TamType } from 'src/app/modules/training-and-assessment-materials/models/tam-type.model';
import { TrainingAndAssessmentMaterialsStorageService } from 'src/app/modules/training-and-assessment-materials/services/training-and-assessment-materials-storage.service';
import { TrainingAndAssessmentMaterialsService } from 'src/app/modules/training-and-assessment-materials/services/training-and-assessment-materials.service';



@Component({
  selector: 'app-tam-list-mra',
  templateUrl: './tam-list-mra.component.html',
  styleUrls: ['./tam-list-mra.component.scss']
})
export class TamListMraComponent implements OnInit {
  applicationPermissions = applicationPermissions;
  tamMainSearchResultDto: TamMainSearchResultDto[] = [];
  onTamMainSubscription$: Subscription;
  file: any = File;

  tamTypeDecodedId: any;
  tamTypeId: number;

  requestData: RequestData = new RequestData();
  userInfo: any;
  reportData: any;
  searchForm: FormGroup;

  tamOccupations: TamOccupation[] = [];
  onTamOccupationSubscription$: Subscription;
  selectedOccupation?: TamOccupation;

  tamTypes: TamType[] = [];
  onTamTypeSubscription$: Subscription;
  selectedTamType?: TamType;
  selectedValue: number;

  tamIndustrialSectors: TamIndustrialSector[] = [];
  onTamIndustrialSectorSubscription$: Subscription;

  tamMainSearch: TamMainSearch;

  totalData: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  sortingKey: string;
  sortingValue: string;

  hiddingHeaderFooter: boolean = true;

  // for pagination
  total: number;
  size: number = 10;
  page: number = 1;

  constructor(
    private trainingAndAssessmentMaterialsService: TrainingAndAssessmentMaterialsService,
    private trainingAndAssessmentMaterialsStorageService: TrainingAndAssessmentMaterialsStorageService,
    private notification: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private excelExport: ExcelDownloadService,
    private commonStorageService: CommonStorageService,
    private datepipe: DatePipe,
    private cd: ChangeDetectorRef,
  ) {
    this.userId();
    (window.location.href.includes("home")) ? this.hiddingHeaderFooter = true : (!(this.userInfo?.roles == null)) ? this.hiddingHeaderFooter = false : this.hiddingHeaderFooter = false ;
  }

  ngOnInit(): void {
    this.createSearchForm();
    this.getIndustrialSectorList();
    this.getTamTypeList();
    this.getOccupationList();
    this.submitSearchForm();

    this.activatedRoute.queryParams.subscribe((params) => {
      const base64EncodedValue = params['tamTypeId'];
      if (base64EncodedValue) {
        try {
          this.tamTypeDecodedId = atob(base64EncodedValue);
          this.searchForm.get('tamTypeId')?.setValue(Number(this.tamTypeDecodedId));
          this.searchForm.get('tamTypeId')?.updateValueAndValidity();
          this.submitSearchForm();
        } catch (e) {
          console.error('Invalid Base64 encoded value:', e);
        }
      } else {
        console.warn('tamTypeId parameter not found in query params');
      }
    });
  }

  onEdit(id: number | undefined): void {
    // this.trainingAndAssessmentMaterialsService.setEditingTamMainSearchResultId(id);
    this.router.navigate(['/home/training-and-assessment-materials/tam-update'], {
      queryParams: { tamMainId: id},
      relativeTo: this.activatedRoute,
    });
  }
  onDownload(data: any): void {
    this.commonStorageService.downloadFile(data.docFilePath).subscribe({
      next: (result: any) => {
        var fileName = data.tamApplicationId;
        const contentDisposition = result.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            var totalFileName = matches[1].replace(/['"]/g, '');
            var fullFile = totalFileName.split('.');
            var exten = fullFile[1];
            fileName = fileName + '.' + exten;
          }
        }
        saveAs(result.body, fileName);
      },
      error: (error) => this.notification.error('Failed!', 'No file found!'),
    });
  }

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo.id;
  }

  selectedCourseAccreditationDocument(tamMainId: number | undefined): void {
    const url = (!(this.userInfo?.roles == null)) ?'/home/training-and-assessment-materials/tam-view/':
    '/tam-view/';
    this.router.navigate([url], {
      queryParams: { tamMainId: tamMainId},
      relativeTo: this.activatedRoute,
    });
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      tamTypeId: [null],
      tamOccupationId: [null],
      isPublished: [null],
      tamApplicationId: [null],
      industrialSectorId: [null],
    });
  }

  resetForm(): void {
    this.searchForm.reset();
  }

  getTamTypeList() {
   this.tamTypes = this.trainingAndAssessmentMaterialsService.getTamTypes();

    if (this.tamTypes.length === 0) {
      this.trainingAndAssessmentMaterialsStorageService
        .readTamTypes()
        .subscribe();
    }
    this.onTamTypeSubscription$ =
      this.trainingAndAssessmentMaterialsService.onRefreshTamTypeList.subscribe(
        (res) => {
          this.tamTypes = res;
        }
      );
  }

  getOccupationList() {
    this.tamOccupations =
      this.trainingAndAssessmentMaterialsService.getTamOccupations();

    if (this.tamOccupations.length === 0) {
      this.trainingAndAssessmentMaterialsStorageService
        .readTamOccupations()
        .subscribe();
    }
    this.onTamOccupationSubscription$ =
      this.trainingAndAssessmentMaterialsService.onRefreshTamOccupationList.subscribe(
        (res) => {
          this.tamOccupations = res;
        }
      );
  }

  getIndustrialSectorList() {
    this.tamIndustrialSectors =
      this.trainingAndAssessmentMaterialsService.getTamIndustrialSectors();

    if (this.tamIndustrialSectors.length === 0) {
      this.trainingAndAssessmentMaterialsStorageService
        .readTamIndustrialSectors()
        .subscribe();
    }
    this.onTamIndustrialSectorSubscription$ =
      this.trainingAndAssessmentMaterialsService.onRefreshTamIndustrialSectorList.subscribe(
        (res) => {
          this.tamIndustrialSectors = res;
        }
      );
  }

  getTamMainSearchResult(tamMainSearch: TamMainSearch) {
    // if(this.tamTypeDecodedId){
    //   tamMainSearch.tamTypeId = this.tamTypeDecodedId;
    // }
    this.trainingAndAssessmentMaterialsStorageService
      .searchTamMain(tamMainSearch)
      .subscribe(
        (res: ServerResponse) => {
          if (res.success) {
            this.tamMainSearchResultDto = res.data;
            this.total = res.count;
            if (
              this.userInfo?.roles &&
              this.userInfo?.roles[0].name === 'User'
            ) {
              this.tamMainSearchResultDto = this.tamMainSearchResultDto.filter(
                (tamMain) => tamMain.isPublished === true
              );
            }
          } else {
            this.tamMainSearchResultDto = [];
            this.total = 0;
          }
        },
        (error) => {
          this.notification.warning(
          'Warning !',
          'No data available !'
        );
          this.tamMainSearchResultDto = [];
          this.total = 0;
        }
      );
  }

  onPageIndexChange(pageIndex: number) {
    this.currentPage = pageIndex;
    this.tamMainSearch.pageNumber = this.currentPage - 1;

    this.getTamMainSearchResult(this.tamMainSearch);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.currentPage= params.pageIndex;
    this.size = params.pageSize;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });
    this.submitSearchForm();
  }

  submitSearchForm() {
    this.tamMainSearch = this.searchForm.value;
    this.tamMainSearch.pageSize = this.pageSize;
    this.tamMainSearch.pageNumber = this.currentPage - 1;
    this.tamMainSearch.sortingKey = this.sortingKey;
    this.tamMainSearch.sortingValue = this.sortingValue;
    this.getTamMainSearchResult(this.tamMainSearch);
  }

  OnDestroy(): void {
    this.onTamMainSubscription$.unsubscribe();
  }


  onDownloadReport() {
    this.trainingAndAssessmentMaterialsStorageService
     .onDownloadTamMainReport(this.tamMainSearch)
      .subscribe(
        (res) => {
            this.reportData = res.data;
            let reportTitle = 'TAM List';
            if (this.reportData === null || this.reportData?.length === 0) {
              // this.toastr.error('No data available to download');
              this.notification.warning(
                'Warning!',
                'No data available to download'
              );
            } else {
              const dataList = this.getFormatDataForExcel(this.reportData);
              this.excelExport.exportExcel(dataList, reportTitle);
            }
          },
          (error) => {
            this.notification.error('Error!', 'Excel Sheet Data fetch Failed');
          }
      );
  }

  getFormatDataForExcel(dataReport: any) {
    let i = 1;
    const dataList = [];
    for (const data of dataReport) {
      const reportMap = new Map();
      reportMap.set('#SL', i);
      reportMap.set('ID', data.applicationId);
      reportMap.set('Date Created', data.createdDate);
      reportMap.set('Type', data.tamTypeName);
      reportMap.set('Industrial Sector', data.tamIndustrialSectorName);
      reportMap.set('Occupation', data.tamOccupationName);
      reportMap.set('DocFilePath', data.docFilePath);
      reportMap.set('Level', data.tamLevelId);
      reportMap.set('Duration', data.duration);
      reportMap.set('Language', data.tamLanguageName);
      reportMap.set('Version', data.version);
      reportMap.set('Approval Date', this.datepipe.transform(data.approvalDate, 'MM-dd-yyyy'));
      reportMap.set('Source', data.docSource);
      reportMap.set('Received Date', this.datepipe.transform(data.receivedDate, 'MM-dd-yyyy'));
      reportMap.set('Approval Status',data.isPublished ? 'Public' : 'Limited');
      if(data?.tamCsOnDownloadDtoList){
        for(let i =0;i<data.tamCsOnDownloadDtoList.length;i++){
          reportMap.set('Title - '+(i+1), data.tamCsOnDownloadDtoList[i]?.title ?? null);
          reportMap.set('Uoc Code - '+(i+1), data.tamCsOnDownloadDtoList[i]?.unitCode ?? null);
          reportMap.set('Duration - '+(i+1), data.tamCsOnDownloadDtoList[i]?.duration ?? null);
        }
      }else{
        reportMap.set('Title - '+(i+1), null);
        reportMap.set('Uoc Code - '+(i+1), null);
        reportMap.set('Duration - '+(i+1), null);
      }

      const jsonObject: any = {};
      reportMap.forEach((value, key) => {
        jsonObject[key] = value;
      });

      dataList.push(jsonObject);
      i++;
    }

    return dataList;
  }
}
