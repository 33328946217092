
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { District, Upazila } from 'src/app/shared/models/common.model';
import { Division } from 'src/app/shared/models/common.model';
import { FinancialDetails } from '../models/FinancialDetails.model';
import { Furniture } from '../models/furniture.model';
import { Payment } from '../models/payment.model';
import { StpManagement } from '../models/stp-management.model';
import { StpOccupationTool } from '../models/stp-occupation-tool.model';
import { StpTrainer } from '../models/stp-trainer.model';
import { STPAppStatus } from '../models/stp_app_status.model';
import { STPAppType } from '../models/stp_app_type.model';
import { STPInfrastructure } from '../models/stp_infrastructure.model';
import { STPLocation } from '../models/stp_location.model';
import { STPMain } from '../models/stp_main.model';
import { STPOwnershipType } from '../models/stp_ownership_type.model';
import { STPOwnerType } from './../models/stp_owner_type.model';
import { STPType } from './../models/stp_type.model';
import { SYSCountry } from './../models/sys_country.model';
import { StpRegistrationService } from './stp-registration.service';
import { RequestData } from '../models/stp_onlinePayment.model';
import { STpNameUpdateRequest } from '../DTO/stp-name-update-request.model';
import { Router } from '@angular/router';
import { StpFilteringRequestData } from '../models/stp_filteringRequest.model';
import { StpMainSearchingDto } from '../DTO/stp_main_searching_dto.model';
import { TempApproveRejectStpUserDto } from '../DTO/temp-approve-reject-stp-user-dto';
import { StpOrganizationAddressUpdateRequest } from '../DTO/stp-organization-address-update-request.model';
import { StpMain } from '../../course-accreditation/models/stp-main.model';
import { Ministry } from '../models/ministry.model';
import { Agency } from '../models/agency.model';
import { InspectorOfficer1 } from '../../course-accreditation/models/inspector-officer-1.model';
import { InspectionOfficer } from '../../course-accreditation/models/inspectionOfficer';

@Injectable({
  providedIn: 'root',
})
export class StpRegistrationStorageService {


  constructor(
    private httpClient: HttpClient,
    private stpRegistrationService: StpRegistrationService,
    private httpErrorHandler: HttpErrorHandler,
    private router: Router
  ) { }

  // #region MANAGEMENT_AND_SUPPORT_STAFF
  readStpManagement(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.management.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setStpManagements(response.data);
            return response.data;
          }
        })
      );
  }
  //#region Nobin

  readStpType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.readStpType}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((stpType: STPType[]) => {
          this.stpRegistrationService.setSTPTypes(stpType);
        })
      );
  }

  readStpOwnerType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.readStpOwnerType}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((stpOwnerType: STPOwnerType[]) => {
          this.stpRegistrationService.setSTPOwnerTypes(stpOwnerType);
        })
      );
  }

  deleteStpManagement(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.management.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpManagement) => {
          this.stpRegistrationService.deleteStpManagement(id);
        })
      );
  }

  readStpPosition(): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(`${applicationUrls}`).pipe(
      tap((response) => {
        if (response.data) {
          this.stpRegistrationService.setStpManagementPositions(response.data);
        }
      })
    );
  }
  // #region Occupation specific tools region
  readStpOwnerShipType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpOwnerShipType}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((stpOwnerType: STPOwnershipType[]) => {
          this.stpRegistrationService.setSTPOwnerShipTypes(stpOwnerType);
        })
      );
  }

  readStpMinistriesByOwnerShipId(ownershipId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpMinistryByOwnershipId}` +
        ownershipId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readStpAgencyByMinistryId(ministrypId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpAgencyByMinistryId}` +
        `ministry/${ministrypId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readStpLocations(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.readStpLocation}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((stpLocation: STPLocation[]) => {
          this.stpRegistrationService.setSTPLocations(stpLocation);
        })
      );
  }

  readStpCountries(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.readStpCountry}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((ghat: SYSCountry[]) => {
          this.stpRegistrationService.setSTPCountries(ghat);
        })
      );
  }


  readStpDivisionsBYCountryID(countryId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpDivision}${countryId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((division: Division[]) => {
          this.stpRegistrationService.setSTPDivisions(division);
        })
      );
  }



  readStpDistricts(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpDistrict}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }



  readStpDistrictsByDivisionId(divisionId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpDistrictByDivisionId}` +
        `districts/${divisionId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readStpUpazillasByDistrictId(districtId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpUpazilla}district/${districtId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readStpPostOfficesByUpazilaId(upazilaId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpPostOfficeByUpazila}upazila/${upazilaId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  readStpUserName(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.stpUserName}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readStpAppStatuses(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.readStpAppStatus}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((stpAppStatus: STPAppStatus[]) => {
          this.stpRegistrationService.setSTPAppStatuses(stpAppStatus);
        })
      );
  }

  readStpAppTypes(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.basicProfile.readStpAppTypes}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((stpAppType: STPAppType[]) => {
          this.stpRegistrationService.setSTPAppTypes(stpAppType);
        })
      );
  }

  createBasicProfile(stpMain: STPMain): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.basicProfile.createBasicProfile}`,
        stpMain
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.stpRegistrationService.addBasicProfile(res.data);
          }
        })
      );
  }

  updateBasicProfile(stpMain: STPMain): Observable<any> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.basicProfile.updateBasicProfile}`,
        stpMain
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.stpRegistrationService.updateBasicProfile(res.data);
          }
        })
      );
  }

  //#endregion nobin

  //#region OCCUPATION_SPECIFIC_TRAINER Parvez

  readStpTrainer(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.stpTrainer.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setStpTrainers(response.data);
          }
        })
      );
  }

  readStpTrainerById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.stpTrainer.readById}` + id
    );
  }

  createStpTrainer(stpTrainer: StpTrainer): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.stpTrainer.create}`, stpTrainer)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.addStpTrainer(response.data);
          }
        })
      );
  }

  deleteStpTrainer(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.stpTrainer.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpTrainer) => {
          this.stpRegistrationService.deleteStpTrainer(id);
        })
      );
  }

  updateStpTrainer(
    stpTrainer: StpTrainer,
    id: number | null
  ): Observable<ServerResponse> {
    // console.log('updating stp Trainer', stpTrainer, id);
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.stpTrainer.update}` + id,
        stpTrainer
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            // console.log('STP Trainer', response.data);
            this.stpRegistrationService.upatedStpTrainer(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  // readStpTrainers(): Observable<StpTrainer | StpTrainer[]> {
  //   return this.httpClient
  //     .get<ServerResponse>(`${applicationUrls.stpTrainer.read}`)
  //     .pipe(
  //       map((serverResponse: ServerResponse) => {
  //         if (!serverResponse.data) {
  //           return [];
  //         }
  //         return serverResponse.data.sort((a: StpTrainer, b: StpTrainer) =>
  //           a.stpTrainerOccupation.occupationName <
  //           b.stpTrainerOccupation.occupationName
  //             ? -1
  //             : 1
  //         );
  //       }),
  //       tap((stpTrainers: StpTrainer[]) => {
  //         this.stpRegistrationService.setStpTrainers(stpTrainers);
  //       })
  //     );
  // }

  // readStpOccupation(): Observable<ServerResponse> {
  //   return this.httpClient
  //     .get<ServerResponse>(`${applicationUrls.stpTrainer.readOccupation}`)
  //     .pipe(
  //       tap((response) => {
  //         if (response.data) {
  //           this.stpRegistrationService.setStpTrainerOccupations(response.data);
  //         }
  //       })
  //     );
  // }
  // #endregion Occupation specific tools region

  // readTrainerOccupationList(): Observable<any[]> {
  //   return this.httpClient
  //     .get<ServerResponse>(
  //       `${applicationUrls.furniture.readTrainerOccupations}`
  //     )
  //     .pipe(
  //       map((serverResponse: ServerResponse) => {
  //         return serverResponse.data;
  //       }),
  //       tap((trainerOccupation: any[]) => {
  //         this.stpRegistrationService.setTrainerOccupations(trainerOccupation);
  //       })
  //     );
  // }

  //#endregion OCCUPATION_SPECIFIC_TRAINER

  //#region Irfan

  readFurnitureById(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.furniture.readFurnitureById + id}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((furniture: any) => { })
      );
  }

  readStpFurniture(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.furniture.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setFurniture(response.data);
          }
        })
      );
  }

  createFurniture(furniture: Furniture): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.furniture.create}`, furniture)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.addFurniture(response.data);
          }
        })
      );
  }

  deleteFurniture(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.furniture.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedStpTrainer) => {
          this.stpRegistrationService.deleteFurniture(id);
        })
      );
  }

  updateFurniture(
    furniture: Furniture,
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.furniture.update}` + id,
        furniture
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.upatedFurniture(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  //#endregion Irfan

  // #region Tareque

  createStpInfrastructure(physicalInfrastructureRequest: any): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.infrastructure.create}`,
        physicalInfrastructureRequest
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.stpRegistrationService.addInfrastructure(res.data);
          }
        })
      );
  }

  updateStpInfrastructure(physicalInfrastructureRequest: any): Observable<any> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.infrastructure.update}`,
        physicalInfrastructureRequest
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.stpRegistrationService.updateInfrastructure(res.data);
          }
        })
      );
  }

  readOwnershipStatusList(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.infrastructure.readOwnershipStatus}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((ownershipStatus: any[]) => {
          this.stpRegistrationService.setOwnershipStatus(ownershipStatus);
        })
      );
  }

  readConstructionTypeList(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.infrastructure.readConstructionType}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((constructionType: any[]) => {
          this.stpRegistrationService.setConstructionType(constructionType);
        })
      );
  }
  // #endregion Tareque

  //#region Emon
  // createPayment(payment: Payment): Observable<ServerResponse> {
  //   return this.httpClient
  //     .post<ServerResponse>(`${applicationUrls.payment.create}`, payment)
  //     .pipe(
  //       map((serverResponse: ServerResponse) => {
  //         return serverResponse;
  //       })
  //     );
  // }

  //#endregion Emon

  //#region Financial Details

  createFinancialDetails(
    financialDetails: FinancialDetails
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        applicationUrls.financialDetails.create,
        financialDetails
      )
      .pipe(
        tap((response) => {
          if (response.data) {
          }
        })
      );
  }

  updateFinancialDetails(financialDetails: FinancialDetails): Observable<any> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.financialDetails.update}`,
        financialDetails
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.stpRegistrationService.updateFinancialDetails(res.data);
          }
        })
      );
  }

  //#endregion Financial Details

  //#region Management & Staff
  updateStpManagement(
    stpManagement: StpManagement,
    id: number | null
  ): Observable<ServerResponse> {
    // console.log('updating stp management', stpManagement, id);
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.management.update}` + id,
        stpManagement
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            // console.log('STP MANAGEMENT', response.data);
            this.stpRegistrationService.updatedStpManagement(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  createStpManagement(
    stpManagement: StpManagement
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.management.create}`,
        stpManagement
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.addStpManagement(response.data);
          }
        })
      );
  }

  //#endregion Management & Staff

  // #region Occupation specific tools region

  readStpOccupationTool(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tools.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setStpOccupationTools(response.data);
          }
        })
      );
  }

  // readStpOccupationList(): Observable<ServerResponse> {
  //   return (
  //     this.httpClient
  //       .get<ServerResponse>(`${applicationUrls.stpTrainer.readOccupation}`)
  //       // need to change the Url
  //       .pipe(
  //         tap((response) => {
  //           return response.data;
  //         })
  //       )
  //   );
  // }

  readStpOccupationToolsById(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tools.readById + id}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((furniture: any) => { })
      );
  }
  updateStpOccupationTool(
    stpOccupationTool: StpOccupationTool,
    id: number | null
  ): Observable<ServerResponse> {
    // console.log('updating stp management', stpOccupationTool, id);
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.tools.update}` + id,
        stpOccupationTool
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            // console.log('STP MANAGEMENT', response.data);
            this.stpRegistrationService.updatedStpOccupationTool(
              response.data,
              response.data.id
            );
          }
        })
      );
  }

  createStpOccupationTool(
    stpOccupationTool: StpOccupationTool
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.tools.create}`,
        stpOccupationTool
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.addStpOccupationTool(response.data);
          }
        })
      );
  }

  deleteStpOccupationTool(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.tools.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }

  // updateStpOccupationTool(
  //   stpOccupationTool: StpOccupationTool,
  //   id: number | null
  // ): Observable<ServerResponse> {
  //   console.log('Updating Occupation', stpOccupationTool);
  //   return this.httpClient
  //     .put<ServerResponse>(
  //       `${applicationUrls.tools.update}` + id,
  //       stpOccupationTool
  //     )
  //     .pipe(
  //       tap((response) => {
  //         if (response.data) {
  //           this.stpRegistrationService.updatedStpOccupationTool(
  //             response.data,
  //             response.data.id
  //           );
  //         }
  //       })
  //     );
  // }

  //#endregion Occupation specific tools region

  //#region payment

  savePaymentinfo(formData: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.payment.create}`, formData)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }

  mailingPdf(file: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.certificate.sendingMailByStpMainId}`,
        file
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }

  mailingBasicProfile(mailingDto: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.basicProfile.mailingAfterFinalSubmit}`,
        mailingDto
      );
  }

  readPaymentDetailsByStpMainId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.payment.readByStpMainId}` + id
    );
  }

  //#endregion payment by Fuad

  // readStpApplicationsList(): Observable<ServerResponse> {
  //   return (
  //     this.httpClient
  //       .get<ServerResponse>(`${applicationUrls.applications.read}`)
  //       // need to change the Url
  //       .pipe(
  //         tap((response) => {
  //           return response.data;
  //         })
  //       )
  //   );
  // }

  readStpApplicationsList(
    page: number,
    size?: number,
    stpMainSearchingDto?: StpMainSearchingDto,
    sortingKey?: string,
    sortingValue?: string
  ): Observable<ServerResponse> {

    const pageableRequestBodyDTO = {
      page: page - 1,
      size: size,
      stpApplicationId: (stpMainSearchingDto?.stpApplicationId ? stpMainSearchingDto?.stpApplicationId : null),
      stpName: (stpMainSearchingDto?.stpName ? stpMainSearchingDto?.stpName : null),
      stpOwnerShipStatusId: (stpMainSearchingDto?.stpOwnerShipStatusId ? stpMainSearchingDto?.stpOwnerShipStatusId : null),
      sysDistrictId: (stpMainSearchingDto?.sysDistrictId ? stpMainSearchingDto?.sysDistrictId : null),
      stpIsApproved: (stpMainSearchingDto?.stpIsApproved == true ? true : null),
      stpIsNotApproved: (stpMainSearchingDto?.stpIsNotApproved == true ? false : null),
      currectionNeeded: stpMainSearchingDto?.currectionNeeded,
      pendingStp : (stpMainSearchingDto?.pendingStp == true ? true: null),
      registrationNo: stpMainSearchingDto?.registrationNo,
      occupationName: (stpMainSearchingDto?.occupationName ? stpMainSearchingDto?.occupationName : null),
      occupationId: (stpMainSearchingDto?.occupationId ? stpMainSearchingDto?.occupationId : null),
      levelId: (stpMainSearchingDto?.levelId ? stpMainSearchingDto?.levelId : null),
      submissionStatusId: (stpMainSearchingDto?.submissionStatusId ? stpMainSearchingDto?.submissionStatusId : null),
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null
    };
    if (pageableRequestBodyDTO.stpIsApproved == true && pageableRequestBodyDTO.stpIsNotApproved == false) {
      pageableRequestBodyDTO.stpIsApproved = null;
      pageableRequestBodyDTO.stpIsNotApproved = null;
    }
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.applications.read}`,
      pageableRequestBodyDTO
    );
  }

  getAllRegisteredStp(
    page: number,
    size?: number,
    stpMainSearchingDto?: StpMainSearchingDto,
    sortingKey?: string,
    sortingValue?: string
  ): Observable<ServerResponse> {

    const pageableRequestBodyDTO = {
      page: page - 1,
      size: size,
      stpApplicationId: (stpMainSearchingDto?.stpApplicationId ? stpMainSearchingDto?.stpApplicationId : null),
      stpName: (stpMainSearchingDto?.stpName ? stpMainSearchingDto?.stpName : null),
      stpOwnerShipStatusId: (stpMainSearchingDto?.stpOwnerShipStatusId ? stpMainSearchingDto?.stpOwnerShipStatusId : null),
      sysDistrictId: (stpMainSearchingDto?.sysDistrictId ? stpMainSearchingDto?.sysDistrictId : null),
      occupationId: (stpMainSearchingDto?.occupationId ? stpMainSearchingDto?.occupationId : null),
      levelId: (stpMainSearchingDto?.levelId ? stpMainSearchingDto?.levelId : null),
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null
    };

    return this.httpClient.post<ServerResponse>(`${applicationUrls.applications.getAllRegisteredStp}`, pageableRequestBodyDTO);
  }

  getAllRegisteredStpWithoutPagination(
    page: number,
    size?: number,
    stpMainSearchingDto?: StpMainSearchingDto,
    sortingKey?: string,
    sortingValue?: string
  ): Observable<ServerResponse> {

    const pageableRequestBodyDTO = {
      page: page - 1,
      size: size,
      stpApplicationId: (stpMainSearchingDto?.stpApplicationId ? stpMainSearchingDto?.stpApplicationId : null),
      stpName: (stpMainSearchingDto?.stpName ? stpMainSearchingDto?.stpName : null),
      stpOwnerShipStatusId: (stpMainSearchingDto?.stpOwnerShipStatusId ? stpMainSearchingDto?.stpOwnerShipStatusId : null),
      sysDistrictId: (stpMainSearchingDto?.sysDistrictId ? stpMainSearchingDto?.sysDistrictId : null),
      occupationId: (stpMainSearchingDto?.occupationId ? stpMainSearchingDto?.occupationId : null),
      levelId: (stpMainSearchingDto?.levelId ? stpMainSearchingDto?.levelId : null),
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null
    };

    return this.httpClient.post<ServerResponse>(`${applicationUrls.applications.getAllRegisteredStpWithoutPagination}`, pageableRequestBodyDTO);
  }

  getAllStpListViewExcelInfo(stpMainSearchingDto: StpMainSearchingDto): Observable<ServerResponse> {
    const pageableRequestBodyDTO = {
      stpName: (stpMainSearchingDto?.stpName ? stpMainSearchingDto?.stpName : null),
      registrationNo: stpMainSearchingDto?.registrationNo,
      stpOwnerShipStatusId: (stpMainSearchingDto?.stpOwnerShipStatusId ? stpMainSearchingDto?.stpOwnerShipStatusId : null),
      sysDistrictId: (stpMainSearchingDto?.sysDistrictId ? stpMainSearchingDto?.sysDistrictId : null),
      occupationName: (stpMainSearchingDto?.occupationName ? stpMainSearchingDto?.occupationName : null)
    };

    return this.httpClient.post<ServerResponse>(`${applicationUrls.applications.getAllStpListViewExcelInfo}`,pageableRequestBodyDTO);
  }

  initiateRollBackRequest(stpUserId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applications.rollBackRequest + '?stpUserId=' + stpUserId
        }`
      )
      .pipe(
        map((serverResponse: any) => {
          return serverResponse;
        })
      );
  }

  getAllUserDetailsInfo(stpMainSearchingDto: StpMainSearchingDto): Observable<ServerResponse> {
    const pageableRequestBodyDTO = {
      stpApplicationId: (stpMainSearchingDto?.stpApplicationId ? stpMainSearchingDto?.stpApplicationId : null),
      stpName: (stpMainSearchingDto?.stpName ? stpMainSearchingDto?.stpName : null),
      stpIsApproved: (stpMainSearchingDto?.stpIsApproved == true ? true : null),
      stpIsNotApproved: (stpMainSearchingDto?.stpIsNotApproved == true ? false : null),
      currectionNeeded: stpMainSearchingDto?.currectionNeeded,
      pendingStp: (stpMainSearchingDto?.pendingStp == true ? true: null),
      stpOwnerShipStatusId: (stpMainSearchingDto?.stpOwnerShipStatusId ? stpMainSearchingDto?.stpOwnerShipStatusId : null),
      sysDistrictId: (stpMainSearchingDto?.sysDistrictId ? stpMainSearchingDto?.sysDistrictId : null),
      occupationName: (stpMainSearchingDto?.occupationName ? stpMainSearchingDto?.occupationName : null),
      submissionStatusId: (stpMainSearchingDto?.submissionStatusId ? stpMainSearchingDto?.submissionStatusId : null)
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.user.getAllUserDetailsInfo}`, pageableRequestBodyDTO
    );
  }

  readStpApplicationsbyId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.applications.readById}` + id
    );
  }

  readStpApplicationsforInspectionbyId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.inspection.readById}` + id
    );
  }
  readStpMainbyId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.applications.readById}` + id
    );
  }

  readStpInfraByStpMainId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.physicalInfra.readById}` + id
    );
  }

  readStpFinancialFundsByStpMainId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.financialDetails.fundsById}` + id
    );
  }

  readStpFinancialIncomeByStpMainId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.financialDetails.incomeById}` + id
    );
  }

  readStpFinancialExpenseByStpMainId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.financialDetails.expenseById}` + id
    );
  }

  readBasicProfileById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.applications.readByStpMainId}` + id
    );
  }

  //#region Unit (common portion with fuad vai)

  readSysUnitList(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.unit.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setSysUnits(response.data);
          }
        })
      );
  }
  getSysUnitById(id: number): Observable<any> {
    // console.log('get Sys Unit By id', id);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.unit.readById}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((unit: any) => { })
      );
  }

  //#endregion Unit (common portion with fuad vai)

  //#region online payment
  initiatePaymentRequest(requestData: RequestData): Observable<any> {
    // console.log("response request data id " ,requestData.id);
    return this.httpClient
      .get<any>(
        `${applicationUrls.payment.createOnlinePayment}` + requestData.id
      )
      .pipe(
        map((serverResponse: any) => {
          return serverResponse;
        })
      );
  }
  //#endregion online payment

  //#region  otp verification
  getOtp(contactNumber: string): Observable<ServerResponse> {
    // console.log("Phone -> " + contactNumber);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.otp.readContact + contactNumber}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  // sendingPdf(userId: number, certificatePdf: any): Observable<ServerResponse> {
  //   // console.log("Phone -> " + contactNumber);
  //   return this.httpClient
  //     .get<ServerResponse>(
  //       `${
  //         applicationUrls.certificate.sendingMailByStpMainId +
  //         userId +
  //         certificatePdf
  //       }`
  //     )
  //     .pipe(
  //       map((serverResponse: ServerResponse) => {
  //         return serverResponse;
  //       })
  //     );
  // }
  //#endregion

  getVerifiedOtp(
    otpNumber: string,
    contactNumber: string,
  ): Observable<ServerResponse> {
    // console.log('otp -> ' + otpNumber);
    // console.log('contact -> ' + contactNumber);
    const params = new HttpParams()
      .append('mobileNo', `${contactNumber}`)
      .append('otp', `${otpNumber}`);
    // console.log(params);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.otp.readOtp}`, {
        params,
      })
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  getVerifiedOtpToEmail(
    otpNumber: string,
    email: string,
  ): Observable<ServerResponse> {
    // console.log('otp -> ' + otpNumber);
    // console.log('contact -> ' + contactNumber);
    const params = new HttpParams()
      .append('email', `${email}`)
      .append('otp', `${otpNumber}`);
    // console.log(params);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.otp.readOtpToEmail}`, {
        params,
      })
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  //#endregion otp verification

  //#region  get all user details info


  getUserApplicationStatusInfo(): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.user.getUserApplicationStatusInfo}`
    );
  }

  //#endregion get user information by id

  getlUserInfoById(userId: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.user.getUserById}?userId=` + userId
    );
  }

  //#region user info by id
  stpMainIdByUserId(requestData: RequestData): Observable<any> {
    // console.log("response request data id " ,requestData.id);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.stpFullInformation.userFullInformation}` +
        requestData.id
      )
      .pipe(
        map((serverResponse: any) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region Update STP Main
  updateStpMain(
    stpNameUpdate: STpNameUpdateRequest
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.applications.stpNameUpdate}`,
      stpNameUpdate
    );
  }

  updateStpOrganizationDetails(
    stpNameUpdate: StpOrganizationAddressUpdateRequest
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.applications.updateStpOrganizationDetails}`,
      stpNameUpdate
    );
  }

  getStpOrganizationDetails(
    stpMainId: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.applications.getStpOrganizationDetails}?stpMainId=` + stpMainId
    );
  }

  updateStpApproval(userId: number, approvalDateFromAdmin: any): Observable<ServerResponse> {
    const params = new HttpParams()
    .append('userId', `${userId}`)
    .append('approvalDateFromAdmin', `${approvalDateFromAdmin.toString()}`);
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.applications.stpApprovalUpdate}`,
      params
    );
  }
  //#endregion Update STP Main

  //#region  Get Certificate Information By StpMain Id

  getCertificateInformationByStpMainId(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.certificate.readByStpMainDecreptionId + '?id=' + id}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          // console.log(serverResponse.data);
          return serverResponse;
        })
      );
  }

  // getCertificateInformation(id: number): Observable<any> {
  //   return this.httpClient
  //     .get<ServerResponse>(
  //       `${applicationUrls.certificate.readByStpMainId + '/' + id}`
  //     )
  //     .pipe(
  //       map((serverResponse: ServerResponse) => {
  //         // console.log(serverResponse.data);
  //         return serverResponse;
  //       })
  //     );
  // }

  getCertificateInformation(id: number): Observable<any> {
    return this.httpClient
      .get<Blob>(
        `${applicationUrls.certificate.downloadCertificateByStpMainId + '/' + id}`,
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        }
      )
  }

  getOccupationLevelDetails(tamMainId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.occupationLevel.registrationOccupationandLevelDetailsById + '/' + tamMainId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          // console.log(serverResponse.data);
          return serverResponse;
        })
      );
  }

  filteringSTPApplication(
    stpFilteringRequestData: StpFilteringRequestData
  ): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applications.filteredStpApplication}` +
        stpFilteringRequestData
      )
      .pipe(
        map((serverResponse: any) => {
          return serverResponse;
        })
      );
  }

  //#endregion Get Certificate Information By StpMain Id

  //#region STP name Unique check by Admin

  checkStpNameExist(stpName: string): Observable<ServerResponse> {
    const params = new HttpParams().append('stpName', `${stpName}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.userVerification.checkStpNameExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion STP name Unique check by Admin

  //#region Excel data save for StpOccupationTool

  saveExcelData(
    stpOccupationTool: StpOccupationTool[]
  ): Observable<ServerResponse> {
    // console.log('service tool', stpOccupationTool);
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.tools.saveExcelData}`,
        stpOccupationTool
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.addStpOccupationTool(response.data[0]);
          }
        })
      );
  }

  //#endregion Excel data save for StpOccupationTool

  //#region check is application submitted

  isApplicationSubmitted(userId: any): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.actionHistory.isApplicationSubmitted +
        '?userId=' +
        userId
        }`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion check is application submitted

  //#region check is application submitted

  applicationSubmit(userId: any): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.actionHistory.applicationSubmit + '?userId=' + userId
        }`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion check is application submitted

  //#region Excel data save for StpManagement

  saveExcelStaffData(
    stpManagement: StpManagement[]
  ): Observable<ServerResponse> {
    // console.log('service stpManagement', stpManagement);
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.management.saveExcelData}`,
        stpManagement
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.addStpManagement(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for StpManagement

  //#region Excel data save for stpTrainer

  saveExcelTrainerData(stpTrainer: StpTrainer[]): Observable<ServerResponse> {
    // console.log('service Trainer', Trainer);
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.stpTrainer.saveExcelData}`,
        stpTrainer
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setStpTrainersForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for stpTrainer

  //#region Excel data save for Furniture

  saveExcelFurnitureData(furniture: Furniture[]): Observable<ServerResponse> {
    // console.log('furniture', furniture);
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.furniture.saveExcelData}`,
        furniture
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.stpRegistrationService.setFurnitureForExcel(response.data);
          }
        })
      );
  }

  //#endregion Excel data save for Furniture


  //#region temporary reject stp user
  temporaryRejectStpUser(approveRejectStpUserDto: TempApproveRejectStpUserDto): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.applications.approveRejectStpUser}`,
      approveRejectStpUserDto
    );
  }
  //#endregion temporary reject stp user


  //#region read stp main info
  readStpMains(): Observable<StpMain[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.applications.readStpMain}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  //#endregion

  readStpBasicProfilebyId(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.basicProfile.readBasicProfileById}` + id
    );
  }


  addMinistry(ministry: Ministry) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.ministry.saveMinistry}`,
        ministry
      )
  }

  getAgencyByMinistryId(id: number) {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.ministry.getMinistryById}/`+ id
      )
  }

  getMinistryList(ministry: Ministry) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.ministry.getMinistryList}`,
        ministry
      )
  }

  getIscOfficerList(inspectorOfficer1: InspectorOfficer1) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.basicProfile.readInspectionOfficers}`,
        inspectorOfficer1
      )
  }

  getAgencyList(agency: Agency) {
    agency.pageNumber -=1;
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.agency.getAgencyList}`,
        agency
      )
  }

  getAllStpMinistries() {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpMinistry}`
      )
  }

  addAgency(agency: Agency) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.agency.saveAgency}`,
        agency
      )
  }

  getAgencyById(id : number) {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.agency.getAgencyById}/`+id
      )
  }

  deleteAgency(id: number | undefined) {
    return this.httpClient
    .delete<ServerResponse>(
      `${applicationUrls.agency.deleteAgency}/`+id
    )
  }

  deleteMinistry(id: number | undefined) {
    return this.httpClient
    .delete<ServerResponse>(
      `${applicationUrls.ministry.deleteMinistry}/`+id
    )
  }


  //#region read approved stp register count
  readApprovedStpRegisterCount(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.landingPageCounter.readApprovedStpRegisterCount}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region read approved stp courses count
  readApprovedStpCoursesCount(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.landingPageCounter.readApprovedStpCoursesCount}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion
  //#region read approved stp center count
  readApprovedStpCenterCount(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.landingPageCounter.readApprovedStpCenterCount}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }
  //#endregion

  getInspectionOfficerById(id : number) {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.inspection.inspectionOfficer.getInspectionOfficerById}/` + id)
  }

  saveInspectionOfficer(inspectionOfficer: InspectionOfficer) {
    return this.httpClient.post<ServerResponse>(`${applicationUrls.inspection.inspectionOfficer.saveInspectionOfficer}`, inspectionOfficer)
  }

  //#region check existing contact number to add representative
  checkContactNumberExist(id: number, phone: string): Observable<ServerResponse> {
    const params = new HttpParams().append('id', `${id}`).append('phone', `${phone}`);

    return this.httpClient.get<ServerResponse>(`${applicationUrls.inspection.checkInspectionOfficerByPhone}`, {params})
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion
  sendingOTPtoMail(email: string, userId:any ): Observable<any> {
    const params = new HttpParams().append('email',  `${email}` ).append('userId',  `${userId}`) ;
    return this.httpClient
      .post<ServerResponse>( 
        `${applicationUrls.otp.sendingOTPtoMail}`,
        {}, // Empty body
        { params } // Attach query parameters
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data || null; // Return null if data is missing
        })
      );
  }
}