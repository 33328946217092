import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { StatusCodes } from 'http-status-codes';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandler {
  createErrorHandler<T>(serviceName = '') {
    return (operation = 'operation', result?: T) => (error: HttpErrorResponse): Observable<T> => {
      let errorMessage = '';
      if (error.status === 0 || error.error instanceof ErrorEvent) {
        errorMessage = 'A client-side or network error occurred';
      } else {
        errorMessage = `${error.error.message}`;
      }

      console.error(`Error in Service: ${serviceName}, Operation: ${operation} -->: `, error);

      if (error.status === StatusCodes.UNAUTHORIZED) {
        // Handle specific errors or add custom handling here
        return throwError('Unauthorized access');
      } else {
        // Default to a fallback result or rethrow error
        return of(result as T);
      }
    };
  }
}
