import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PDFDocument } from 'pdf-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegistrationCard } from 'src/app/modules/applicant/models/registrationCard';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { environment } from 'src/environments/environment';
import fontkit from '@pdf-lib/fontkit';
import { DatePipe } from '@angular/common';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class PdfGenerateService {
  constructor(
    private httpClient: HttpClient,
    private notification: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  async embedFileExtensionDecide(fileExt: any, imgByte: any, pdfDoc: any) {
    fileExt = fileExt?.toLowerCase();
    if (fileExt == 'png') {
      return await pdfDoc.embedPng(imgByte);
    } else if (fileExt == 'jpg' || fileExt == 'jpeg') {
      return await pdfDoc.embedJpg(imgByte);
    } else {
      throw new Error('Unsupported image format: ' + fileExt);
    }
  }

  async getImageFormat(imageBytes: ArrayBuffer): Promise<string | null> {
    const view = new DataView(imageBytes);

    // Check for JPG or JPEG format
    if (view.getUint8(0) === 0xff && view.getUint8(1) === 0xd8) {
      return 'jpeg';
    }

    // Check for PNG format
    if (
      view.getUint32(0, false) === 0x89504e47 &&
      view.getUint32(4, false) === 0x0d0a1a0a
    ) {
      return 'png';
    }

    // Check for GIF format
    if (view.getUint32(0, false) === 0x47494638) {
      return 'gif';
    }

    // Check for BMP format
    if (view.getUint16(0, false) === 0x424d) {
      return 'bmp';
    }

    return null; // Unknown format
  }

  async freelancerRegistrationCard(data: RegistrationCard) {
    let userInfo = data;

    // const assessmentDateRange = userInfo.assessmentDate as unknown as string;
    // let assessEndDateStr: string;
    // if (assessmentDateRange.includes(' to ')) {
    //   assessEndDateStr = assessmentDateRange.split(' to ')[1].trim();
    // } else {
    //   assessEndDateStr = assessmentDateRange;
    // }
    // const parsedAssessEndDateStr = new Date(
    //   assessEndDateStr.split('-').reverse().join('-')
    // );

    const assessmentScheduleDateStr = userInfo.assessmentScheduleDate;
    const assessmentScheduleDate = new Date(assessmentScheduleDateStr);
    assessmentScheduleDate.setHours(0, 0, 0, 0);
    const comparisonDate = new Date('2024-08-08');
    comparisonDate.setHours(0, 0, 0, 0);
    const isBeforeOrEqual = assessmentScheduleDate <= comparisonDate;
    let formUrl: string;
    if (isBeforeOrEqual) {
      formUrl =
        '../../../../assets/certificate/assessment-registration-card.pdf';
    } else {
      formUrl =
        '../../../../assets/certificate/assessment-registration-card-chief-advisors-office.pdf';
    }

    // const photoExt = data?.photoUrl?.split('.').pop();
    // const signExt = data?.signatureUrl?.split('.').pop();
    const photoUrl =
      environment.tamApiUrl + 'file-download?fileUrl=' + data.photoUrl;
    const signUrl =
      environment.tamApiUrl + 'file-download?fileUrl=' + data.signatureUrl;
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const photoImageBytes = await fetch(photoUrl).then((res) =>
      res.arrayBuffer()
    );
    const signImageBytes = await fetch(signUrl).then((res) =>
      res.arrayBuffer()
    );

    const actualPhotoImageFormat = await this.getImageFormat(photoImageBytes);
    const actualSignImageFormat = await this.getImageFormat(signImageBytes);

    // Calculate the total size of selected files
    const photoImageByteSize = photoImageBytes.byteLength;
    const signImageByteSize = signImageBytes.byteLength;
    console.log(
      'Photo image byte size of selected files:',
      photoImageByteSize,
      'bytes'
    );
    console.log(
      'Signature image byte size of selected files:',
      signImageByteSize,
      'bytes'
    );

    let qrBase64;
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const photoEmbeded = await this.embedFileExtensionDecide(
      actualPhotoImageFormat,
      photoImageBytes,
      pdfDoc
    );
    const signEmbeded = await this.embedFileExtensionDecide(
      actualSignImageFormat,
      signImageBytes,
      pdfDoc
    );
    pdfDoc.registerFontkit(fontkit);
    const form = pdfDoc.getForm();
    const pages = pdfDoc.getPages();

    //QR code generate
    if (data.qrImageString && data.qrImageString != '') {
      qrBase64 = 'data:image/jpeg;base64,' + data.qrImageString;
      const embedImage = await pdfDoc.embedPng(qrBase64);
      const qrCodeField = form.getButton('qrCode');
      qrCodeField.setImage(embedImage);
    }

    const regDate =
      new DatePipe('en-US')
        .transform(userInfo?.registrationDate, 'dd-MM-yyyy')
        ?.toString() || '';
    // Get Text Field
    const registrationNo = form.getTextField('registrationNo');
    const registrationDate = form.getTextField('registrationDate');
    const nameOfOccupation = form.getTextField('nameOfOccupation');
    const nameOfApplicant = form.getTextField('nameOfApplicant');
    const nameOfMother = form.getTextField('nameOfMother');
    const nameOfFather = form.getTextField('nameOfFather');
    const nidNumber = form.getTextField('nidNumber');
    const assessmentVenu = form.getTextField('assessmentVenu');
    const assessmentDate = form.getTextField('assessmentDate');
    const downloadDate = form.getTextField('downloadDate');
    let occupation: string =
      userInfo?.occupationName + ' - Level ' + userInfo?.occupationLevel;
    // set TextField
    registrationNo.setText(
      userInfo?.registrationNo ? userInfo?.registrationNo : 'N/A '
    );

    registrationDate.setText(regDate);
    nameOfOccupation.setText(occupation);
    nameOfApplicant.setText(
      userInfo?.nameOfApplicant ? userInfo?.nameOfApplicant : 'N/A'
    );
    nameOfMother.setText(userInfo?.motherName ? userInfo?.motherName : 'N/A');
    nameOfFather.setText(userInfo?.fatherName ? userInfo?.fatherName : 'N/A');
    nidNumber.setText(
      userInfo?.nid ? userInfo?.nid : userInfo?.birthRegistration
    );
    downloadDate.setText(
      new DatePipe('en-US')
        .transform(Date.now().toString(), 'dd-MM-yyyy')
        ?.toString()
    );
    assessmentVenu.setText(userInfo?.venu ? userInfo?.venu : 'N/A');
    assessmentDate.setText(userInfo?.assessmentDate.toString());

    pages[0].drawImage(photoEmbeded, {
      x: 428,
      y: 480,
      width: 93,
      height: 105,
    });

    pages[0].drawImage(signEmbeded, {
      x: 100,
      y: 390,
      width: 40,
      height: 20,
    });
    form.flatten();

    const pdfBytes = await pdfDoc.save();
    const download = new Blob([pdfBytes], { type: 'application/pdf' });
    let name = userInfo?.nameOfApplicant + ' registrationCard.pdf';
    FileSaver.saveAs(download, name);
  }

  async freelancerRegistrationCardForRplIndividuals(data: RegistrationCard) {
    let userInfo = data;

    const registrationDateStr = userInfo.registrationDate;
    const registrationDateRplIndividual = new Date(registrationDateStr);
    registrationDateRplIndividual.setHours(0, 0, 0, 0);
    const comparisonDate = new Date('2024-08-08');
    comparisonDate.setHours(0, 0, 0, 0);
    const isBeforeOrEqual = registrationDateRplIndividual <= comparisonDate;

    let formUrl: string;
    if (isBeforeOrEqual) {
      formUrl =
        '../../../../assets/certificate/assessment-registration-card-rpl-individuals.pdf';
    } else {
      formUrl =
        '../../../../assets/certificate/assessment-registration-card-rpl-individuals-chief-advisors-office.pdf';
    }

    // const photoExt = data?.photoUrl?.split('.').pop();
    // const signExt = data?.signatureUrl?.split('.').pop();
    const photoUrl =
      environment.tamApiUrl + 'file-download?fileUrl=' + data.photoUrl;
    const signUrl =
      environment.tamApiUrl + 'file-download?fileUrl=' + data.signatureUrl;
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const photoImageBytes = await fetch(photoUrl).then((res) =>
      res.arrayBuffer()
    );
    const signImageBytes = await fetch(signUrl).then((res) =>
      res.arrayBuffer()
    );

    const actualPhotoImageFormat = await this.getImageFormat(photoImageBytes);
    const actualSignImageFormat = await this.getImageFormat(signImageBytes);

    // Calculate the total size of selected files
    const photoImageByteSize = photoImageBytes.byteLength;
    const signImageByteSize = signImageBytes.byteLength;
    console.log(
      'Photo image byte size of selected files:',
      photoImageByteSize,
      'bytes'
    );
    console.log(
      'Signature image byte size of selected files:',
      signImageByteSize,
      'bytes'
    );

    let qrBase64;
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const photoEmbeded = await this.embedFileExtensionDecide(
      actualPhotoImageFormat,
      photoImageBytes,
      pdfDoc
    );
    const signEmbeded = await this.embedFileExtensionDecide(
      actualSignImageFormat,
      signImageBytes,
      pdfDoc
    );
    pdfDoc.registerFontkit(fontkit);
    const form = pdfDoc.getForm();
    const pages = pdfDoc.getPages();

    //QR code generate
    if (data.qrImageString && data.qrImageString != '') {
      qrBase64 = 'data:image/jpeg;base64,' + data.qrImageString;
      const embedImage = await pdfDoc.embedPng(qrBase64);
      const qrCodeField = form.getButton('qrCode');
      qrCodeField.setImage(embedImage);
    }

    const regDate =
      new DatePipe('en-US')
        .transform(userInfo?.registrationDate, 'dd-MM-yyyy')
        ?.toString() || '';
    // Get Text Field
    const registrationNo = form.getTextField('registrationNo');
    const registrationDate = form.getTextField('registrationDate');
    const nameOfOccupation = form.getTextField('nameOfOccupation');
    const nameOfApplicant = form.getTextField('nameOfApplicant');
    const nameOfMother = form.getTextField('nameOfMother');
    const nameOfFather = form.getTextField('nameOfFather');
    const nidNumber = form.getTextField('nidNumber');
    const assessmentVenu = form.getTextField('assessmentVenu');
    const downloadDate = form.getTextField('downloadDate');
    let occupation: string =
      userInfo?.occupationName + ' - Level ' + userInfo?.occupationLevel;
    // set TextField
    registrationNo.setText(
      userInfo?.registrationNo ? userInfo?.registrationNo : 'N/A '
    );

    registrationDate.setText(regDate);
    nameOfOccupation.setText(occupation);
    nameOfApplicant.setText(
      userInfo?.nameOfApplicant ? userInfo?.nameOfApplicant : 'N/A'
    );
    nameOfMother.setText(userInfo?.motherName ? userInfo?.motherName : 'N/A');
    nameOfFather.setText(userInfo?.fatherName ? userInfo?.fatherName : 'N/A');
    nidNumber.setText(
      userInfo?.nid ? userInfo?.nid : userInfo?.birthRegistration
    );
    downloadDate.setText(
      new DatePipe('en-US')
        .transform(Date.now().toString(), 'dd-MM-yyyy')
        ?.toString()
    );
    assessmentVenu.setText(userInfo?.venu ? userInfo?.venu : 'N/A');

    pages[0].drawImage(photoEmbeded, {
      x: 428,
      y: 480,
      width: 93,
      height: 105,
    });

    pages[0].drawImage(signEmbeded, {
      x: 100,
      y: 425,
      width: 40,
      height: 20,
    });
    form.flatten();

    const pdfBytes = await pdfDoc.save();
    const download = new Blob([pdfBytes], { type: 'application/pdf' });
    let name = userInfo?.nameOfApplicant + ' registrationCard.pdf';
    FileSaver.saveAs(download, name);
  }
}
