import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import saveAs from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import { TamMain } from '../../models/tam-main.model';
import { TrainingAndAssessmentMaterialsStorageService } from '../../services/training-and-assessment-materials-storage.service';

@Component({
  selector: 'app-tam-view',
  templateUrl: './tam-view.component.html',
  styleUrls: ['./tam-view.component.scss'],
})
export class TamViewComponent implements OnInit {
  [x: string]: any;
  tamMain: TamMain;
  tamMainId: number | null;
  csShow: boolean | false;
  cadShow: boolean | false;
  file: any = File;
  userInfo: any;


  constructor(
    private trainingAndAssessmentMaterialsStorageService: TrainingAndAssessmentMaterialsStorageService,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private commonStorageService: CommonStorageService
  ) {
    // this.tamMainId = this.route.snapshot.params.id;

    this.tamMainId = Number(this.route.snapshot.queryParamMap.get('tamMainId'));

    if (this.tamMainId) {
      this.trainingAndAssessmentMaterialsStorageService
        .readTamMainById(Number(this.tamMainId))
        .subscribe((res) => {
          this.tamMain = res.data;
          this.additionalInformation(this.tamMain);
        });
    }
  }

  ngOnInit(): void {this.userId()}

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    // this.requestData.id = this.userInfo.id;
  }

  //docFilePath: Object;

  additionalInformation(tamMain: TamMain): void {
    if (tamMain.tamType.shortName.toUpperCase() === 'CS') {
      this.csShow = true;
    } else if (tamMain.tamType.shortName.toUpperCase() === 'CAD') {
      this.cadShow = true;
    }
  }

  onDownload(data: any): void {
    this.commonStorageService.downloadFile(data.docFilePath).subscribe({
      next: (result: any) => {
        var fileName = data.tamApplicationId;
        const contentDisposition = result.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            var totalFileName = matches[1].replace(/['"]/g, '');
            var fullFile = totalFileName.split('.');
            var exten = fullFile[1];
            fileName = fileName + '.' + exten;
          }
        }
        saveAs(result.body, fileName);
      },
      error: (error: any) => this.notification.error('Failed!', 'No file found!'),
    });
  }
}
