import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { AuthorizationService } from 'src/app/modules/authorization/services/authorization.service';

@Component({
  selector: 'app-NAP-default-layout',
  templateUrl: './NAP-default-layout.component.html',
  styleUrls: ['./NAP-default-layout.component.scss']
})
export class NAPDefaultLayoutComponent implements OnInit {

  userInfo: any;
  isCollapsed = false;

  constructor(
    public authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userId();
  }

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('NAPUserInfo') || '{}');
  }

  onNAPLogout(): void {
    this.authenticationService.NAPLogout();
  }

  onChangePassword(): void {
    this.router.navigate(['/NAP-home/authentication/nap-change-password']);
  }

  //Online Payment Part redirect to ek pay.
  constructRequestData() {
    this.userInfo = JSON.parse(localStorage.getItem('NAPUserInfo') || '{}');
  }

  // userProfile(): void {
  //   this.router.navigate(['home/setting/my-profile'])
  // }
}
