import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import FileSaver from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { StpApplicationDto } from '../../DTO/stp_application_dto.model';
import { StpApplications } from '../../models/stp-applications.model';
import { RequestData } from '../../models/stp_onlinePayment.model';
import { STPOwnershipType } from '../../models/stp_ownership_type.model';
import { StpRegistrationStorageService } from '../../services/stp-registration-storage.service';
import { StpRegistrationService } from '../../services/stp-registration.service';
import { differenceInCalendarDays, parse  } from 'date-fns';

@Component({
  selector: 'app-stp-applications',
  templateUrl: './stp-applications.component.html',
  styleUrls: ['./stp-applications.component.scss'],
})
export class StpApplicationsComponent implements OnInit {
  applicationPermissions = applicationPermissions;
  stpApplicationDto: StpApplicationDto[] = [];
  searchingForm: FormGroup = this.fb.group({
    stpApplicationId: [null],
    stpIsApproved: [null],
    stpIsNotApproved: [null],
    currectionNeeded: [null],
    stpName: [null],
    stpOwnerShipStatusId: [null],
    sysDistrictId: [null],
    occupationName: [null],
    submissionStatusId: [null],
    pendingStp: [null],
    registrationNo: [null]
  });
  reportData = [];
  reportTitle = 'STP Application List';
  modalOkText = 'Procced';
  stpApplication: StpApplications;
  stpOwnershipTypeList: STPOwnershipType[] = [];
  districtList: any[];
  pdfBlob: any;
  approvalForm: FormGroup = this.fb.group({
    approvalDateFromAdmin: [null]});

  requestData: RequestData = new RequestData();
  userInfo: any;
  hiddingHeaderFooter: boolean = true;
  // for pagination
  total: number;
  size: number = 10;
  page: number = 1;
  sortingKey: string;
  sortingValue: string;
  value: any = null;
  rollbackButtonDisabled: boolean = false;

  applicationSubmissionStatusList: { id: number; name: string }[] = [
    { id: 2, name: 'Ongoing' },
    { id: 3, name: 'Completed' }
  ];

  applicationReviewList = [
    {code: 'pending', name: 'Pending'},
    {code: 'correction', name: 'Correction Requested'},
    {code: 'accepted', name: 'Accepted'},
  ]

  approvalList = [
    {code: 'approved', name: 'Approved'},
    {code: 'not_approved', name: 'Not-Approved'},
  ]

  contentVisble = false;
  isStpBasicInfoModalVisible = false;
  stpBasicData: any;
  selectedReviewStatus: any;
  selectedApprovalStatus: any;

  isVisible = false;
  currentStpApplication: StpApplicationDto;
  selectedDate: Date | null = null;
  today = new Date();

  constructor(
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private stpRegistrationService: StpRegistrationService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private excelExport: ExcelDownloadService
  ) {
    this.userId();
    (window.location.href.includes("home")) ? this.hiddingHeaderFooter = true : (!(this.userInfo?.roles == null)) ? this.hiddingHeaderFooter = false : this.hiddingHeaderFooter = false ;
  }

  ngOnInit(): void {
    this.getOwnerShipTypeList();
    this.getStpDistrictsList();

    if(this.hiddingHeaderFooter){
      this.loadstpApplicationsFromServer();
    }else{
      this.searchingForm.controls.stpIsApproved.setValue(true);
      this.loadstpApplicationsFromServer();
    }
  }

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo.id;
    if(this.userInfo.id)
      this.contentVisble = true;
  }

  loadstpApplicationsFromServer(): void {
    this.rollbackButtonDisabled = false;
    this.stpRegistrationStorageService
      .readStpApplicationsList(
        this.page,
        this.size,
        this.searchingForm.value,
        this.sortingKey,
        this.sortingValue
      )
      .subscribe({
        next: (res) => {
          this.stpApplicationDto = res.data;
          this.total = res.count;
        },
      });
  }

  onDownloadReport() {
    this.stpRegistrationStorageService
      .getAllUserDetailsInfo(this.searchingForm.value)
      .subscribe(
        (res) => {
          this.reportData = res.data;

          if (this.reportData === null || this.reportData?.length === 0) {
            this.notification.warning(
              'Warning!',
              'No data available to download'
            );
          } else {
            const dataList = this.getFormatDataForExcel(this.reportData);
            this.excelExport.exportExcelV2(dataList, this.reportTitle);
          }
        },
        (error) => {
          this.notification.error('Error!', 'Excel Sheet Data fetch Failed');
        }
      );
  }

  onRollBack(stpApplicationDto: StpApplicationDto) {
    const stpUserId = stpApplicationDto.stpUserId;
    this.stpRegistrationStorageService
      .initiateRollBackRequest(stpUserId)
      .subscribe(
        (res) => {
          this.notification.success('Success!', res.message);
          this.rollbackButtonDisabled = true;
          stpApplicationDto.applicationStatus = "On Going";
        },
        (error) => {
          this.notification.error('Error!', error.message);
          this.rollbackButtonDisabled = false;
        }
      );
  }

  downloadCertificate(stpId: number): void {
    this.stpRegistrationStorageService
      .getCertificateInformation(stpId)
      .subscribe((data: any) =>{
        var fileName = 'report.pdf';
        const contentDisposition = data.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          FileSaver(data.body, fileName);
          {
            this.notification.success('Success!', "STP Certificate download completed");
          }
        }
      },
      (error) => {
        this.notification.error('Error!', "STP Certificate could not be downloaded");
      });
  }

  mailCertificate(stpId: any): void {
    this.stpRegistrationStorageService
      .getCertificateInformation(stpId)
      .subscribe({
        next: async (res) => {
          this.stpApplication = res.data;
          this.pdfBlob = await this.stpRegistrationService.mailCertificate(
            this.stpApplication
          );
          let file = new FormData();
          file.append('file', this.pdfBlob);
          file.append('id', stpId);
          this.stpRegistrationStorageService.mailingPdf(file).subscribe({
            next: (res) => {
              this.notification.success('Successful', 'Mail Send Successfull');
            },
          });
        },
      });
  }

  onSearch() {
    this.page = 1;
    this.size = 10;
    this.loadstpApplicationsFromServer();
  }

  onRefresh() {
    if(this.hiddingHeaderFooter){
      this.searchingForm.reset();
      this.onSearch();
    }else{
      this.searchingForm.reset();
      this.searchingForm.get('stpIsApproved')?.patchValue(true);
      this.onSearch();
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    params.sort.forEach((element) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });
    this.loadstpApplicationsFromServer();
  }

  getFormatDataForExcel(dataReport: any) {
    const reportMap = new Map();
    const dataList = [];
    let i = 1;
    for (const data of dataReport) {
      reportMap.set('#SL', i);
      reportMap.set('Stp Name', data.stpName);
      reportMap.set('Stp Name Bangla', data.stpNameBangla);
      reportMap.set('Application Number', data.stpApplicationNo);
      reportMap.set('Application Date', data.applicationDate ? new Date(data.applicationDate) : null);
      reportMap.set('Application Status', data.applicationStatus);
      reportMap.set(
        'Approval Status',
        data.approvalStatus ? 'Approved' : 'Not Approved'
      );

      let reviewStatus;
      if (data.applicationStatus == 'Completed' && data.isTempRejected == null) {
        reviewStatus = 'Pending';
      } else {
        if (data.isTempRejected == false) {
          reviewStatus = 'Accepted';
        } else if (data.isTempRejected == true){
          reviewStatus = 'Correction Requested';
        }
      }
      reportMap.set('Application Review Status', reviewStatus);
      reportMap.set('Registration Number', data.stpRegistrationNo);
      reportMap.set('Stp Type', data.stpType);
      reportMap.set('Ownership Type', data.ownershipType);
      reportMap.set('Ministry Name', data.ministryName);
      reportMap.set('Agency Name', data.agencyName);
      reportMap.set('Establish Year', data.establishYear);
      reportMap.set('Location Name', data.locationName);
      reportMap.set('Country', data.country);
      reportMap.set('Division', data.division);
      reportMap.set('District', data.district);
      reportMap.set('Upazilla', data.upazilla);
      reportMap.set('Post Office', data.postOffice);
      reportMap.set('Address', data.address);
      reportMap.set('Org Contact', data.orgContact);
      reportMap.set('Org Email', data.orgEmail);
      reportMap.set('Contact Name', data.contactName);
      reportMap.set('Contact Designation', data.contactDesignation);
      reportMap.set('Contact Number', data.contactNumber);
      reportMap.set('Contact Email', data.contactEmail);
      reportMap.set('Committee', data.isCommittee? 'Yes' : 'No');
      reportMap.set('Total Member', data.totalMember);
      reportMap.set('Total Female Member', data.totalFemaleMember);


      reportMap.set('Approval Date', data.approvalDate ? new Date(data.approvalDate) : null);
      reportMap.set('Expire Date', data.expireDate ? new Date(data.expireDate) : null);
      reportMap.set('Construction Building', data.constructionBuilding);
      reportMap.set('Ownership Building', data.ownershipBuilding);
      reportMap.set('Total Area', data.totalArea);
      reportMap.set('Floor', data.floor);
      reportMap.set('Class room Size', data.classroomSize);
      reportMap.set('Classroom', data.classroom);
      reportMap.set('Workshop Size', data.workshopSize);
      reportMap.set('Workshop', data.workshop);
      reportMap.set('No Of Computer Labs', data.noOfComputerLabs);
      reportMap.set('Size Of Computer Labs', data.sizeOfComputerLabs);
      reportMap.set('Office', data.office);
      reportMap.set('Training Room', data.trainingRoom);
      reportMap.set('Washroom', data.washroom);
      reportMap.set('Female Washroom', data.femaleWashroom);
      reportMap.set('Internet', data.isInternet? 'Yes' : 'No');
      reportMap.set('Electricity', data.isElectricity? 'Yes' : 'No');
      reportMap.set('Fire Equipment', data.isFireEq? 'Yes' : 'No');
      reportMap.set('Library', data.isLibrary? 'Yes' : 'No');
      reportMap.set('Drinking Water', data.isDrinkingWater? 'Yes' : 'No');
      reportMap.set('Lighting Ventelent', data.isLightingVent? 'Yes' : 'No');
      reportMap.set('Disable Friendly', data.isDisableFriendly? 'Yes' : 'No');
      reportMap.set('Reserve Fund', data.reserveFund);
      reportMap.set('FDR', data.fdr);
      reportMap.set('General Fund', data.generalFund);
      reportMap.set('Available Balance', data.availableBalance);
      reportMap.set('Account No', data.accountNo);
      reportMap.set('BankName', data.bankName);
      reportMap.set('Branch Name', data.branchName);
      reportMap.set('Other Fund', data.otherFund);
      reportMap.set('Remarks', data.remarks);
      reportMap.set('Fees From Student', data.feesFromStudent);
      reportMap.set('Own Asset Income', data.ownAssetIncome);
      reportMap.set('Govt Income', data.govIncome);
      reportMap.set('Development Charge', data.developmentCharge);
      reportMap.set('Admission Fee', data.admissionFee);
      reportMap.set('Exam Fee', data.examFee);
      reportMap.set('Session Charge', data.sessionCharge);
      reportMap.set('ProdSell', data.prodSell);
      reportMap.set('Sources of Income Other', data.incomeOther);
      reportMap.set('Salary', data.salary);
      reportMap.set('Incidental', data.incidental);
      reportMap.set('Repairing', data.repairing);
      reportMap.set('Exam', data.exam);
      reportMap.set('Travel', data.travel);
      reportMap.set('Proc Books', data.procBooks);
      reportMap.set('Proc Training', data.procTraining);
      reportMap.set('Proc Raw Material', data.procRawMat);
      reportMap.set('Expenditure Others', data.expenditureOthers);

      reportMap.set('Payment Completed', data.isSuccess ? 'Yes' : 'No');
      reportMap.set('Payment Date', data.payDate ? new Date(data.payDate) : null);
      reportMap.set('Amount', data.amount);
      reportMap.set('Payment Reference Number', data.transactionNumber);
      reportMap.set('Payment Type', data.paymentType);

      const jsonObject: any = {};
      reportMap.forEach((value, key) => {
        jsonObject[key] = value;
      });

      dataList.push(jsonObject);
      i++;
    }

    return dataList;
  }

  getOwnerShipTypeList() {
    this.stpRegistrationStorageService.readStpOwnerShipType().subscribe({
      next: (res) => {
        this.stpOwnershipTypeList = res;
      },
    });
  }

  getStpDistrictsList() {
    this.stpRegistrationStorageService.readStpDistricts().subscribe({
      next: (res) => {
        this.districtList = res;
      },
    });
  }

  captchVerificationStatus(verificationStatus: { success: boolean, message: string }) {
    if (verificationStatus.success) {
      this.notification.success("Success", verificationStatus.message);
      this.contentVisble = true;
    } else {
      this.notification.error("Error", verificationStatus.message);
      this.contentVisble = false;
    }
  }

  showModal(): void {
    this.isStpBasicInfoModalVisible = true;
  }

  handleOk(): void {
    this.isStpBasicInfoModalVisible = false;
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isStpBasicInfoModalVisible = false;

    // Handle form submission or any other logic
    this.isVisible = false;
  }

  getStpAppBasicInfoById(stpMainId: number) {
    this.stpRegistrationStorageService.readStpBasicProfilebyId(stpMainId).subscribe(
      (res) => {
        if (res.success) {
          this.stpBasicData = res.data;
        } else {
          this.notification.error('Error!', res.message);
        }
      },
      (error) => {
        this.notification.error('Error!', 'Error getting STP basic info');
      }
    )
  }

  onStpMainClick(stpMainId: number) {
    if (!this.hiddingHeaderFooter) {
      this.getStpAppBasicInfoById(stpMainId);
      this.showModal();
    } else {
      // this.router.navigateByUrl('/home/stp-registration/stp-application-view/' + stpMainId);
    }
  }


  /*
    pending status: correction needed = null and application status = 'completed'
    correction requested: correction needed = true
    accepted: correction needed = false
  */
  onReviewStatusChange(reviewStatus: any) {
    switch(reviewStatus) {
      case 'pending':
        this.searchingForm.controls.currectionNeeded.setValue(null);
        this.searchingForm.controls.pendingStp.setValue(true);
        this.searchingForm.controls.submissionStatusId.setValue(3); // 3 = application completed status
        this.selectedApprovalStatus = 'not_approved';
        this.searchingForm.controls.stpIsNotApproved.setValue(true);
        this.searchingForm.controls.stpIsApproved.setValue(null);
        break;
      case 'correction':
        this.searchingForm.controls.currectionNeeded.setValue(true);
        this.searchingForm.controls.submissionStatusId.setValue(2); // 2 = application on-going status
        this.searchingForm.controls.pendingStp.setValue(null);
        this.selectedApprovalStatus = 'not_approved';
        this.searchingForm.controls.stpIsNotApproved.setValue(true);
        this.searchingForm.controls.stpIsApproved.setValue(null);
        break;
      case 'accepted':
        this.searchingForm.controls.currectionNeeded.setValue(false);
        this.searchingForm.controls.submissionStatusId.setValue(3); // 3 = application completed status
        this.searchingForm.controls.pendingStp.setValue(null);
        this.selectedApprovalStatus = null;
        this.searchingForm.controls.stpIsNotApproved.setValue(null);
        this.searchingForm.controls.stpIsApproved.setValue(null);
        break;
      default:
        this.searchingForm.controls.currectionNeeded.setValue(null);
        this.searchingForm.controls.pendingStp.setValue(null);
        this.searchingForm.controls.submissionStatusId.setValue(null);
        this.selectedApprovalStatus = null;
        this.searchingForm.controls.stpIsNotApproved.setValue(null);
        this.searchingForm.controls.stpIsApproved.setValue(null);
    }
  }

  onApprovalStatusChange(status: any) {
    switch(status) {
      case 'approved':
        this.searchingForm.controls.stpIsApproved.setValue(true);
        this.searchingForm.controls.stpIsNotApproved.setValue(null);
        break;
      case 'not_approved':
        this.searchingForm.controls.stpIsNotApproved.setValue(true);
        this.searchingForm.controls.stpIsApproved.setValue(null);
        break;
      default:
        this.searchingForm.controls.stpIsNotApproved.setValue(null);
        this.searchingForm.controls.stpIsApproved.setValue(null);
    }

  }


  submitApprovalDate() {
    var pipe = new DatePipe('en-US');
    const enrollmentDateFormat = pipe.transform(
      this.approvalForm.controls.approvalDateFromAdmin.value,
      'yyyy-MM-dd HH:mm'
    );
    this.approvalForm.controls.approvalDateFromAdmin.setValue(enrollmentDateFormat);
    if(this.approvalForm.value.approvalDateFromAdmin && this.currentStpApplication){
      this.stpRegistrationStorageService
      .updateStpApproval(this.currentStpApplication.createdBy, this.approvalForm.value.approvalDateFromAdmin)
      .subscribe({
        next: (res) => {
          this.isVisible = false;
          if (res.success) {
            this.currentStpApplication.approval = true;
            this.notification.success('Success!', res.message);
          } else {
            this.notification.error(
              'Error!',
              `${res.message}. STP User is not Approved!`
            );
          }
        },
      });
    }else{
      this.notification.error("Error","Data is missing!");
    }
  }

  // tslint:disable-next-line:typedef
  setApproved(stpApplicationDto: StpApplicationDto): void {
    this.isVisible = true;
    this.currentStpApplication = stpApplicationDto;
  }

  disabledDate = (current: Date): boolean =>
  {
    // differenceInCalendarDays(current, this.today) > 0;
    const october2021 = parse('2021-10-01', 'yyyy-MM-dd', new Date());
    const daysFromToday = differenceInCalendarDays(current, this.today);
    const daysBeforeOctober2021 = differenceInCalendarDays(current, october2021);
    return daysFromToday > 0 || daysBeforeOctober2021 < 0;
  }

}
