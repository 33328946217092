import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerResponse } from 'http';
import { Observable } from 'rxjs';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

  constructor(private httpClient:HttpClient) { }

  verifyRecaptcha(data:any):Observable<ServerResponse>{   
    return this.httpClient.post<ServerResponse>(`${applicationUrls.recaptcha.verifyCaptcha}`,data);
  }
}
