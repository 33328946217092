import { AuthenticationService } from './../../../modules/authentication/services/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    // const authToken = this.auth.getToken();
    let authToken = this.auth.getToken();

    /*
    * The verbose way:
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', authToken)
    });
    */

    // add auth header with jwt if user is logged in and request is to the api url
    /*const user = this.authenticationService.userValue;
    const isLoggedIn = user && user.jwtToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${user.jwtToken}` },
      });
    }*/

    // Clone the request and set the new header in one step.

    if(req?.url?.includes("nap-service")){
      authToken = this.auth.getNAPToken();

      if(authToken == null){
        authToken = this.auth.getToken();
      }
    }

    const authReq = req.clone({
      setHeaders: { Authorization: 'Bearer ' + authToken },
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
