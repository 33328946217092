<!-- <div class="container-fluid">
  <nz-card [nzExtra]="extraTemplate">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> List of Required Furniture
    </div>
    <div class="boxAerar">
      <nz-table
        [nzShowPagination]="false"
        #basicTable
        [nzData]="cadFurnitureList"
      >
        <thead>
          <tr>
            <th>Name of Furniture</th>
            <th>Specification</th>
            <th>Unit</th>
            <th>Minimum Quantity Required</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.furnitureName }}</td>
            <td>{{ data.specification }}</td>
            <td>{{ data.unit }}</td>
            <td>{{ data.minQuantityReq }}</td>
            <td>{{ data.remark }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>

<ng-template #extraTemplate>
  <button
    nz-button
    nzType="primary"
    (click)="createCustomButtonModal()"
    *ngIf="createButton"
  >
    Add Furniture
  </button>
</ng-template> -->




<div class="container-fluid">
  <nz-card [nzExtra]="extraTemplate">
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="form" nzTheme="outline"></i> List of Required Furniture
    </div>
    <div class="boxAerar">
      <nz-table #basicTable [nzData]="cadList" 
      nzShowSizeChanger
      nzBordered [nzTotal]="total" [(nzPageSize)]="size"
      [nzShowTotal]="totalRowRangeTemplate" [(nzPageIndex)]="page" >
      <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template>
      
      <!-- Without Pagination -->
      <!-- <nz-table #basicTable [nzData]="cadList" 
      nzShowSizeChanger
      nzBordered
      nzAlign="middle"
      [nzFrontPagination]="false"
      [nzTotal]="total" [(nzPageSize)]="size"
      [nzShowTotal]="totalRowRangeTemplate" [(nzPageIndex)]="page"
      class="css-serial table-striped table">
      <ng-template #totalRowRangeTemplate let-range="range" let-total style="text-align: left">
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template> -->
      <thead>
        <tr>
          <th>Name of Furniture</th>
          <th>Specification</th>
          <th>Unit</th>
          <th>Minimum Quantity Required</th>
          <th>Remarks</th>
          <th [hidden]="(this.showingAction)" >Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.furnitureName }}</td>
          <td>{{ data.specification }}</td>
          <td>{{ data.unit }}</td>
          <td>{{ data.minQuantityReq }}</td>
          <td>{{ data.remark }}</td>
          <td [hidden]="(this.showingAction)" style="text-align: center; vertical-align: middle;">
            <button (click)="onEdit(data.id!)" nz-button nzType="default" [nzSize]="'small'">
              <i nz-icon nzType="edit" nzTheme="fill"></i>
            </button>
            |
            <button nz-popconfirm nzPopconfirmTitle="Are you sure delete this task?" nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="onDelete(data.id!)" nz-button nzType="default" [nzSize]="'small'">
              <i nz-icon nzType="delete" nzTheme="fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>

<ng-template #extraTemplate>
  <button
    nz-button
    nzType="primary"
    (click)="createCustomButtonModal()"
    *ngIf="createButton"
  >
    Add Furniture
  </button>
</ng-template>
