<app-header *ngIf="!hiddingHeaderFooter"></app-header>
<!-- {{ searchForm.value | json }}  -->
<div class="container-fluid">
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Search Document
      by
    </div>
    <form
      nz-form
      [formGroup]="searchForm"
      class="ant-advanced-search-form"
      style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      "
    >
      <div class="row">
        <div class="col-md-4">
          <div class="col-md-12">
            <nz-form-label>Document ID</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please insert valid document Id">
                <input
                  nz-input
                  type="text"
                  placeholder="Document Id"
                  formControlName="generatedDocId"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12">
            <nz-form-label>Document Type</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please select document type">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  id="documentTypeId"
                  formControlName="documentTypeId"
                  nzPlaceHolder="Please select document type"
                >
                  <nz-option
                    *ngFor="let dt of documentTypeDD"
                    [nzValue]="dt.id"
                    [nzLabel]="dt.documentName"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12">
            <nz-form-label>Document Title</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please insert valid document title">
                <input
                  nz-input
                  type="text"
                  placeholder="Title"
                  formControlName="documentTitle"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 form-group">
          <div class="col-md-12 form-group">
            <nz-form-label>From Date</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="fromTimeIdErrorTpl">
                <nz-date-picker
                  [nzShowToday]="true"
                  [nzShowTime]="false"
                  style="width: 100%"
                  [nzDisabledDate]="disabledStartDate"
                  [nzShowTime]
                  nzFormat="dd/MM/yyyy"
                  formControlName="dateFrom"
                  (nzOnOpenChange)="handleStartOpenChange($event)"
                ></nz-date-picker>
                <ng-template #fromTimeIdErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select your From Time
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="col-md-4 form-group">
          <div class="col-md-12 form-group">
            <nz-form-label>To Date</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="toTimeIdErrorTpl">
                <nz-date-picker
                  [nzShowToday]="true"
                  style="width: 100%"
                  #toDatePicker
                  [nzDisabledDate]="disabledEndDate"
                  nzFormat="dd/MM/yyyy "
                  formControlName="dateTo"
                  (nzOnOpenChange)="handleEndOpenChange($event)"
                ></nz-date-picker>
                <ng-template #toTimeIdErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select your To Time
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area text-right">
          <button
            class="mr-2"
            nz-button
            [nzType]="'primary'"
            (click)="submitSearchForm()"
            [disabled]="!this.isSearchButtonEnable"
          >
            Search
          </button>
          <button
            class="mr-2 btn-success"
            nz-button
            type="button"
            (click)="onRefresh()"
          >
            Refresh Data
          </button>
        </div>
      </div>
    </form>
  </nz-card>

  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i> List of
      Documents
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTable
        [nzData]="documentInfo"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzFrontPagination]="false"
        [nzTotal]="total"
        [(nzPageSize)]="size"
        [nzShowTotal]="totalRowRangeTemplate"
        [(nzPageIndex)]="page"
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzScroll]="{ x: '1920px' }"
      >
        <ng-template
          #totalRowRangeTemplate
          let-range="range"
          let-total
          style="text-align: left"
        >
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
        <thead>
          <tr>
            <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
            <th
              style="width: 20%; text-align: center"
              rowspan="1"
              nzColumnKey="generatedDocId"
              [nzSortFn]="true"
            >
              Document ID
            </th>
            <th
              style="width: 13%; text-align: center"
              rowspan="1"
              nzColumnKey="documentType.documentName"
              [nzSortFn]="true"
            >
              Document Type
            </th>
            <th
              style="width: 12%; text-align: center"
              rowspan="1"
              nzColumnKey="documentTitle"
              [nzSortFn]="true"
            >
              Document Title
            </th>
            <th style="width: 10%; text-align: center" rowspan="1">Version</th>
            <th style="width: 8%; text-align: center" rowspan="1">Language</th>
            <th style="width: 7%; text-align: center" rowspan="1">
              Date of Document Creation
            </th>
            <th style="width: 7%; text-align: center" rowspan="1">
              Open for Collecting Opinion?
            </th>
            <th style="width: 7%; text-align: center" rowspan="1">Notes</th>
            <th style="width: 18%; text-align: center" rowspan="1">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i = index">
            <td style="text-align: center">
              {{ (page - 1) * size + 1 + i }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              <a (click)="showModal(data)" style="color: blue">{{
                data.generatedDocId
              }}</a>
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.documentType.documentName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.documentTitle }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.language.languageName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.version.versionName }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.docCreatedDate | date : "dd-MM-yyy" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.openForOpinion == true ? "Yes" : "No" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.collectingOpinionNote }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              <button
                title="View"
                (click)="showModal(data)"
                nz-button
                nzType="default"
              >
                <i nz-icon nzType="eye" nzTheme="fill"></i> View
              </button>

              <button
                class="ml-2 mt-2"
                title="Download Document"
                nz-button
                nzType="default"
                (click)="onDownload(data)"
                style="margin-right: 5px"
              >
                <i nz-icon nzType="download" nzTheme="outline"></i>
                Download
              </button>

              <button
                class="ml-2 mt-2"
                title="Give Opinion"
                nz-button
                nzType="default"
                (click)="giveOpinion(data.id)"
                style="margin-right: 5px"
                *ngIf="
                  data.isCollectable == true &&
                  data.openForOpinion == true &&
                  checkIsCollectableAndGivenTillDate(data?.collectTillDate) == 0
                "
              >
                <i nz-icon nzType="question" nzTheme="outline"></i>
                Give Opinion
              </button>

              <!-- <button
                class="ml-2 mt-2"
                title="View Opinion"
                nz-button
                nzType="default"
                (click)="viewOpinion(data)"
                style="margin-right: 5px"
                *ngIf="
                  data.isCollectable == true && data.openForOpinion == true"
              >
                <i nz-icon nzType="question" nzTheme="outline"></i>
                View Opinion
              </button> -->
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>

<ng-container *ngIf="!userInfo.id">
  <app-captcha (verifyStatus)="captchVerificationStatus($event)"></app-captcha>
</ng-container>

<app-footer *ngIf="!hiddingHeaderFooter"></app-footer>

<nz-modal
  [nzTitle]="'Document View'"
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="700"
>
  <div class="container-fluid">
    <nz-table
      #basicTable
      [nzData]="['']"
      nzTableLayout="fixed"
      nzBordered
      [nzShowPagination]="false"
      nzAlign="middle"
    >
      <tbody *ngIf="documents">
        <tr>
          <td style="text-align: left; font-weight: bold">Document ID</td>
          <td style="text-align: left">
            {{ documents.generatedDocId }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">Document Type</td>
          <td style="text-align: left">
            {{ documents.documentType.documentName }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">Document Title</td>
          <td style="text-align: left">
            {{ documents.documentTitle }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">Version</td>
          <td style="text-align: left">
            {{ documents.version.versionName }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">Language</td>
          <td style="text-align: left">
            {{ documents.language.languageName }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">
            Date of Document Creation
          </td>
          <td style="text-align: left">
            {{ documents.docCreatedDate | date : "dd-MM-yyy" }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">Access Type</td>
          <td style="text-align: left">
            {{ documents.accessType.accessTypeName }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left; font-weight: bold">
            Open for Collecting Opinion?
          </td>
          <td style="text-align: left">
            {{ documents.openForOpinion == true ? "Yes" : "No" }}
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="handleCancel()">Close</button>
    </div>
  </div>
</nz-modal>

<!-- <nz-modal
  [nzTitle]="'Document ID : ' + this.documentId"
  [(nzVisible)]="isVisibleOpinion"
  (nzOnCancel)="handleCancelOpinion()"
  [nzWidth]="1000"
>
<div class="ng-Header col-xs-12">
  <i nz-icon nzType="unordered-list" nzTheme="outline"></i>Document Opinion List
</div>
<div nz-col [nzSpan]="24">
  <nz-table
    #opinionTable
    [nzData]="this.documentOpinionList"
    nzBordered
    nzSize="middle"
    nzAlign="middle"
    nzHideOnSinglePage="true"
    [nzScroll]="{ x: '1500px', y: '300px' }"
  >
    <thead>
      <tr>
        <th style="text-align: center" nzWidth="5%" rowspan="1">SI.</th>
        <th style="text-align: center" nzWidth="15%" rowspan="1">Opinion By</th>
        <th style="text-align: center" nzWidth="15%" rowspan="1">Opinion</th>
        <th style="text-align: center" nzWidth="10%" rowspan="1">Designation</th>
        <th style="text-align: center" nzWidth="15%" rowspan="1">Organization</th>
        <th style="text-align: center" nzWidth="20%" rowspan="1">Address</th>
        <th style="text-align: center" nzWidth="10%" rowspan="1">Contact No</th>
        <th style="text-align: center" nzWidth="10%" rowspan="1">Email</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of opinionTable.data; let i = index">
        <td style="text-align: center">{{ 1 + i }}</td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.opinionBy }}
        </td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.detailedOpinion }}
        </td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.designation }}
        </td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.organization }}
        </td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.address }}
        </td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.contactNo }}
        </td>
        <td style="text-align: center; vertical-align: middle">
          {{ data?.email }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
<div *nzModalFooter>
  <button nz-button nzType="primary" (click)="handleCancelOpinion()">Close</button>
</div>
</nz-modal> -->
