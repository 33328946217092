import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { applicationPermissions } from '../../application-constants/application-permissions';
import { AuthorizationService } from 'src/app/modules/authorization/services/authorization.service';
import { RequestData } from 'src/app/modules/stp-registration/models/stp_onlinePayment.model';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';
import { StpRegistrationService } from 'src/app/modules/stp-registration/services/stp-registration.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import FileSaver from 'file-saver';
@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  requestData: RequestData = new RequestData();
  userInfo: any;
  stpMainId: any;
  isCollapsed = false;
  stpApplication: any;
  isComplete: boolean;
  isComplete$: Subscription;
  applicationPermissions = applicationPermissions;
  environment = environment;
  stpMasterId = environment.roles.stpMasterId;
  stpBasicId = environment.roles.stpBasicId;
  isCertificateModalVisible: boolean = false;
  isOk: boolean = false;
  constructor(
    private stpRegistrationStorageService: StpRegistrationStorageService,
    public authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private stpRegistrationService: StpRegistrationService,
    private notification: NzNotificationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userId();
    this.onUserFullInformationRequest();

    this.isComplete$ = this.stpRegistrationService.getCheckIsCompleteRegistration().subscribe(
      (res) => {
        this.isComplete = res;
      }
    );
  }

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo.id;
  }

  onUserFullInformationRequest() {
    this.stpRegistrationStorageService
      .stpMainIdByUserId(this.requestData)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.stpMainId = res.data;
          }
        },
      });
  }

  onLogout(): void {
    this.authenticationService.logout();
  }
  onChangePassword(): void {
    this.router.navigate(['/home/authentication/change-password']);
  }

  //Online Payment Part redirect to ek pay.
  constructRequestData() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo?.id;
  }

  onSubmitPaymentRequest() {
    // this.constructRequestData();
    // this.stpRegistrationStorageService
    //   .initiatePaymentRequest(this.requestData)
    //   .subscribe((response) => {
    //     window.location.href = response.data;
    //   });
    window.location.href = 'https://admin.ekpay.gov.bd/';
  }

  //#region modal
  showModal(): void {
    this.isCertificateModalVisible = true;
  }

  handleCancel(): void {
    this.isCertificateModalVisible = false;
  }
  handleOk(): void {
    this.isOk = true;
    this.isCertificateModalVisible = false;
    this.downloadCertificate(this.stpMainId);
  }
  //#endregion

  downloadCertificate(stpId: number) {
    if (!stpId) {
      this.notification.error(
        'Sorry!',
        'Please fill Up your forms first.'
      );
    } else {
      this.stpRegistrationStorageService
        .readStpApplicationsbyId(stpId)
        .subscribe(
          (res) => {
            this.stpApplication = res.data;
            if (res.success) {
              if (!this.stpApplication.stpUserInfoDto.stpRegistrationNo || (this.stpApplication.stpUserInfoDto.approvalStatus == false)) {
                this.notification.error(
                  'Sorry!',
                  'Your STP Registration is not approved yet.'
                );
              } else {
                this.stpRegistrationStorageService
                  .getCertificateInformation(stpId)
                  .subscribe((data: any) => {
                    var fileName = 'report.pdf';
                    const contentDisposition = data.headers.get('Content-Disposition');
                    if (contentDisposition) {
                      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                      const matches = fileNameRegex.exec(contentDisposition);
                      if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                      }
                      FileSaver(data.body, fileName);
                      {
                        this.notification.success('Success!', "STP Certificate download completed");
                      }
                    }
                  },
                    (error) => {
                      this.notification.error('Error!', "STP Certificate could not be downloaded");
                    });
              }
            }
          });
    }
  }

  userProfile(): void {
    this.router.navigate(['home/setting/my-profile'])
  }

  ngOnDestroy(): void {
    this.isComplete$.unsubscribe();
  }
}
