import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationStorageService } from 'src/app/modules/authentication/services/authentication-storage.service';
import { ExternalUserRegistrationRequest } from '../../models/DTO/external-user-registration-request';

@Component({
  selector: 'app-external-user-registration',
  templateUrl: './external-user-registration.component.html',
  styleUrls: ['./external-user-registration.component.scss'],
})
export class ExternalUserRegistrationComponent implements OnInit {
  rsExternalRegForm: FormGroup;
  validateForm!: FormGroup;
  otpForm!: FormGroup;

  nzFooterVisible = false;
  emailAlredyExist = false;
  nameAlredyExist = false;
  passwordVisible = false;
  confirmPasswordVisible = false;
  isVisible = false;

  display: any;
  totalSeconds: any;
  tempNumber = 0;
  contactNumber!: string;
  receivedOtp!: string;
  regex = /[a-zA-Z]*/;
  nameRegex = /^[a-zA-Z\s][a-zA-Z0-9\s][\w\s.,-,(,)]+$/;

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private authStorageService: AuthenticationStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.rsExternalRegForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(this.nameRegex),
          this.noWhitespaceValidator,
        ],
      ],
      nid: ['', [Validators.pattern(/^(?:\01)?(?:\d{10}|\d{13}|\d{17})$/)]],
      email: [null, [Validators.email, Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/
          ),
        ],
      ],
      confirmPassword: ['', [Validators.minLength(8), this.confirmValidator]],
      contactNumberPrefix: ['+880'],
      contactNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
    });
    // for Otp Form
    this.otpForm = this.fb.group({
      otp: [
        '',
        [Validators.required, Validators.pattern(/^(?:\01)?(?:\d{5})$/)],
      ],
    });
  }
  ngOnInit(): void {}

  submitForm(): void {
    for (const key of Object.keys(this.rsExternalRegForm.controls)) {
      this.rsExternalRegForm.controls[key].markAsDirty();
      this.rsExternalRegForm.controls[key].updateValueAndValidity();
    }
    this.showModal();
  }

  submitOtp(): void {
    this.receivedOtp = this.otpForm.get('otp')?.value;
    const externalUserRegistrationRequest =
      new ExternalUserRegistrationRequest();
    externalUserRegistrationRequest.name =
      this.rsExternalRegForm.controls.name.value;
    externalUserRegistrationRequest.nid =
      this.rsExternalRegForm.controls.nid.value;
    externalUserRegistrationRequest.email =
      this.rsExternalRegForm.controls.email.value;
    externalUserRegistrationRequest.password =
      this.rsExternalRegForm.controls.confirmPassword.value;
    externalUserRegistrationRequest.contactNumber =
      this.rsExternalRegForm.controls.contactNumber.value;
    externalUserRegistrationRequest.contactNumberPrefix =
      this.rsExternalRegForm.controls.contactNumberPrefix.value;
    externalUserRegistrationRequest.role = 'R&S_External_User';
    this.authStorageService
      .getVerifiedOtp(this.receivedOtp, this.contactNumber)
      .subscribe({
        next: (response) => {
          this.authStorageService
            .createUser(externalUserRegistrationRequest)
            .subscribe({
              next: (serverResponse) => {
                this.isVisible = false;
                this.rsExternalRegForm.reset();
                const returnUrl =
                  this.route.snapshot.queryParams.returnUrl || '/login';
                this.router.navigateByUrl(returnUrl);
                this.notification.success(
                  'Success!',
                  'Please check your email for validation'
                );
              },
              error: (error) => {
                this.notification.error(error, error.message);
              },
            });
        },
        error: (error) => this.notification.error('Failed', 'OTP is not valid'),
      });
    this.otpForm.reset();
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.rsExternalRegForm.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  checkEmailExist(email: any): void {
    this.emailAlredyExist = false;
    setTimeout(() => {
      if (email.value.length > 3) {
        this.authStorageService
          .checkApplicantEmailExist(email.value)
          .subscribe({
            next: (res) => {
              if (res.data == null) {
                this.emailAlredyExist = false;
              } else {
                this.emailAlredyExist = true;
                this.rsExternalRegForm.controls.email.setErrors({
                  incorrect: true,
                });
              }
            },
          });
      }
    }, 2000);
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.rsExternalRegForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  nidValidation(control: AbstractControl) {
    if (control.value.length <= 10) {
      return { error: true, required: true };
    } else if (control.value.length <= 17) {
      return { error: true, required: true };
    }
    return {};
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.rsExternalRegForm.reset();
    this.rsExternalRegForm.get('contactNumberPrefix')?.setValue('+880');

    for (const key of Object.keys(this.rsExternalRegForm.controls)) {
      this.rsExternalRegForm.controls[key].markAsPristine();
      this.rsExternalRegForm.controls[key].updateValueAndValidity();
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  //#region OTP
  showModal(): void {
    this.timer(5);
    this.contactNumber =
      '0' + this.rsExternalRegForm.get('contactNumber')?.value;
    this.authStorageService.getOtp(this.contactNumber).subscribe({
      next: (res) => {
        if (res.success == true) {
          this.notification.success('Success!', 'OPT sent successfully');
        } else {
          this.notification.error(
            'Failed!',
            'OTP Sent Failed! Please Try Again later...'
          );
          this.handleCancel();
        }
      },
    });
    this.isVisible = true;
    setTimeout(() => {
      this.isVisible = false;
    }, 300000);
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  //#endregion OTP

  timer(minutes: number) {
    // Start at 4 minutes and 59 seconds when given any minute value
    this.totalSeconds = minutes * 60 - 1;
    let displayMinutes: number;
    let displaySeconds: number;
    const timer = setInterval(() => {
      displayMinutes = Math.floor(this.totalSeconds / 60);
      displaySeconds = this.totalSeconds % 60;
      const formattedMinutes =
        displayMinutes < 10 ? '0' + displayMinutes : displayMinutes.toString();
      const formattedSeconds =
        displaySeconds < 10 ? '0' + displaySeconds : displaySeconds.toString();
      this.display = `${formattedMinutes}:${formattedSeconds}`;
      if (this.totalSeconds <= 0) {
        clearInterval(timer);
        this.isVisible = false;
      }
      this.totalSeconds--;
    }, 1000);
  }
}
