import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { applicationUrls } from '../application-constants/application-urls.const';
import { ServerResponse } from '../models/dto/server-response.dto';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CommonStorageService {

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  readDivisions(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.userLocation.readDivisions}`)
      .pipe(
        tap((response) => {
          this.commonService.setDivisions(response.data);
        })
      );
  }

  readDistricts(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.userLocation.readDistricts}`)
      .pipe(
        tap((response) => {
          this.commonService.setDistricts(response.data);
        })
      );
  }

  readUpazilas(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.userLocation.readUpazilas}`)
      .pipe(
        tap((response) => {
          this.commonService.setUpazilas(response.data);
        })
      );
  }

  uploadFile(userFile: any = File): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.file.upload}`, userFile)
      .pipe(
        tap((response) => {
          if (response.data) {
            console.log(response.data);
          }
        })
      );
  }
  downloadFile(filePath: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(
      `${applicationUrls.file.download}?fileUrl=${filePath}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.httpClient.get(
      `${applicationUrls.file.download}?fileUrl=${imageUrl}`,
      { responseType: 'blob' }
    );
  }


  getAllTrainingType():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getAllTrainingType}`)
  }

  getIdType():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getIdType}`)
  }

  getGender():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getGender}`)
  }

  getReligion():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getReligion}`)
  }

  getEthnicity():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getEthnicity}`)
  }

  getEducationalQualification():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getEducationalQualification}`)
  }

  getRuralOrUrban():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getRuralOrUrban}`)
  }

  getMaritalStatus():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getMaritalStatus}`)
  }

  getEmploymentStatus():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getEmploymentStatus}`)
  }

  getLearnAboutTraining():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.getLearnAboutTraining}`)
  }

  getTrainingTypes():Observable<ServerResponse>{
    return this.httpClient.get<ServerResponse>(`${applicationUrls.common.readTrainingType}`)
  }
}
