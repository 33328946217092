import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import FileSaver from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { TciInfoDto } from '../../template-for-certificate-issuance/DTO/tciInfoDto.model';
import { TciInfoSearchingDto } from '../../template-for-certificate-issuance/DTO/tciInfoSearchingDto.model';
import { TciInfoStorageService } from '../../template-for-certificate-issuance/services/tci-info-storage.service';
import { TamOccupation } from '../../training-and-assessment-materials/models/tam-occupation.model';
import { TrainingAndAssessmentMaterialsStorageService } from '../../training-and-assessment-materials/services/training-and-assessment-materials-storage.service';
import { TrainingAndAssessmentMaterialsService } from '../../training-and-assessment-materials/services/training-and-assessment-materials.service';
import { RplStorageService } from '../../stp-operations/services/rpl-storage.service';
import { RegularAssessmentService } from '../../stp-operations/services/regular-assessment.service';

@Component({
  selector: 'app-download-certificate',
  templateUrl: './download-certificate.component.html',
  styleUrls: ['./download-certificate.component.scss']
})
export class DownloadCertificateComponent implements OnInit {

  clickEventSubscription: Subscription;
  tciInfoList: TciInfoDto[] = [];
  total: number;
  size: number = 10;
  page: number = 1;
  onTciInfoSubscription$: Subscription;
  tciInfoFiltered: TciInfoDto[] = [];
  tamOccupations: TamOccupation[] = [];
  onTamOccupationSubscription$: Subscription;
  searchingForm: FormGroup = this.fb.group({
    occupationNameEnglish: [null],
    level: [''],
    registrationNo: [''],
    email: [''],
    contact: ['',
      [
        Validators.maxLength(11),
        Validators.minLength(11),
        Validators.pattern(/^[0-9]*$/),
      ]],
    certificateSerialNo: ['']
  });

  contentVisble = false;

  constructor(
    private tciInfoStorageService: TciInfoStorageService,
    private notification: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private trainingAndAssessmentMaterialsService: TrainingAndAssessmentMaterialsService,
    private trainingAndAssessmentMaterialsStorageService: TrainingAndAssessmentMaterialsStorageService,
    private regularAssessmentService: RegularAssessmentService,
    private rplStorageService: RplStorageService
  ) {
  }

  ngOnInit(): void {

  }

  onSearch() {
    let regNo = this.searchingForm.get('registrationNo')?.value;
    let email = this.searchingForm.get('email')?.value;
    let contact = this.searchingForm.get('contact')?.value;
    let certificateSerialNo = this.searchingForm.get('certificateSerialNo')?.value;

    if (!regNo && !email && !contact && !certificateSerialNo) {
      this.notification.error("Error!", "Atleast one search criteria is required");
      this.tciInfoList = [];
    } else {
      this.page = 1;
      this.size = 10;
      this.getAllCertificates(this.searchingForm.value);
    }
  }

  getAllCertificates(tciFilteringRequestData?: TciInfoSearchingDto): void {
    this.tciInfoStorageService
      .getAllCertificates(this.page, this.size, tciFilteringRequestData)
      .subscribe({
        next: (res) => {
          this.tciInfoList = res.data;
          this.total = res.count;
        },
      });
  }

  ngOnDestroy(): void {
    this.onTciInfoSubscription$?.unsubscribe();
  }

  onRefresh() {
    this.page = 1;
    this.size = 10;
    this.searchingForm.reset();
    this.tciInfoList = [];
    this.total = 0;
  }

  onDownloadCertificate(data: any) {
    if(data?.certificateType == 1){  //Regular Batch
      let com = data.isCompetent? true: false;

      this.regularAssessmentService.onCollectdataToDownloadCertificate(data.id).subscribe({
        next: (res: any) => {
          if (res) {
            res['isCompetent'] = com;
            this.certificateDownloadForBothRplRegular(res);
          }
        },
        error: (error) => {
          this.notification.warning('Warning!!! ', 'No data found');
        },
      });
    }
    else if(data?.certificateType == 2){  //RPL Batch
      let com = data.isCompetent? true: false;

      this.rplStorageService.onCollectdataToDownloadCertificate(data.id).subscribe({
        next: (res: any) => {
          if (res) {
            res['isCompetent'] = com;
            this.certificateDownloadForBothRplRegular(res);
          }
        },
        error: (error) => {
          this.notification.warning('Warning!!! ', 'No data found');
        },
      });
    }
    else if(data?.certificateType == 3){  //Tci
      this.tciInfoStorageService.downloadFreelancerCertificate(data.id).subscribe((data: any) => {
        var fileName = 'report.pdf';
        const contentDisposition = data.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        FileSaver(data.body, fileName);
      });
    }
  }

  certificateDownloadForBothRplRegular(data: any){
    this.rplStorageService.downloadCertificateForRpl(data).subscribe((data: any) => {
      var fileName = 'report.pdf';
      const contentDisposition = data.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      FileSaver(data.body, fileName);
    })
  }

  onCertificateView(data: any) {
    this.router.navigate(['certificate-view', data.id], { relativeTo: this.activatedRoute });
  }

  getOccupationList() {
    this.tamOccupations =
      this.trainingAndAssessmentMaterialsService.getTamOccupations();

    if (this.tamOccupations.length === 0) {
      this.trainingAndAssessmentMaterialsStorageService
        .readTamOccupations()
        .subscribe();
    }
    this.onTamOccupationSubscription$ =
      this.trainingAndAssessmentMaterialsService.onRefreshTamOccupationList.subscribe(
        (res) => {
          this.tamOccupations = res;
        }
      );
  }

  captchVerificationStatus(verificationStatus: { success: boolean, message: string }) {
    if (verificationStatus.success) {
      this.notification.success("Success", verificationStatus.message);
      this.contentVisble = true;
    } else {
      this.notification.error("Error", verificationStatus.message);
      this.contentVisble = false;
    }
  }
}
