<app-header></app-header>
<main>
  <div class="marketing">
    <section style="background-color: #eee">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-12 col-xl-11">
            <div class="card text-black" style="border-radius: 25px">
              <div class="card-body p-md-5">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-lg-6 col-xl-6 order-2 order-lg-1">
                    <p class="text-center h4 fw-bold mb-6 mx-1 mx-md-4 mt-4">
                      Reset Your Password
                    </p>

                    <form nz-form [formGroup]="validateForm" class="forgotpassword-form" (ngSubmit)="submitForm()">

                      <nz-form-item>
                        <nz-form-label [nzSpan]="12" nzRequired> New Password</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzHasFeedback>
                          <input type="password" (ngModelChange)="validateConfirmPassword()" nz-input
                            formControlName="newPassword" placeholder="New Password" />
                          <ng-template #permissionNameErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please input new password!
                            </ng-container>
                          </ng-template>
                        </nz-form-control>
                      </nz-form-item>

                      <nz-form-item>
                        <nz-form-label [nzSpan]="12" nzRequired>Confirm New Password</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                          <input type="password" formControlName="confirmPassword" nz-input
                            placeholder="Confirm Password" />
                          <ng-template #passwordErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please confirm your password!
                            </ng-container>
                            <ng-container *ngIf="control.hasError('confirm')">
                              Password is inconsistent!
                            </ng-container>
                          </ng-template>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-control [nzOffset]="5" [nzSpan]="14">
                          <!-- <button class="mr-3" nz-button type="button" nzType="primary"
                            routerLink="/forgot-password">Back</button> -->
                          <button nz-button type="submit" nzType="primary"><i nz-icon nzType="save"
                              nzTheme="fill"></i>{{isEditMode?'Update':'Submit'}}</button>
                        </nz-form-control>
                      </nz-form-item>

                    </form>
                  </div>
                  <!-- <div class="
                      col-md-10 col-lg-6 col-xl-7
                      d-flex
                      align-items-center
                      order-1 order-lg-2
                    ">
                    <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-registration/draw1.png"
                      class="img-fluid" alt="Sample image" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-footer></app-footer>


<!-- <div class="center">
  <nz-card nzTitle="New Password" style="text-align: center;">
    <form nz-form [formGroup]="validateForm" class="forgotpassword-form" (ngSubmit)="submitForm()">

      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
            <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                   placeholder="Password" />
            <ng-template #suffixTemplate>
              <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                 (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="24" nzDisableAutoTips [nzErrorTip]="passwordErrorTpl">
          <nz-input-group nzPrefixIcon="lock">
          <input nz-input type="password" formControlName="confirm" placeholder="confirm your password" />
        </nz-input-group>
          <ng-template #passwordErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Please confirm your password!
            </ng-container>
            <ng-container *ngIf="control.hasError('confirm')">
              Password is inconsistent!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <button style="margin: 10px;" nz-button routerLink="/forgot-password" nzType="primary">Back</button>
      <button nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
    </form>
  </nz-card>
</div> -->
