import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Attachment } from '../../models/attachment';
import { RelatedResearch } from '../../models/related-research-DTO';
import { Author } from '../../models/author.model';
import { DemandSurvey } from '../../models/demand-survey.model';
import { DemandSurveyStorageService } from '../../services/demand-survey-storage.service';
import { ResearchAndStudiesStorageService } from '../../services/research-and-studies-storage.service';
@Component({
  selector: 'app-document-public-view',
  templateUrl: './document-public-view.component.html',
  styleUrls: ['./document-public-view.component.scss'],
})
export class DocumentPublicViewComponent implements OnInit {
  researchStudiesMainIdDecodedValue: any;
  attachmentList: Attachment[] = [];
  authorList: Author[] = [];
  relatedResearchList: RelatedResearch[] = [];
  demandSurveyList: DemandSurvey[] = [];
  historyList: any[] = [];
  researchStudiesMainInfo: any;
  previousFileBase64: any;

  //#region image part
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png'];
  otherPdfUrl: any;
  pdfUrl: any;
  imageURL: any;
  isImageLoading: boolean;
  isVisible: boolean = false;
  //#endregion

  // for pagination
  sortingKey: string;
  sortingValue: string;

  //author
  totalAuthor: number;
  pageSizeAuthor: number = 10;
  currentPageAuthor: number = 1;

  //attachment
  totalAttachment: number;
  pageSizeAttachment: number = 10;
  currentPageAttachment: number = 1;

  //related research
  totalRr: number;
  pageSizeRr: number = 10;
  currentPageRr: number = 1;

  constructor(
    private demandSurveyStorageService: DemandSurveyStorageService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private researchAndStudiesStorageService: ResearchAndStudiesStorageService,
    private location: Location,
    private translate: TranslateService
  ) {
    this.translate.use(environment.language.setDefaultLang);

    this.activatedRoute.queryParams.subscribe((params) => {
      const base64EncodedValue = params['researchStudiesMainId'];
      this.researchStudiesMainIdDecodedValue = atob(base64EncodedValue);
    });
  }

  ngOnInit(): void {
    this.getResearchStudiesMainInfoById();
  }

  getResearchStudiesMainInfoById() {
    this.researchAndStudiesStorageService
      .getResearchStudiesMainDetailsInfoByIdForPublicList(
        this.researchStudiesMainIdDecodedValue
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.researchStudiesMainInfo = res;
            this.attachmentList =
              this.researchStudiesMainInfo?.previewAttachments;
            this.authorList = this.researchStudiesMainInfo?.authorList;
            this.relatedResearchList =
              this.researchStudiesMainInfo?.relatedResearchList;
            this.historyList = this.researchStudiesMainInfo?.statusTypeHistory;
            this.getDemandSurveyInfoByResearchStudiesMainId();
          } else {
            this.notification.error(
              'Error',
              'Data cannot be obtained for unusual activity.'
            );
          }
        },
      });
  }

  getDemandSurveyInfoByResearchStudiesMainId() {
    this.demandSurveyStorageService
      .readDemandSurveyInfoByRsMainId(
        Number(this.researchStudiesMainIdDecodedValue)
      )
      .subscribe((res) => {
        this.demandSurveyList = res.data;
      });
  }

  async setBase64AttachmentforPreviewing(fileName: string, i: number) {
    let fileUrl = '';
    switch (fileName) {
      case 'documents':
        fileUrl =
          this.researchStudiesMainInfo?.previewAttachments[i]?.fileUrl ?? '';
        break;
    }
    const data = await fetch(environment.fileServiceApiUrl + fileUrl);
    const blob = await data.blob();
    let fileUrlBase64 = URL.createObjectURL(blob);
    let fileType = this.getFileTypeFromPreviousAttachment(fileUrl);
    this.previewAllImage(fileType, fileUrlBase64);
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';
    let basename = fileUrl.split(/[\\/]/).pop();
    let pos = basename?.lastIndexOf('.') ?? 0;
    let fileType = basename?.slice(pos + 1) ?? '';
    if (this.imageTypeArray.includes(fileType.toLowerCase())) {
      contentType = 'image';
    } else if (fileType == 'pdf') {
      contentType = 'pdf';
    } else if (fileType == 'doc') {
      contentType = 'doc';
    } else if (fileType == 'docx') {
      contentType = 'docx';
    } else if (fileType == 'xlsx') {
      contentType = 'xlsx';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  previewAllImage(fielType: string | undefined, fileUrl: any) {
    this.imageURL = '';
    this.pdfUrl = '';
    this.otherPdfUrl = '';
    if (fielType == 'image') {
      this.isVisible = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else if (fielType == 'pdf') {
      this.isVisible = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', 'file');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  backToPreviousPage() {
    this.location.back();
  }
}
