import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { TciInfoDto } from '../DTO/tciInfoDto.model';
import { TciInfoService } from './tci-info.service';
import {TciInfoSearchingDto} from '../DTO/tciInfoSearchingDto.model';


@Injectable({
  providedIn: 'root'
})
export class TciInfoStorageService {

constructor(
  private httpClient: HttpClient,
  private tciService: TciInfoService) { }



    //#region Excel data save for Tci Info

    saveExcelTciInfoData(tciInfoDto: TciInfoDto[]): Observable<ServerResponse> {
      return this.httpClient
        .post<ServerResponse>(
          `${applicationUrls.tci.saveExcelData}`,
          tciInfoDto
        )
        .pipe(
          tap((response) => {
            if (response.data) {
              this.tciService.setTciInfo();
            }
          })
        );
    }

    //#endregion Excel data save for Tci Info

  readTciList(
    page: number,
    size?: number,
    tciInfoSearchingDto? : TciInfoSearchingDto
  ): Observable<ServerResponse> {
    const pageableRequestBodyDTO = {
      occupationNameEnglish: (tciInfoSearchingDto?.occupationNameEnglish ? tciInfoSearchingDto?.occupationNameEnglish : null ),
      level: (tciInfoSearchingDto?.level ? tciInfoSearchingDto?.level : null ),
      registrationNo: (tciInfoSearchingDto?.registrationNo ? tciInfoSearchingDto?.registrationNo : null),
      email: (tciInfoSearchingDto?.email ? tciInfoSearchingDto?.email : null),
      contact: (tciInfoSearchingDto?.contact ? tciInfoSearchingDto?.contact : null),
    };
    const startPage= page - 1;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.tci.getAllTciList}` + '?page='+`${startPage}`+'&size='+`${size}`,
      pageableRequestBodyDTO
    ).pipe(
      tap((response) => {
        if (response.data) {
          this.tciService.setTciInfoList(response.data);
        }
      })
    );
  }

  downloadFreelancerCertificate(id:any): any {
    return this.httpClient.get<Blob>(
      `${applicationUrls.tci.getDownloadFreelancerCertificate}` + id,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  getFreelancerCertificateByEncryptedId(id:any): any {
    const userId = encodeURIComponent(id??"");
    return this.httpClient
    .get<ServerResponse>(`${applicationUrls.tci.getFreelancerCertificateByEncryptedId}` +"?id=" + userId)
    .pipe(
      map((serverResponse: any) => {
        if (!serverResponse.data) {
          return [];
        }
        return serverResponse.data;
      }),
      tap((result: any) => {
        return result.data;
      })
    );
}

sendAllEmail():Observable<ServerResponse>{
  return this.httpClient.get<ServerResponse>(applicationUrls.tci.sendAllEmail);
}

getAllCertificates(
  page: number,
  size?: number,
  tciInfoSearchingDto? : TciInfoSearchingDto
): Observable<ServerResponse> {
  const pageableRequestBodyDTO = {
    occupationNameEnglish: (tciInfoSearchingDto?.occupationNameEnglish ? tciInfoSearchingDto?.occupationNameEnglish : null ),
    level: (tciInfoSearchingDto?.level ? tciInfoSearchingDto?.level : null ),
    registrationNo: (tciInfoSearchingDto?.registrationNo ? tciInfoSearchingDto?.registrationNo : null),
    email: (tciInfoSearchingDto?.email ? tciInfoSearchingDto?.email : null),
    contact: (tciInfoSearchingDto?.contact ? tciInfoSearchingDto?.contact : null),
    certificateSerialNo: tciInfoSearchingDto?.certificateSerialNo
  };
  const startPage= page - 1;
  return this.httpClient.post<ServerResponse>(
    `${applicationUrls.certificates.getAllCertificates}` + '?page='+`${startPage}`+'&size='+`${size}`,
    pageableRequestBodyDTO
  );
}

//#region read certificates count
readCertificatesCount(): Observable<any> {
  return this.httpClient
    .get<ServerResponse>(`${applicationUrls.landingPageCounter.readCertificatesCount}`)
    .pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse.data;
      })
    );
}
//#endregion

}
