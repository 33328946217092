<app-header></app-header>
<main>
  <div class="marketing">
    <section style="background-color: #eee">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-12 col-xl-11">
            <div class="card text-black" style="border-radius: 25px">
              <div class="card-body p-md-5">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-lg-6 col-xl-6 order-2 order-lg-1">
                    <p class="text-center h4 fw-bold mb-6 mx-1 mx-md-4 mt-4">
                      Reset Your Password
                    </p>

                    <form nz-form [formGroup]="validateForm" class="forgotpassword-form" (ngSubmit)="submitForm()">

                      <nz-form-item>
                        <nz-form-label [nzSpan]="12" nzRequired> New Password</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzHasFeedback>
                          <input type="password" (ngModelChange)="validateConfirmPassword()" nz-input
                            formControlName="newPassword" placeholder="New Password" />
                          <ng-template #permissionNameErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please input new password!
                            </ng-container>
                          </ng-template>
                        </nz-form-control>
                      </nz-form-item>

                      <nz-form-item>
                        <nz-form-label [nzSpan]="12" nzRequired>Confirm New Password</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                          <input type="password" formControlName="confirmPassword" nz-input
                            placeholder="Confirm Password" />
                          <ng-template #passwordErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                              Please confirm your password!
                            </ng-container>
                            <ng-container *ngIf="control.hasError('confirm')">
                              Password is inconsistent!
                            </ng-container>
                          </ng-template>
                        </nz-form-control>
                      </nz-form-item>

                      <div style="margin-left: 180px;">
                        <table>
                          <tbody style="width: 100%;">
                            <td style="width: 20%;">
                              <button nz-button type="submit" nzType="primary" [disabled]="!validateForm.valid">
                                <i nz-icon nzType="save"nzTheme="fill"></i>{{isEditMode?'Update':'Submit'}}
                              </button>
                            </td>
                          </tbody>
                        </table>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-footer></app-footer>
