<app-header [hidden]="(!(this.userInfo?.roles == null))"></app-header>

<div class="container-fluid">
  <nz-card>
    <div class="col-md-20 downloadButton">
      <button
        nz-button
        nzType="primary"
        style="float: right; top: -35px;"
        class="border-radius"
        [hidden]="(!tamMain?.isPublished == true) && ((this.userInfo?.roles == null) || (this.userInfo?.roles[0].name === 'User'))"
        (click)="onDownload(tamMain)"
      >
        <i nz-icon nzType="download"></i>
        Download attachment
      </button>
    </div>
    <div class="ng-Header col-xs-15">
      <i nz-icon nzType="form" nzTheme="outline"></i> Training & Assessment Materials (TAM)
    </div>
    <div class="boxAerar">
      <div
        class="row"
        style="margin-left: 20px; padding-top: 20px; padding-right: 20px"
      >
        <nz-descriptions
          nzSize="small"
          [nzColumn]="{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }"
        >
          <nz-descriptions-item nzTitle="ID">{{
            tamMain?.tamApplicationId ? tamMain?.tamApplicationId : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Type">{{
            tamMain?.tamType?.name ? tamMain?.tamType?.name : "N\A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Industry Sector">{{
            tamMain?.tamOccupation?.tamIndustrialSector?.name ? tamMain?.tamOccupation?.tamIndustrialSector?.name : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Occupation">{{
            tamMain?.tamOccupation?.name ? tamMain?.tamOccupation?.name : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Level">{{
            tamMain?.tamLevel?.levelNo ? tamMain?.tamLevel?.levelNo : "N/A"
          }}</nz-descriptions-item>

          <nz-descriptions-item nzTitle="Duration (Hours)">{{
            tamMain?.duration ? tamMain?.duration : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Document Title">{{
            tamMain?.docTitle ? tamMain?.docTitle : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Language">{{
            tamMain?.tamLanguage?.name ? tamMain?.tamLanguage?.name : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Version">{{
            tamMain?.version ? tamMain?.version : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Approval Date">{{
            tamMain?.approvalDate
              ? (tamMain?.approvalDate | date: "dd-MM-YYYY")
              : "N/A"
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Source">{{
            tamMain?.docSource ? tamMain?.docSource : "N/A"
          }}</nz-descriptions-item>

          <nz-descriptions-item nzTitle="Received Date">{{
            tamMain?.receivedDate
              ? (tamMain?.receivedDate | date: "dd-MM-YYYY")
              : "N/A"
          }}</nz-descriptions-item>
          
          <nz-descriptions-item nzTitle="Funded By">{{
            tamMain?.fundedBy ? tamMain?.fundedBy : "N/A"
          }}</nz-descriptions-item>

          <nz-descriptions-item nzTitle="Published?">{{
            tamMain?.isPublished ? "Public" : "Limited"
          }}</nz-descriptions-item>
        </nz-descriptions>
      </div>
    </div>
  </nz-card>
</div>
<div *ngIf="csShow">
  <div
    style="
      text-align: center;
      font-size: large;
      font-weight: bold;
      padding-top: 40px;
    "
  >
    Additional Information for Competency Standard
  </div>
  <div>
    <app-cs-generic-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cs-generic-table>

    <app-cs-sector-specific-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cs-sector-specific-table>

    <app-cs-occupation-specific-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cs-occupation-specific-table>
  </div>
</div>

<div *ngIf="cadShow">
  <div
    style="
      text-align: center;
      font-size: large;
      font-weight: bold;
      padding-top: 40px;
    "
  >
    Additional Information for Course Accreditation Document (CAD)
  </div>
  <div>
    <app-cad-equipment-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-equipment-table>

    <app-cad-measuring-instrument-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-measuring-instrument-table>

    <app-cad-tool-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-tool-table>

    <app-cad-material-consumable-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-material-consumable-table>

    <app-cad-ppe-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-ppe-table>

    <app-cad-furniture-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-furniture-table>

    <app-cad-learning-material-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-learning-material-table>

    <app-cad-training-facility-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-training-facility-table>

    <app-cad-trainer-and-staff-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-trainer-and-staff-table>

    <app-cad-other-requirement-table
      [tamMainId]="tamMainId"
      [createButton]="false"
    ></app-cad-other-requirement-table>
  </div>
</div>
<app-footer [hidden]="(!(this.userInfo?.roles == null))"></app-footer>