import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PasswordChange } from '../../models/password-change.dto';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  pwChange!: PasswordChange;
  changePasswordForm!: FormGroup;
  isEditMode!: boolean;

  oldPasswordVisible = false;
  newPasswordVisible = false;
  confirmPasswordVisible = false;

  constructor(
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      oldPassword: [null, Validators.required],
      newPassword: [null, [Validators.required, 
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%?&^*;:,<>.`~-])[A-Za-z\d$@#$!%?&^*;:,<>.`~-]{8,}$/)
        ]],
      confirmPassword: ['', [
        Validators.minLength(8), this.confirmValidator]]
    });
  }

  submitForm() {
    for (const key of Object.keys(this.changePasswordForm.controls)) {
      this.changePasswordForm.controls[key].markAsDirty();
      this.changePasswordForm.controls[key].updateValueAndValidity();
    }
    this.pwChange = this.changePasswordForm.value;

    this.pwChange.userName = this.authenticationService.CurrentUserValue?.email ?? '';

    this.authenticationService.changePassword(this.pwChange).subscribe({
      next: (response) =>
        this.notification.success('Success!', 'Password successfully changed'),
      error: (error) => this.notification.error('Failed', error.error.details),
    });
    this.resetForm();
  }

  resetForm(): void {
    this.changePasswordForm.reset();
    this.isEditMode = false;
    for (const key of Object.keys(this.changePasswordForm.controls)) {
      this.changePasswordForm.controls[key].markAsPristine();
      this.changePasswordForm.controls[key].updateValueAndValidity();
    }
  }
  validateConfirmPassword(): void {
    setTimeout(() =>
      this.changePasswordForm.controls.confirmPassword.updateValueAndValidity()
    );
  }
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.changePasswordForm.controls.newPassword.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };
}
