import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, Observer } from 'rxjs';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';
import { SelfRegistrationRequest } from '../../models/signup-request.model';
import { StpUsers } from '../../models/stp-users.model';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';

@Component({
  selector: 'app-stp-registration',
  templateUrl: './stp-registration.component.html',
  styleUrls: ['./stp-registration.component.scss'],
})
export class StpRegistrationComponent {
  registrationForm: FormGroup;
  stpUsers!: StpUsers;
  selfRegistrationRequest!: SelfRegistrationRequest;
  regex = /[a-zA-Z]*/;
  // stpNameRegex = /^[a-zA-Z\s][a-zA-Z0-9\s][\w\s.,-,(,)]+$/; //previous pattern for STP Name
  stpNameRegex = /^[a-zA-Z\s][a-zA-Z0-9\s][-'\w\s]+$/; //update pattern for STP Name
  password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%?&^*;:,<>.`~-])[A-Za-z\d$@#$!%?&^*;:,<>.`~-]{8,}$/;

  // for Otp
  tempNumber = 0;
  isVisible = false;
  validateForm!: FormGroup;
  otpForm!: FormGroup;
  // mobile
  contactNumber!: string;
  // otp
  receivedOtp!: string;
  nzFooterVisible = false;
  emailAlredyExist = false;
  stpNameAlredyExist = false;
  display: any;

  passwordVisible = false;
  confirmPasswordVisible = false;

  // Otp section ends
  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private authenticationStorageService: AuthenticationStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private stpRegistrationStorageService: StpRegistrationStorageService
  ) {
    this.registrationForm = this.fb.group({
      stpName: [
        '',
        [
          Validators.required,
          // Validators.pattern(this.stpNameRegex),
          this.noWhitespaceValidator,
        ],
      ],
      stpAuthorizeName: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z\s]+$/),
          // Validators.pattern(
          //   /^[a-zA-Z0-9_ !@#$%^&*()+=\-\[\]\\\';,./{}|\":<>\?]+$/
          // ),
        ],
      ],
      //  userName: ['', [Validators.required], [this.userNameAsyncValidator]],
      nid: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?:\01)?(?:\d{10}|\d{13}|\d{17})$/),
        ],
      ],
      stpDesignation: ['', [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.password),
        ],
      ],
      confirmPassword: ['', [Validators.minLength(8), this.confirmValidator]],
      contactNumberPrefix: ['+880'],
      contactNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern(/^1[3-9][0-9]{8}$/),
        ],
      ],
    });
    // for Otp Form
    this.otpForm = this.fb.group({
      otp: [
        '',
        [Validators.required, Validators.pattern(/^(?:\01)?(?:\d{5})$/)],
      ],
    });
    this.timer(5);
  }

  submitForm(): void {
    for (const key of Object.keys(this.registrationForm.controls)) {
      this.registrationForm.controls[key].markAsDirty();
      this.registrationForm.controls[key].updateValueAndValidity();
    }
    this.showModal();
  }
  saveStpUsers(stpUsers: StpUsers): void {
    this.authenticationStorageService.createStpUser(stpUsers).subscribe({
      next: (serverResponse) => {
        this.registrationForm.reset();
        const returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
        this.router.navigateByUrl(returnUrl);
        this.notification.success(
          'Success!',
          'Please check your email for validation'
        );
      },
      error: (error) => {
        this.notification.error(error, error.message);
      },
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.registrationForm.reset();
    this.registrationForm.get('contactNumberPrefix')?.setValue('+880');
    // this.registrationForm.value.contactNumberPrefix = '+880';

    for (const key of Object.keys(this.registrationForm.controls)) {
      this.registrationForm.controls[key].markAsPristine();
      this.registrationForm.controls[key].updateValueAndValidity();
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.registrationForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.registrationForm.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  // tslint:disable-next-line: typedef
  nidValidation(control: AbstractControl) {
    if (control.value.length <= 10) {
      return { error: true, required: true };
    } else if (control.value.length <= 17) {
      return { error: true, required: true };
    }
    return {};
  }

  // for OTP Modal
  showModal(): void {
    this.contactNumber =
      '0' + this.registrationForm.get('contactNumber')?.value;
    this.stpRegistrationStorageService.getOtp(this.contactNumber).subscribe({
      next: (res) => {
        if (res.success == true) {
          this.isVisible = true;
          this.notification.success('Success!', 'OPT sent successfully');
        } else {
          this.notification.error(
            'Failed!',
            'OTP Sent Failed! Please Try Again later...'
          );
          this.handleCancel();
          this.isVisible = false;
        }
      },
      error: (error) => {
        this.notification.error(
          error,
         'OTP Sent Failed! Please Try Again later...'
        );
        this.handleCancel();
        this.isVisible = false;
      },
    });

    setTimeout(() => {
      this.isVisible = false;
    }, 300000);
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  submitOtp(): void {
    this.receivedOtp = this.otpForm.get('otp')?.value;

    const selfRegistrationRequest = new SelfRegistrationRequest();
    selfRegistrationRequest.email = this.registrationForm.controls.email.value;
    selfRegistrationRequest.contactNumber =
      this.registrationForm.controls.contactNumber.value;
    selfRegistrationRequest.contactNumberPrefix =
      this.registrationForm.controls.contactNumberPrefix.value;
    selfRegistrationRequest.name =
      this.registrationForm.controls.stpAuthorizeName.value;
    selfRegistrationRequest.password =
      this.registrationForm.controls.confirmPassword.value;
    selfRegistrationRequest.nid = this.registrationForm.controls.nid.value;
    selfRegistrationRequest.designation =
      this.registrationForm.controls.stpDesignation.value;
    selfRegistrationRequest.role = 'STP_Master';
    this.stpRegistrationStorageService
      .getVerifiedOtp(this.receivedOtp, this.contactNumber)
      .subscribe({
        next: (response) => {
          this.authenticationStorageService
            .createUser(selfRegistrationRequest)
            .subscribe({
              next: (serverResponse) => {
                this.selfRegistrationRequest = serverResponse.data;
                const stpUsers = new StpUsers();
                stpUsers.userId = this.selfRegistrationRequest.id;
                stpUsers.nid = this.registrationForm.controls.nid.value;
                stpUsers.stpAuthorizeUserName =
                  this.registrationForm.controls.stpAuthorizeName.value;
                stpUsers.stpDesignation =
                  this.registrationForm.controls.stpDesignation.value;
                stpUsers.stpEmail = this.registrationForm.controls.email.value;
                stpUsers.stpMobileNo =
                  this.registrationForm.controls.contactNumber.value;
                stpUsers.stpName = this.registrationForm.controls.stpName.value;

                this.saveStpUsers(stpUsers);
                this.isVisible = false;
              },
              error: (error) => {
                this.notification.error(error, error.message);
              },
            });
        },
        error: (error) => this.notification.error('Failed', 'OTP is not valid'),
      });
    this.otpForm.reset();

    // this.validateForm.reset();
  }

  // tslint:disable-next-line: typedef
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  checkEmailExist(email: any): void {
    this.emailAlredyExist = false;
    setTimeout(() => {
      if (email.value.length > 3) {
        this.authenticationStorageService
          .checkEmailExist(email.value)
          .subscribe({
            next: (res) => {
              if (res.data == null) {
                this.emailAlredyExist = false;
              } else {
                this.emailAlredyExist = true;
                this.registrationForm.controls.email.setErrors({
                  incorrect: true,
                });
              }
            },
          });
      }
    }, 2000);
  }

  checkStpNameExist(stpName: any): void {
    this.stpNameAlredyExist = false;
    setTimeout(() => {
      if (stpName.value.length > 2) {
        this.authenticationStorageService
          .checkStpNameExist(stpName.value)
          .subscribe({
            next: (res) => {
              if (res.data == null) {
                this.stpNameAlredyExist = false;
              } else {
                this.stpNameAlredyExist = true;
                this.registrationForm.controls.stpName.setErrors({
                  incorrect: true,
                });
              }
            },
          });
      }
    }, 2000);
  }

  timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(timer);
      }
    }, 1000);
  }
}
