import { Component, OnInit, VERSION } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import saveAs from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { RegistrationCard } from 'src/app/modules/applicant/models/registrationCard';
import { ApplicantStorageService } from 'src/app/modules/applicant/services/applicant-storage.service';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import { RequestData } from '../../models/stp_onlinePayment.model';
import { StpRegistrationStorageService } from '../../services/stp-registration-storage.service';
import { StpRegistrationService } from '../../services/stp-registration.service';

@Component({
  selector: 'app-registration-card-varify-page',
  templateUrl: './registration-card-varify-page.component.html',
  styleUrls: ['./registration-card-varify-page.component.scss'],
})
export class RegistrationCardVarifyPageComponent implements OnInit {
  requestData: RequestData = new RequestData();
  userInfo: any;
  stpMainId: any;
  tamMainDetails: any;
  id: string;
  stpRegistrationId: string = "RPL-GDF-L3-000001";
  stpDateOfRegistration: string =  "7-Nov-2021";
  nameOfOccupation: string =  "New Occupation";
  occupationLevel: string;
  nameOfApplicant: string;
  motherName: string;
  fatherName: string;
  nid: number;
  dateOfAssessment: string =  "7-Nov-2021";
  venueOfAssessment: string =  "Dhaka";
  photoUrl: string;
  qrCodeUrl: string;
  filePathUrl: string ="http://172.16.28.80:8401/uploadfile?fileUrl=";
  photoUrlApplicant: string;
  signatureUrlApplicant: string;
  isImageLoading: boolean;
  imageURL: SafeUrl;
  signatureURL: SafeUrl;
  registrationCardDetails: RegistrationCard;

  constructor(
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private applicantStorageService: ApplicantStorageService,
    private commonStorageService: CommonStorageService,
    private stpRegistrationService: StpRegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      } else {
        this.id = this.route.snapshot.params.id;
      }

      this.getStpApplicationById();
    });
  }
  
  getStpApplicationById() {
    this.applicantStorageService
      .getRegistrationInformationByTamMainId(this.id)
      .subscribe(
        (res) => {
          if (res.success) {
            this.registrationCardDetails = res.data.data as RegistrationCard;
            if (this.registrationCardDetails?.photoUrl) {
            this.getImageFromService(
              this.registrationCardDetails?.photoUrl , 1
            );
          }

          if (this.registrationCardDetails?.signatureUrl) {
            this.getImageFromService(
              this.registrationCardDetails?.signatureUrl , 2
            );
          }
            console.log("res.data this.id +++++", res.data.data, res);
          } else {
            this.notification.error('Failed', res.message);
          }
        },
        (error) => {
          this.notification.error('Failed', 'could not download registration card');
        }
      );
  }

  // onDownloadRegistrationCard() {
  //   var detailsId = 6019;
  //   this.applicantStorageService
  //     .getRegistrationCardDetails(detailsId)
  //     .subscribe({
  //       next: async (res) => {
  //         if(res.success) {
  //           console.log("onDownloadRegistrationCard data", res.data, res);
  //           this.registrationCardDetails = res.data as RegistrationCard;

  //           this.stpRegistrationService.freelancerRegistrationCard(this.registrationCardDetails);
  //         } else {
  //           this.notification.error('Failed', res.message);
  //         }

  //       },
  //       error : (error) => {
  //         this.notification.error('Failed', 'could not download registration card');
  //       }
  //     });
  // }

  getImageFromService(imageUrl: string, id : number): void {
    this.isImageLoading = true;
    this.commonStorageService.getImage(imageUrl).subscribe(
      (data) => {
        this.createImageFromBlob(data, id);
        this.isImageLoading = false;
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
      );
    }
    createImageFromBlob(image: Blob, id: number) {
      if (image) {
        if( id == 1){
          this.imageURL = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(image)
          );
        }else{
          this.signatureURL = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(image)
          );
        }

    }
  }


}
