import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/modules/authorization/services/authorization.service';

@Directive({
  selector: '[appRequiredPermission]',
})
export class RequiredPermissionDirective implements OnInit {
  @Input('appRequiredPermission') permission = ''; // Required permission passed in

  constructor(
    private el: ElementRef,
    public authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    if(this.permission.includes(',')){
      let tempList = this.permission.split(',');

      if(this.authorizationService.checkIfCurrentUserHaveAnyPermission(tempList) == false){
        this.el.nativeElement.style.display = 'none';
      };
    }else if (
      !this.authorizationService.checkIfCurrentUserHavePermission(
        this.permission
      )
    ) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
