<app-header></app-header>
<div class="container-fluid">
  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="search" nzTheme="outline"></i>
      {{ "Search" | translate }}
    </div>
    <form
      nz-form
      [formGroup]="searchForm"
      class="ant-advanced-search-form"
      (ngSubmit)="submitSearchForm()"
      style="
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      "
    >
      <div class="row">
        <div class="form-group col-md-3">
          <div class="col-md-12">
            <nz-form-label>{{ "Applicant Type" | translate }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                nzHasFeedback
                [nzErrorTip]="applicantTypeErrorTpl"
              >
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="applicantTypeId"
                  nzPlaceHolder="Select one"
                >
                  <nz-option
                    *ngFor="let at of cirApplicantTypeList"
                    [nzValue]="at.id"
                    [nzLabel]="at.name"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #applicantTypeErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select type!
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #fundErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Select type please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Select valid type please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div class="col-md-12">
            <nz-form-label>{{
              "Max Course in Application" | translate
            }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                nzHasFeedback
                [nzErrorTip]="maxCourseInApplicationErrorTpl"
              >
                <input
                  nz-input
                  type="text"
                  formControlName="maxCourseInApplication"
                  placeholder="Insert max course"
                />
              </nz-form-control>
              <ng-template #maxCourseInApplicationErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Insert max course please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Insert valid max course please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <!-- <div class="form-group col-md-4">
          <div class="col-md-12">
            <nz-form-label>{{ "Organization Type" | translate }}</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback [nzErrorTip]="orgTypeErrorTpl">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  formControlName="orgTypeId"
                  nzPlaceHolder="Select one"
                >
                  <nz-option
                    *ngFor="let org of cirOrganizationTypeList"
                    [nzValue]="org.id"
                    [nzLabel]="org.name"
                  >
                  </nz-option>
                </nz-select>
                <ng-template #orgTypeErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please select type!
                  </ng-container>
                </ng-template>
              </nz-form-control>
              <ng-template #fundErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Select type please!
                </ng-container>
                <ng-container *ngIf="control.hasError('whitespace')"
                  >Select valid type please!</ng-container
                >
              </ng-template>
            </nz-form-item>
          </div>
        </div> -->
        <div class="form-group col-md-3">
          <nz-form-label style="margin-left: 15px">{{
            "Circular Start Date" | translate
          }}</nz-form-label>
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                [nzErrorTip]="circularStartDateErrorTpl"
              >
                <nz-date-picker
                  formControlName="circularStartDate"
                  placeholder="Start Date"
                  style="width: 100%"
                  nzFormat="dd-MM-yyyy"
                >
                </nz-date-picker>
              </nz-form-control>
              <ng-template #circularStartDateErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please select start date!
                </ng-container>
                <ng-container *ngIf="control.errors?.dateValidator">
                  Start date can not be greater than End Date
                </ng-container>
              </ng-template>
            </nz-form-item>
          </div>
        </div>
        <div class="form-group col-md-3">
          <nz-form-label style="margin-left: 15px">{{
            "Circular End Date" | translate
          }}</nz-form-label>
          <div class="col-md-12">
            <nz-form-item>
              <nz-form-control
                [nzSpan]="null"
                nzHasFeedback
                nz-col
                [nzErrorTip]="circularEndDateErrorTpl"
              >
                <nz-date-picker
                  formControlName="circularEndDate"
                  placeholder="End Date"
                  style="width: 100%"
                  nzFormat="dd-MM-yyyy"
                >
                </nz-date-picker>
              </nz-form-control>
              <ng-template #circularEndDateErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please select end date!
                </ng-container>
              </ng-template>
            </nz-form-item>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="col-md-12">
            <nz-form-label>{{ "Filter" | translate }}</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  type="text"
                  placeholder="Enter Text"
                  formControlName="globalText"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div> -->

      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area text-right">
          <button nz-button [nzType]="'primary'">
            {{ "Search" | translate }}
          </button>
          <button class="btn-success ml-3" nz-button (click)="onRefresh()">
            {{ "Refresh Data" | translate }}
          </button>
          <!-- <button
            type="button"
            class="btn-info ml-3"
            (click)="onDownloadReport()"
            nz-button
          >
            Download Circular
          </button> -->
        </div>
      </div>
    </form>
  </nz-card>

  <nz-card>
    <div class="ng-Header col-xs-12">
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
      {{ "List of Circular" | translate }}
    </div>
    <div nz-col [nzSpan]="24">
      <nz-table
        #basicTableForCircularListInfo
        [nzData]="circularListInfo"
        nzTableLayout="fixed"
        nzShowSizeChanger
        nzBordered
        nzSize="middle"
        nzAlign="middle"
        [nzFrontPagination]="false"
        [nzTotal]="total"
        [(nzPageSize)]="size"
        [nzShowTotal]="totalRowRangeTemplate"
        [(nzPageIndex)]="page"
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzPaginationPosition]="'both'"
        [nzScroll]="{ x: '1100px' }"
      >
        <ng-template
          #totalRowRangeTemplate
          let-range="range"
          let-total
          style="text-align: left"
        >
          <div style="text-align: left">
            Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
          </div>
        </ng-template>
        <thead>
          <tr>
            <th style="width: 50px; text-align: center" rowspan="1">SI.</th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="generated_circular_id"
              [nzSortFn]="true"
              nzLeft
            >
              Circular ID
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="applicant_type_id"
              [nzSortFn]="true"
            >
              Applicant Type
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="max_course_in_application"
              [nzSortFn]="true"
            >
            Applicable Number of Courses
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="circular_start_date"
              [nzSortFn]="true"
            >
              Circular Start Date
            </th>
            <th
              style="text-align: center"
              rowspan="1"
              nzColumnKey="circular_end_date"
              [nzSortFn]="true"
            >
              Circular End Date
            </th>
            <th style="width: 15%; text-align: center" nzRight>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let data of basicTableForCircularListInfo.data;
              let i = index
            "
          >
            <td style="text-align: center">
              {{ (page - 1) * size + 1 + i }}
            </td>
            <td style="text-align: center; vertical-align: middle" nzLeft>
              {{ data.generatedCircularId }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.cirApplicantType?.name }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.maxCourseInApplication }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.circularStartDate | date : "dd-MM-yyyy" }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ data.circularEndDate | date : "dd-MM-yyyy" }}
            </td>
            <td style="text-align: center; vertical-align: middle" nzRight>
              <!-- <a
                  class="ml-2 mt-2"
                  nz-tooltip
                  nzTooltipTitle="View in Details"
                  nzTooltipPlacement="bottomLeft"
                  nz-button
                  nzType="primary"
                  [routerLink]="['../document-view']"
                  [queryParams]="{ researchStudiesMainId: data.id }"
                  target="_blank"
                  routerLinkActive="router-link-active"
                  style="margin: 1%"
                  [appRequiredPermission]="
                    applicationPermissions.researchAndStudies
                      .adminListViewButtonPermission
                  "
                >
                  <i nz-icon nzType="eye" nzTheme="outline"></i>
                </a> -->

              <a
                nz-button
                nzType="primary"
                (click)="onViewButtonClick(data.id)"
                routerLinkActive="router-link-active"
                nz-tooltip
                [nzTooltipTitle]="'View Attachment'"
                style="margin: 1%"
                nzTooltipPlacement="bottomLeft"
                ><i nz-icon nzType="eye" nzTheme="outline"></i>
              </a>
              <a
              *ngIf="data?.cirRelatedAttachmentsList?.length != 0"
              nz-button
              nzType="primary"
              (click)="
                downloadZipAttachment(
                  data?.generatedCircularId,
                  data?.cirRelatedAttachmentsList
                )
              "
              routerLinkActive="router-link-active"
              style="margin: 1%"
              nz-tooltip
              [nzTooltipTitle]="'Download Attachment'"
              nzTooltipPlacement="bottomLeft"
              ><i nz-icon nzType="download" nzTheme="outline"></i>
            </a>
              <a
                nz-button
                type="button"
                nzType="primary"
                [routerLink]="['/login']"
                target="_blank"
                routerLinkActive="router-link-active"
                style="margin: 1%"
                nz-tooltip
                [nzTooltipTitle]="'Click to Apply'"
                nzTooltipPlacement="bottomLeft"
                style="margin: 1%"
              >
                <i nz-icon nzType="login" nzTheme="outline"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzClosable]="false"
  [nzOkText]="null"
  [nzWidth]="1200"
>
  <ng-container *nzModalContent>
    <iframe
      *ngIf="!isImageLoading && pdfUrl"
      [src]="pdfUrl"
      style="width: 100%; height: 35vw"
    ></iframe>
    <div style="text-align: center">
      <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
      <ng-template #noImageFound>
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
          alt="Fallbackimage"
        />
      </ng-template>
    </div>
  </ng-container>
</nz-modal>
<app-footer></app-footer>
