import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import {
  ErrorHandler,
  HttpErrorHandler,
} from 'src/app/shared/services/http-error-handler.service';
import { PdfGenerateService } from 'src/app/shared/services/pdf-generate.service';
import { ServerResponse } from '../../../shared/models/dto/server-response.dto';
import { AuthorizationService } from '../../authorization/services/authorization.service';
import { RegistrationCard } from '../models/registrationCard';
import { ApplicantService } from './applicant.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicantStorageService {
  private handleError: ErrorHandler;
  constructor(
    private httpClient: HttpClient,
    private applicantService: ApplicantService,
    private authorizationService: AuthorizationService,
    private httpErrorHandler: HttpErrorHandler,
    private notification: NzNotificationService,
    private pdfGenerateService: PdfGenerateService
  ) {
    this.handleError =
      this.httpErrorHandler.createErrorHandler('Freelancer Service');
  }

  checkEmailExist(email: string): Observable<ServerResponse> {
    const params = new HttpParams().append('email', `${email}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.freelancerUserVerification.checkEmailExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  checknameExist(name: string): Observable<ServerResponse> {
    const params = new HttpParams().append('name', `${name}`);
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.freelancerUserVerification.checkNameExist}`,
        {
          params,
        }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  createUser(newUserInfo: any) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.freelancerUser.register}`,
        newUserInfo
      )
      .pipe(
        catchError(this.handleError<never>('Create User')),
        map((serverResponse: ServerResponse) => {
          console.log('map: ', serverResponse);
          return serverResponse;
        }),
        tap(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log('error result in tap', error);
          }
        )
      );
  }

  createFreelancerUser(newUserInfo: any): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.freelancerUser.createFreelancerUser}`,
        newUserInfo
      )
      .pipe(
        catchError(this.handleError<never>('Create Freelancer User')),
        map((serverResponse: ServerResponse) => {
          console.log('map: ', serverResponse);
          return serverResponse.data;
        }),
        tap(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log('error result in tap', error);
          }
        )
      );
  }

  //#region OTP

  getVerifiedOtp(
    otpNumber: string,
    contactNumber: string
  ): Observable<ServerResponse> {
    const params = new HttpParams()
      .append('mobileNo', `${contactNumber}`)
      .append('otp', `${otpNumber}`);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.freelancerOtp.readOtp}`, {
        params,
      })
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  getOtp(contactNumber: string): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.freelancerOtp.readContact + contactNumber}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  //#endregion OTP

  //#region Applicant Short List

  readApplicantshortList(
    page: number,
    size: number,
    dataSet: any,
    sortingKey?: string,
    sortingValue?: string
  ): Observable<ServerResponse> {
    // const startPage = page - 1;
    // let param: string = '';
    // if (dataSet.tamMainId) {
    //   param += '&tamMainId=' + `${dataSet.tamMainId}`;
    // }
    // if (dataSet.nameEnglish) {
    //   param += '&nameEnglish=' + `${dataSet.nameEnglish}`;
    // }
    // if (dataSet.applicationId) {
    //   dataSet.applicationId = encodeURIComponent(dataSet.applicationId??"");
    //   param += '&applicationId=' + `${dataSet.applicationId}`;
    // }
    // if (dataSet.isPayment == 0 || dataSet.isPayment == 1) {
    //   param += '&isPayment=' + `${dataSet.isPayment}`;
    // }
    // if (dataSet.applicationStatus) {
    //   param += '&statusId=' + `${dataSet.applicationStatus}`;
    // }
    // param += '&type=' + `${dataSet.type}`;
    // return this.httpClient.get<ServerResponse>(
    //   `${applicationUrls.freelancer.readApplicantShortList}` +
    //     '?page=' +
    //     `${startPage}` +
    //     '&size=' +
    //     `${size}` +
    //     param
    // );

    let paymentValue = null;
    if(dataSet?.isPayment == 0){
      paymentValue = false;
    }else if(dataSet?.isPayment == 1){
      paymentValue = true;
    }
    let applicantRequestDTO = {
      page: page - 1,
      size: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      tamMainId: (dataSet?.tamMainId ? dataSet?.tamMainId : null),
      nameEnglish: (dataSet?.nameEnglish ? dataSet?.nameEnglish : null),
      applicationId: (dataSet?.applicationId ? encodeURIComponent(dataSet.applicationId??"") : null),
      isPayment: paymentValue,
      applicationStatus: (dataSet?.applicationStatus ? dataSet?.applicationStatus : null),
      type: (dataSet?.type  ? dataSet?.type : null),
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.freelancer.readApplicantShortList}`,
      applicantRequestDTO
    );
  }

  readApplicantOccupations(occupationName: string, tamType?: number): Observable<ServerResponse> {
    let url = applicationUrls.tamMain.readTamMains;
    if (occupationName && tamType) {
      url += '?occupationName=' + occupationName + '&tamType=' + tamType;
    } else if (occupationName) {
      url += '?occupationName='+ occupationName;
    } else if (tamType) {
      url += '?tamType=' + tamType;
    }
    return this.httpClient
      .get<ServerResponse>(
        // `${applicationUrls.freelancer.readApplicantOccupations}`
        url
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.applicantService.setOccupationList(response.data);
          }
        })
      );
  }
  //#endregion Applicant Short List
  //#Application_skill_certificate
  createApplicationSkillCertificate(
    tamMain: FormData
  ): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.applicationSkillCertificate.create}`,
        tamMain
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  getApplicationSkillCertificateByAclUserId(
    aclUserId: number
  ): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applicationSkillCertificate.readByAclUserId}` +
          aclUserId
      )
      .pipe(
        map((serverResponse) => {
          return serverResponse;
        })
      );
  }

  getApplicationSkillCertificateByApplicationDetailsID(
    applicationDetailsID: number
  ): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applicationSkillCertificate.applicationDetailsID}` +
          applicationDetailsID
      )
      .pipe(
        map((serverResponse) => {
          return serverResponse;
        })
      );
  }

  getApplicationSkillCertificateByProfileApplicantId(
    profileApplicantId: number
  ): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applicationSkillCertificate.readByprofileApplicantId}` +
          profileApplicantId
      )
      .pipe(
        map((serverResponse) => {
          return serverResponse;
        })
      );
  }

  readReligions(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applicationSkillCertificate.readReligion}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readNationalitys(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.applicationSkillCertificate.readNationalitys}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  // getImage(): Observable<File> {
  //   return this.httpClient
  //     .get(`${applicationUrls.applicationSkillCertificate.readReligion}`, {
  //       responseType: 'blob',
  //     })
  //     .map((res: Response) => res.blob);
  // }

  //#region Applicant Short List Approval
  updateApplicationApproval(data: any): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.freelancer.applicantApprovalUpdate}`,
      data
    );
  }
  //#endregion Applicant Short List Approval

  //#region START Applicant Short List

  readApplicantAppliedList(
    page: number,
    size: number,
    dataSet: any,
    sortingKey?: string,
    sortingValue?: string
  ): Observable<ServerResponse> {
    console.log('dataSet', dataSet);

    // const startPage = page - 1;
    // let param: string = '';
    // if (dataSet.tamMainId) {
    //   param += '&tamMainId=' + `${dataSet.tamMainId}`;
    // }
    // if (dataSet.nameEnglish) {
    //   param += '&nameEnglish=' + `${dataSet.nameEnglish}`;
    // }
    // if (dataSet.applicationId) {
    //   param += '&applicationId=' + `${dataSet.applicationId}`;
    // }
    // if (dataSet.isPayment == 0 || dataSet.isPayment == 1) {
    //   param += '&isPayment=' + `${dataSet.isPayment}`;
    // }
    // if (dataSet.applicationStatus) {
    //   param += '&statusId=' + `${dataSet.applicationStatus}`;
    // }
    // if (dataSet.userId) {
    //   param += '&aclUserId=' + `${dataSet.userId}`;
    // }
    // param += '&type=' + `${dataSet.type}`;

    let paymentValue = null;
    if(dataSet?.isPayment == 0){
      paymentValue = false;
    }else if(dataSet?.isPayment == 1){
      paymentValue = true;
    }
    let applicantRequestDTO = {
      page: page - 1,
      size: size,
      sortingKey: sortingKey ? sortingKey : null,
      sortingValue: sortingValue ? sortingValue : null,
      tamMainId: (dataSet?.tamMainId ? dataSet?.tamMainId : null),
      nameEnglish: (dataSet?.nameEnglish ? dataSet?.nameEnglish : null),
      applicationId: (dataSet?.applicationId ? encodeURIComponent(dataSet.applicationId??"") : null),
      isPayment: paymentValue,
      applicationStatus: (dataSet?.applicationStatus ? dataSet?.applicationStatus : null),
      type: (dataSet?.type  ? dataSet?.type : null),
      aclUserId: (dataSet?.userId  ? dataSet?.userId : null),
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.freelancer.readApplicantShortList}`,
      applicantRequestDTO
    );
  }
  //#region END Applicant Short List

  //#region Applicant Profile
  readApplicantProfileById(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.user.readUserById}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  //#endregion Applicant Profile

  // required for certificate generation
  getRegistrationCardDetails(detailsId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${
          applicationUrls.registrationCard.registrationCardGenerateApi +
          '/' +
          detailsId
        }`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          // console.log(serverResponse.data);
          return serverResponse;
        })
      );
  }

  //#start region Applicant download registration card
  downloadRegistrationCard(detailsId: number) {
    // var detailsId = 6335;
    this.getRegistrationCardDetails(detailsId).subscribe({
      next: async (res) => {
        if (res.success) {
          let data = res.data as RegistrationCard;

          this.pdfGenerateService.freelancerRegistrationCardForRplIndividuals(data);
        } else {
          this.notification.error('Failed', res.message);
        }
      },
      error: (error) => {
        this.notification.error(
          'Failed',
          'could not download registration card'
        );
      },
    });
  }
  //#END region Applicant download registration card

  getRegistrationInformationByTamMainId(id: string): Observable<any> {
    const encodeId = encodeURIComponent(id??"");

    return this.httpClient
      .get<ServerResponse>(
        `${
          applicationUrls.registrationCard.readByTamMainDecreptionId +
          '?id=' +
          encodeId
        }`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
}
