import { Component } from '@angular/core';

@Component({
  templateUrl: 'not-permitted.component.html'
})
export class NotPermittedComponent {

  constructor() { }

}
