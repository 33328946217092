import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tci-table',
  templateUrl: './tci-table.component.html',
  styleUrls: ['./tci-table.component.scss']
})
export class TciTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
