import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { RequestData } from 'src/app/modules/stp-registration/models/stp_onlinePayment.model';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLoginHidden: boolean = false;
  isProfileHidden: boolean = true;
  requestData: RequestData = new RequestData();
  userInfo: any;
  napUserInfo: any;
  stpMainId: any;
  currentUserInfo: any;
  visible:boolean = false

  constructor(
    private authenticationService: AuthenticationService,
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private router: Router
  ) {
    this.checkUserAlreadyLogedin();
  }

  ngOnInit() {
    this.userId();

    if (window.screen.width > 768) { // 768px portrait
      this.visible = true;
    }
  }

  ngOnDestroy(): void {
  }

  toggleMobileMenu()
  {
    this.visible = !this.visible
  }


  checkUserAlreadyLogedin(): void {
    const currentUser = this.authenticationService.CurrentUserValue;
    if (currentUser?.id) {
      this.isLoginHidden = true;
      this.isProfileHidden = false;
    } else if(currentUser?.userId) {
      this.isLoginHidden = true;
      this.isProfileHidden = false;
    } else {
      this.isLoginHidden = false;
      this.isProfileHidden = true;
    }
  }

  onLogout(): void {
    this.isLoginHidden = false;
    this.isProfileHidden = true;
    this.currentUserInfo = null;
    this.authenticationService.logout();

    if(this.userInfo != null){
      this.router.navigate(['/login']);
    } else if(this.napUserInfo != null){
      this.router.navigate(['/NAP-login']);
    } else{
      this.router.navigate(['/']);
    }
  }


  tciCertificate(): void {
    this.router.navigate(['/home/tci/tci-shared-list']);
  }


  userId() {
    this.currentUserInfo = localStorage.getItem('currentUserInfo');
    this.userInfo = JSON.parse(this.currentUserInfo) || null;

    //For NAP User Info
    if(this.userInfo == null){
      this.currentUserInfo = localStorage.getItem('NAPUserInfo');
      this.napUserInfo = JSON.parse(this.currentUserInfo) || null;
    }
    //For NAP User Info

    if (this.userInfo == null) {
      this.userInfo = null;
    } else {
      this.requestData.id = this.userInfo.id;
      this.onUserFullInformationRequest();
    }
  }

  onUserFullInformationRequest() {
    this.stpRegistrationStorageService
      .stpMainIdByUserId(this.requestData)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.stpMainId = res.data;
          }
        },
      });
  }

  downloadCertificate(){
    this.router.navigate(['tci/download-certificate-portal']);
  }

  sendEmail(){
    this.router.navigate(['tci/send-email']);
  }
}
