import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { IndustrySkillsCouncilService } from '../../services/industry-skills-council.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-published-isc-director-list',
  templateUrl: './published-isc-director-list.component.html',
  styleUrls: ['./published-isc-director-list.component.scss']
})
export class PublishedIscDirectorListComponent implements OnInit {

  publishedIscDirectorList: any;
  iscId: number;

  constructor(
    private notification: NzNotificationService,
    private IndustrySkillsCouncilService: IndustrySkillsCouncilService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.iscId = this.activatedRoute.snapshot.params.id;

    if(this.iscId != null){
      this.getPublishedIscDirectorListByIscId(this.iscId);
    }
  }

  getPublishedIscDirectorListByIscId(iscId: number) {
    this.IndustrySkillsCouncilService.getPublishedIscDirectorListByIscId(iscId)
      .subscribe({
        next: (res) => {
          this.publishedIscDirectorList = res?.data;
        },
        error: (error) => this.notification.error('Failed', error.error.message)
      });
  }
}
