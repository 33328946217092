import { ReCaptchaService } from './../services/re-captcha.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {

  isVisible: boolean = true;

  siteKey = environment.recaptcha.siteKey;

  recaptchaForm = this.fb.group({
    recaptcha: ['', Validators.required]
  });  

  @Output() verifyStatus: EventEmitter<{success:boolean,message:string}> = new EventEmitter<{success:boolean,message:string}>();

  constructor(
    private notification: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private reCaptchaService: ReCaptchaService
  ) { }

  ngOnInit(): void {
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  resolved(captchaResponse: string) {
   // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.verifyCaptcha(captchaResponse);
  }

  verifyCaptcha(captchaResponse: string) {
    this.reCaptchaService.verifyRecaptcha({ captchaResponse: captchaResponse })
      .subscribe(
        (res: any) => {
          if (res.success) {                      
            this.isVisible = false;
          } else {           
            this.isVisible = true;;
          }

          this.verifyStatus.emit({success: res.success,message:res.message});
        },

        (err) => {
          this.notification.error("Error", "Something went wrong while verifying captcha");
        })

  }

}
