import { LoginResponse } from '../models/login-response.dto';
import { ServerResponse } from './../../../shared/models/dto/server-response.dto';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { applicationUrls } from '../../../shared/application-constants/application-urls.const';
import { PasswordChange } from '../models/password-change.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<LoginResponse | null>;
  public currentUser: Observable<LoginResponse | null>;
  private isAuthenticated = false;
  private token: string | null = '';
  private tokenTimer: any;
  private userId!: number | null;


  // publishCertificate : EventEmitter<boolean> = new EventEmitter<boolean>();

  // setPublishCertificate(status: boolean):void{
  //   this.publishCertificate.emit(status);
  // }

  // getPublishCertificate():EventEmitter<boolean>{
  //   return this.publishCertificate;
  // }

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse | null>(
      JSON.parse(localStorage.getItem('currentUserData') ?? '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getToken(): string | null {
    return this.token;
  }

  getNAPToken(): string | null {
    return localStorage.getItem("NAPUserToken");
  }

  getIsUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get CurrentUserValue(): LoginResponse | null {
    return this.currentUserSubject.value;
  }

  public saveCurrentUserInfo(currentUserInfo: LoginResponse): void {
    const tokenExpiresIn = currentUserInfo.tokenDuration;
    this.setAutoLogoutTimer(tokenExpiresIn);

    const now = new Date();
    const tokenExpirationDate = new Date(now.getTime() + tokenExpiresIn * 1000);

    currentUserInfo.tokenExpirationDate = tokenExpirationDate;

    this.currentUserSubject.next(currentUserInfo);
    localStorage.setItem('currentUserInfo', JSON.stringify(currentUserInfo));
    this.token = currentUserInfo.token;
    this.isAuthenticated = true;
    this.userId = currentUserInfo.id;
  }

  public saveNAPUserInfo(currentUserInfo: any): void {
    const tokenExpiresIn = 22000;
    this.setNAPAutoLogoutTimer(tokenExpiresIn);

    const now = new Date();
    const tokenExpirationDate = new Date(now.getTime() + tokenExpiresIn * 1000);

    currentUserInfo.tokenExpirationDate = tokenExpirationDate;

    this.currentUserSubject.next(currentUserInfo);
    localStorage.setItem('NAPUserInfo', JSON.stringify(currentUserInfo));
    localStorage.setItem('NAPUserToken', currentUserInfo?.accessToken);
    localStorage.setItem('userId', currentUserInfo?.userId);

    this.isAuthenticated = true;
    this.userId = currentUserInfo?.userId;
  }

  logout(): void {
    this.token = null;
    this.isAuthenticated = false;
    this.currentUserSubject.next(null);
    this.userId = null;
    clearTimeout(this.tokenTimer);
    this.clearCurrentUserInfo();
    this.clearNAPUserInfo();

    this.router.navigate(['/']);
  }

  NAPLogout(): void {
    this.token = null;
    this.isAuthenticated = false;
    this.currentUserSubject.next(null);
    this.userId = null;
    clearTimeout(this.tokenTimer);
    this.clearCurrentUserInfo();
    this.clearNAPUserInfo();

    this.router.navigate(['/NAP-login']);
  }

  public clearCurrentUserInfo(): void {
    localStorage.removeItem('currentUserInfo');
  }

  public clearNAPUserInfo(): void {
    localStorage.removeItem('NAPUserInfo');
    localStorage.removeItem('NAPUserToken');
    localStorage.removeItem('userId');
  }

  public getCurrentUserInfo(): LoginResponse | null {
    const currentUserInfo: LoginResponse = JSON.parse(
      localStorage.getItem('currentUserInfo') ?? '{}'
    );

    if (!currentUserInfo) {
      return null;
    }

    currentUserInfo.tokenExpirationDate = new Date(
      currentUserInfo.tokenExpirationDate
    );
    return currentUserInfo;
  }

  public getNAPUserInfo(): LoginResponse | null {
    const currentUserInfo: LoginResponse = JSON.parse(
      localStorage.getItem('NAPUserInfo') ?? '{}'
    );

    if (!currentUserInfo) {
      return null;
    }

    currentUserInfo.tokenExpirationDate = new Date(
      currentUserInfo.tokenExpirationDate
    );
    return currentUserInfo;
  }

  public setAutoLogoutTimer(duration: number): void {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  public setNAPAutoLogoutTimer(duration: number): void {
    this.tokenTimer = setTimeout(() => {
      this.NAPLogout();
    }, duration * 1000);
  }

  sendEmail(email: string): Observable<any> {
    return this.http
      .post<ServerResponse>(`${applicationUrls.user.forgotpassword}`, email)
      .pipe(
        map((serverResponse: ServerResponse) => {
          localStorage.setItem('changePasswordToken', serverResponse.data);
          return serverResponse.data;
        })
      );
  }

  sendNapEmail(email: string): Observable<any> {
    return this.http
      .post<ServerResponse>(`${applicationUrls.agencyUser.forgotpassword}`, email)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        })
      );
  }

  resetPassword(resetPassword: any, newToken: string): Observable<any> {
    const params = new HttpParams().set('newToken', newToken ?? '');
    return this.http.put<any>(
      `${applicationUrls.user.resetpassword}`,
      resetPassword,
      { params }
    );
  }

  resetNapPassword(resetPassword: any, agencyUserId: string): Observable<any> {
    const params = new HttpParams().set('id', agencyUserId ?? '');
    return this.http.put<any>(`${applicationUrls.agencyUser.resetpassword}`, resetPassword, { params });
  }

  changePassword(passwordChangeRequest: PasswordChange): Observable<any> {
    return this.http.put<any>(
      `${applicationUrls.changePassword.update}`,
      passwordChangeRequest
    );
  }

  changeNAPPassword(passwordChangeRequest: any): Observable<any> {
    return this.http.post<any>(
      `${applicationUrls.changeNAPPassword.change}`,
      passwordChangeRequest
    );
  }
}
