import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-nap-change-password',
  templateUrl: './nap-change-password.component.html',
  styleUrls: ['./nap-change-password.component.scss']
})
export class NAPChangePasswordComponent implements OnInit {
  pwChange: any;
  changePasswordForm!: FormGroup;
  userInfo: any;
  isCollapsed = false;
  passwordVisible = false;
  reTypePasswordVisible = false;

  constructor(
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      oldPassword: [null, [Validators.required ]],
      newPassword: [null, [Validators.required ]],
      confirmPassword: ['', [Validators.required, this.confirmValidator]]
    });
  }

  submitForm() {
    for (const key of Object.keys(this.changePasswordForm.controls)) {
      this.changePasswordForm.controls[key].markAsDirty();
      this.changePasswordForm.controls[key].updateValueAndValidity();
    }
    this.pwChange =  this.changePasswordForm.value;

    this.authenticationService.changeNAPPassword(this.pwChange).subscribe({
      next: (response) =>{
        if(response.success == 'true'){
          this.notification.success('Success!', response?.message)
        }else{
          this.notification.warning('Warning!', response?.message)
        }
      },
      error: (error) =>{
        this.notification.error('Failed', error)
      }
    });
    this.resetForm();
  }

  resetForm(): void {
    this.changePasswordForm.reset();
    for (const key of Object.keys(this.changePasswordForm.controls)) {
      this.changePasswordForm.controls[key].markAsPristine();
      this.changePasswordForm.controls[key].updateValueAndValidity();
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.changePasswordForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.changePasswordForm.controls.newPassword.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };
}
