<nz-layout class="app-layout">
  <nz-sider
    style="background: white"
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="container-fluid d-flex justify-content-between bd-highlight">
      <div class="p-2 bd-highlight">
        <div class="d-flex flex-row">
          <div>
            <a href="#">
              <img src="assets/images/Group 98.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li>
        <ul>
          <li nz-menu-item>
            <a routerLink="/"
              ><i nz-icon nzType="home" nzTheme="outline"></i>Home</a
            >
          </li>
        </ul>
        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/nap-agency-dashboard"
              ><i nz-icon nzType="profile" nzTheme="outline"></i>Dashboard</a
            >
          </li>
        </ul>
      </li>

      <li
        nz-submenu
        nzIcon="application"
        nzClose
        nzIcon="profile"
        nzTitle="NAP"
      >

        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/add-NAP-target"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Add NAP Target</a
            >
          </li>
        </ul>
        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/add-training-target"
              ><i nz-icon nzType="form" nzTheme="outline"></i>Add Training Target</a
            >
          </li>
        </ul>
        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/submit-nap-report"
              ><i nz-icon nzType="check-circle" nzTheme="outline"></i>Submit NAP Report</a
            >
          </li>
        </ul>
        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/submit-training-report"
              ><i nz-icon nzType="check-circle" nzTheme="outline"></i>Submit Training Report</a
            >
          </li>
        </ul>
        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/adjustment-nap-target"
              ><i nz-icon nzType="edit" nzTheme="outline"></i>NAP Target Adjustment</a
            >
          </li>
        </ul>
        <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/adjustment-training-target"
              ><i nz-icon nzType="edit" nzTheme="outline"></i>Training Target Adjustment</a
            >
          </li>
        </ul>
        <!-- <ul>
          <li nz-menu-item>
            <a routerLink="/NAP-home/national-action-plan/generate-report"
              ><i nz-icon nzType="download" nzTheme="outline"></i>Generate Report</a
            >
          </li>
        </ul> -->
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>
        <ul
          class="mr-10"
          nz-menu
          nzMode="horizontal"
          style="float: right; margin-right: 80px"
        >
          <li nz-submenu nzClose nzTitle="Profile" nzIcon="user">
            <ul>
              <li nz-menu-item>
                <a routerLink="/NAP-home/national-action-plan/organization-profile"
                  ><i nz-icon nzType="form" nzTheme="outline"></i>Profile Update</a
                >
              </li>
            </ul>
           <ul style="float: left; margin-right: 2px">
              <li nz-menu-item (click)="onChangePassword()">
                <i nz-icon nzType="setting" nzTheme="outline"></i>Change
                Password
              </li>
            </ul>

             <!-- <ul>
              <li nz-menu-item (click)="userProfile()">
                <i nz-icon nzType="user" nzTheme="outline"></i>Profile
              </li>
            </ul> -->
            <ul>
              <li nz-menu-item (click)="onNAPLogout()">
                <i nz-icon nzType="logout" nzTheme="outline"></i>Logout
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <!-- <nz-footer>Synesis It ©2021 </nz-footer> -->
    <app-footer></app-footer>
  </nz-layout>
</nz-layout>
