import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-nap-forgot-password',
  templateUrl: './nap-forgot-password.component.html',
  styleUrls: ['./nap-forgot-password.component.scss']
})
export class NapForgotPasswordComponent implements OnInit {

  validateForm!: FormGroup;
  isEditMode!: boolean;
  emailExist: boolean = true;
  isSubmit: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private authenticationStorageService: AuthenticationStorageService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    for (const key of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    this.authenticationService.sendNapEmail(this.validateForm.value.email).subscribe({
      next: (response) =>{
        this.resetForm();
        this.notification.success('Success!', 'Please Check your mail to reset your password !');
      },
      error: (error) =>{
        this.notification.error('Failed', 'Please provide your valid E-mail !');
      }
    });
  }

  resetForm(): void {
    this.validateForm.reset();
    this.isEditMode = false;

    for (const key of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  checkNapEmailExist(email: any): void {
    this.emailExist = false;
    this.isSubmit = false;

    setTimeout(() => {
      if (email.value.length > 3) {
        this.authenticationStorageService.checkNapEmailExist(email.value)
          .subscribe({
            next: (res) => {
              if (res?.body == '' || res?.body == null) {
                this.emailExist = false;
              } else {
                this.emailExist = true;
                this.isSubmit = true;
              }
            },
          });
      }
    }, 2000);
  }
}
