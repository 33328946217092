export class TciInfoDto {
  nameEnglish: string;
  nameBangla: string;
  fatherNameEnglish: string;
  fatherNameBangla: string;
  motherNameEnglish: string;
  motherNameBangla: string;
  chairmanEnglishName: string;
  chairmanBanglaName: string;
  chairmanEnglishDesignation: string;
  chairmanBanglaDesignation: string;

  nidEnglish: string;
  nidBangla: string;

  applicationId: string;
  certificateSerialNo: string;
  registrationNo: string;
  issueDate: Date;
  validityDate: Date;

  occupationNameEnglish: string;
  occupationNameBangla: string;

  level: number;
  email: string;
  contact: string;
  id?: number;
  certificateType: number;
  isCompetent: boolean;

  tciTrainingDetailsPayload: TciTrainingDetailsPayload[];
}

export class TciTrainingDetailsPayload {
  trainingName: string;
  titleName: string;
}
