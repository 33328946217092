import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  // public allowedExtensions = environment.validation.allowedPaymentExtensions;
  constructor() {}

  validateInputFile(
    files: FileList,
    allowedExtensions: string,
    maxFileSize: number
  ): string {
    if (!files || files.length <= 0) {
      return 'File is required !';
    }
    const fileExtension = files[0].name.substring(
      files[0].name.lastIndexOf('.')
    );

    // alert(JSON.stringify(fileExtension));
    if (!allowedExtensions.split(',').includes(fileExtension.toLowerCase())) {
      return `Invalid file format. Only allowed ${allowedExtensions} !`;
    }

    const fileSize = Math.max(files[0].size / 1024 / 1024);
    // console.log('fileSize', fileSize);
    if (fileSize > maxFileSize) {
      // return `${files[0].name}, max allowed file size : ${maxFileSize} MB `;
      return `Max allowed file size : ${maxFileSize} MB !`;
    }

    return '';
  }

  public noWhitespaceValidator(control: FormControl): any {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid
      ? null
      : {
          whitespace: true,
        };
  }
}
