export class StpUsers {
  public id?: number;
  public userId?: number;
  public stpName?: string;
  public stpAuthorizeUserName?: string;
  public stpDesignation?: string;
  public nid?: string;
  public stpMobileNo?: string;
  public stpEmail?: string;
  constructor() // public id: number,
  // public userId: number,
  // public stpName: string,
  // public stpAuthorizeUserName: string,
  // public stpDesignation: string,
  // public nid: string,
  // public stpMobileNo: string,
  // public stpEmail: string
  {}
}
