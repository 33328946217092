import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TciInfoStorageService } from './../modules/template-for-certificate-issuance/services/tci-info-storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-email-assessment-certificate',
  templateUrl: './send-email-assessment-certificate.component.html',
  styleUrls: ['./send-email-assessment-certificate.component.scss']
})
export class SendEmailAssessmentCertificateComponent implements OnInit {

  constructor(
    private tciInfoStorageService: TciInfoStorageService,
    private notification: NzNotificationService,
  ) { }

  ngOnInit(): void {
  }

  sendEmail() {
    this.tciInfoStorageService.sendAllEmail()
      .subscribe((res) => {
        if (res.success) {
          this.notification.success(
            'Success!',
            res.message
          );
        }else{
          this.notification.error(
            'Success!',
            res.message
          );
        }
        
    })
  }

}
