import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  ErrorHandler,
  HttpErrorHandler,
} from 'src/app/shared/services/http-error-handler.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { User } from '../models/user.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  private handleError: ErrorHandler;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private httpService: HttpService,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = this.httpErrorHandler.createErrorHandler(
      'User Storage Service'
    );
  }

  getUserByIdFromServer(id: number): Observable<any> {
    return this.httpClient.get<User>(
      `${applicationUrls.user.readUserById}` + id
    );
  }

  readUsersFromServer(
    queryParamsObj: any
  ): Observable<{ data: User[]; count: number }> {
    const queryParamsObjTemp = { ...queryParamsObj };
    queryParamsObjTemp.pageIndex = queryParamsObj.pageIndex - 1;
    const queryParams = this.httpService.constructParam(queryParamsObjTemp);
    return this.httpClient
      .get<{ data: User[]; count: number }>(
        `${applicationUrls.user.readUsers}`,
        {
          params: queryParams,
        }
      )
      .pipe(catchError(this.handleError<never>('Read Users')));
  }

  readInspectionOfficersFromServer(
    queryParamsObj: any
  ): Observable<{ data: User[]; count: number }> {
    const queryParamsObjTemp = { ...queryParamsObj };
    queryParamsObjTemp.pageIndex = queryParamsObj.pageIndex - 1;
    const queryParams = this.httpService.constructParam(queryParamsObjTemp);
    return this.httpClient
      .get<{ data: User[]; count: number }>(
        `${applicationUrls.basicProfile.readInspectionOfficers}`,
        {
          params: queryParams,
        }
      )
      .pipe(catchError(this.handleError<never>('Read Users')));
  }

  readUserById(userId: number): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.user.readUserById}${userId}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  getUserProfile(userId: number): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.user.getUserProfile}${userId}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  createUser(user: User): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.user.create}`, user)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  deleteUser(user: User): Observable<any> {
    return this.httpClient
      .put<any>(`${applicationUrls.user.delete}` + user.id, user)
      .pipe(
        tap((res) => {
          if (res.data) {
            this.userService.deleteUser(res.data.id);
          }
        })
      );
  }

  updateUser(user: User, id: number): Observable<any> {
    return this.httpClient
      .put<any>(`${applicationUrls.user.update}` + id, user)
      .pipe(tap((res) => {}));
  }

  updateUserProfile(user: User, id: number): Observable<any> {
    return this.httpClient
      .put<any>(`${applicationUrls.user.updateUserProfile}` + id, user)
      .pipe(tap((res) => {}));
  }

  readUserLocation(id: number): any {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.userLocation.readUserLocation}` + id
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  getStpUserByStpMainId(page?: number| 0, size?:number| 10, sortingKey?: string | null, sortingValue?: string | null, stpMain?:number){
    let projectPageableRequestDTO = {
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
      stpMaindId : stpMain
    };
    return this.httpClient.post<ServerResponse>(`${applicationUrls.user.getStpUserByStpMainId}`,projectPageableRequestDTO);
  }
}
