export class ExternalUserRegistrationRequest {
    public id?: number;
    public name?: string;
    public nid?: string;
    public password?: string;
    public email?: string;
    public contactNumber?: string;
    public contactNumberPrefix?: string;
    public role?: string;
    constructor() {}
  }