<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Search Certificate
  </div>
  <form
    nz-form
    [formGroup]="searchingForm"
    (ngSubmit)="onSearch()"
    class="ant-advanced-search-form"
    style="
      padding: 24px;
      background: #fbfbfb;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
    "
  >
    <div class="row">
      <div class="form-group col-md-4">
        <div class="form-group col-md-12">
          <nz-form-label>Occupation Name</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-select
                nzShowSearch
                nzAllowClear
                id="tamOccupationId"
                formControlName="occupationNameEnglish"
                nzPlaceHolder="Select Occupation"
              >
                <nz-option
                  class="option"
                  *ngFor="let data of tamOccupations"
                  [nzValue]="data.name"
                  [nzLabel]="data.name"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="form-group col-md-4">
        <div class="form-group col-md-12">
          <nz-form-label>Level</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-select
                nzShowSearch
                nzAllowClear
                id="level"
                formControlName="level"
                nzPlaceHolder="Provide Level please"
              >
                <nz-option [nzValue]="1" nzLabel="1"></nz-option>
                <nz-option [nzValue]="2" nzLabel="2"></nz-option>
                <nz-option [nzValue]="3" nzLabel="3"></nz-option>
                <nz-option [nzValue]="4" nzLabel="4"></nz-option>
                <nz-option [nzValue]="5" nzLabel="5"></nz-option>
                <nz-option [nzValue]="6" nzLabel="6"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="form-group col-md-4">
        <div class="form-group col-md-12">
          <nz-form-label>Reg No.</nz-form-label>
          <input
            nz-input
            type="text"
            placeholder="Provide Reg No. please"
            formControlName="registrationNo"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-4">
        <div class="form-group col-md-12">
          <nz-form-label>Contact</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzErrorTip]="contactNumberErrorTpl">
              <input
                nz-input
                type="text"
                placeholder="Provide Contact please"
                formControlName="contact"
              />
              <ng-template #contactNumberErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please input valid contact number!
                </ng-container>
                <ng-container *ngIf="control.hasError('maxlength')">
                  Max length reached
                </ng-container>
                <ng-container *ngIf="control.hasError('minlength')">
                  Contact number must be 11 digit
                </ng-container>
                <ng-container *ngIf="control.hasError('pattern')">
                  <div>Only digits are allowed</div>
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="form-group col-md-4">
        <div class="form-group col-md-12">
          <nz-form-label>Email</nz-form-label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
              <input
                nz-input
                type="text"
                placeholder="Provide Email please"
                formControlName="email"
              />
              <ng-template #emailErrorTpl let-control> </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24" style="text-align: right">
        <button
          nz-button
          [nzType]="'primary'"
          [disabled]="!searchingForm.valid"
          style="margin-right: 7px"
        >
          Search
        </button>
        <button nz-button class="btn-success" (click)="onRefresh()">
          Refresh Data
        </button>
      </div>
    </div>
  </form>
</nz-card>
<nz-card>
  <div class="ng-Header col-xs-12">
    <i nz-icon nzType="form" nzTheme="outline"></i> List of Competent Persons
  </div>
  <div class="searchboxAerar p-3">
    <nz-table
      nzSize="middle"
      #basicTable
      [nzData]="tciInfoList"
      nzTableLayout="fixed"
      nzShowSizeChanger
      nzBordered
      nzAlign="middle"
      [nzFrontPagination]="false"
      [nzTotal]="total"
      [(nzPageSize)]="size"
      [nzShowTotal]="totalRowRangeTemplate"
      [(nzPageIndex)]="page"
      (nzQueryParams)="onQueryParamsChange($event)"
      class="css-serial table-striped table"
    >
      <ng-template
        #totalRowRangeTemplate
        let-range="range"
        let-total
        style="text-align: left"
      >
        <div style="text-align: left">
          Showing {{ range[0] }}-{{ range[1] }} of {{ total }} items
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="text-align: center; vertical-align: middle">SI.</th>
          <th style="text-align: center; vertical-align: middle">Occupation</th>
          <th style="text-align: center; vertical-align: middle">Level</th>
          <th style="text-align: center; vertical-align: middle">
            Name of Competent Person
          </th>
          <th style="text-align: center; vertical-align: middle">
            Registration Number
          </th>
          <th style="text-align: center; vertical-align: middle">Email ID</th>
          <th style="text-align: center; vertical-align: middle">Contact</th>
          <th style="text-align: center; vertical-align: middle">
            Certificate View/Download
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td style="text-align: center; vertical-align: middle">
            {{ (page - 1) * size + 1 + i }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.occupationNameEnglish }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.level }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.nameEnglish }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.registrationNo }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.email }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            {{ data.contact }}
          </td>
          <td style="text-align: center; vertical-align: middle">
            <button
              class="mr-1"
              (click)="onDownloadFreelancerCertificate(data)"
              nz-button
              type="submit"
              nzType="primary"
              title="Download Certificate"
              [nzSize]="'small'"
            >
              <i nz-icon nzType="container" nzTheme="outline"></i>
            </button>
            <!-- <a (click)="onCertificateView(data)" class="mr-1" nz-button type="submit" nzType="primary" title="Certificate View" [nzSize]="'small'"><i nz-icon nzType="eye" nzTheme="outline"></i>
              </a> -->
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
