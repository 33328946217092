<app-header></app-header>
<div class="searchboxAerar p-3">
  <div class="card">

    <form nz-form [formGroup]="getEventForm">
      <div class="ng-Header col-xs-12">
        <i nz-icon nzType="form" nzTheme="outline"></i>
          Event Report
      </div>
      <br>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Event ID
          </nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <label style="padding-top: 4px; font-weight: bold;">{{eventDataList?.eventId}}</label>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Event Title</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" formControlName="eventTitle" />
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Event Type
          </nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <nz-select nzShowSearch nzAllowClear formControlName="eventTypeId" nzPlaceHolder="Select One">
                <nz-option *ngFor="let item of eventTypesList" [nzValue]="item.id" [nzLabel]="item.typeName">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Sub-type</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">

              <input style="color:#2d2d2d;" nz-input type="text" formControlName="subType" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Date From
          </nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <label style="padding-top: 4px; font-weight: bold;">{{eventDataList?.dateFrom | date}}</label>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Date To</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <label style="padding-top: 4px; font-weight: bold;">{{eventDataList?.dateTo | date}}</label>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Purpose of the event</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" formControlName="purpose" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right font-size-adjust ">
          <nz-form-label>Venue</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" formControlName="venue" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Division</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <nz-select nzMode="multiple" nzShowSearch nzAllowClear formControlName="divisions"
                nzPlaceHolder="Select Division" (ngModelChange)="onSelectDivision($event)">
                <nz-option *ngFor="let division of divisionList" [nzValue]="division.id"
                  [nzLabel]="division.divisionName">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>District</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <nz-select nzMode="multiple" nzShowSearch nzAllowClear formControlName="districts"
                nzPlaceHolder="Select District">
                <nz-option *ngFor="let district of districtList" [nzValue]="district.id"
                  [nzLabel]="district.districtName">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Organised by</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
              <nz-form-control class="row">
                <nz-select nzShowSearch nzAllowClear formControlName="organiserId" nzPlaceHolder="Select One">
                  <nz-option *ngFor="let item of organizedByList" [nzValue]="item.id" [nzLabel]="item.organiserName">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Focal Person From Organiser</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" formControlName="focalPerson" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>

    <form nz-form [formGroup]="addEventReportForm">

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Facilitator(s)/Agency
          </nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Facilitator(s)/Agency Name" formControlName="facilitators" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Event Objectives</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Event Objectives" formControlName="eventObjectives" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Estimated Budget(BDT)
          </nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <label style="padding-top: 4px; font-weight: bold;">{{eventDataList?.budgetEstimate}}</label>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Actual Cost</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Actual Cost" formControlName="actualCost" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/^0+/, '')"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Challenges faced</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Challenges faced" formControlName="challengesFaced" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Number of Female Participants</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Number of Female Participants"
                formControlName="femaleParticipants" (change)="updateTotalParticipants()"   (input)="numericValidation('femaleParticipants','int')"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Number of Male Participants</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Number of Male Participants"
                formControlName="maleParticipants" (change)="updateTotalParticipants()"   (input)="numericValidation('maleParticipants','int')"/>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Number of Other Gender Participants</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Number of Other Gender Participants"
                formControlName="otherGenderParticipants" (change)="updateTotalParticipants()"  (input)="numericValidation('otherGenderParticipants','int')"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Total Number of Participants</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" readonly
               [(ngModel)]="totalNumberOfParticipants" [ngModelOptions]="{standalone: true}"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Number of PWD Participants</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Number of PWD Participants" formControlName="pwdParticipants"  (input)="numericValidation('pwdParticipants','int')"/>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Participants from Minority Group</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" #minorityParticipants placeholder="Number of Participants from Minority Group"
                formControlName="minorityParticipants"  (input)="numericValidation('minorityParticipants','int')"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Comments About Participants</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Comments about Participants"
                formControlName="commentsAboutParticipants" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Participant's feedback</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Participant's feedback" formControlName="participantsFeedback" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Lessons Learnt</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Lessons Learnt" formControlName="lessonsLearnt" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Future Steps</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Future Steps" formControlName="futureSteps" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Remarks</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Remarks" formControlName="remarks" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label nzRequired>Report prepared by</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row" nzHasFeedback>
              <input style="color:#2d2d2d;" nz-input type="text" placeholder="Report Prepared By" formControlName="preparedBy" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-md-3 col-sm-12 text-right">
          <nz-form-label>Upload Document</nz-form-label>
        </div>
        <div class="col-md-3 col-sm-12">
          <nz-form-item>
            <nz-form-control class="row">
              <table>
                <tr>
                  <td colspan="2">
                    <input #attachmentInput style="color:#2d2d2d;" type="file" class="form-control" id="attachment"
                (change)="uploadAttachmentFile($event, attachmentInput)">
                  </td>
                </tr>
                <tr *ngIf="this.addEventReportForm.get('attachmentFileName')?.value">
                  <td style="width: 60%">
                    {{ this.addEventReportForm.get('attachmentFileName')?.value }}
                  </td>
                  <td style="width: 40%">
                    <button nz-button type="button" nzType="default" [nzSize]="'small'" nz-tooltip
                        nzTooltipTitle="Preview" nzTooltipPlacement="top" (click)="previewImage(this.addEventReportForm.get('attachmentFileType')?.value,
                        this.addEventReportForm.get('attachmentLinks')?.value, this.addEventReportForm.get('attachmentFileName')?.value)">
                        <i nz-icon *ngIf="this.addEventReportForm.get('attachmentFileType')?.value == 'image' || this.addEventReportForm.get('attachmentFileType')?.value == 'pdf'"
                        nzType="eye" nzTheme="fill"></i>
                        <i nz-icon *ngIf="this.addEventReportForm.get('attachmentFileType')?.value == 'other'" nzType="file" nzTheme="fill"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <!-- <label style="color:#D2232A; font-size: 10px; font-weight: bold;">(Please upload pdf/doc/docx/xls/xlsx/png/jpg/jpeg and max file size 3 mb)</label> -->
                    <span style="color: red; font-size: 11px"><sup>*</sup>Note: </span>
              &nbsp;<span style="font-size: 11px; ">
                Please upload .pdf/.doc/.docx/.xls/.xlsx/.png/.jpg/.jpeg file. File size max 10
                MB.</span>
                  </td>
                </tr>
              </table>

            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
     <br>
    </form>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisibleForAttachment"
  (nzOnCancel)="handleCancelFile()"
  [nzClosable]="false"
  [nzOkText]="null"
  (nzOnOk)="handleOkFile()"
  [nzWidth]="1200"
>
  <ng-container *nzModalContent>
    <iframe
      *ngIf="!isImageLoading && pdfUrl"
      [src]="pdfUrl"
      style="width: 100%; height: 35vw"
    ></iframe>
    <div style="text-align: center">
      <img class="img-thumbnail" *ngIf="imageURL" [src]="imageURL" /><br />
      <ng-template #noImageFound>
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
          alt="Fallbackimage"
        />
      </ng-template>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="handleCancelFile()">Close</button>
  </div>
</nz-modal>
<app-footer></app-footer>
