<app-header></app-header>
<main>
  <div class="marketing">
    <section class="form-section">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-12 col-xl-11">
            <div class="card text-black forget-card">
              <div class="card-body p-md-5">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-lg-6 col-xl-6 order-2 order-lg-1">
                    <p class="text-center h4 fw-bold mb-3 mx-1 mx-md-4 mt-4">
                      Enter your registered email address
                    </p>

                    <form nz-form [formGroup]="validateForm" class="forgotpassword-form" (ngSubmit)="submitForm()">
                      <nz-form-item class="mb-2">
                        <nz-form-control nzHasFeedback [nzErrorTip]="email">
                          <input type="text" formControlName="email" placeholder="E-mail" (keyup)="checkNapEmailExist($event.target)" class="form-control form-control-lg"/>
                        </nz-form-control>
                        <ng-template #email let-control>
                          <ng-container *ngIf="control.hasError('required')">
                            Please input your E-mail !
                          </ng-container>
                        </ng-template>
                      </nz-form-item>
                      <ng-container *ngIf="!emailExist">
                        <p style="color: red;">This E-mail is not registered !</p>
                      </ng-container>

                      <div class="container d-flex justify-content-center">
                        <button style="margin: 10px" nz-button nzType="primary" routerLink="/NAP-login">Back</button>
                        <button style="margin: 10px" nz-button nzType="primary" [disabled]="!isSubmit">Submit</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-footer></app-footer>
