<app-header></app-header>
<main>
  <div class="marketing">
    <section class="form-section">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-12 col-xl-11">
            <div class="card text-black forget-card">
              <div class="card-body p-md-5">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-lg-6 col-xl-6 order-2 order-lg-1">
                    <p class="text-center h4 fw-bold mb-3 mx-1 mx-md-4 mt-4">
                      Enter your registered email address
                    </p>

                    <form
                      nz-form
                      [formGroup]="validateForm"
                      class="forgotpassword-form"
                      (ngSubmit)="submitForm()"
                    >
                      <nz-form-item class="mb-2">
                        <nz-form-control nzHasFeedback [nzErrorTip]="email">
                          <input
                            type="text"
                            id="form3Example9"
                            formControlName="email"
                            placeholder="E-mail"
                            (keyup)="checkEmailExist($event.target)"
                            class="form-control form-control-lg"
                          />
                        </nz-form-control>
                        <!-- <ng-template #email>
                          <ng-container *ngIf="!emailAlreadyExist">
                            Please provide a valid registered email
                          </ng-container>
                        </ng-template> -->
                        <ng-template #email let-control>
                          <ng-container *ngIf="control.hasError('required')">
                            Please input your E-mail !
                          </ng-container>
                        </ng-template>
                      </nz-form-item>
                      <nz-form-item
                        #email
                        *ngIf="emailAlreadyExist"
                        class="text-success"
                      >
                        <ng-template>
                          <div>
                            <p>This E-mail is registered</p>
                          </div>
                        </ng-template>
                      </nz-form-item>
                      <!-- <div #email *ngIf="emailAlreadyExist" class="text-success">
                        <div>
                          <p>This E-mail is registered</p>
                        </div>
                      </div> -->
                      <div
                        #email
                        *ngIf="
                          !emailAlreadyExist &&
                          validateForm.controls['email']?.touched
                        "
                        class="text-danger"
                      >
                        <div>
                          <p>Please provide valid registered email</p>
                        </div>
                      </div>

                      <!-- <div class="form-outline mb-4"> -->
                      <!-- <label class="form-label" for="form3Example9"><strong class="required">*</strong> E-mail</label> -->
                      <!-- <nz-form-item>
                          <nz-form-control nzHasFeedback [nzErrorTip]="email">
                            <input type="text" formControlName="email" placeholder="E-mail"
                              (keyup)=" checkEmailExist($event.target)" class="form-control form-control-lg" />
                          </nz-form-control>
                          <ng-template class="mt-5" #email let-control>
                            <ng-container *ngIf="showingmessage">
                              Please provide a valid registered email !
                            </ng-container> -->
                      <!-- <ng-container *ngIf="!emailAlreadyExist">
                              Please provide a valid registered email !
                            </ng-container> -->
                      <!-- </ng-template> -->
                      <!-- <ng-template class="mt-5" #other_content>
                            <ng-container>
                              Invalid registered email !
                            </ng-container>
                          </ng-template> -->

                      <!-- </nz-form-item>
                      </div> -->

                      <div class="container d-flex justify-content-center">
                        <button
                          style="margin: 10px"
                          nz-button
                          nzType="primary"
                          routerLink="/login"
                        >
                          Back
                        </button>
                        <button
                          style="margin: 10px"
                          nz-button
                          nzType="primary"
                          [disabled]="!emailAlreadyExist"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  <!-- <div class="
                      col-md-10 col-lg-6 col-xl-7
                      d-flex
                      align-items-center
                      order-1 order-lg-2
                    ">
                    <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-registration/draw1.png"
                      class="img-fluid" alt="Sample image" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-footer></app-footer>
