import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  TciInfoDto,
  TciTrainingDetailsPayload,
} from '../../DTO/tciInfoDto.model';
import { TciInfoStorageService } from '../../services/tci-info-storage.service';

@Component({
  selector: 'app-view-freelancer-certificate',
  templateUrl: './view-freelancer-certificate.component.html',
  styleUrls: ['./view-freelancer-certificate.component.scss'],
})
export class ViewFreelancerCertificateComponent implements OnInit {
  tciId: string | null;
  tciInfo: TciInfoDto;
  tciTrainingDetailsPayload: TciTrainingDetailsPayload;
  officeText: string;

  constructor(
    private tciInfoStorageService: TciInfoStorageService,
    private route: ActivatedRoute
  ) {
    this.tciId = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit(): void {
    if (this.tciId != null) {
      this.getCertificateData();
    }
  }

  getCertificateData() {
    this.tciInfoStorageService
      .getFreelancerCertificateByEncryptedId(this.tciId)
      .subscribe({
        next: (result: any) => {
          this.tciInfo = result.tciInfoPayload;
          let issueDateStr = this.tciInfo.issueDate.toString();
          issueDateStr = issueDateStr.split('T')[0];
          let issueDate = this.convertStringToDate(issueDateStr);
          const cutoffDate = this.convertStringToDate('2024-08-08');
          this.officeText =
            issueDate <= cutoffDate
              ? 'Prime Minister’s Office'
              : 'Chief Adviser’s Office';
          //#endregion
        },
      });
  }

  convertStringToDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(day, month - 1, year);
  }
}
