import { PublicDashboardComponent } from './modules/event/components/public-dashboard/public-dashboard.component';
import { NAPDefaultLayoutComponent } from './shared/modules/NAP-default-layout/NAP-default-layout.component';
import { SendEmailAssessmentCertificateComponent } from './send-email-assessment-certificate/send-email-assessment-certificate.component';
import { DownloadCertificatePortalComponent } from './download-certificate-portal/download-certificate-portal.component';
import { OtpForSharedPreviewInspectionReportComponent } from './modules/otp-for-shared-preview-inspection-report/otp-for-shared-preview-inspection-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicantProfileRegistrationComponent } from './modules/authentication/components/applicant-profile-registration/applicant-profile-registration.component';
import { LoginComponent } from './modules/authentication/components/login/login.component';
import { RegistrationCategoryComponent } from './modules/authentication/components/registration-category/registration-category.component';
import { AuthenticationGuard } from './modules/authentication/services/authentication.guard';
import { P404Component } from './modules/error/404.component';
import { NotPermittedComponent } from './modules/error/not-permitted.component';
import { LandingComponent } from './modules/landing/component/landing/landing.component';
import { OtpForCompleteInspectionPreviewComponent } from './modules/otp-for-complete-inspection-preview/otp-for-complete-inspection-preview.component';
import { CertificateVarifyPageComponent } from './modules/stp-registration/components/certificate-varify-page/certificate-varify-page.component';
import { RegistrationCardVarifyPageComponent } from './modules/stp-registration/components/registration-card-varify-page/registration-card-varify-page.component';
import { StpApplicationViewComponent } from './modules/stp-registration/components/stp-application-view/stp-application-view.component';
import { StpApplicationsComponent } from './modules/stp-registration/components/stp-applications/stp-applications.component';
import { TciTableComponent } from './modules/template-for-certificate-issuance/components/tci-table/tci-table.component';
import { ViewFreelancerCertificateComponent } from './modules/template-for-certificate-issuance/components/view-freelancer-certificate/view-freelancer-certificate.component';
import { TamListComponent } from './modules/training-and-assessment-materials/components/tam-list/tam-list.component';
import { TamViewComponent } from './modules/training-and-assessment-materials/components/tam-view/tam-view.component';
import { DefaultLayoutComponent } from './shared/modules/default-layout/default-layout.component';
import { OtpForStpSacInspectionReportPreviewComponent } from './modules/otp-for-stp-sac-inspection-report-preview/otp-for-stp-sac-inspection-report-preview.component';
import { NAPAuthenticationGuard } from './modules/authentication/services/NAP-authentication.guard';
import { AssessorRegistrationComponent } from './modules/authentication/components/assessor-registration/assessor-registration.component';
import { DocumentListComponent } from './modules/policy-guidelines/components/documents/components/document-list/document-list.component';
import { GiveOpinionCreateComponent } from './modules/policy-guidelines/components/give-opinion/components/give-opinion-create/give-opinion-create.component';
import { ViewRegularCertificateComponent } from './modules/stp-operations/components/view-reg-certificate/view-reg-certificate.component';
import { ViewRplCertificateComponent } from './modules/stp-operations/components/view-rpl-certificate/view-rpl-certificate.component';
import { PublicAddEventReportComponent } from './modules/event/components/public-add-event-report/public-add-event-report.component';
import { PublicAddNewEventComponent } from './modules/event/components/public-add-new-event/public-add-new-event.component';
import { PublishedIscListComponent } from './modules/industry-skills-council/components/published-isc-list/published-isc-list.component';
import { PublishedIscDirectorListComponent } from './modules/industry-skills-council/components/published-isc-director-list/published-isc-director-list.component';
import { ResearchListPublicComponent } from './modules/research-and-studies/components/research-list-public/research-list-public.component';
import { PublicStpApplicationsListComponent } from './modules/stp-registration/components/public-stp-applications-list/public-stp-applications-list.component';
import { ResearchDocumentListPublicComponent } from './modules/research-and-studies/components/research-document-list-public/research-document-list-public.component';
import { NAPLoginComponent } from './modules/authentication/components/NAP-login/NAP-login.component';
import { ExternalUserRegistrationComponent } from './modules/research-and-studies/components/external-user-registration/external-user-registration.component';
import { DocumentPublicViewComponent } from './modules/research-and-studies/components/document-public-view/document-public-view.component';
import { CircularListPublicComponent } from './modules/financial-application/components/circular-list-public/circular-list-public.component';
import { CircularViewComponent } from './modules/financial-application/components/circular-view/circular-view.component';
import { MraListPublicComponent } from './modules/mra/components/mra-list-public/mra-list-public.component';
import { MraVeiwDetailsPublicComponent } from './modules/mra/components/mra-veiw-details-public/mra-veiw-details-public.component';
import { TamListMraComponent } from './modules/mra/components/tam-list-mra/tam-list-mra.component';

const routes: Routes = [
  {
    path: 'home/tci/tci-shared-list',
    component: DownloadCertificatePortalComponent,
  },

  {
    path: 'tci/download-certificate-portal',
    component: DownloadCertificatePortalComponent,
  },
  { path: '', component: LandingComponent },
  {
    path: 'certificate-verification-page/:id',
    component: CertificateVarifyPageComponent,
  },
  {
    path: 'certificate-verification-page',
    component: CertificateVarifyPageComponent,
  },
  {
    path: 'registration-card-verify-page/:id',
    component: RegistrationCardVarifyPageComponent,
  },
  {
    path: 'registration-card-verify-page',
    component: RegistrationCardVarifyPageComponent,
  },
  { path: 'login', component: LoginComponent },
  { path: 'tam-list', component: TamListComponent },
  { path: 'tam-view', component: TamViewComponent },
  { path: 'certificate-list', component: TciTableComponent },
  {
    path: 'certificate-list/certificate-view',
    component: ViewFreelancerCertificateComponent,
  },
  {
    path: 'rpl/certificate-view',
    component: ViewRplCertificateComponent,
  },
  {
    path: 'reg/certificate-view',
    component: ViewRegularCertificateComponent,
  },
  {
    path: 'registration-category',
    component: RegistrationCategoryComponent,
  },
  {
    path: 'applicant-register',
    component: ApplicantProfileRegistrationComponent,
  },
  {
    path: 'assessor-register',
    component: AssessorRegistrationComponent,
  },
  {
    path: 'external-user-register',
    component: ExternalUserRegistrationComponent,
  },
  { path: 'stp-list', component: StpApplicationsComponent },
  { path: 'policy-guidelines/document-list', component: DocumentListComponent },
  {
    path: 'policy-guidelines/document-list/give-opinion-create',
    component: GiveOpinionCreateComponent,
  },
  {
    path: 'stp-list/stp-list-view/:id',
    component: StpApplicationViewComponent,
  },
  {
    path: 'public-stp-applications-list',
    component: PublicStpApplicationsListComponent,
  },

  {
    path: 'event/public-dashboard',
    component: PublicDashboardComponent,
  },
  {
    path: 'event/public-add-new-event',
    component: PublicAddNewEventComponent,
  },
  {
    path: 'event/public-add-event-report',
    component: PublicAddEventReportComponent,
  },

  {
    path: 'industry-skills-council/published-isc-list',
    component: PublishedIscListComponent,
  },
  {
    path: 'industry-skills-council/published-isc-director-list/:id',
    component: PublishedIscDirectorListComponent,
  },
  {
    path: 'research-and-studies/list-public',
    component: ResearchListPublicComponent,
  },
  {
    path: 'research-and-studies/document-list-public',
    component: ResearchDocumentListPublicComponent,
  },
  {
    path: 'research-and-studies/document-public-view',
    component: DocumentPublicViewComponent,
  },

  {
    path: 'financial/circular-list-public',
    component: CircularListPublicComponent,
  },

  {
    path: 'mra/mra-list-public',
    component: MraListPublicComponent,
  },
  {
    path: 'mra/mra-view-details-public',
    component: MraVeiwDetailsPublicComponent,
  },
  { path: 'mra/view-mra-public', component: TamListMraComponent },





  { path: 'financial/circular-view', component: CircularViewComponent },
  { path: 'NAP-login', component: NAPLoginComponent },
  {
    path: 'NAP-home',
    component: NAPDefaultLayoutComponent,
    canActivate: [NAPAuthenticationGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
      },
      {
        path: 'national-action-plan',
        loadChildren: () =>
          import('./modules/national-action-plan/nap.module').then(
            (m) => m.NAPModule
          ),
      },
      {
        path: 'authentication',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },

  {
    path: 'home',
    component: DefaultLayoutComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'tci',
        loadChildren: () =>
          import(
            './modules/template-for-certificate-issuance/template-for-certificate-issuance.module'
          ).then((m) => m.TemplateForCertificateIssuanceModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
      },
      {
        path: 'stp-registration',
        loadChildren: () =>
          import('./modules/stp-registration/stp-registration.module').then(
            (m) => m.StpRegistrationModule
          ),
      },
      {
        path: 'training-and-assessment-materials',
        loadChildren: () =>
          import(
            './modules/training-and-assessment-materials/training-and-assessment-materials.module'
          ).then((m) => m.TrainingAndAssessmentMaterialsModule),
      },
      {
        path: 'course-accreditation',
        loadChildren: () =>
          import(
            './modules/course-accreditation/course-accreditation.module'
          ).then((m) => m.CourseAccreditationModule),
      },
      {
        path: 'course-accreditation-inspection-report',
        loadChildren: () =>
          import(
            './modules/course-acc-inspection-report/course-acc-inspection-report.module'
          ).then((m) => m.CourseAccInspectionReportModule),
      },
      // {
      //   path: 'tci',
      //   loadChildren: () =>
      //     import(
      //       './modules/template-for-certificate-issuance/template-for-certificate-issuance.module'
      //     ).then((m) => m.TemplateForCertificateIssuanceModule),
      // },
      {
        path: 'applicant',
        loadChildren: () =>
          import('./modules/applicant/applicant.module').then(
            (m) => m.ApplicantModule
          ),
      },
      {
        path: 'tam',
        loadChildren: () =>
          import(
            './modules/training-and-assessment-materials/training-and-assessment-materials.module'
          ).then((m) => m.TrainingAndAssessmentMaterialsModule),
      },
      {
        path: 'landing',
        loadChildren: () =>
          import('./modules/landing/landing.module').then(
            (m) => m.LandingModule
          ),
      },
      {
        path: 'authentication',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: 'authorization',
        loadChildren: () =>
          import('./modules/authorization/authorization.module').then(
            (m) => m.AuthorizationModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./modules/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'Inspection Officer',
        loadChildren: () =>
          import('./modules/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./modules/payment/payment.module').then(
            (m) => m.PaymentModule
          ),
      },
      {
        path: 'tci/send-email',
        component: SendEmailAssessmentCertificateComponent,
      },
      // {
      //   path:'payment-online',
      //   loadChildren: ()=>
      //     import('./shared/modules/payment-message/payment-message.module').then((m) => m.PaymentMessageModule),
      // },
      {
        path: 'stp-operations-admin',
        loadChildren: () =>
          import(
            './modules/stp-operations-admin-view/stp-operations-admin-view.module'
          ).then((m) => m.StpOperationsAdminViewModule),
      },
      {
        path: 'stp-operations',
        loadChildren: () =>
          import('./modules/stp-operations/stp-operations.module').then(
            (m) => m.StpOperationsModule
          ),
      },
      {
        path: 'rollback',
        loadChildren: () =>
          import('./modules/rollback/rollback.module').then(
            (m) => m.RollbackModule
          ),
      },
      {
        path: 'national-action-plan',
        loadChildren: () =>
          import('./modules/national-action-plan/nap.module').then(
            (m) => m.NAPModule
          ),
      },
      {
        path: 'event',
        loadChildren: () =>
          import('./modules/event/event.module').then((m) => m.EventModule),
      },
      {
        path: 'policy-guidelines',
        loadChildren: () =>
          import('./modules/policy-guidelines/pg.module').then(
            (m) => m.PgModule
          ),
      },
      // {
      //   path:'payment-online',
      //   loadChildren: ()=>
      //     import('./shared/modules/payment-message/payment-message.module').then((m) => m.PaymentMessageModule),
      // },
      {
        path: 'assessor-pool',
        loadChildren: () =>
          import('./modules/assessor-pool/assessor-pool.module').then(
            (m) => m.AssessorPoolModule
          ),
      },
      {
        path: 'industry-skills-council',
        loadChildren: () =>
          import('./modules/industry-skills-council/isc.module').then(
            (m) => m.ISCModule
          ),
      },
      {
        path: 'research-and-studies',
        loadChildren: () =>
          import(
            './modules/research-and-studies/research-and-studies.module'
          ).then((m) => m.ResearchAndStudiesModule),
      },
      {
        path: 'financial',
        loadChildren: () =>
          import(
            './modules/financial-application/financial-application.module'
          ).then((m) => m.FinancialApplicationModule),
      },
      {
        path: 'mra',
        loadChildren: () =>
          import('./modules/mra/mra.module').then((m) => m.MraModule),
      },
      {
        path: 'program-project',
        loadChildren: () =>
          import('./modules/program-project/program-project.module').then((m) => m.ProgramProjectModule),
      },
    ],
  },
  {
    path: 'home/course-accreditation/preview-for-otp',
    component: OtpForCompleteInspectionPreviewComponent,
  },
  {
    path: 'home/course-accreditation/stp-sac-inspection-report-preview-for-otp',
    component: OtpForStpSacInspectionReportPreviewComponent,
  },
  {
    path: 'home/course-acc-inspection-report/inspection-report-preview-for-otp/:inspectionResultId',
    component: OtpForSharedPreviewInspectionReportComponent,
  },
  {
    path: 'payment-online',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './shared/modules/payment-message/payment-message.module'
          ).then((m) => m.PaymentMessageModule),
      },
    ],
  },

  {
    path: 'not-permitted',
    component: NotPermittedComponent,
  },

  { path: 'not-found', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
