import { Component, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NewEventModel } from '../../models/new-event-model';
import { environment } from 'src/environments/environment';
import { applicationPermissions } from 'src/app/shared/application-constants/application-permissions';
import { EventModuleService } from '../../event-module.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-public-add-new-event',
  templateUrl: './public-add-new-event.component.html',
  styleUrls: ['./public-add-new-event.component.scss']
})
export class PublicAddNewEventComponent implements OnInit {

  addNewEventForm: FormGroup;
  updateEventForm: FormGroup;
  eventTypesList: any;
  industrialSectorsList: any;
  districtList: any;
  divisionList: any;
  countryId: number;
  newEventModel: NewEventModel;
  currentUserInfo: any;

  tempStatus: any = null;
  organizedByList: any;
  file: File;
  dateFromTemp: any;
  dateToTemp: any;

  imageURL: any;
  pdfUrl: any;
  otherUrl: any;
  isImageLoading: boolean;
  isVisibleForAttachment = false;
  imageTypeArray: string[] = ['jpg', 'jpeg', 'png', 'PNG'];

  allowedExtensions = environment.validation.allowedExtensions;
  maxFormatFileSize = 10;
  conceptTemplateRef: ElementRef;
  applicationPermissions = applicationPermissions;

  yesNoList: { bool: boolean; name: string }[] = [
    { bool: true, name: 'Yes' },
    { bool: false, name: 'No' }];

  statusList: { id: string; name: string }[] = [
    { id: "POSTPONED", name: 'Postponed' },
    { id: "CANCELLED", name: 'Cancelled' }];


  buttonShow: boolean;
  id: any = null;
  updateStatus: any;
  updateRemarks: any;

  constructor(
    private fb: FormBuilder,
    private eventService: EventModuleService,
    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.addNewEventForm = this.fb.group({
      eventId: [null],
      eventTypeId: [null, [Validators.required]],
      eventTitle: [null, [Validators.required]],
      eventTypeIfOthers: [null],
      subType: [null],
      dateFrom: [null, [Validators.required, this.dateValidator('dateFrom')],],
      dateTo: [null, [Validators.required, this.dateValidator('dateTo')],],
      purpose: [null],
      venue: [null],
      partners: [null],
      focalPerson: [null],
      requiredMaterials: [null],
      budgetHeadCode: [null],
      budgetEstimate: [null],
      organisedByIfOthers: [null],
      organiserId: [null, [Validators.required]],
      isBudgetApproved: [null],
      sectors: [null],
      isConceptNoteAvailable: [null],
      status: [null],
      divisions: [null],
      districts: [null],
      conceptDownloadLink: [null],
      budgetDownloadLink: [null],
      conceptFileName: [null],
      budgetFileName: [null],
      conceptFileType: [null],
      budgetFileType: [null],
      remarks: [null],
      conceptNote: [null],
      budget: [null]
    });

    this.updateEventForm = this.fb.group({
      updateStatus: [null],
      updateRemarks: [null]
    });

    this.resetForm();
    this.getEventTypesPublic();
    this.readIndustrialSectors();
    this.getAllDivision();
    this.getOrganizersPublic();

    this.route.queryParams
      .subscribe(params => {

        this.resetForm();

        if (params?.status != null && params?.id != null) {
          this.id = params.id;
          this.tempStatus = params.status;

          this.getNewEventPublic(params.id, params.reportId, params.status);
        }
      });

      this.currentUserInfo = JSON.parse(
        localStorage.getItem('currentUserInfo') ?? '{}'
      );
  }

  getEventTypesPublic(): void {
    this.eventTypesList = [];
    this.eventService.getEventTypesPublic()
      .subscribe({
        next: (response) => {
          this.eventTypesList = response;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  getOrganizersPublic(): void {
    this.organizedByList = [];
    this.eventService.getOrganizersPublic()
      .subscribe({
        next: (response) => {
          this.organizedByList = response;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  readIndustrialSectors(): void {
    this.industrialSectorsList = [];
    this.eventService.readIndustrialSectors()
      .subscribe({
        next: (response) => {
          this.industrialSectorsList = response?.data;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  getAllDivision() {
    this.eventService
      .readStpDivisionsBYCountryID(Number(1))
      .subscribe({
        next: (res) => {
          this.divisionList = res;
        },
      });
  }

  onSelectDivision(event: number[]): void {
    const divisionIds = event;

    this.districtList = []
    divisionIds?.forEach(divisionId => {
      if (divisionId) {
        this.eventService
          .readStpDistrictsByDivisionId(Number(divisionId))
          .subscribe({
            next: (response) => {
              this.districtList = this.districtList.concat(response);
            },
          });
      }
    });
  }

  onSave(): void {
    const formData: any = new FormData();

    if (this.id != null) {
      formData.append("id", this.id);
    }

    for (const field in this.addNewEventForm.controls) {

      let fieldValue = this.addNewEventForm.get(field)?.value;

      if (fieldValue) {
        if (field === 'dateFrom' || field === 'dateTo') {
          fieldValue = this.datePipe.transform(fieldValue, 'yyyy-MM-dd');
        }
        formData.append(field, fieldValue);
      }
    }

    this.eventService.saveNewEvent(formData)
      .subscribe(
        (response) => {
          if (response.success == 'true') {
            this.notification.success('Success!', response.message);
            this.id = response.data;
          }
          else {
            this.notification.error('Failed!', response.message);
          }
        },
        (error) => this.notification.error('Failed', error.error.message)
      );
  }

  onSubmit() {
    this.eventService.submitNewEvent(this.id)
      .subscribe(
        (response) => {
          if (response.success == 'true') {
            this.notification.success('Success!', response.message);
            this.router.navigate(['/home/event/user-dashboard']);
          }
          else {
            this.notification.error('Failed!', response.message);
          }
        },
        (error) => this.notification.error('Failed', error.error.message)
      );
  }

  onPostpone() {
    this.updateStatus = this.updateEventForm?.get("updateStatus")?.value;
    this.updateRemarks = this.updateEventForm?.get("updateRemarks")?.value;
    this.eventService.postponeNewEvent(this.id, this.updateStatus, this.updateRemarks).subscribe(
      (response) => {
        if (response.success == 'true') {
          this.notification.success('Success!', response.message);

          this.router.navigate(['/home/event/user-dashboard']);
        }
        else {
          this.notification.error('Failed!', response.message);
        }
      },
      (error) => this.notification.error('Failed', error.error.message)
    );
  }

  numericValidation(controlName: string, type: string) {
    let value = this.addNewEventForm.controls[controlName].value;
    if (type == 'int') {
      value = value.replace(/[^0-9^]+/g, '').replace(/^0+/, '');
    }
    else if (type == 'float') {
      value = value.replace(/[^0-9^\.]+/g, '').replace(/^0+/, '');
    }
    this.addNewEventForm.controls[controlName].setValue(value)
  }

  dateValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let startDate = control.parent?.get('dateFrom');
      let endDate = control.parent?.get('dateTo');

      if (
        startDate?.pristine ||
        endDate?.pristine ||
        !startDate?.value ||
        !endDate?.value
      ) {
        control.parent
          ?.get('dateFrom')
          ?.setErrors(startDate?.errors?.required ? { required: true } : null);
        control.parent
          ?.get('dateTo')
          ?.setErrors(endDate?.errors?.required ? { required: true } : null);
        return null;
      }

      let formatFromDate = formatDate(startDate?.value, 'yyyy-MM-dd', 'en_US');
      let formatToDate = formatDate(endDate?.value, 'yyyy-MM-dd', 'en_US');

      if (formatFromDate > formatToDate) {
        if (controlName == 'dateTo') {
          control.parent
            ?.get('dateFrom')
            ?.setErrors({ dateValidator: true });
        } else {
          control.parent
            ?.get('dateTo')
            ?.setErrors({ dateValidator: true });
        }
        return { dateValidator: true };
      } else {
        if (controlName == 'dateTo') {
          control.parent
            ?.get('dateFrom')
            ?.setErrors(
              startDate?.errors?.required ? { required: true } : null
            );
        } else {
          control.parent
            ?.get('dateTo')
            ?.setErrors(endDate?.errors?.required ? { required: true } : null);
        }
        return null;
      }
    };
  }

  getNewEventPublic(id: number, reportId: number, status: string) {
    this.eventService.getNewEventPublic(id).subscribe({
      next: (res) => {
        if (res != null) {

          let divisionListNumber: number[] = [];
          res?.divisionList.forEach((element: String) => {
            divisionListNumber.push(+element)
          });

          let districtListNumber: number[] = [];
          res?.districtList.forEach((element: String) => {
            districtListNumber.push(+element)
          });

          let sectorListNumber: number[] = [];
          res?.sectorList.forEach((element: String) => {
            sectorListNumber.push(+element)
          });

          this.addNewEventForm.patchValue({
            eventId: res?.eventId,
            eventTypeId: res?.eventType.id,
            eventTitle: res?.eventTitle,
            eventTypeIfOthers: res?.eventTypeIfOthers,
            subType: res?.subType,
            dateFrom: res?.dateFrom,
            dateTo: res?.dateTo,
            purpose: res?.purpose,
            venue: res?.venue,
            partners: res?.partners,
            focalPerson: res?.focalPerson,
            requiredMaterials: res?.requiredMaterials,
            budgetHeadCode: res?.budgetHeadCode,
            budgetEstimate: res?.budgetEstimate,
            organisedByIfOthers: res?.organisedByIfOthers?.value,
            organiserId: res?.organiser.id,
            isBudgetApproved: res?.isBudgetApproved,
            sectors: sectorListNumber,
            isConceptNoteAvailable: res?.isConceptNoteAvailable,
            status: res?.status,
            divisions: divisionListNumber,
            districts: districtListNumber,
            conceptDownloadLink: res?.conceptDownloadLink,
            conceptFileName: res?.conceptFileName,
            conceptFileType: this.getFileTypeFromPreviousAttachment(res?.conceptDownloadLink),
            budgetDownloadLink: res?.budgetDownloadLink,
            budgetFileName: res?.budgetFileName,
            budgetFileType: this.getFileTypeFromPreviousAttachment(res?.budgetDownloadLink),
            remarks: res?.remarks
          })

          if(res?.conceptFileName != null && res?.conceptFileName != ''){
            this.setBlobAttachmentforPreviewing(res?.conceptFileName);
          }

          if(res?.budgetFileName != null && res?.budgetFileName != ''){
            this.setBlobAttachmentforPreviewingBudget(res?.budgetFileName);
          }

          if (status == 'Schedule Submitted' || status == 'Completed' || status == 'Schedule Approved' ||
          (localStorage.getItem('isEventAdmin')==='true' && status == 'Schedule Need Correction')) {
            this.addNewEventForm.disable();
            this.buttonShow = false;
          }

        }
      }
    })
  }

  uploadConceptFile(event: any, conceptTemplateRef: any) {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      conceptTemplateRef.value = null;
      return;
    }

    const file = event.target.files[0];
    let fileFor = event.target.id;
    this.addNewEventForm.patchValue({
      [fileFor]: file,
    });
    this.addNewEventForm.get(fileFor)?.updateValueAndValidity();

    this.addNewEventForm.get('conceptFileName')?.setValue(file?.name);
    this.addNewEventForm.get('conceptDownloadLink')?.setValue(URL.createObjectURL(file));
    this.addNewEventForm.get('conceptFileType')?.setValue(this.getFileTypeFromNewAttachment(file?.type));
  }

  uploadBudgetFile(event: any, budgetTemplateRef: any) {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      budgetTemplateRef.value = null;
      return;
    }
    const file = event.target.files[0];
    let fileFor = event.target.id;
    this.addNewEventForm.patchValue({
      [fileFor]: file,
    });
    this.addNewEventForm.get(fileFor)?.updateValueAndValidity();

    this.addNewEventForm.get('budgetFileName')?.setValue(file?.name);
    this.addNewEventForm.get('budgetDownloadLink')?.setValue(URL.createObjectURL(file));
    this.addNewEventForm.get('budgetFileType')?.setValue(this.getFileTypeFromNewAttachment(file?.type));
    budgetTemplateRef.value = null;
  }

  getFileTypeFromNewAttachment(fileType: string) {
    let contentType = '';
    if (fileType.match(/image/)) {
      contentType = 'image';
    } else if (fileType == 'application/pdf') {
      contentType = 'pdf';
    } else {
      contentType = 'other';
    }
    return contentType;
  }

  getFileTypeFromPreviousAttachment(fileUrl: string) {
    let contentType = '';

    if(fileUrl != null && fileUrl != ''){
      let basename = fileUrl.split(/[\\/]/).pop();
      let pos = basename?.lastIndexOf('.') ?? 0;

      let fileType = basename?.slice(pos + 1) ?? '';
      if (this.imageTypeArray.includes(fileType)) {
        contentType = 'image';
      } else if (fileType == 'pdf') {
        contentType = 'pdf';
      } else {
        contentType = 'other';
      }
    }

    return contentType;
  }

  async setBlobAttachmentforPreviewing(fileName: string) {
    const data = await fetch(`${environment.eventApiUrl}public-dashboard/download-file?fileName=${fileName}`, {
      method: 'GET',
      headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${this.currentUserInfo?.token}`,
      }
    });

    if(data.status == 200){
      const blob = await data?.blob();
      this.addNewEventForm.get('conceptDownloadLink')?.setValue(URL.createObjectURL(blob));
    }
  }

  async setBlobAttachmentforPreviewingBudget(fileName: string) {
    const data = await fetch(`${environment.eventApiUrl}public-dashboard/download-file?fileName=${fileName}`, {
      method: 'GET',
      headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${this.currentUserInfo?.token}`,
      }
    });

    if(data.status == 200){
      const blob = await data?.blob();
      this.addNewEventForm.get('budgetDownloadLink')?.setValue(URL.createObjectURL(blob));
    }
  }

  previewImage(fileType: string | undefined, downloadLink: any, fileName: any) {
    this.imageURL = '';
    this.pdfUrl = '';
    this.otherUrl = '';

    if (fileType == 'image') {
      this.isVisibleForAttachment = true;
      this.imageURL = this.sanitizer.bypassSecurityTrustResourceUrl(downloadLink);
    } else if (fileType == 'pdf') {
      this.isVisibleForAttachment = true;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(downloadLink);
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', downloadLink);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  handleOkFile(): void {
    this.isVisibleForAttachment = false;
  }

  handleCancelFile(): void {
    this.isVisibleForAttachment = false;
  }

  resetForm(): void {
    this.addNewEventForm.reset();
    this.id = null;
    for (const key of Object.keys(this.addNewEventForm.controls)) {
      this.addNewEventForm.controls[key].markAsPristine();
      this.addNewEventForm.controls[key].updateValueAndValidity();
    }
  }
}
