<app-header></app-header>
<main>
  <div class="marketing">
    <section class="form-section">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-lg-12 col-xl-11">
            <div class="card text-black login-card">
              <div class="card-body p-md-5">
                <div class="row justify-content-center">
                  <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                    <p class="text-center h5 fw-bold mx-1 mx-md-4 mt-4">
                      National Action Plan Monitoring
                    </p>
                    <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                      Login
                    </p>

                    <form
                      class="mx-1 mx-md-4"
                      [formGroup]="loginForm"
                      (ngSubmit)="submitForm()"
                    >
                      <div class="d-flex flex-row align-items-center mb-4">
                        <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                        <div class="form-outline flex-fill mb-0">
                          <label class="form-label" for="form3Example3c"
                            >Your Email</label
                          >
                          <input
                            type="email"
                            id="form3Example3c"
                            class="form-control"
                            formControlName="userName"
                          />
                        </div>
                      </div>

                      <div class="d-flex flex-row align-items-center mb-4">
                        <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                        <div class="form-outline flex-fill mb-0">
                          <label class="form-label" for="form3Example4c"
                            >Password</label
                          >
                          <nz-input-group [nzSuffix]="suffixTemplate">
                            <input
                              [type]="passwordVisible ? 'text' : 'password'"
                              nz-input
                              placeholder="password"
                              formControlName="password"
                            />
                          </nz-input-group>

                          <ng-template #suffixTemplate>
                            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                          </ng-template>

                          <a
                            class="login-form-forgot"
                            routerLink="/nap-forgot-password"
                            >Forgot password</a
                          >

                        </div>
                      </div>

                      <div class="d-flex justify-content-center">
                        <div
                          class="d-flex justify-content-center mx-4 mb-3 mb-lg-4"
                        >
                          <button
                            type="button"
                            class="btn btn-danger"
                            (click)="resetLoginForm()"
                          >
                            Reset
                          </button>
                        </div>
                        <div
                          class="d-flex justify-content-center mx-4 mb-3 mb-lg-4"
                        >
                          <button
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="!loginForm.valid"
                          >
                            Log in
                          </button>
                        </div>
                      </div>

                      <!-- <div class="d-flex justify-content-md-center">
                        Or
                        <a routerLink="/registration-category" class="ml-2"
                          >Register now!
                        </a>
                      </div> -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-footer></app-footer>
