export const applicationPermissions = {
  authentication: {},
  authorization: {
    //just added
    menuPermission: 'menu-authorization',
    readPermission: 'read-authorization',
    role: {
      //just added
      menuPermission: 'menu-role',
      readPermission: 'read-role',
      updatePermission: 'update-role',
      deletePermission: 'delete-role',
      createPermission: 'create-role',
      rolePermission: {
        //just added
        menuPermission: 'menu-role-permission',
        readPermission: 'read-role-permission',
        updatePermission: 'update-role-permission',
        deletePermission: 'delete-role-permission',
        createPermission: 'create-role-permission',
      },
    },
    projectModule: {
      //just added
      menuPermission: 'menu-project-module',
      readPermission: 'read-project-module',
      updatePermission: 'update-project-module',
      deletePermission: 'delete-project-module',
      createPermission: 'create-project-module',
    },
    permission: {
      //just added
      menuPermission: 'menu-permission',
      readPermission: 'read-permission',
      updatePermission: 'update-permission',
      deletePermission: 'delete-permission',
      createPermission: 'create-permission',
    },
    featurePermission: {
      menuFeaturePermission: 'menu-feature-permission'
    },
  },
  user: {
    menuPermission: 'menu-user',
    readPermission: 'read-user',
    userList: {
      menuPermission: 'menu-user-list',
      menuCreateUserPermission: 'menu-create-user',
      readPermission: 'read-user',
      updatePermission: 'update-user',
      deletePermission: 'delete-user',
      createPermission: 'create-user',
      stpReadPermission: 'get-stp-user-by-id',
      createNewStpUser: 'create-new-stp-user',
      readStpUser: 'read-stp-user',
      changeUserStatus: 'change-user-status'
    },
    userDetails: {
      menuPermission: 'menu-user-details',
      readPermission: 'read-user-details',
      updatePermission: 'update-user-details',
      deletePermission: 'delete-user-details',
      createPermission: 'create-user-details',
      getAllStpUserIdAclUserIdAndName: 'get-all-stpUserId-aclUserId-and-Name',
    },
    userProfile: {
      menuPermission: 'menu-user-profile',
      readPermission: 'read-user-profile',
      updatePermission: 'update-user-profile',
      deletePermission: 'delete-user-profile',
      createPermission: 'create-user-profile',
    },
  },

  tci: {
    menuPermission: 'menu-tci',
    readPermission: 'read-tci',
    menuSendEmail: 'menu-send-all-email',
    tciList: {
      menuPermission: 'menu-tci-list',
      readPermission: 'read-tci-list',
      updatePermission: 'update-tci-list',
      deletePermission: 'delete-tci-list',
      createPermission: 'create-tci-list',
      menuUploadCertificateListPermission: 'menu-upload-tci-list',
    },
    tciView: {
      menuPermission: 'menu-tci-view',
      readPermission: 'read-tci-view',
      updatePermission: 'update-tci-view',
      deletePermission: 'delete-tci-view',
      createPermission: 'create-tci-view',
    },
  },
  tam: {
    menuPermission: 'menu-tam',
    readPermission: 'read-tam-main',
    tam: {
      menuCreatePermission: 'menu-tam-create',
      menuListPermission: 'menu-tam-list',
      updatePermission: 'update-tam-main',
      deletePermission: 'delete-tam-main',
      createPermission: 'create-tam-main',
    },
    tamView: {
      menuPermission: 'menu-tam-view',
      readPermission: 'read-tam-view',
    },
    archive: {
      menuListPermission: 'menu-archive-list',
      readPermission: 'read-tam-archive-list',
    },
    occupation: {
      menuPermission: 'menu-occupation',
      menuListPermission: 'menu-occupation-list',
      readPermission: 'read-tam-occupation',
      updatePermission: 'update-tam-occupation',
      deletePermission: 'delete-tam-occupation',
      createPermission: 'create-tam-occupation',
      menuCreatePermission: 'menu-occupation-create',
    },
    tamOccupation: {
      readPermission: 'read-tam-occupation-list',
      // updatePermission: 'update-information-for-cad',
      // deletePermission: 'delete-information-for-cad',
      // createPermission: 'create-information-for-cad',
    },
    informationForCs: {
      menuPermission: 'menu-information-for-cs',
      readPermission: 'read-information-for-cs',
      updatePermission: 'update-information-for-cs',
      deletePermission: 'delete-information-for-cs',
      createPermission: 'create-information-for-cs',
    },
    informationForCad: {
      menuPermission: 'menu-information-for-cad',
      readPermission: 'read-information-for-cad',
      updatePermission: 'update-information-for-cad',
      deletePermission: 'delete-information-for-cad',
      createPermission: 'create-information-for-cad',
    },
    informationForCBLM: {
      menuPermission: 'menu-information-for-cblm',
      readPermission: 'read-information-for-cblm',
      updatePermission: 'update-information-for-cblm',
      deletePermission: 'delete-information-for-cblm',
      createPermission: 'create-information-for-cblm',
    },
  },
  payment: {
    menuPermission: 'menu-payment',
    readPermission: 'read-payment',
    paymentList: {
      menuPermission: 'menu-payment-list',
      menuGatewayPermission: 'menu-gateway-payment',
      readPermission: 'read-payment-list',
    },
  },
  applicant: {
    menuPermission: 'menu-applicant',
    readPermission: 'read-applicant',
    applicantList: {
      menuPermission: 'menu-applied-list',
      readPermission: 'read-applied-list',
      updatePermission: 'update-applied-list',
      deletePermission: 'delete-applied-list',
      createPermission: 'create-applied-list',
    },
    applicantForm: {
      menuPermission: 'menu-application-form',
      readPermission: 'read-application-form',
      updatePermission: 'update-application-form',
      deletePermission: 'delete-application-form',
      createPermission: 'create-application-form',
    },
    applicantShortList: {
      menuPermission: 'menu-applicant-short-list',
      readPermission: 'read-applicant-short-list',
      updatePermission: 'update-applicant-short-list',
      deletePermission: 'delete-applicant-short-list',
      createPermission: 'create-applicant-short-list',
    },
    applicantPreviewForm: {
      menuPermission: 'menu-application-preview-form',
      readPermission: 'read-application-preview-form',
      updatePermission: 'update-application-preview-form',
      deletePermission: 'delete-application-preview-form',
      createPermission: 'create-application-preview-form',
    },
  },

  courseAccreditation: {
    menuPermission: 'menu-course-accreditation',
    courseAccreditation: {
      readPermission: 'read-course-accreditation',
      updatePermission: 'update-course-accreditation',
      deletePermission: 'delete-course-accreditation',
      createPermission: 'create-course-accreditation',
      rollbackPermission: 'rollBack-course-accreditation',
      menuApplyForCourseAccreditation: 'menu-apply-for-course-accreditation'
    },
    courseAccreditationList: {
      menuPermission: 'menu-course-accreditation-list',
      menuNsdaPermission: 'menu-course-accreditation-list-nsda',
      menuAppliedForCourseList: 'menu-applied-for-course-list',
      readPermission: 'read-course-accreditation-list',
      readPermissionAdmin: 'read-course-accreditation-list-admin',
      readPermissionInspector: 'read-course-accreditation-list-inspector',
      updatePermission: 'update-course-accreditation-list',
      deletePermission: 'delete-course-accreditation-list',
      createPermission: 'create-course-accreditation-list',
    },
    scheduleInspection: {
      menuPermission: 'menu-schedule-inspection',
      readPermission: 'read-schedule-inspection',
      updatePermission: 'update-schedule-inspection',
      deletePermission: 'delete-schedule-inspection',
      createPermission: 'create-schedule-inspection ',
      genericPermission: 'schedule-inspection', //This permission used for all schedule inspection API
    },
    stpSacInspectionReport: {
      menuPermission: 'menu-stp-sac-inspection-report',
      readPermission: 'read-stp-sac-inspection-report',
      updatePermission: 'update-stp-sac-inspection-report',
      deletePermission: 'delete-stp-sac-inspection-report',
      createPermission: 'create-stp-sac-inspection-report',
    },
    scrutinyResult: {
      menuPermission: 'menu-scrutiny-result',
      readPermission: 'read-scrutiny-result',
      updatePermission: 'update-scrutiny-result',
      deletePermission: 'delete-scrutiny-result',
      createPermission: 'create-scrutiny-result',
      genericPermission: 'scrutiny-result-inspection', //This permission used for all Scrutiny  API
    },
    courseAccreditationPreview: {
      menuPermission: 'menu-course-accreditation-preview',
      readPermission: 'read-course-accreditation-preview',
      updatePermission: 'update-course-accreditation-preview',
      deletePermission: 'delete-course-accreditation-preview',
      createPermission: 'create-course-accreditation-preview',
    },
    completeInspection: {
      genericPermission: 'complete-inspection-report', //This permission used for all Complete inspection API
    },
    decision: {
      genericPermission: 'inspection-decision', //This permission used for all decision API
    },
  },

  stpRegistration: {
    menuPermission: 'menu-stp-registration',
    readPermission: 'read-stp-registration',
    stpApplicationView: {
      menuProfilePermission: 'menu-profile-view',
      menuFullViewPermission: 'menu-full-application-view',
      menuStpDownloadCertificate: 'menu-stp-download-certificate',
      menuEditPermission: 'menu-edit-application-view',
      readPermission: 'read-stp-application-view',
      updatePermission: 'update-stp-application-view',
      deletePermission: 'delete-stp-application-view',
      createPermission: 'create-stp-application-view',
      menuUpdateContactInformation:'menu-update-contact-information',
    },
    stpApplication: {
      menuPermission: 'menu-stp-application',
      menuListPermission: 'menu-application-list',
      readPermission: 'read-stp-application',
      updatePermission: 'update-stp-application',
      deletePermission: 'delete-stp-application',
      createPermission: 'create-stp-application',
      updateSTPNamePermission: 'update-stp-name',
      downloadCertificatePermission: 'download-stp-certificate',
      mailCertificatePermission: 'send-mail-stp-certificate',
      approveSTPPermission: 'approve-stp-application',
      rollbackSTPPermission: 'rollback-stp-application',
      tempApproveRejectPermission: 'temporary-approve-reject-stp-application',
    },
    basicProfile: {
      readPermission: 'read-basic-profile',
      updatePermission: 'update-basic-profile',
      deletePermission: 'delete-basic-profile',
      createPermission: 'create-basic-profile',
      updateBasicProfileOrganizationDetails:
        'update-basic-profile-organization-details'
    },
    stpPayment: {
      readPermission: 'read-stp-payment',
      updatePermission: 'update-stp-payment',
      deletePermission: 'delete-stp-payment',
      createPermission: 'create-stp-payment',
    },
    dashBoard: {
      readPermission: 'read-dash-board',
      updatePermission: 'update-dash-board',
      deletePermission: 'delete-dash-board',
      createPermission: 'create-dash-board',
    },
    stpSuccess: {
      readPermission: 'read-stp-success',
      updatePermission: 'update-stp-success',
      deletePermission: 'delete-stp-success',
      createPermission: 'create-stp-success',
    },
    otpVerification: {
      readPermission: 'read-stp-otp-verification',
      updatePermission: 'update-stp-otp-verification',
      deletePermission: 'delete-stp-otp-verification',
      createPermission: 'create-stp-otp-verification',
    },
    qrCode: {
      readPermission: 'read-stp-qr-code',
      updatePermission: 'update-stp-qr-code',
      deletePermission: 'delete-stp-qr-code',
      createPermission: 'create-stp-qr-code',
    },
  },

  stpOperation: {
    menuPermission: 'menu-training-management',
    project: {
      menuPermission: 'menu-project',
      menuCeatePermission: 'menu-project-create',
      menuListPermission: 'menu-project-list',
      readPermission: 'read-project',
      updatePermission: 'update-project',
      deletePermission: 'delete-project',
      createPermission: 'create-project',
    },
    course: {
      menuPermission: 'menu-course',
      menuCeatePermission: 'menu-course-create',
      menuListPermission: 'menu-course-list',
      readPermission: 'read-course',
      updatePermission: 'update-course',
      deletePermission: 'delete-course',
      createPermission: 'create-course',
    },
    batch: {
      menuPermission: 'menu-batch',
      menuCreatePermission: 'menu-batch-create',
      menuListPermission: 'menu-batch-list',
      readPermission: 'read-batch',
      updatePermission: 'update-batch',
      deletePermission: 'delete-batch',
      createPermission: 'create-batch',
      readIndividualBatch: 'read-individual-batch'
    },
    trainer: {
      menuPermission: 'menu-trainer',
      menuCreatePermission: 'menu-trainer-create',
      menuListPermission: 'menu-trainer-list',
      readPermission: 'read-trainer',
      updatePermission: 'update-trainer',
      deletePermission: 'delete-trainer',
      createPermission: 'create-trainer',
      trainingBatchesByTrainer: 'training-batches-by-trainer'
    },
    trainee: {
      menuPermission: 'menu-trainee',
      menuCreatePermission: 'menu-trainee-create',
      menuListPermission: 'menu-trainee-list',
      readPermission: 'read-trainee',
      updatePermission: 'update-trainee',
      deletePermission: 'delete-trainee',
      createPermission: 'create-trainee',
      removeTrainee: 'remove-trainee',
      dropoutTrainee: 'dropout-trainee'
    },
    asseessmentApplication: {
      menuPermission: 'menu-assessment-application',
      menuAssessmentResultAdmin: 'menu-assessment-result-admin'
    },
    asseessmentStp: {
      menuAssessmentStp: 'menu-assessment-stp',
      menuAssessmentResultStp: 'menu-assessment-result-stp'
    },
    specialBatch: {
      menuSpecialBatch: 'menu-special-batch',
      menuSpecialBatchAdmin: 'menu-special-batch-admin',
      menuSpecialBatchApply: 'menu-special-batch-apply',
      menuSpecialBatchApplicationList: "menu-special-batch-application-list",
      menuSpecialBatchApplicationListAdmin: "menu-special-batch-application-list-admin",
      menuSpecialBatchAssignedAssessmentList: "menu-special-batch-assigned-assessment-list",
      resultScrutinyButtonSpecialBatch: "result-scrutiny-button-special-batch",
      issueCertificateButtonSpecialBatch: "issue-certificate-button-special-batch",
    },
    approvedOccupations: {
      menuApprovedOccupationList: "menu-approved-occupations-list",
      readApprovedOccupations: "read-approved-occupations",
      readOtherAapprovedAssessmentCenter: "other-approved-assessment-center"
    },
    graduateTracking: {
      menuPermission: "menu-graduate-tracking",
      addGraduateTracking: "add-graduate-tracking",
      readGraduateTracking: "read-graduate-tracking"
    },
    newStpUser: {
      menuPermission: 'menu-new-stp-user',
      menuCreatePermission: 'menu-user-create',
      menuListPermission: 'menu-user-list',
      addNewStpUser: 'add-new-stp-user',
      newStpUserList: 'new-stp-user-list',
      updateNewStpUser: 'update-stp-user',
      readNewStpUser: 'read-new-stp-user',
      readStpUsersByStpMain: 'stp-users-by-stp-main-id'
    },
    executeTraining: {
      readExecuteTraining: "read-execute-training",
      addExecuteTraining: "add-execute-training",
      startExecuteTraining: "start-execute-training",
      completeExecuteTraining: "complete-execute-training"
    },
    regularAssessment: {
      menuPermission: 'menu-regular-assessment',
      menuListPermission: 'menu-regular-assessment-list',
      applyRegularAssessment: "apply-regular-assessment",
      updateRegularAssessment: "update-regular-assessment",
      submitRegularAssessment: "submit-reg-assessment",
      //not used yet in frontend
      readRegularAssessmentByBatch: "get-regular-assessment-by-batch",
      //not used yet in frontend
      readRegularAssessmentByApplication: "get-regular-assessment-by-application",
      correctionRequestForRegularAssessment: "correction-request-regular-assessment",
      acceptRegularAssessment: "accept-regular-assessment",
      readRegularAssessmentAdminList: "get-regular-assessment-admin-list",
      saveRegularAssessmentSchedule: "save-regular-assessment-schedule",
      submitRegularAssessmentSchedule: "submit-regular-assessment-schedule",
      resultScrutinyButtonRegular: "result-scrutiny-button-regular",
      issueCertificateButtonRegular: "issue-certificate-button-regular",

      menuRegularCertificatesPending: 'menu-regular-certificates-pending',
      menuRegularCertificatesIssued: 'menu-regular-certificates-issued',
      readRegularAssessmentPendingCertificate : "read-regular-assessment-pending-certificates",
      readRegularAssessmentIssuedCertificate :"read-regular-assessment-issued-certificates",
      reviewButtonRegular :"review-button-regular",
      regularAssessmentRollBack: "regular-assessment-roll-back",
      regularAssessmentViewEditTrainee: "regular-assessment-view-edit-trainee",
      viewTraineeById: "regular-batch-assessment-view-trainee-by-id",
      regularAttendanceAndResultSheetJasperReportDownloadPermission: "regular-batch-attendance-and-result-sheet-download",
    },
    rplAssessment: {
      menuPermission: 'menu-rpl-assessment',
      menuListPermission: 'menu-rpl-assessment-list',
      applyRplAssessment: "apply-rpl-assessment",
      updateRplAssessment: "update-rpl-assessment",
      submitRplAssessment: "submit-rpl-assessment",
      readRplAssessmentByApplication: "get-rpl-assessment-by-application",
      correctionRequestForRplAssessment: "correction-request-rpl-assessment",
      acceptRplAssessment: "accept-rpl-assessment",
      readRplAssessmentAdminList: "get-rpl-assessment-admin-list",
      saveRplAssessmentSchedule: "save-rpl-assessment-schedule",
      submitRplAssessmentSchedule: "submit-rpl-assessment-schedule",
      resultScrutinyButtonRpl: "result-scrutiny-button-rpl",
      issueCertificateButtonRpl: "issue-certificate-button-rpl",

      menuRplCertificatesPending: 'menu-rpl-certificates-pending',
      menuRplCertificatesIssue: 'menu-rpl-certificates-issued',
      readRplAssessmentPendingCertificate : "read-rpl-assessment-pending-certificates",
      readRplAssessmentIssuedCertificate : "read-rpl-assessment-issued-certificates",
      reviewButtonRpl :"review-button-rpl",
      rplAssessmentRollBack: "rpl-assessment-roll-back",
      rplBatchAssessmentViewEditCandidate: "rpl-batch-assessment-view-edit-candidate",
      viewCandidateById: "rpl-batch-assessment-view-candidate-by-id",
      rplAttendanceAndResultSheetJasperReportDownloadPermission: "rpl-batch-attendance-and-result-sheet-download",

      // readRplAssessmentAppListByCertificatesIssuedStatus: "get-rpl-assessment-app-list-by-certificates-issued-status",
      // readRplAssessmentAppListByCertificatesPendingStatus: "get-rpl-assessment-app-list-by-certificates-pending-status",
      // readRegularAssessmentAppListByCertificatesIssuedStatus: "get-regular-assessment-app-list-by-certificates-issued-status",
      // readRegularAssessmentAppListByCertificatesPendingStatus: "get-regular-assessment-app-list-by-certificates-pending-status"
    },
    commonForRplRegAssessment: {
      rplRegAssesApplicationStpList: "get-assessment-application-stp-list",
      //not used yet in frontend
      readAllAssessmentByStp: "read-all-assessment-by-stp",
      updatePaymentSlip: "update-payment-slip",
      menuAssessmentApplicationList: "menu-assessment-application-list",
    },
    stpListAdminView: {
      menuPermission: 'menu-stp-list-admin-view'
    },
    assessmentPayment: {
      regularAssessmentOfflinePayment: "save-reg-offline-payment",
      rplAssessmentOfflinePayment: "save-rpl-offline-payment"
    },
    rplCandidate:{
      menuRplCandidate: "menu-rpl-candidate",
      menuRegisterRplCandidate: "menu-register-rpl-candidate",
      menuCandidateList: "menu-candidate-list",
      menuAssessmentApply: "menu-assessment-apply",
      saveRplCandidate: "save-rpl-candidate",
      readCandidatesExcludingAssessmentId: "candidate-list-without-assessment-id",
      readRplCandidateByRegistrationNo: "read-rpl-candidate-by-registration-no",
      readAllCandidates: "read-all-candidates",
      updateCandidateForReassessment: "update-candidate-for-reassessment",
      readCandidateById: "read-candidate-by-id",
      deleteCandidate: "delete-candidate-by-id"
    },
    rplIndividualAssessment: {
      saveRplIndivScheduleAssessment: "save-rpl-individual-assessment",
      updateRplIndivScheduleAssessment: "update-rpl-individual-assessment",
      submitRplIndivScheduleAssessment: "submit-rpl-individual-assessment",
      deleteRplIndivScheduleAssessment: "delete-rpl-individual-assessment",
      getAllRplIndivScheduleAssessment: "getAll-rpl-individual-assessment",
      getRplIndivScheduleAssessment: "get-rpl-individual-assessment",
      getAllCandidateByAssessmentApplyId: "getAll-candidate-by-assessment-applyId",
      setProfileApplicantAssessmentIdToNull: "set-profile-applicant-assessmentId-toNull",
      getAllRplIndividualStatusType: "getAll-individual-status-type",
    },
    assessor: {
      getAssessorsByOccupationAndLevelRegRpl: "get-assessors-by-occupation-and-level-for-reg-rpl",
      getAssignedAssessmentList: "get-assigned-assessment-list",
      getUploadResultData: "get-upload-result-data",
      saveRegularRplResult: "save-regular-rpl-result",
      submitRegularRplResult: "submit-regular-rpl-result",
      approveResult: "approve-result",
      issueCertificates: "issue-certificates",
      assessorNeedCorrection: "assessor-need-correction",
      clarificationOrCorrection: "clarification-or-correction",
      menuAssessorApplicationListNsda: "menu-assessor-application-list-nsda",
      menuNsdaAssessorPool: "menu-nsda-assessor-pool",
      menuAssessorAccessView: "menu-assessor-access-view",
      menuEntryAssessorPool: "menu-entry-assessor-pool",
      menuAssessor: "menu-assessor",
      menuAssignedAssessmentList: "menu-assigned-assessment-list",
      menuRegularAssignedAssessmentList: "menu-regular-assigned-assessment-list",
      menuRplAssignedAssessmentList: "menu-rpl-assigned-assessment-list",
    },
    FinancialApplication: {
      createapplication: "create-application",
    },
    rollback: {
      menuPermission: 'menu-rollback',
      rollbackStatus: {
        menuPermission: 'menu-rollback-status',
        rollBackApplicationStatus: 'get-rollback-application-status',
      },
    },
  },

  event: {
    menuPermission: 'menu-event',
    menuEventScheduleCreate: 'menu-event-schedule-create',
    menuEventListDashboard: 'menu-event-list-dashboard',
    eventScheduleCreate: 'event-schedule-create',
    eventScheduleReview: 'event-schedule-review',
    eventReportCreate: 'event-report-create',
    eventReportReview: 'event-report-review',
  },

  nap: {
    menuPermission: 'menu-nap',
    menuNapDashboard: 'menu-nap-dashboard',
    menuNapAgency: 'menu-nap-agency',
    menuNapCreateAgency: 'menu-nap-agency-create',
    menuNapAgencyUserList: 'menu-nap-agency-user-list',
    menuNapReview: 'menu-nap-review',
    menuNapTargetReview: 'menu-nap-target-review',
    menuTrainingTargetReview: 'menu-training-target-review',
    menuNapReportReview: 'menu-nap-report-review',
    menuTrainingReportReview: 'menu-training-report-review',
    menuNapTargetAdjReview: 'menu-nap-target-adj-review',
    menuTrainingTargetAdjReview: 'menu-training-target-adj-review',
  },

  policiesAndGuidelines:{
    menuPermission: 'menu-policies-and-guidelines',
    menuDocumentListNsda: 'menu-get-document-list-nsda',
    menuAddDocument: 'menu-add-document',
    menuArchivedList: 'menu-archived-list'
  },

  settings:{
    menuPermission: 'menu-settings',
    ministry:{
      menuPermission: 'menu-ministry',
      menuCreatePermission: 'menu-ministry-create',
      menuListPermission: 'menu-ministry-list',
    },

    agency:{
      menuPermission: 'menu-agency',
      menuCreatePermission: 'menu-agency-create',
      menuListPermission: 'menu-agency-list',
    },
    inspectionOfficer:{
      menuPermission: 'menu-inspection-officer-list',
    },
    addAssessor:{
      menuAddAssessor: 'menu-add-assessor',
    }
  },
  assessorPool:{
    assessorApplicationListNsda:{
      reviewApplicationPermission: 'review-application-button',
      giveDecisionPermission: 'give-decision-button',
      saveDecisionPermission: 'save-decision-button',
      submitDecisionPermission: 'submit-decision-button',
    }
  },
  reports: {
    menuReportPermission: 'menu-report',
    menuPaymentCollection: 'menu-payment-collection',
    menuIssuedCertificates: 'menu-issued-certificates',
    menuStpInfo: 'menu-stp-info'
  },
  isc: {
    menuIscPermission: 'menu-isc',
    menuCreateIscPermission: 'menu-create-isc',
    menuRepresentativeIscPermission: 'menu-representative-isc',
    menuNsdaIscListPermission: 'menu-nsda-isc-list',
    menuRepresentativeIscListPermission: 'menu-representative-isc-list',
    nsdaIscEditPermission: 'nsda-isc-edit',
    iscLevel1ApprovalPermission: 'isc-level-1-approval',
    iscLevel2ApprovalPermission: 'isc-level-2-approval',
    iscLevel3ApprovalPermission: 'isc-level-3-approval',
    iscLevel4ApprovalPermission: 'isc-level-4-approval',
    iscPublishPermission: 'isc-publish',
    iscUnpublishPermission: 'isc-unpublish'
  },

  mra: {
    mraMenuPermission: 'menu-mra',
    mraAttachmentViewPermission: 'view-attachment-mra',
    mraAgreementTypeAddPermission: 'mra-agreement-type-add-permission',
    mraAgreementTypeEditPermission: 'mra-agreement-type-edit-permission',
    mraAgreementTypeDeletePermission: 'mra-agreement-type-delete-permission',
    mraCountryEditPermission: 'mra-country-edit-permission',
    mraountryDeletePermission: 'mra-country-delete-permission',
    mraEditPermission: 'mra-edit',
    mraDeletePermission: 'mra-delete',
    menuAddCountryPermission: 'menu-add-country',
    menuCreateMraPermission: 'menu-create-mra',
    menuMraListPermission: 'menu-mra-list'
  },

  programAndProjects: {
    programAndProjectsMenuPermission: 'menu-program-and-projects',
  },

  researchAndStudies: {
    menuResearchAndStudiesPermission: 'menu-research-and-studies',
    menuAddResearchAndStudiesPermission: 'menu-add-research-and-studies',
    menuListResearchAndStudiesAdminPermission: 'menu-list-research-and-studies-admin',
    menuListResearchAndStudiesPublicPermission: 'menu-list-research-and-studies-public',
    menuListExternalUsersPermission: 'menu-list-external-users',
    menuListIscUsersPermission: 'menu-list-isc-users',
    menuExternalUserRegFormFunctionPermission: 'menu-external-user-reg-form-function',
    addResearchTypeButton: 'add-research-type-button',
    addConductedByButton: 'add-conducted-by-button',
    addAuthorButton: 'add-author-button',
    addRoleButton: 'add-role-button',
    addSectorButton: 'add-sector-button',
    relatedResearchSearchButton: 'related-research-search-button',
    clickToApplyButton: 'click-to-apply-button',
    giveOpinionButton: 'give-opinion-button',
    addFormSaveButtonPermission: 'add-form-save-button',
    addFormSubmitButtonPermission: 'add-form-submit-button',
    adminListViewButtonPermission: 'admin-list-view-button',
    iscListViewButtonPermission: 'isc-list-view-button',
    externalUserListViewButtonPermission: 'external-user-list-view-button',
    demandSurveyEditButtonOnApprovalProcessPermission: 'demand-survey-edit-button-on-approval-process',
    levelOneRecommendationButtonPermission: 'level-one-recommendation-button-permission',
    levelTwoRecommendationButtonPermission: 'level-two-recommendation-button-permission',
    levelThreeRecommendationButtonPermission: 'level-three-recommendation-button-permission',
    historyLogViewButtonPermission: 'history-log-view-button'
  },
  financialApplication: {
    menuFinancing: "menu-financing",
    menuApplicationListStp: "menu-application-list-stp",
    menuApplicationListAdmin: "menu-application-list-admin",
    menuApplicationListNhrdf: "menu-application-list-nhrdf",
    menuCircularListAdmin: "menu-circular-list-admin",
    menuCircularListStp: "menu-circular-list-stp",
    menuCreateCircular: "menu-create-circular",
    menuResolution: "menu-resolution",
    menuResolutionList: "menu-resolution-list",
    summarySheet: "summary-sheet",
    nsdaLevel1Approval: "nsda-level-1-approval",
    nsdaLevel2Approval: "nsda-level-2-approval",
    nhrdfLevel1Approval: "nhrdf-level-1-approval",
    nhrdfLevel2Approval: "nhrdf-level-2-approval",
    correctionApplicationResubmission: "correction-application-resubmission"
  },
};
