import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationStorageService } from '../../services/authentication-storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  title = 'Back ';
  onStartResetPasswordEdit$!: Subscription;
  onEditingResetPasswordId!: number;
  validateForm!: FormGroup;
  isEditMode!: boolean;
  editedItem: any;
  editedItemId!: number;
  emailAlreadyExist: boolean;
  // showingmessage = false;

  constructor(
    private authenticationService: AuthenticationService,
    private authenticationStorageService: AuthenticationStorageService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
    });
  }

  submitForm(): void {
    for (const key of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    this.authenticationService.sendEmail(this.validateForm.value.email).subscribe({
      next: (response) =>
        this.notification.success('Success!', 'Please Check your mail to reset your password'),
      error: (error) =>
        this.notification.error('Failed', 'Please! provide your valid E-mail'),
    });
    this.resetForm();
  }

  resetForm(): void {
    this.validateForm.reset();
    this.isEditMode = false;
    for (const key of Object.keys(this.validateForm.controls)) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  checkEmailExist(email: any): void {
    this.emailAlreadyExist = false;
    // this.showingmessage = false;
    setTimeout(() => {
      if (email.value.length > 3) {
        this.authenticationStorageService
          .checkEmailExist(email.value)
          .subscribe({
            next: (res) => {
              if (res.data == null) {
                this.emailAlreadyExist = false;
                // this.showingmessage = true;
              } else {
                this.emailAlreadyExist = true;
              }
            },
          });
      }
    }, 2000);
  }
}
