<div>
    <nz-modal [(nzVisible)]="isVisible" [nzMaskClosable]="false" nzTitle="Please verify recaptcha"
      (nzOnCancel)="handleCancel()">
      <ng-container *nzModalContent>        
        <form [formGroup]="recaptchaForm">       
          <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>         
        </form>
      </ng-container>
      <div *nzModalFooter style="display: none"></div>
    </nz-modal>
  </div>