import { Batch } from './../components/batch/models/batch';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PaymentDto } from 'src/app/shared/models/dto/payment-dto.model';
import { AssessmentApply } from './../components/assessment/models/assement-apply.model';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Trainee } from './../components/trainee/models/trainee.model';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from './../../../shared/models/dto/server-response.dto';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { regularAssessmentPayload } from '../components/assessment/models/regular-assessment-payload-interface';
import { IAssessmentSubmissionData } from '../components/assessment/models/assessment-interface';
import { ITraineeAssessment } from '../components/assessment/models/trainee-assessment';
import { traineeForAssessment } from '../components/assessment/models/trainee-for-assessment';
import { AssessmentApplicationDetails } from '../components/RPL/components/models/DTO/rpl-assessment-details.response';
import { map } from 'rxjs/operators';
import { IPaymentVerificationRequest } from '../components/assessment/models/DTO/payment-verification-request';

@Injectable({
  providedIn: 'root',
})
export class RegularAssessmentService {
  constructor(
    private httpClient: HttpClient,
    private notification: NzNotificationService
  ) {}

  selectedAllTrainees: BehaviorSubject<ITraineeAssessment[]> =
    new BehaviorSubject<ITraineeAssessment[]>([]);
  selectedTraineesFromPrevious: EventEmitter<Trainee[]> = new EventEmitter<
    Trainee[]
  >();

  paymentSubmissionFormData: EventEmitter<IAssessmentSubmissionData> =
    new EventEmitter<IAssessmentSubmissionData>();
  applicationDetails: EventEmitter<AssessmentApply> =
    new EventEmitter<AssessmentApply>();

  batchLstEmitter: EventEmitter<Batch[]> = new EventEmitter<Batch[]>();
  //#region httprequest type methods
  saveRegularAssessment(data: regularAssessmentPayload) {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.regBatchAssessment.saveRegBatchAssessment}`,
      data
    );
  }

  getRegularAssessmentByBatchId(batchId: number) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.getRegAssessmentByBatchId}/${batchId}`
    );
  }

  getRegularAssessmentByApplicationId(applicationId: number) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.getRegAssessmentByApplicationId}` +
        applicationId
    );
  }

  updateRegularAssessment(appId: number, data: regularAssessmentPayload) {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.updateRegBatchAssessment}/${appId}`,
      data
    );
  }

  getAllTraineeByCurrentBatchId(
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null,
    batchId?: number
  ) {
    let traineeRequestDTO = {
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
      batchId: batchId,
    };
    return this.httpClient.post<ServerResponse>(
      applicationUrls.assessment?.getAllTraineesByCurrentBatchId,
      traineeRequestDTO
    );
  }

  getAllTraineeByPreviousBatchId(
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null,
    batchId?: number,
    previousBatchId?: number,
    // traineeId?: string,
    nidBirthId?: string,
    contactNo?: string,
    occupationId?: number,
    levelId?: number
  ): Observable<ServerResponse> {
    let traineeRequestDTO = {
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
      batchId: batchId,
      previousBatchId: previousBatchId,
      // traineeId: traineeId,
      nidBirthId: nidBirthId,
      contactNo: contactNo,
      occupationId: occupationId,
      levelId: levelId,
    };
    return this.httpClient.post<ServerResponse>(
      applicationUrls.assessment?.getAllTraineesFromPreviousBatchId,
      traineeRequestDTO
    );
  }

  savePaymentInfoforRegularAssessment(
    formData: FormData
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.offlinePayment}`,
      formData
    );
  }

  submitAssessmentApplication(
    applicationId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.submitRegAssessment}`,
      { assessApplyId: applicationId }
    );
  }

  saveRegAssessmentSchedule(
    assessmentApplicationDetails: AssessmentApplicationDetails
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.saveRegAssessmentSchedule}`,
      assessmentApplicationDetails
    );
  }

  submitRegAssessmentSchedule(
    applicationId: number,
    assessor1: number,
    assessor2: number,
    representative1: number,
    representative2: number,
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.submitRegAssessmentSchedule}`,
      {
        assessApplyId: applicationId,
        assessor1: assessor1,
        assessor2: assessor2,
        representative1: representative1,
        representative2: representative2,
      }
    );
  }

  submitPaymentVerifirificationResult(
    data: IPaymentVerificationRequest
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.submitPaymentVerificationResult}`,
      data
    );
  }

  getPaymentInfo(assessApplyId: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.getPaymentInfo}${assessApplyId}`
    );
  }

  getAssessorsByOccupationAndLevel(
    occupationId: number,
    levelId: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.rpl.readAssessorsByOccupationAndLevel}?occupationId=${occupationId}&levelId=${levelId}`
    );
  }

  getOtherApprovedAssessmentCenter(
    stpMainId: number,
    tamMainId: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.getOtherApprovedAssessmentCenter}?stpMainId=${stpMainId}&tamMainId=${tamMainId}`
    );
  }

  removeTraineeFromAssessment(traineeId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.removeTraineeFromAssessment}`,
      { traineeId: traineeId }
    );
  }

  updatePaymentSlip(formData: FormData) {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.updatePaymentSlip}`,
      formData
    );
  }
  //#endregion

  //#region subject/eventemitter methods
  setAllAssessmentTrainees(traineeList: ITraineeAssessment[]) {
    this.selectedAllTrainees.next(traineeList);
  }

  getAllAssessmentTrainees(): Observable<ITraineeAssessment[]> {
    return this.selectedAllTrainees;
  }

  setSelectedTraineeFromPrevious(traineeList: Trainee[]) {
    this.selectedTraineesFromPrevious.emit(traineeList);
  }

  getSelectedTraineeFromPrevious(): EventEmitter<Trainee[]> {
    return this.selectedTraineesFromPrevious;
  }

  setPaymentSubmissionFormData(traineeList: IAssessmentSubmissionData) {
    this.paymentSubmissionFormData.emit(traineeList);
  }

  getPaymentSubmissionFormData(): EventEmitter<IAssessmentSubmissionData> {
    return this.paymentSubmissionFormData;
  }

  setapplicationDetails(appDetails: AssessmentApply) {
    this.applicationDetails.emit(appDetails);
  }

  getapplicationDetails(): EventEmitter<AssessmentApply> {
    return this.applicationDetails;
  }

  setBatchLst(batchList: Batch[]) {
    this.batchLstEmitter.emit(batchList);
  }

  getBatchList(): EventEmitter<Batch[]> {
    return this.batchLstEmitter;
  }
  //#end region

  //#region get rpl reg assessment history by assess apply id
  readRplRegAssessHistoryByAssessApplyId(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.regBatchAssessment.readRplRegAssessHistoryByApplicationId}` +
          id
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //representative List For Trainee
  getRepresentativeInformation(representativeId: number) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.user.readUserById}${representativeId}`
    );
  }
  //#endregion

  //#region download certificate
  onCollectdataToDownloadCertificate(candidateId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.regBatchAssessment.collectDataToDownloadCertificate}` +
          candidateId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  //#endregion
}
