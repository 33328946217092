import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { PgStorageService } from 'src/app/modules/policy-guidelines/services/pg-storage.service';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import { DocumentDTO } from '../../models/DTO/document-dto';
import { DocumentSearch } from '../../models/DTO/document-search';
import { saveAs } from 'file-saver';
import { RequestData } from 'src/app/modules/stp-registration/models/stp_onlinePayment.model';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
  searchForm: FormGroup;
  documentSearch: DocumentSearch;
  hiddingHeaderFooter: boolean = true;
  contentVisble: boolean = true;
  @ViewChild('toDatePicker') toDatePicker!: NzDatePickerComponent;

  documentInfo: DocumentDTO[] = [];
  documents: DocumentDTO;
  userInfo: any;
  requestData: RequestData = new RequestData();
  documentOpinionList: any;
  documentId: any;

  isVisible: boolean = false;
  isVisibleOpinion: boolean = false;
  isInitialSearch: boolean = false;
  isSearchButtonEnable = false;

  //#region Excel part
  excelReportData: any;
  //#endregion

  documentTypeDD: any;
  accessTypeDD: any;

  testData: { id: number; name: string }[] = [
    { id: 1, name: 'Test data 1' },
    { id: 2, name: 'Test data 2' },
  ];

  // for pagination
  total: number;
  size: number = 10;
  page: number = 1;
  sortingKey: string;
  sortingValue: string;
  value: any = null;

  constructor(
    private fb: FormBuilder,
    private pgStorageService: PgStorageService,
    private router: Router,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private commonStorageService: CommonStorageService
  ) {
    this.userId();
    window.location.href.includes('home')
      ? (this.hiddingHeaderFooter = true)
      : !(this.userInfo?.roles == null)
      ? (this.hiddingHeaderFooter = false)
      : (this.hiddingHeaderFooter = false);
  }

  ngOnInit() {
    this.createSearchForm();
    // this.submitSearchForm();
    this.readDocumentType();
    this.readAccessType();
  }

  readDocumentType() {
    this.pgStorageService.readDocumentType().subscribe({
      next: (response) => (this.documentTypeDD = response),
      error: (error) => this.notification.error('Failed', error),
    });
  }

  readAccessType() {
    this.pgStorageService.readAccessType().subscribe({
      next: (response) => (this.accessTypeDD = response),
      error: (error) => this.notification.error('Failed', error),
    });
  }

  readListDataFromServer(documentSearch: DocumentSearch) {
    this.pgStorageService.readDocumentList(documentSearch).subscribe({
      next: (res: ServerResponse) => {
        if (res.success) {
          this.documentInfo = res?.data?.content;
          this.total = res.data?.totalElements;
          // this.notification.success(
          //   'Success!',
          //   'Successfully loaded all data !'
          // );
        } else {
          this.documentInfo = [];
          this.total = 0;
        }
      },
      error: (error) => {
        this.notification.warning('Warning!!! ', 'No data found');
        this.documentInfo = [];
        this.total = 0;
      },
    });
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      generatedDocId: [null],
      documentTypeId: [null],
      documentTitle: [null],
      accessType: [null],
      dateFrom: [null],
      dateTo: [null],
      openForOpinion: [null],
    });
  }

  //#region trainee searching
  submitSearchForm() {
    this.documentSearch = this.searchForm.value;
    this.isInitialSearch = true;
    this.documentSearch.pageSize = this.size;
    this.documentSearch.pageNumber = this.page - 1;
    this.documentSearch.sortingKey = this.sortingKey;
    this.documentSearch.sortingValue = this.sortingValue;
    this.readListDataFromServer(this.documentSearch);
  }
  //#endregion

  onRefresh() {
    this.searchForm.reset();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.page = params.pageIndex;
    this.size = params.pageSize;
    params.sort.forEach((element: { value: string | null; key: string }) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });
    if (this.isInitialSearch == true) {
      this.submitSearchForm();
    }
  }

  //#region modal
  showModal(documents: DocumentDTO): void {
    this.isVisible = true;
    this.documents = documents;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  //#endregion

  //#region date change functionality on select date
  disabledStartDate = (fromDate: Date): boolean => {
    if (!fromDate || !this.searchForm.get('toDate')?.value) {
      return false;
    }
    return fromDate.getTime() > this.searchForm.get('toDate')?.value.getTime();
  };
  disabledEndDate = (toDate: Date): boolean => {
    if (!toDate || !this.searchForm.get('fromDate')?.value) {
      return false;
    }
    return toDate.getTime() <= this.searchForm.get('fromDate')?.value.getTime();
  };
  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.toDatePicker.open();
    }
  }
  handleEndOpenChange(open: boolean): void {}
  //#endregion

  onDownload(data: any): void {
    this.commonStorageService.downloadFile(data.documentUrl).subscribe({
      next: (result: any) => {
        var fileName = data.documentTitle;
        const contentDisposition = result.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            var totalFileName = matches[1].replace(/['"]/g, '');
            var fullFile = totalFileName.split('.');
            var exten = fullFile[1];
            fileName = fileName + '.' + exten;
          }
        }
        saveAs(result.body, fileName);
      },
      error: (error) => this.notification.error('Failed!', 'No file found!'),
    });
  }

  giveOpinion(id: number): void {
    this.router.navigate(['give-opinion-create'], {
      queryParams: { documentId: id },
      relativeTo: this.activatedRoute,
    });
  }

  captchVerificationStatus(verificationStatus: {
    success: boolean;
    message: string;
  }) {
    if (verificationStatus.success) {
      this.notification.success('Success', verificationStatus.message);
      this.contentVisble = true;
      this.submitSearchForm();
      this.isSearchButtonEnable = true;
    } else {
      this.notification.error('Error', verificationStatus.message);
      this.contentVisble = false;
    }
  }

  userId() {
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo') || '{}');
    this.requestData.id = this.userInfo.id;
    if (this.userInfo.id) this.contentVisble = true;
  }

  checkIsCollectableAndGivenTillDate(collectTD?: any) {
    let today = new Date();
    let dateToday = formatDate(today, 'yyyyMMdd', 'en_US');
    let collectTillDate = formatDate(collectTD, 'yyyyMMdd', 'en_US');
    if (collectTD != null) {
      if (
        parseInt(dateToday.toString()) < parseInt(collectTillDate.toString())
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  viewOpinion(document: DocumentDTO): void {
    this.isVisibleOpinion = true;
    this.documentId = document.generatedDocId;

    this.pgStorageService.getDocumentOpinionListById(document.id).subscribe({
      next: (response) => {
        if (response?.success === true) {
          this.notification.success('Success!', response?.message);
          this.documentOpinionList = response?.data;
        } else {
          this.notification.error('Failed', 'Could not delete this document');
        }
      },
      error: (error) => this.notification.error('Failed', error),
    });
  }

  handleCancelOpinion() {
    this.isVisibleOpinion = false;
  }
}
