import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { Country } from 'src/app/modules/mra/components/model/country.model';
import { PaginatedResponse } from 'src/app/modules/mra/components/model/paginated-response.model';
import { HttpErrorHandler } from 'src/app/shared/services/customhttp-error-handler';
import { AgreementType } from '../components/model/agreementType.model';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private apiUrl = applicationUrls.pgDocument; // Base URL for country-related endpoints
  private apiUrlforAllCountry = `${applicationUrls.pgDocument.getAllCountry}`;
  private apiUrlforCountry = `${applicationUrls.pgDocument.getCountry}`;
  private apiUrlforAgreementType = `${applicationUrls.pgDocument.getAllAgreementType}`;

  constructor(
    private httpClient: HttpClient,
    private customErrorHandler: HttpErrorHandler
  ) {}

  private handleError<T>(operation?: string, result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return throwError(() => new Error(error.message || 'An unknown error occurred'));
    };
  }

  getCountriesAll(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(this.apiUrlforCountry).pipe(
      map((response) => response as Country[]), // Cast response to Country[]
      catchError(this.handleError<Country[]>('getCountries', [])) // Provide an empty array as a fallback
    );
  }

  getAllAgreementType(): Observable<AgreementType[]> {
    return this.httpClient.get<AgreementType[]>(this.apiUrlforAgreementType).pipe(
      map((response) => response as AgreementType[]), // Cast response to Country[]
      catchError(this.handleError<AgreementType[]>('AgreementType', [])) // Provide an empty array as a fallback
    );
  }

  getCountries(page: number, size: number, sortingKey: string, sortingValue: string): Observable<PaginatedResponse<Country>> {
    return this.httpClient.get<PaginatedResponse<Country>>(`${this.apiUrlforAllCountry}`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        sortingKey: sortingKey,
        sortingValue: sortingValue
      }
    }).pipe(
      catchError(this.handleError<PaginatedResponse<Country>>('getCountries', { items: [], totalCount: 0 }))
    );
  }

  // Save a new country
  saveCountry(newCountry: Country): Observable<Country> {
    return this.httpClient.post<Country>(this.apiUrl.addCountry, newCountry).pipe(
      catchError(this.handleError<Country>('saveCountry'))
    );
  }

  // Delete a country by ID
  deleteCountry(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl.deleteCountry}/${id}`).pipe(
      catchError(this.handleError<void>('deleteCountry'))
    );
  }

  // Edit an existing country
  editCountry(id: number, updatedCountry: Country): Observable<Country> {
    return this.httpClient.put<Country>(`${this.apiUrl.editCountry}/${id}`, updatedCountry).pipe(
      catchError(this.handleError<Country>('editCountry'))
    );
  }
}
