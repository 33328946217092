import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventModuleService } from '../../event-module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { DatePipe } from '@angular/common';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-public-dashboard',
  templateUrl: './public-dashboard.component.html',
  styleUrls: ['./public-dashboard.component.scss']
})
export class PublicDashboardComponent implements OnInit {

  dashboardDataList: any;
  searchUserDashboardForm!: FormGroup;

  eventStatus: { type: string; name: string }[] = [
    { type: 'Schedule Drafted', name: 'Schedule Drafted' },
    { type: 'Schedule Submitted', name: 'Schedule Submitted' },
    { type: 'Schedule Approved', name: 'Schedule Approved' },
    { type: 'Schedule Need Correction', name: 'Schedule Need Correction' },
    { type: 'Schedule Postponed', name: 'Schedule Postponed' },
    { type: 'Schedule Cancelled', name: 'Schedule Cancelled' },
    { type: 'Report Drafted', name: 'Report Drafted' },
    { type: 'Report Submitted', name: 'Report Submitted' },
    { type: 'Report Need Correction', name: 'Report Need Correction' },
    { type: 'Completed', name: 'Completed' }
  ];

  pageIndex: number;
  sortingKey: any;
  sortingValue: any;
  total: number;
  pageSize: number = 50;
  currentPage: number = 1;
  value: any = null;
  eventTypesList: any;
  organizedByList: any;
  dashboardData: any[];
  districtsList: any[];
  divisionsList: any[];

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private eventService: EventModuleService,
    private router: Router,
    private excelExport: ExcelDownloadService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.searchUserDashboardForm = this.fb.group({
      eventType: [null],
      eventStatus: [null],
      orgName: [null],
      eventTitle: [null],
      dateFrom: [null],
      dateTo: [null]
    });
    this.getEventTypesPublic();
    this.getOrganizersPublic();
    this.getAllDivisions();
    this.getAllDistricts();
  }

  getEventTypesPublic(): void {
    this.eventTypesList = [];
    this.eventService.getEventTypesPublic()
      .subscribe({
        next: (response) => {
          this.eventTypesList = response;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  getOrganizersPublic(): void {
    this.organizedByList = [];
    this.eventService.getOrganizersPublic()
      .subscribe({
        next: (response) => {
          this.organizedByList = response;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  getAllDivisions(): void {
    this.eventService.readDivisions()
      .subscribe({
        next: (response) => {
          this.divisionsList = response.data;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  getAllDistricts(): void {
    this.districtsList = [];
    this.eventService.readDistricts()
      .subscribe({
        next: (response) => {
          this.districtsList = response.data;
        },
        error: (error) => this.notification.error('Failed', error)
      })
  }

  search() {
    this.eventService.getDashboardPublic(this.searchUserDashboardForm?.value, this.pageIndex - 1, this.pageSize, this.sortingKey, this.sortingValue)
      .subscribe({
        next: (res) => {
          this.dashboardDataList = res?.data.content;
          this.total = res?.data.totalElements;
        },
      });
  }

  getDashboardPublic() {
    this.eventService.getDashboardPublic(this.searchUserDashboardForm?.value, this.pageIndex - 1, this.pageSize, this.sortingKey, this.sortingValue)
      .subscribe({
        next: (res) => {
          this.dashboardDataList = res?.data.content;
          this.total = res?.data.totalElements;
        },
        error: (error) => this.notification.error('Failed', error.error.message)
      });
  }

  onPageIndexChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;

    params.sort.forEach((element: { value: string | null; key: string }) => {
      if (element.value != null) {
        this.sortingKey = element.key;
        this.sortingValue = element.value;
      }
    });
    this.getDashboardPublic();
  }

  viewSchedule(tempscheduleId: number, reportId: number, tempStatus: string) {
    if (tempStatus == 'Schedule Submitted' || tempStatus == 'Schedule Approved' || tempStatus == 'Schedule Need Correction') {
      this.router.navigate(['/event/public-add-new-event'], {
        relativeTo: this.activatedRoute,
        queryParams: { id: tempscheduleId, status: tempStatus }
      });
    }
    else if (tempStatus == 'Report Submitted' || tempStatus == 'Report Need Correction') {
      this.router.navigate(['/event/public-add-event-report'], {
        relativeTo: this.activatedRoute,
        queryParams: { id: tempscheduleId, reportId: reportId, status: tempStatus }
      });
    }
  }

  viewReport(tempscheduleId: number, reportId: number, tempStatus: string) {
    if (tempStatus == 'Completed') {
      this.router.navigate(['/event/public-add-event-report'], {
        relativeTo: this.activatedRoute,
        queryParams: { id: tempscheduleId, reportId: reportId, status: tempStatus }
      });
    }
  }

  getBgColor(status: string) {
    if (status == 'Schedule Approved') {
      return '#FFA500';
    }
    if (status == 'Schedule Need Correction') {
      return '#e71d36';
    }
    if (status == 'Schedule Submitted') {
      return '#2a9d8f';
    }
    if (status == 'Report Need Correction') {
      return '#e71d36';
    }
    if (status == 'Report Submitted') {
      return '#2a9d8f';
    }
    if (status == 'Completed') {
      return '#38761d';
    }
    if (status == 'Schedule Postponed') {
      return '#e71d36';
    }
    if (status == 'Schedule Cancelled') {
      return '#e71d36';
    }
    return '#ffffff'
  }

  reset() {
    this.searchUserDashboardForm.get('eventType')?.setValue(null);
    this.searchUserDashboardForm.get('eventStatus')?.setValue(null);
    this.searchUserDashboardForm.get('orgName')?.setValue(null);
    this.searchUserDashboardForm.get('eventTitle')?.setValue(null);
    this.searchUserDashboardForm.get('dateFrom')?.setValue(null);
    this.searchUserDashboardForm.get('dateTo')?.setValue(null);
    this.getDashboardPublic();
  }

  downloadDashboard(): void {
    this.eventService.downloadDashboard(this.searchUserDashboardForm?.value, this.pageIndex - 1, this.pageSize, this.sortingKey, this.sortingValue).subscribe(
      (res) => {
        if (res == null) {
          this.notification.warning(
            'Warning!',
            'No data available to download'
          );
        } else {
          this.dashboardData = res;
          this.excelExport.exportExcelV2(this.getJsonData(this.dashboardData), 'Event Report');
        }
      },
      (error) => {
        this.notification.error('Error!', 'Excel Sheet Data fetch Failed');
      }
    );
  }

  getJsonData(dashboardData: any[]): any[] {
    let csvList: any[] = [];
    dashboardData.forEach((data,index) => {
      let csvData = {
        "Sl": index+1,
        "Event Id": data.eventId,
        "Created At": this.datePipe.transform(data.createdAt, 'dd/MM/yy'),
        "Event Status": data.status,
        "Remarks for Update of Event Schedule": data.approveRemarks,
        "Schedule Review Date": this.datePipe.transform(data.updatedAt, 'dd/MM/yy'),
        "Event Type": data.typeName,
        "Sub-Type": data.subType,
        "Event Title": data.eventTitle,
        "Event Purpose": data.purpose,
        "Event Date From": this.datePipe.transform(data.dateFrom, 'dd/MM/yy'),
        "Event Date To": this.datePipe.transform(data.dateTo, 'dd/MM/yy'),
        "Venue": data.venue,
        "Divisions": this.getDivisionName(data.divisionList),
        "Districts": this.getDistrictName(data.districtList),
        "Organised By": data.organiserName,
        "Focal Person": data.focalPerson,
        "Required Materials": data.requiredMaterials,
        "Estimated Budget": data.budgetEstimate,
        "Budget Head": data.budgetHeadCode,
        "Budget Approved?": data.budgetApproved != null ? (data.budgetApproved == true ? "Yes" : "No") : "",
        "data Uploaded?": data.reportId == null ? "No" : "Yes",
        "Actual Event Date From": this.datePipe.transform(data.dateFrom, 'dd/MM/yy'),
        "Actual Event Date To": this.datePipe.transform(data.dateTo, 'dd/MM/yy'),
        "Actual Cost": data.actualCost,
        "Reason for Variation":"",
        "No. of Female Participants": data.femaleParticipants,
        "No. of Male Participants": data.maleParticipants,
        "No. of Other sex Participants": data.otherGenderParticipants,
        "Total No. of Participants": data.femaleParticipants+data.maleParticipants+data.otherGenderParticipants,
        "No. of PWD Participants": data.pwdParticipants,
        "No. of  Participants from Minority/Marginalized Group": data.minorityParticipants,
        "Report Preapred By": data.preparedBy,
        "Report Preapred Date": this.datePipe.transform(data.createdAt, 'dd/MM/yy'),
        "Report Need Correction Remarks": data.needCorrectionRemarks,
        "Report Approval date": this.datePipe.transform(data.updatedAt, 'dd/MM/yy')
      }
      csvList.push(csvData);
    })
    return csvList;
  }

  getDivisionName(divisionsId: any[]) {
    let divisionNameList: any[] = [];
    divisionsId.forEach((data) => {
      this.divisionsList.forEach(obj =>
      {
        if(obj.id == data)
        {
        divisionNameList.push(obj.divisionName);
        }
      });
    })
    const string = String(divisionNameList);
    return string;
  }

  getDistrictName(districtsId: any[]) {
    let districtNameList: any[] = [];
    districtsId.forEach((data) => {
      this.districtsList.forEach(obj =>
      {
        if(obj.id == data)
        {
        districtNameList.push(obj.districtName);
        }
      });
    })
    const string = String(districtNameList);
    return string;
  }
}
