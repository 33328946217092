import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient: HttpClient) { }

  getStpMainInfoByStpMainIds(data:number[]):Observable<ServerResponse>{
    return this.httpClient.post<ServerResponse>(`${applicationUrls.user.readStpMainInfoByStpMainIds}`,{stpMainIds: data});
  }

  getRegularCertificateByEncryptedId(id:any): any {
    const userId = encodeURIComponent(id??"");
    return this.httpClient
    .get<ServerResponse>(`${applicationUrls.rpl.getCertificateDataForRegularCandidate}` +"?id=" + userId)
    .pipe(
      map((serverResponse: any) => {
        if (!serverResponse.data) {
          return [];
        }
        return serverResponse.data;
      }),
      tap((result: any) => {
        return result.data;
      })
    );
  }

  readTamMains(occupationName: string, tamType?: number): Observable<ServerResponse> {
    let url = applicationUrls.commonService.common.readTamMains;
    if (occupationName && tamType) {
      url += '?occupationName=' + occupationName + '&tamType=' + tamType;
    } else if (occupationName) {
      url += '?occupationName=' + occupationName;
    } else if (tamType) {
      url += '?tamType=' + tamType;
    }
    return this.httpClient.get<ServerResponse>(url).pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse;
      })
    );
  }
}
