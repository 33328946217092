import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../authorization/services/authorization.service';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { map, tap } from 'rxjs/operators';
import { ResearchStudiesMainSearch } from '../models/research-studies-main-search-dto.model';
import { fileDeleteRequest } from '../models/fileDeleteRequest';
import { ResearchAndStudiesService } from './research-and-studies.service';
import { OpinionSearch } from '../models/opinion-search';
import { HistoryLogSearch } from '../models/history-log-search';

@Injectable({
  providedIn: 'root',
})
export class ResearchAndStudiesStorageService {
  constructor(
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService,
    private httpErrorHandler: HttpErrorHandler,
    private notification: NzNotificationService,
    private researchAndStudiesService: ResearchAndStudiesService
  ) {}

  getIndustryList(): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.tamIndustrialSector.read}`
    );
  }

  readResearchType(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.researchTypeInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  readLanguageData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.researchAndStudies.readLanguage}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  readAccessTypeData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.readAccessType}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  readRoleData(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.researchAndStudies.readRole}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  readCommissionedByInfo(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.commissionedByInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  readFundInfo(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.researchAndStudies.fundInfo}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readConductOrOrganizationTypeInfo(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.conductOrResearcherOrganizationTypeInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  // readMinistaryAndAgency(): Observable<any[]> {
  //   return this.httpClient
  //     .get<ServerResponse>(
  //       `${applicationUrls.researchAndStudies.ministaryAndAgencyInfo}`
  //     )
  //     .pipe(
  //       map((serverResponse: ServerResponse) => {
  //         if (!serverResponse.data) {
  //           return [];
  //         }
  //         return serverResponse.data;
  //       })
  //     );
  // }

  readConductOrResearcherOrganization(): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.conductOrResearcherOrganizationInfo}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  checkResearchTypeNameIfExist(
    researchTypeName: string
  ): Observable<ServerResponse> {
    const params = new HttpParams().append(
      'researchTypeName',
      `${researchTypeName}`
    );
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.checkResearchTypeNameExist}`,
        { params }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  checkResearchTypeCodeIfExist(
    researchTypeCode: string
  ): Observable<ServerResponse> {
    const params = new HttpParams().append(
      'researchTypeCode',
      `${researchTypeCode}`
    );
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.researchAndStudies.checkResearchTypeCodeExist}`,
        { params }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  saveResearchType(researchType: any): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.researchAndStudies.saveResearchType}`,
      researchType
    );
  }

  saveResearchAndStudiesMainInfo(
    researchSudiesInfo: any,
    fileList: File[]
  ): Observable<ServerResponse> {
    fileList.forEach((item: File, index: number) => {
      researchSudiesInfo.append('file', item);
    });
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.saveResearchAnsStudiesInfo}`,
      researchSudiesInfo
    );
  }

  updateResearchAndStudiesInfo(
    researchSudiesInfo: any,
    researchStudiesManiId: number | null,
    fileList: File[]
  ): Observable<ServerResponse> {
    fileList.forEach((item: File, index: number) => {
      researchSudiesInfo.append('file', item);
    });
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.researchAndStudies.updateResearchAnsStudiesInfo}` +
        researchStudiesManiId,
      researchSudiesInfo
    );
  }

  deleteAuthor(authorId?: number): Observable<ServerResponse> {
    return this.httpClient.delete<ServerResponse>(
      `${applicationUrls.researchAndStudies.deleteAuthor}` + authorId
    );
  }

  //#region read admin
  readAdminInfo(
    researchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    researchStudiesMainSearch.disable = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getAdminList}`,
      researchStudiesMainSearch
    );
  }

  readRsMainInfoForRelatedResearch(
    researchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    researchStudiesMainSearch.disable = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getRSMainListForRelatedResearch}`,
      researchStudiesMainSearch
    );
  }

  readExternalUserListInfo(
    researchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    researchStudiesMainSearch.disable = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getExternalUsersList}`,
      researchStudiesMainSearch
    );
  }

  readIscUserListInfo(
    researchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    researchStudiesMainSearch.disable = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getIscUsersList}`,
      researchStudiesMainSearch
    );
  }

  getResearchStudiesDataByGlobalSearch(
    researchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getResearchStudiesDataByGlobalSearch}`,
      researchStudiesMainSearch
    );
  }

  //#region read admin
  readResearchAndStudiesPublicInfo(
    researchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    researchStudiesMainSearch.disable = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getPublicList}`,
      researchStudiesMainSearch
    );
  }

  getResearchAndStudiesMainInfoDetailsById(
    id: number
  ): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.researchAndStudies.getDetailsInfo}` + id
    );
  }

  getAuthorInfoById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.researchAndStudies.getAuthorInfo}` + id
    );
  }

  getResearchStudiesMainDetailsInfoById(
    researchStudiesMainId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append(
      'researchStudiesMainId',
      `${researchStudiesMainId}`
    );
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.researchAndStudies.getResearchStudiesMainDetailsInfoById}`,
      { params }
    );
  }

  getResearchStudiesMainDetailsInfoByIdForPublicList(
    researchStudiesMainId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append(
      'researchStudiesMainId',
      `${researchStudiesMainId}`
    );
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.researchAndStudies.getResearchStudiesMainDetailsInfoByIdForPublicList}`,
      { params }
    );
  }

  submitDocument(
    id?: any,
    isUserRoleExternalUsers?: boolean,
    isUserRoleIsc?: boolean,
    isUserTagNsda?: boolean
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.researchAndStudies.submitDocument}`,
      {
        researchStudiesMainId: id,
        isUserRoleExternalUsers: isUserRoleExternalUsers,
        isUserRoleIsc: isUserRoleIsc,
        isUserTagNsda: isUserTagNsda,
      }
    );
  }
  //#endregion

  onMakePublishPublicOrLimited(
    id?: any,
    statusTypeId?: any,
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.researchAndStudies.makePublishPublicOrPublishLimited}`,
      {
        researchStudiesMainId: id,
        statusTypeId: statusTypeId
      }
    );
  }
  //#endregion

  //#region delete document
  deleteDocument(researchStudiesMainId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.researchAndStudies.deleteDocument}`,
      {
        researchStudiesMainId: researchStudiesMainId,
      }
    );
  }
  deleteAttachment(data: fileDeleteRequest): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.deleteAttachment}`,
      data
    );
  }

  saveIndustrySector(industrySector: any): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.researchAndStudies.saveTamIndustrySector}`,
      industrySector
    );
  }

  saveRole(role: any): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.researchAndStudies.saveRole}`,
      role
    );
  }

  saveConductedByForm(conductedBy: any): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.researchAndStudies.saveConductedBy}`,
      conductedBy
    );
  }

  //#region remove document
  removeRelatedResearch(
    id: number,
    researchStudiesMainId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.researchAndStudies.removeRelatedResearch}`,
      {
        id: id,
        researchStudiesMainId: researchStudiesMainId,
      }
    );
  }

  //#region download rs report
  onDownloadRsReport(
    sesearchStudiesMainSearch: ResearchStudiesMainSearch
  ): Observable<ServerResponse> {
    sesearchStudiesMainSearch.disable = true;
    return this.httpClient
      .post<ServerResponse>(
        applicationUrls.researchAndStudies.downloadRsReport,
        sesearchStudiesMainSearch
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.researchAndStudiesService.setResearchAndStudiesInfoForReport(
              response.data
            );
          }
        })
      );
  }

  saveOpinion(opinionData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${applicationUrls.researchAndStudies.saveOpinion}`,
      opinionData
    );
  }

  getOpinionInfoById(opinionSearch: OpinionSearch) {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getOpinionInfo}`,
      opinionSearch
    );
  }
  getHistoryLogViewInfoById(historyLogSearch: HistoryLogSearch) {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.researchAndStudies.getHistoryLogInfo}`,
      historyLogSearch
    );
  }

  saveOrUpdateExternalUserRegFormManager(data: any): Observable<any> {
    console.log(data);

    return this.httpClient.post<any>(
      `${applicationUrls.researchAndStudies.externalUser.saveOrUpdateExternalUserRegFormInformation}`,
      data
    );
  }


  //#region read external user reg form manager count
readExternalUserRegFormInfo(): Observable<ServerResponse> {
  return this.httpClient
    .get<ServerResponse>(`${applicationUrls.researchAndStudies.externalUser.getExternalUserRegFormInformation}`)
}
//#endregion
  getUserInformation(representativeId: number) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.user.readUserById}${representativeId}`
    );
  }
}
