import { CustomValidatorService } from './../../shared/services/custom-validator.service';
import { CourseAccInspectionReportService } from './../course-acc-inspection-report/services/course-acc-inspection-report.service';
import { CourseAccInspectionReportStorageService } from './../course-acc-inspection-report/services/course-acc-inspection-report-storage.service';
import { InspectionService } from './../course-accreditation/services/inspection.service';
import { InspectionStorageService } from './../course-accreditation/services/inspection-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from './../../ng-zorro-antd.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompleteInspectPreviewSharedComponent } from './complete-inspect-preview-shared/complete-inspect-preview-shared.component';
import { CourseAccreditationInspectionPreviewSharedComponent } from './course-accreditation-inspection-preview-shared/course-accreditation-inspection-preview-shared.component';
import { StpSacPreviewSharedComponent } from './stp-sac-preview-shared/stp-sac-preview-shared.component';

@NgModule({
  declarations: [
    CompleteInspectPreviewSharedComponent,
    CourseAccreditationInspectionPreviewSharedComponent,
    StpSacPreviewSharedComponent
  ],
  imports: [
    CommonModule,     
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    CompleteInspectPreviewSharedComponent,
    CourseAccreditationInspectionPreviewSharedComponent,
    StpSacPreviewSharedComponent
  ],  
  providers:[
    InspectionStorageService,
    InspectionService,
    CourseAccInspectionReportStorageService,
    CourseAccInspectionReportService,
    CustomValidatorService,
  ]
})
export class SharedCourseAccreditationModule { }
