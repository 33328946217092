import { RplAssessmentApplicationLRequestDto } from './../components/RPL/components/models/RplAssessmentApplicationLRequestDto.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';

import {
  ErrorHandler,
  HttpErrorHandler,
} from 'src/app/shared/services/http-error-handler.service';
import { RegAssessmentSearch } from '../components/assessment/models/DTO/reg-assessment-search';
import { Candidate } from '../components/RPL/components/models/candidate.model';
import { ApplyForRPLBatchAssessmentDTO } from '../components/RPL/components/models/DTO/apply-for-rpl-bath-assessment-DTO';
import { RPLAssessmentSearch } from '../components/RPL/components/models/DTO/rpl-assessment-search';
import { RplService } from './rpl.service';
import { AssessmentApplicationDetails } from '../components/RPL/components/models/DTO/rpl-assessment-details.response';
import { CertificateSearchRequest } from '../components/assessment/models/DTO/certificate-search-request';
@Injectable({
  providedIn: 'root',
})
export class RplStorageService {
  private handleError: ErrorHandler;
  constructor(
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private rplService: RplService
  ) {
    this.handleError =
      this.httpErrorHandler.createErrorHandler('Freelancer Service');
  }

  readStpDistrictsByDivisionId(divisionId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpDistrictByDivisionId}` +
          `districts/${divisionId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  readStpUpazillasByDistrictId(districtId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.basicProfile.readStpUpazilla}district/${districtId}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }

  //#region candidate list
  readNullableAssessApplyIdCandidateList(
    name: string,
    registrationNo: string,
    nidBirthId: string,
    email: string,
    contactNo: string,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<ServerResponse> {
    let pageableRequestDTO = {
      name: name,
      registrationNo: registrationNo,
      nidBirthId: nidBirthId,
      email: email,
      contactNo: contactNo,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.getNullableAssessApplyIdCandidateList}`,
      pageableRequestDTO
    );
  }
  //#endregion

  //#region create rpl batch assessment
  createRplBatchAssessment(
    rplBathAssessmentSave: ApplyForRPLBatchAssessmentDTO
  ) {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.rpl.create}`,
        rplBathAssessmentSave
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  //#endregion

  //#region get rpl batch assessment by id
  readRplBatchAssessmentById(assessApplyId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.rpl.readReviewApplicationById}` + assessApplyId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region update rpl batch assessment
  updateRplBatchAssessment(
    rplBathAssessmentUpdate: ApplyForRPLBatchAssessmentDTO,
    id: number | null
  ) {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.rpl.update}` + id,
        rplBathAssessmentUpdate
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.rplService.updatedRPLBatchAssessment(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
  //#endregion

  //#region Submit rpl batch assessment
  onSubmitRplBatchAssessment(
    assessApplyId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.submitRplBatchAssessment}`,
      { assessApplyId: assessApplyId }
    );
  }
  //#endregion

  //#region payment for rpl batch assessment
  savePaymentInfoForRPL(formData: FormData): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.rplPayment.paymentForRPL}`,
        formData
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region get review application info by id
  readReviewApplicationInfoById(applicationId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.rpl.readReviewApplicationById}` + applicationId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region get rpl reg assessment history by assess apply id
  readRplRegAssessHistoryByAssessApplyId(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.rpl.readRplRegAssessHistoryByAssessApplyId}` + id
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region accept application
  acceptApplication(applicationId: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.acceptReviewApplication}` + applicationId,
      applicationId
    );
  }
  //#endregion

  //#region accept reg application
  acceptRegReviewApplication(
    applicationId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.acceptRegReviewApplication}` +
        applicationId,
      applicationId
    );
  }
  //#endregion

  readAllTrainees(
    rplAssessmentApplicationLRequestDto: RplAssessmentApplicationLRequestDto
  ): Observable<ServerResponse> {
    rplAssessmentApplicationLRequestDto.disabled = false;
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.rpl.getAssessmentApplicationList}`,
        rplAssessmentApplicationLRequestDto
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.rplService.setAssessmentAppl(response.data);
          }
        })
      );
  }

  readAllAssessmentApplication(
    data: any,
    pageSize: number,
    currentPage: number,
    sortingKey: any,
    sortingValue: any
  ): Observable<ServerResponse> {
    let object = {
      occupation: data.occupation,
      level: data.level,
      applicationStatus: data.applicationStatus,
      assessmentType: data.assessmentType,
      pageSize: pageSize,
      pageNumber: currentPage,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
      disabled: false,
    };
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.rpl.getAssessmentApplicationList}`,
        object
      )
      .pipe(
        tap((response) => {
          if (response.data) {
            this.rplService.setAssessmentAppl(response.data);
          }
        })
      );
  }

  getAssessmentType(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.rpl.getAssessmentType}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }

  //#endregion

  //#region need correction
  onNeedCorrection(
    applicationId: number,
    correctionRemarks: string
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.needCorrection}`,
      {
        assessmentApplicationId: applicationId,
        correctionRemarks: correctionRemarks,
      }
    );
  }
  //#endregion

  //#region need correction
  onRegNeedCorrection(
    applicationId: number,
    correctionRemarks: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.needCorrection}`,
      {
        assessmentApplicationId: applicationId,
        correctionRemarks: correctionRemarks,
      }
    );
  }
  //#endregion

  //#region
  candidateByOccupationAndLevel(tamMainId: number) {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.RPLIndividual.searchCandidateByOccupationAndLevel}`,
      { tamMainId: tamMainId }
    );
  }
  //#endregion

  //#region add candidate
  createRplBatch(newRplBatchInfo: any) {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.RPLBatch.createRplBatch}`,
      newRplBatchInfo
    );
  }
  //#endregion

  //#region read RPL Assessment
  readRPLAssessment(
    rplAssessmentListSearch: RPLAssessmentSearch
  ): Observable<ServerResponse> {
    rplAssessmentListSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.getRPLAssessmentInfo}`,
      rplAssessmentListSearch
    );
  }

  //#region read assessment app list by certificate pending status
  readRplAssessmentAppListByCertificatesPendingStatus(
    certificateSearchRequest: CertificateSearchRequest
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.readRplAssessmentAppListByCertificatesPendingStatus}`,
      certificateSearchRequest
    );
  }
  //#endregion

  //#region read assessment app list by certificate issued status
  readRplAssessmentAppListByCertificatesIssuedStatus(
    certificateSearchRequest: CertificateSearchRequest
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.readRplAssessmentAppListByCertificatesIssuedStatus}`,
      certificateSearchRequest
    );
  }
  //#endregion

  //#region read assessment app list by certificate pending status
  readRegularAssessmentAppListByCertificatesPendingStatus(
    certificateSearchRequest: CertificateSearchRequest
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.readRegularAssessmentAppListByCertificatesPendingStatus}`,
      certificateSearchRequest
    );
  }
  //#endregion

  //#region read assessment app list by certificate issued status
  readRegularAssessmentAppListByCertificatesIssuedStatus(
    certificateSearchRequest: CertificateSearchRequest
  ): Observable<ServerResponse> {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.readRegularAssessmentAppListByCertificatesIssuedStatus}`,
      certificateSearchRequest
    );
  }
  //#endregion

  readRPLAssessmentFixedStatus(
    rplAssessmentListSearch: RPLAssessmentSearch
  ): Observable<ServerResponse> {
    rplAssessmentListSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.getRPLAssessmentInfoByStatus}`,
      rplAssessmentListSearch
    );
  }
  //#endregion

  //#region read Reg Assessment
  readRegAssessment(
    regAssessmentListSearch: RegAssessmentSearch
  ): Observable<ServerResponse> {
    regAssessmentListSearch.disabled = false;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.assessment.getRegAssessmentInfo}`,
      regAssessmentListSearch
    );
  }
  //#endregion

  //#region get review application info by id
  readRegReviewApplicationInfoById(applicationId: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.regBatchAssessment.readRegReviewApplicationById}` +
          applicationId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region get rpl Schedule Assessment Batch Info by id
  readRplScheduleAssessmentBatchInfoById(
    applicationId: number
  ): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.rpl.readReviewApplicationById}` + applicationId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region get reg Schedule Assessment Batch Info by id
  readRegScheduleAssessmentBatchInfoById(
    applicationId: number
  ): Observable<any> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.readRegReviewApplicationById}` +
        applicationId
    );
  }
  //#endregion

  //#region list of assessors
  readAssessors(): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.rpl.readAssessors}`)
      .pipe(
        tap((response) => {
          this.rplService.setAssessor(response.data);
        })
      );
  }
  //#endregion

  //#region Submit rpl assessment schedule
  onSubmitRplAssessmentSchedule(
    applicationId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.submitRplAssessmentSchedule}`,
      { assessApplyId: applicationId }
    );
  }
  //#endregion

  //#region Submit Verification Result If Failed
  submitVerificationResultIfFailed(
    applicationId: number,
    VerificationFailedReason: string
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.submitVerificationResultIfFailed}`,
      { applicationId: applicationId, remarks: VerificationFailedReason }
    );
  }
  //#endregion

  //#region Save rpl assessment Schedule
  saveRplAssessmentSchedule(
    assessmentApplicationDetails: AssessmentApplicationDetails
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.saveRplAssessmentSchedule}`,
      assessmentApplicationDetails
    );
  }
  //#endregion

  //#region Save reg assessment Schedule
  saveRegAssessmentSchedule(
    assessmentApplicationDetails: AssessmentApplicationDetails
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.regBatchAssessment.saveRegAssessmentSchedule}`,
      assessmentApplicationDetails
    );
  }
  //#endregion

  //#region get review application info by id
  readRegAssessmentApplicationsCandidateListById(
    applicationId: number
  ): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.regBatchAssessment.readRegReviewApplicationById}` +
          applicationId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }
  //#endregion get review application info by id

  getAllCandidate(): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.RPLBatch.getAllCandidate}`
    );
  }

  getCandidateInfoByStpMainId(
    registrationNo: string,
    nidBirthId: string,
    email: string,
    contactNo: string,
    page?: number | 0,
    size?: number | 10,
    sortingKey?: string | null,
    sortingValue?: string | null
  ): Observable<ServerResponse> {
    let pageableRequestDTO = {
      registrationNo: registrationNo,
      nidBirthId: nidBirthId,
      email: email,
      contactNo: contactNo,
      page: page,
      size: size,
      sortingKey: sortingKey,
      sortingValue: sortingValue,
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.RPLBatch.getCandidateInfoByStpMainId}`,
      pageableRequestDTO
    );
  }

  deleteCandidate(id: number): Observable<ServerResponse> {
    return this.httpClient.delete<ServerResponse>(
      `${applicationUrls.RPLBatch.deleteCandidate}` + id
    );
  }
  //#endregion

  //#region accept reg application
  getRegAssessmentApplicationsByAssessmentId(
    assessmentId: number
  ): Observable<any> {
    console.log(
      'applicationUrls.regBatchAssessment.getRegAssessmentApplicationsByAssessmentId',
      applicationUrls.regBatchAssessment
        .getRegAssessmentApplicationsByAssessmentId
    );
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.regBatchAssessment.getRegAssessmentApplicationsByAssessmentId}` +
          assessmentId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          console.log(serverResponse);
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region get Rpl Candidate By Registration Number

  getRplCandidateByRegistrationNumber(
    registrationNo: string
  ): Observable<ServerResponse> {
    const encodedRegistrationNumber = encodeURIComponent(registrationNo);
    const decodeRegistrationNumber = decodeURIComponent(
      encodedRegistrationNumber
    );
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.RPLBatch.getRplCandidateByRegistrationNumber}?registrationNo=` +
          decodeRegistrationNumber
      )
      .pipe(
        map((serverResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion get Rpl Candidate By Registration Number

  //#region assessors by occupation and level
  getAssessorsByOccupationAndLevel(occupationId: number, levelId: number) {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.rpl.readAssessorsByOccupationAndLevel}?occupationId=${occupationId}&levelId=${levelId}`
    );
  }
  //#endregion

  //#region remove rpl candidate from assessment apply
  removeRplCandidateFromAssessment(
    candidateId: number
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.rpl.removeRplCandidateFromAssessment}`,
      { candidateId: candidateId }
    );
  }

  updateCandidateForReAssessment(
    rplCandidate: any,
    candidateId: number | null
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.RPLBatch.updateCandidateForReAssessment}` +
        candidateId,
      rplCandidate
    );
  }
  updateCandidateForReAssessmentAdmin(
    rplCandidate: any,
    candidateId: number | null
  ): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.RPLBatch.updateCandidateForReAssessmentAdmin}` +
        candidateId,
      rplCandidate
    );
  }

  //#region download certificate
  onCollectdataToDownloadCertificate(candidateId: number): Observable<any[]> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.rpl.collectDataToDownloadCertificate}` + candidateId
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        })
      );
  }
  //#endregion

  //#region get assessor info info by id
  readAssessorInfoInfoById(applicationId: number): Observable<any> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.rpl.readAssessorInfoById}?assessApplyId=${applicationId}`
    );
  }
  //#endregion

  //#region get assessor info info by id
  readRegAssessorInfosInfoById(applicationId: number): Observable<any> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.readRegAssessorInfoById}?assessApplyId=${applicationId}`
    );
  }
  //#endregion

  //#region get assessor result status info by assess apply id
  readRegRplResultStatusInfoById(applicationId: number): Observable<any> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.regBatchAssessment.readRegRplResultStatusIInfoById}?assessApplyId=${applicationId}`
    );
  }
  //#endregion

  //#region download certificate
  downloadCertificateForRpl(candidate: any): any {
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.downloadRplCertificateFromTci}`,
      candidate,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }
  //#endregion

  //#region download RPL batch assessment info
  onDownloadRPLAssessmentBatchReport(
    rplBatchAssessmentReport: RPLAssessmentSearch
  ): Observable<ServerResponse> {
    rplBatchAssessmentReport.disabled = true;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.rpl.downloadRplBatchAssessmentReport}`,
      rplBatchAssessmentReport
    );
  }
  //#endregion

  //#region download Regular batch assessment info
  onDownloadRegularAssessmentBatchReport(
    regAssessmentListSearch: RegAssessmentSearch
  ): Observable<ServerResponse> {
    regAssessmentListSearch.disabled = true;
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.assessment.downloadRegularBatchAssessmentReport}`,
      regAssessmentListSearch
    );
  }
  //#endregion

  //#region rpl candidate info for QR Code
  getCertificateDataForRplCandidate(id: any): any {
    const userId = encodeURIComponent(id ?? '');
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.rpl.getCertificateDataForRplCandidate}` +
          '?id=' +
          userId
      )
      .pipe(
        map((serverResponse: any) => {
          if (!serverResponse.data) {
            return [];
          }
          return serverResponse.data;
        }),
        tap((result: any) => {
          return result.data;
        })
      );
  }
  //#endregion

  //#region roll back
  onRollBack(id: number, assessmentType: number): Observable<ServerResponse> {
    return this.httpClient.put<ServerResponse>(
      `${applicationUrls.assessment.onRollBack}`,
      {
        applicationId: id,
        assessmentType: assessmentType,
      }
    );
  }
  //#endregion

  //#region check existing stp center
  checkStpCenterIfExist(tamMainId: number): Observable<ServerResponse> {
    const params = new HttpParams().append('tamMainId', `${tamMainId}`);

    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.course.getStpCenterByTamMainId}`,
        { params }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region check existing stp center for rpl assessment
  checkStpCenterForRplAssessmentIfExist(
    tamMainId: number
  ): Observable<ServerResponse> {
    const params = new HttpParams().append('tamMainId', `${tamMainId}`);

    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.course.getStpCenterForRplAssessmentByTamMainId}`,
        { params }
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion

  //#region roll back
  getApprovedCsOccupations(
    occupationIds: any[],
    tamType: number
  ): Observable<ServerResponse> {
    const payload = {
      occupationIds: occupationIds,
      tamType: tamType,
    };
    return this.httpClient.post<ServerResponse>(
      `${applicationUrls.tamMain.readApprovedCSOccupation}`,
      payload
    );
  }
  //#endregion

  //#region jasper report
  onDownloadRPLAssessmentReportUsingJasper(
    assessApplyId: number,
    // assessorRefIdOne: number,
    exportTypeId: string
  ): Observable<ServerResponse> {
    const params = new HttpParams()
      .append('assessApplyId', `${assessApplyId}`)
      // .append('assessorRefIdOne', `${assessorRefIdOne}`)
      .append('exportTypeId', `${exportTypeId}`);
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.jasper.onDownloadMainReport}`, {
        params,
      })
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
  //#endregion
}
