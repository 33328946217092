import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import saveAs from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { RegistrationCard } from 'src/app/modules/applicant/models/registrationCard';
import { StpRegistrationStorageService } from 'src/app/modules/stp-registration/services/stp-registration-storage.service';
import { StpRegistrationService } from 'src/app/modules/stp-registration/services/stp-registration.service';
import { CommonStorageService } from 'src/app/shared/services/common-storage.service';
import * as XLSX from 'xlsx';
import { UtilityService } from '../../../../shared/services/utility.service';

import {
  TciInfoDto,
  TciTrainingDetailsPayload,
} from '../../DTO/tciInfoDto.model';
import { TciInfoService } from '../../services/tci-info.service';
import { TciInfoStorageService } from './../../services/tci-info-storage.service';

@Component({
  selector: 'app-tci-view',
  templateUrl: './tci-view.component.html',
  styleUrls: ['./tci-view.component.scss'],
})
export class TciViewComponent implements OnInit {
  userInfo: any;
  tamMainDetails: any;
  excelData: any[] = [];
  userFile: any = File;
  allowedExtensions = '.xlsx,.xls';
  maxFormatFileSize = 10;
  @ViewChild('myInput')
  myInputVariable: ElementRef;

  stpRegistrationId: string = "RPL-GDF-L3-000001";
  stpDateOfRegistration: string = "7-Nov-2021";
  nameOfOccupation: string = "New Occupation";
  occupationLevel: string;
  nameOfApplicant: string;
  motherName: string;
  fatherName: string;
  nid: number;
  dateOfAssessment: string = "7-Nov-2021";
  venueOfAssessment: string = "Dhaka";
  photoUrl: string;
  qrCodeUrl: string;
  filePathUrl: string = "http://172.16.28.80:8401/uploadfile?fileUrl=";
  photoUrlApplicant: string;
  signatureUrlApplicant: string;
  birthRegistration: string;
  applicantPhoto: string;
  applicantSign: string;

  constructor(
    private stpRegistrationStorageService: StpRegistrationStorageService,
    private stpRegistrationService: StpRegistrationService,
    private commonStorageService: CommonStorageService,
    private utilityService: UtilityService,
    private tciInfoStorageService: TciInfoStorageService,
    private tciInfoService: TciInfoService,
    private notification: NzNotificationService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void { }

  //#region Excel File Upload And Read Excel Data

  onSelectFile(event: any): void {
    const message = this.utilityService.validateInputFile(
      event.target.files,
      this.allowedExtensions,
      this.maxFormatFileSize
    );
    if (message !== '') {
      this.notification.error('error', message);
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    const file = event.target.files[0];
    this.userFile = file;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {
        type: 'binary',
        cellDates: true,
      });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
      this.excelData = data;
      return data;
    };
  }

  submitExcelData(): void {
    const tciInfoList: TciInfoDto[] = [];

    let trainingDetailsList: TciTrainingDetailsPayload[] = [];

    if (this.myInputVariable.nativeElement.value == 0) {
      this.notification.error(
        'Failed!',
        'Please upload excel file'
      );
      return;
    } else {
      for (const item of this.excelData) {
        console.log('item', item);

        let tciInfo = new TciInfoDto();
        trainingDetailsList = [];

        tciInfo.nameEnglish = item.Name_in_English;
        tciInfo.nameBangla = item.Name_in_Bangla;
        tciInfo.fatherNameEnglish = item.Father_Name_in_English;
        tciInfo.fatherNameBangla = item.Father_Name_in_Bangla;
        tciInfo.motherNameEnglish = item.Mother_Name_in_English;
        tciInfo.motherNameBangla = item.Mother_Name_in_Bangla;
        tciInfo.nidEnglish = item.National_ID_No_in_English;
        tciInfo.nidBangla = item.National_ID_No_in_Bangla;
        tciInfo.certificateSerialNo = item.Certificate_Serial_No;
        tciInfo.registrationNo = item.Registration_Number;


        var issuedDateConverted = item.Issued_Date.replace(/(\d+[/])(\d+[/])/, '$2$1');
        let issuedDate = new Date(issuedDateConverted);
        tciInfo.issueDate = issuedDate;

        var validUntilConverted = item.Valid_Until.replace(/(\d+[/])(\d+[/])/, '$2$1');
        let validUntil = new Date(validUntilConverted);
        tciInfo.validityDate = validUntil;

        tciInfo.occupationNameEnglish = item.Occupation_English_Name;
        tciInfo.occupationNameBangla = item.Occupation_Bangla_Name;
        tciInfo.level = item.Level;
        tciInfo.email = item.Email_ID;
        tciInfo.contact = item.Contact;

        if (item.title_Uoc_1) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_1;
          trainingDetails.titleName = 'title_Uoc_1';
          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_2) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_2;
          trainingDetails.titleName = 'title_Uoc_2';
          console.log('obj 2', trainingDetails);

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_3) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_3;
          trainingDetails.titleName = 'title_Uoc_3';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_4) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_4;
          trainingDetails.titleName = 'title_Uoc_4';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_5) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_5;
          trainingDetails.titleName = 'title_Uoc_5';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_6) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_6;
          trainingDetails.titleName = 'title_Uoc_6';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_7) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_7;
          trainingDetails.titleName = 'title_Uoc_7';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_8) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_8;
          trainingDetails.titleName = 'title_Uoc_8';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_9) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_9;
          trainingDetails.titleName = 'title_Uoc_9';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_10) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_10;
          trainingDetails.titleName = 'title_Uoc_10';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_11) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_11;
          trainingDetails.titleName = 'title_Uoc_11';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_12) {
          let trainingDetails = new TciTrainingDetailsPayload();

          trainingDetails.trainingName = item.title_Uoc_12;
          trainingDetails.titleName = 'title_Uoc_12';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_13) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_13;
          trainingDetails.titleName = 'title_Uoc_13';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_14) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_14;
          trainingDetails.titleName = 'title_Uoc_14';

          trainingDetailsList.push(trainingDetails);
        }

        if (item.title_Uoc_15) {
          let trainingDetails = new TciTrainingDetailsPayload();
          trainingDetails.trainingName = item.title_Uoc_15;
          trainingDetails.titleName = 'title_Uoc_15';

          trainingDetailsList.push(trainingDetails);
        }

        tciInfo.tciTrainingDetailsPayload = trainingDetailsList;

        let existItem = this.excelData.filter(
          (item) => item.Registration_Number == tciInfo.registrationNo
        );
        if (existItem.length > 1) {
          this.notification.error(
            'Failed',
            'Duplicate data found for registration no. in Excel, Please check & try again!'
          );
          this.myInputVariable.nativeElement.value = '';
          return;
        }

        tciInfoList.push(tciInfo);
        console.log('tciInfoList: ', tciInfoList);

        // const existItem = tciInfoList.filter(
        //   (item) => item.itemName == tciInfo.itemName
        // );

        // for (let item of cadOtherRequirementList) {
        //   let msg = item.itemName;
        //   // @ts-ignore
        //   if (isNaN(item.unit)) {
        //     this.notification.error(      for (const item of this.excelData) {

        //       'Failed',
        //       `Invalid unit for other requirement item name:  ${msg}, Please correct and reupload!`
        //     );
        //     this.myInputVariable.nativeElement.value = '';
        //     return;
        //   }
        // }

        // if (existItem.length > 1) {
        //   this.notification.error(
        //     'Failed',
        //     'Duplicate item name found in Excel, Please check!'
        //   );
        //   this.myInputVariable.nativeElement.value = '';
        //   return;
        // }
      }
    }

    //#region Excel duplication value check

    // let existItem = this.excelData.filter(
    //   (item) => item.Contact == tciInfo.contact || item.Email_ID == tciInfo.email
    // );
    // if (existItem.length > 1) {
    //   this.notification.error(
    //     'Failed',
    //     'Duplicate Data found for phone number and email in Excel, Please check!'
    //   );
    //   this.myInputVariable.nativeElement.value = '';
    //   return;
    // }
    this.tciInfoStorageService.saveExcelTciInfoData(tciInfoList).subscribe({
      next: (response) => {
        this.notification.success('Success!', 'Successfully uploaded excel information');
        this.tciInfoService.tciInfoSaveClickEvent();
      },
      // error: (error) =>
      //   this.notification.error(
      //     'Failed',
      //     'Please! provide valid format and information'
      //   ),
      error: (error) => {
        this.notification.error('Failed', error.error.message);
      },
    });

    this.myInputVariable.nativeElement.value = '';
    return;
  }


  // downloadFiles(filePath: string){
  //   this.commonStorageService.downloadFile(filePath).subscribe(
  //     (data: any) => {
  //       var fileName = 'report.pdf';
  //       const contentDisposition = data.headers.get('Content-Disposition');
  //       if (contentDisposition) {
  //         const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //         const matches = fileNameRegex.exec(contentDisposition);
  //         if (matches != null && matches[1]) {
  //           fileName = matches[1].replace(/['"]/g, '');
  //         }
  //       }
  //       saveAs(data.body, fileName);
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

}
