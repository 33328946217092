import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { applicationUrls } from 'src/app/shared/application-constants/application-urls.const';
import { ServerResponse } from 'src/app/shared/models/dto/server-response.dto';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { SearchTamOccupationInfo } from '../models/search-tam-occupation-list-info.model';
import { TamIndustrialSector } from '../models/tam-Industrial-sector.model';
import { TamLanguage } from '../models/tam-language.model';
import { TamLevel } from '../models/tam-level.model';
import { TamMainSearch } from '../models/tam-main-search.model';
import { TamMain } from '../models/tam-main.model';
import { TamOccupation } from '../models/tam-occupation.model';
import { TamType } from '../models/tam-type.model';
import { TrainingAndAssessmentMaterialsService } from './training-and-assessment-materials.service';

@Injectable({
  providedIn: 'root',
})
export class TrainingAndAssessmentMaterialsStorageService {
  constructor(
    private trainingAndAssessmentMaterialsService: TrainingAndAssessmentMaterialsService,
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler
  ) {}

  //#region TAM_MAIN
  readTamMain(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamMain.read}`)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.trainingAndAssessmentMaterialsService.setTamMains(
              response.data
            );
          }
        })
      );
  }

  readTamMains(occupationName: string, tamType?: number): Observable<ServerResponse> {
    let url = applicationUrls.tamMain.readTamMains;
    if (occupationName && tamType) {
      url += '?occupationName=' + occupationName + '&tamType=' + tamType;
    } else if (occupationName) {
      url += '?occupationName=' + occupationName;
    } else if (tamType) {
      url += '?tamType=' + tamType;
    }
    return this.httpClient.get<ServerResponse>(url).pipe(
      map((serverResponse: ServerResponse) => {
        return serverResponse;
      })
    );
  }

  readTamOccupationsandLevels(): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(
        `${applicationUrls.tamMain.getAllTamOccupationsAndLevels}`
      )
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }

  searchTamMain(tamMainSearch: TamMainSearch): Observable<ServerResponse> {
    tamMainSearch.disabled = false;
    return this.httpClient
      .post<ServerResponse>(applicationUrls.tamMain.search, tamMainSearch)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.trainingAndAssessmentMaterialsService.setTamMains(
              response.data
            );
          }
        })
      );
  }

  searchTamMainWithoutPagination(tamMainSearch: TamMainSearch): Observable<ServerResponse> {
    tamMainSearch.disabled = false;
    return this.httpClient
      .post<ServerResponse>(applicationUrls.tamMain.searchWithoutPagination, tamMainSearch)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.trainingAndAssessmentMaterialsService.setTamMains(
              response.data
            );
          }
        })
      );
  }

  readTamArchiveInfo(tamMainSearch: TamMainSearch): Observable<ServerResponse> {
    tamMainSearch.disabled = false;
    return this.httpClient
      .post<ServerResponse>(applicationUrls.tamMain.readTamArchiveInfo, tamMainSearch)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.trainingAndAssessmentMaterialsService.setTamMains(
              response.data
            );
          }
        })
      );
  }

  // onDownloadTamMainReport(tamMainSearch: TamMainSearch): Observable<ServerResponse> {
  //   tamMainSearch.disabled = true;
  //   return this.httpClient
  //   .post<ServerResponse>(applicationUrls.tamMain.search, tamMainSearch)
  //   .pipe(
  //     tap((response) => {
  //       if (response.data) {
  //         this.trainingAndAssessmentMaterialsService.setTamMains(
  //           response.data
  //         );
  //       }
  //     })
  //   );
  // }

  onDownloadTamMainReport(tamMainSearch: TamMainSearch): Observable<ServerResponse> {
    tamMainSearch.disabled = true;
    return this.httpClient
    .post<ServerResponse>(applicationUrls.tamMain.getDownloadTamMainReport, tamMainSearch)
    .pipe(
      tap((response) => {
        if (response.data) {
          this.trainingAndAssessmentMaterialsService.setTamMains(
            response.data
          );
        }
      })
    );
  }

  onDownloadTamMainArchivedInfo(tamMainSearch: TamMainSearch): Observable<ServerResponse> {
    tamMainSearch.disabled = true;
    return this.httpClient
    .post<ServerResponse>(applicationUrls.tamMain.downloadTamMainArchivedInfo, tamMainSearch)
    .pipe(
      tap((response) => {
        if (response.data) {
          this.trainingAndAssessmentMaterialsService.setTamMains(
            response.data
          );
        }
      })
    );
  }


  readTamMainById(id: number): Observable<any> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamMain.readById}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          if (!serverResponse.data) {
            return null;
          }
          return serverResponse;
        })
      );
  }

  createTamMain(tamMain: FormData): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(`${applicationUrls.tamMain.create}`, tamMain)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        }),
        tap((response) => {
          if (response.data) {
            this.trainingAndAssessmentMaterialsService.addTamMain(
              response.data
            );
          }
        })
      );
  }

  deleteTamMain(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.tamMain.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedTamMain) => {
          this.trainingAndAssessmentMaterialsService.deleteTamMain(id);
        })
      );
  }

  updateTamMain(
    tamMain: FormData,
    id: number | null
  ): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(`${applicationUrls.tamMain.update}` + id, tamMain)
      .pipe(
        tap((response) => {
          if (response.data) {
            this.trainingAndAssessmentMaterialsService.upatedTamMain(
              response.data,
              response.data.id
            );
          }
        })
      );
  }
  //#endregion TAM_MAIN

  //#region TAM_OCCUPATION
  // showTamOccupations(): Observable<TamOccupation[]> {
  //   return this.httpClient
  //     .get<ServerResponse>(`${applicationUrls.tamOccupation.show}`)
  //     .pipe(
  //       // catchError(this.handleError('Read TamOccupation', [])),
  //       map((serverResponse: ServerResponse) => {
  //         console.log(serverResponse.data);
  //         return serverResponse.data;
  //       }),
  //       tap((tamOccupation: TamOccupation[]) => {
  //         this.trainingAndAssessmentMaterialsService.setTamOccupations(
  //           tamOccupation
  //         );
  //       })
  //     );
  // }
  readTamOccupations(): Observable<TamOccupation[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamOccupation.read}`)
      .pipe(
        // catchError(this.handleError('Read TamOccupation', [])),
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((tamOccupation: TamOccupation[]) => {
          this.trainingAndAssessmentMaterialsService.setTamOccupations(
            tamOccupation
          );
        })
      );
  }

  readTamOccupationById(id: number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(
      `${applicationUrls.tamOccupation.readById}` + id
    );
  }

  createTamOccupation(tamOccupation: TamOccupation): Observable<any> {
    return this.httpClient
      .post<ServerResponse>(
        `${applicationUrls.tamOccupation.create}`,
        tamOccupation
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.trainingAndAssessmentMaterialsService.addTamOccupation(
              res.data
            );
          }
        })
      );
  }

  deleteTamOccupation(id: number): Observable<ServerResponse> {
    return this.httpClient
      .delete<ServerResponse>(`${applicationUrls.tamOccupation.delete}` + id)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((updatedRole) => {
          this.trainingAndAssessmentMaterialsService.deleteTamOccupation(id);
        })
      );
  }

  updateTamOccupation(
    tamOccupation: TamOccupation
  ): Observable<ServerResponse> {
    return this.httpClient
      .put<ServerResponse>(
        `${applicationUrls.tamOccupation.update}` + tamOccupation.id,
        tamOccupation
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.trainingAndAssessmentMaterialsService.upatedTamOccupation(
              res.data,
              res.data.id
            );
          }
        })
      );
  }
  //#endregion TAM_OCCUPATION

  //#region TAM_TYPE
  readTamTypes(): Observable<TamType[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamType.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((tamType: TamType[]) => {
          this.trainingAndAssessmentMaterialsService.setTamTypes(tamType);
        })
      );
  }
  //#endregion TAM_TYPE

  //#region TAM_LEVEL
  readTamLevels(): Observable<TamLevel[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamLevel.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((tamLevel: TamLevel[]) => {
          this.trainingAndAssessmentMaterialsService.setTamLevels(tamLevel);
        })
      );
  }
  //#endregion TAM_LEVEL

  //#region TAM_LANGUAGE
  readTamLanguages(): Observable<TamLanguage[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamLanguage.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((tamLanguage: TamLanguage[]) => {
          this.trainingAndAssessmentMaterialsService.setTamLanguages(
            tamLanguage
          );
        })
      );
  }
  //#endregion TAM_LANGUAGE

  searchTamOccupationInfo(searchTamOccupationInfo: SearchTamOccupationInfo): Observable<ServerResponse> {
    return this.httpClient
      .post<ServerResponse>(applicationUrls.tamOccupation.tamOccupationSearchList, searchTamOccupationInfo)
      .pipe(
        tap((response) => {
          if (response.data) {
            console.log("response.data->", response.data);
            this.trainingAndAssessmentMaterialsService.setTamOccupations(
              response.data
            );
          }
        })
      );
  }


  //#region TAM_IndustrialSector
  readTamIndustrialSectors(): Observable<TamIndustrialSector[]> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.tamIndustrialSector.read}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse.data;
        }),
        tap((tamIndustrialSector: TamIndustrialSector[]) => {
          this.trainingAndAssessmentMaterialsService.setTamIndustrialSectors(
            tamIndustrialSector
          );
        })
      );
  }

  searchExcelTamOcupationUserInfo(searchTamOccupationInfo: SearchTamOccupationInfo): Observable<ServerResponse> {
    searchTamOccupationInfo.disabled = true;
    return this.httpClient
      .post<ServerResponse>(applicationUrls.tamOccupation.tamOccupationSearchList, searchTamOccupationInfo)
      .pipe(
        tap((response) => {
          if (response.data) {
            console.log("response.data->", response.data);
            this.trainingAndAssessmentMaterialsService.setTamOccupations(
              response.data
            );
          }
        })
      );
  }
  //#endregion TAM_IndustrialSector


  getCandidateInfoByCandidateId(candidateId:number): Observable<ServerResponse> {
    return this.httpClient.get<ServerResponse>(`${applicationUrls.RPLBatch.getCandidateInfoByCandidateId}`+ candidateId)
  }

  onDownloadApprvdOccReportUsingJasper(
    id: any): Observable<ServerResponse> {
    return this.httpClient
      .get<ServerResponse>(`${applicationUrls.jasper.onDownloadMApprvdOccReport}/${id}`)
      .pipe(
        map((serverResponse: ServerResponse) => {
          return serverResponse;
        })
      );
  }
}
